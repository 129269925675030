<template>
    <div>
        <div
            data-simplebar
            style="max-height: calc(70vh - 68px); margin-left: .75rem;"
        >
            <table>
                <div>
                    <div>
                        <thead>
                            <tr
                                style="height: auto; vertical-align: middle;"
                            >
                                <th
                                    class="columnTimeAlle"
                                >
                                    <img
                                        :src="clockIcon"
                                        alt="Uhr"
                                        style="height: 20px;"
                                    >
                                </th>
                                <div
                                    v-if="currentGroup.name !== 'Alle'"
                                    style="height: 100%;"
                                >
                                    <div
                                        class="d-flex flex-row"
                                    >
                                        <th
                                            v-for="(dayString, index) in dayStrings"
                                            :key="`dailyHeaderInWeek${dayString}${index}`"
                                            class="centerHeader columnDay"
                                            :class="`${dayString === 'tue' || dayString === 'thu' ? 'greyDayBody' : 'whiteDayBody'}`"
                                            style="height: 100%; border-radius: 5px 5px 0 0"
                                        >
                                            <div
                                                :class="`${isDisplayDateTodayShort(nicelyFormattedWeekDayDate(dayString)) ?
                                                    'colHeaderCurrent' : 'colHeader'} text-center`"
                                                style="width: 100%; padding-top: 5px"
                                            >
                                                {{ nicelyFormattedWeekDayDate(dayString) }}
                                                <v-tooltip
                                                    v-if="sickMode && editMode"
                                                    bottom
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            class="ml-2 pa-0"
                                                            small
                                                            style="height: 36px; width: 36px; border-radius: 12px"
                                                            min-width="29px"
                                                            :style="`background-color: ${sickAtWholeDay(dayString) ? 'var(--v-error-base)' : 'white'}`"
                                                            v-on="on"
                                                            @click="handleDayStringClick(dayString)"
                                                        >
                                                            <img
                                                                v-if="!sickAtWholeDay(dayString)"
                                                                :src="anwesendIcon"
                                                                alt="Für den ganzen Tag Abwesend melden"
                                                                style="height: 18px"
                                                            >
                                                            <img
                                                                v-else
                                                                :src="abwesendIcon"
                                                                alt="Abwesenheit für diesen Tag zurücknehmen"
                                                                class="iconToWhite"
                                                                style="height: 18px"
                                                            >
                                                        </v-btn>
                                                    </template>
                                                    <span>
                                                        {{
                                                            sickAtWholeDay(dayString) ?
                                                                'Abwesenheit für diesen Tag zurücknehmen' : 'Für den ganzen Tag Abwesend melden'
                                                        }}
                                                    </span>
                                                </v-tooltip>
                                            </div>
                                        </th>
                                    </div>
                                </div>
                                <div
                                    v-else
                                >
                                    <th
                                        v-for="(group, index) in groups"
                                        :key="`${group._id}${index}`"
                                        class="columnDayAlle px-2"
                                        :class="index % 2 === 0 ? 'whiteDayBody' : 'greyDayBody'"
                                    >
                                        <div
                                            class="d-flex flex-row justify-space-between"
                                        >
                                            {{ group.name }}
                                            <div class="d-flex flex-row justify-space-around">
                                                <Avatar
                                                    :profile="(group.leaders[0] ? teachersById[group.leaders[0]._id || group.leaders[0]] : {}).account"
                                                    :size="25"
                                                    class="mr-1"
                                                />
                                                {{ getLeaderPictureGroup(group._id) ? getDisplayName(getLeaderPictureGroup(group._id).leaders[0]): 'Kein Lehrender' }}
                                            </div>
                                        </div>
                                    </th>
                                </div>
                            </tr>
                        </thead>
                        <tr
                            v-for="(timeslot) in slots"
                            :key="timeslot._id"
                            style="display: flex; flex-direction: row; width: 100%; align-items: center;"
                        >
                            <!--#region Timeslots -->
                            <td
                                style="color: var(--v-dunkelgrau-base); font-weight: normal;"
                                class="d-flex align-center justify-center columnTimeAlle"
                            >
                                <div>
                                    {{ ("0" + timeslot.start.getHours()).slice(-2) + ":" + ("0" + timeslot.start.getMinutes()).slice(-2) }}
                                    <br>
                                    {{ ("0" + timeslot.end.getHours()).slice(-2) + ":" + ("0" + timeslot.end.getMinutes()).slice(-2) }}
                                </div>
                            </td>
                            <!--#endregion -->

                            <!--#region Days -->
                            <div
                                v-if="currentGroup.name !== 'Alle'"
                                class="d-flex flex-row"
                            >
                                <!-- can't rename index in v-for -->
                                <td
                                    v-for="(dayString) in dayStrings"
                                    :key="`dayInWeek${dayString}`"
                                    class="d-flex columnDay"
                                    :class="`${dayString === 'tue' || dayString === 'thu' ? 'greyDayBody' : ''}`"
                                >
                                    <div
                                        style="width: 100%"
                                    >
                                        <div
                                            v-for="appointment in getAppointmentsPerDay(timeslot._id, dayString)"
                                            :key="appointment._id + dayString + refreshKey"
                                            style="width: 100%"
                                        >
                                            <Fach
                                                :ref="`substitutionAppointment${appointment._id}`"
                                                class="py-0"
                                                :is-running="isTimeslotCurrentlyRunning(timeslot, dayString)"
                                                :appointment="appointment"
                                                :timeslot="timeslot"
                                                :request-appointments="requestAppointments"
                                                :slots="slots"
                                                :me="mePupil"
                                                :parent="accountRole === 'parent'"
                                                :edit-mode="editMode"
                                                :fullscreen-weekly-view="true"
                                                :subjects="subjects"
                                                :teachers="teachers"
                                                :current-group="currentGroup"
                                                :day="getDateFromWeekday(dayString)"
                                                :copy-mode="copyMode"
                                                :current-copied-appointment="currentCopiedAppointment"
                                                :icons="icons"
                                                :substitute-plan="substitutePlan"
                                                :get-work-day-of-week="getWorkDaysOfWeek"
                                                :all-appointments="allAppointments"
                                                :date="date"
                                                :person="person"
                                                :sick-mode="true"
                                                :sick-appointments="sickAppointments"
                                                :remove-sick-appointments="removeSickAppointments"
                                                @pass-on-bbb-tut="receivedEmit"
                                                @update-me="requestAppointments"
                                                @setCopyLesson="setCopyLesson"
                                                @stopCopyMode="stopCopyMode"
                                                @sickClick="handleSickClick"
                                                @undoSick="handleUndoSick"
                                            />
                                        </div>
                                        <div
                                            v-if="!getAppointmentsPerDay(timeslot._id, dayString).length"
                                            style="width: 100%"
                                        >
                                            <Fach
                                                :is-running="isTimeslotCurrentlyRunning(timeslot, dayString)"
                                                :request-appointments="requestAppointments"
                                                :edit-mode="editMode"
                                                :fullscreen-weekly-view="true"
                                                :subjects="subjects"
                                                :teachers="teachers"
                                                :current-group="currentGroup"
                                                :timeslot="timeslot"
                                                :copy-mode="copyMode"
                                                :icons="icons"
                                                :get-work-day-of-week="getWorkDaysOfWeek"
                                                :all-appointments="allAppointments"
                                                :date="date"
                                                :person="person"
                                                :current-copied-appointment="currentCopiedAppointment"
                                                :day="getDateFromWeekday(dayString)"
                                                :substitute-plan="substitutePlan"
                                                :sick-mode="true"
                                                :sick-appointments="sickAppointments"
                                                :remove-sick-appointments="removeSickAppointments"
                                                @pass-on-bbb-tut="receivedEmit"
                                                @update-me="requestAppointments"
                                                @sickClick="handleSickClick"
                                                @undoSick="handleUndoSick"
                                            />
                                        </div>
                                    </div>
                                </td>
                            </div>
                            <!-- can't rename index in v-for -->
                            <div
                                v-else
                                class="d-flex flex-row"
                            >
                                <td
                                    v-for="(group, index) in groups"
                                    :key="group._id"
                                    class="d-flex columnDayAlle"
                                    :class="index % 2 === 0 ? 'whiteDayBody' : 'greyDayBody'"
                                >
                                    <div
                                        :style="index !== Object.keys(slots).length - 1 ? '' : 'border-radius: 0 0 10px 10px'"
                                        style="width: 100%"
                                    >
                                        <div
                                            v-for="appointment in getAppointmentsByGroup(timeslot._id, group._id)"
                                            :key="appointment._id + refreshKey"
                                            :class="index % 2 === 0 ? 'whiteDayBody' : 'greyDayBody'"
                                            style="width: 100%"
                                        >
                                            <Fach
                                                :ref="`substitutionAppointment${appointment._id}`"
                                                class="py-0"
                                                :appointment="appointment"
                                                :timeslot="timeslot"
                                                :request-appointments="requestAppointments"
                                                :slots="slots"
                                                :me="mePupil"
                                                :parent="accountRole === 'parent'"
                                                :edit-mode="editMode"
                                                :fullscreen-weekly-view="true"
                                                :subjects="subjects"
                                                :teachers="teachers"
                                                :current-group="currentGroup"
                                                :day="null"
                                                :copy-mode="copyMode"
                                                :current-copied-appointment="currentCopiedAppointment"
                                                :icons="icons"
                                                :substitute-plan="substitutePlan"
                                                :get-work-day-of-week="getWorkDaysOfWeek"
                                                :all-appointments="allAppointments"
                                                :date="date"
                                                :person="person"
                                                :sick-mode="true"
                                                :sick-appointments="sickAppointments"
                                                :remove-sick-appointments="removeSickAppointments"
                                                @pass-on-bbb-tut="receivedEmit"
                                                @update-me="requestAppointments"
                                                @setCopyLesson="setCopyLesson"
                                                @stopCopyMode="stopCopyMode"
                                                @sickClick="handleSickClick"
                                                @undoSick="handleUndoSick"
                                            />
                                        </div>
                                        <div
                                            v-if="!getAppointmentsByGroup(timeslot._id, group._id).length"
                                            style="width: 100%"
                                        >
                                            <Fach
                                                :request-appointments="requestAppointments"
                                                :edit-mode="editMode"
                                                :fullscreen-weekly-view="true"
                                                :subjects="subjects"
                                                :teachers="teachers"
                                                :current-group="currentGroup"
                                                :timeslot="timeslot"
                                                :copy-mode="copyMode"
                                                :current-copied-appointment="currentCopiedAppointment"
                                                :day="null"
                                                :icons="icons"
                                                :substitute-plan="substitutePlan"
                                                :all-appointments="allAppointments"
                                                :date="date"
                                                :person="person"
                                                :sick-mode="true"
                                                :sick-appointments="sickAppointments"
                                                :remove-sick-appointments="removeSickAppointments"
                                                @pass-on-bbb-tut="receivedEmit"
                                                @update-me="requestAppointments"
                                                @sickClick="handleSickClick"
                                                @undoSick="handleUndoSick"
                                            />
                                        </div>
                                    </div>
                                </td>
                            </div>
                            <!--#endregion -->
                        </tr>
                    </div>
                </div>
            </table>
        </div>
        <div
            class="d-flex flex-row align-center"
            style="margin-left: 12px; margin-bottom: 0px; margin-top: 5px;"
        >
            <h1 
                style="color: red; "
            >
                Lehrer abwesend melden
            </h1>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <img
                        :src="leftArrow"
                        class="icon20 iconToDunkelgrau"
                        style="cursor: pointer; "
                        alt="scrollLinks"
                        v-bind="attrs"
                        v-on="on"
                        @click="scrollLeft"
                    >
                </template>
                <span>Nach links verschieben</span>
            </v-tooltip>
            <v-spacer />
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <img
                        :src="leftArrow"
                        class="icon20 iconToDunkelgrau mr-2"
                        style="cursor: pointer;transform:rotate(180deg)"
                        alt="scrollRight"
                        v-bind="attrs"
                        v-on="on"
                        @click="scrollRight"
                    >
                </template>
                <span>Nach rechts verschieben</span>
            </v-tooltip>
        </div>
        <div
            id="vertretung-scroll"
            data-simplebar
            style="max-height: auto; min-height: 15vh; width: 100%; margin-left: 12px"
        >
            <VertretungsplanTeacherList
                :teachers="teachers"
                :subjects="subjects"
                :date="date"
                :appointments="allAppointments"
                :icons="icons"
                :appointment="null"
                :request-appointments="requestAppointments"
            />
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";

import Fach from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/Fach";
import vueCustomScrollbar from "vue-custom-scrollbar";
import clockIcon from '@/assets/Icons/FaceliftIcons/stundenplan_uhr.svg'
import abwesendIcon from "@/assets/Icons/FaceliftIcons/lehrer_abwesend.svg";
import anwesendIcon from "@/assets/Icons/FaceliftIcons/lehrer.svg";
import { getWeekDayDate, getDayDateLocaleString } from "@/util/timetable";
import AreYouSurePopup from "@/components/Utils/AreYouSurePopup";
import Timepicker from "@/components/Utils/Timepicker";
import VertretungsplanTeacherList from "@/components/SharedViews/Vertretungsplan/VertretungsplanTeacherList";
import Avatar from "@/components/SharedViews/Profile/Avatar";
import leftArrow from '@/assets/Icons/FaceliftIcons/zurueck_pfeil.svg';

export default {
    components: {
        Avatar,
        VertretungsplanTeacherList,
        Fach,
    },
    props: {
        allAppointments: { required: true, type: Array },
        editMode: { required: false, type: Boolean },
        currentGroup: {required: false},
        date: {required: false},
        subjects: {required: true},
        slots: {required: true},
        teachers: {required: true},
        groups: {required: true},
        icons: { type: Array, required: false},
        showLoadingWeeklyView: { type: Boolean, required: false },
        requestAppointments: {type: Function},
        requestSlots: {type: Function},
        getWorkDaysOfWeek: {type: Function},
        datesOnSameDay: {type: Function},
        substitutePlan: {type: Boolean},
        person: {type: Object},
        sickAppointments: { type: Array, required: false},
        removeSickAppointments: { type: Array, required: false},
        markOrUnmarkAppointmentAsSick: {type: Function, required: false},
        markAppointmentToRemoveFromSickList: {type: Function, required: false},
    },
    data: () => ({
        leftArrow,
        sickMode: false,

        groupAppointments: [],

        settings: {
            suppressScrollY: false,
            suppressScrollX: false,
            wheelPropagation: false,
            //left out keyboard
            handlers: ['click-rail', 'drag-thumb', 'wheel', 'touch']
        },
        refreshKey: false,
        today: new Date(),
        appointmentsThisWeek: [],
        localOpen: false,
        dayStrings: ['mon', 'tue', 'wed', 'thu', 'fri'],

        mePupil: null,
        teacher: null,

        // Copy Feature
        copyMode: false,
        currentCopiedAppointment: null,

        // Timeslots
        createTimeslotDialog: false,
        areYouSureDeleteDialog: false,
        areYouSureText: 'Dieser Zeitslot und alle Stunden, die in diesem Zeitslot stattfinden/stattgefunden haben, werden mit ihren Inhalten gelöscht.',
        newTimeslotTimePickerStart: '',
        newTimeslotTimePickerEnd: '',
        timeslotToDelete: null,

        // Icons
        clockIcon,
        anwesendIcon,
        abwesendIcon,
        displayedGroups: [],
    }),
    computed: {
        ...mapGetters("auth",["accountRole"]),
        ...mapGetters("teachers",["teachersById"]),
        ...mapGetters("accounts",["accountsById"]),

        teacherSlotsInWeek() {
            let slots = [];
            const daysOfWeek = this.getWorkDaysOfWeek(this.date);
            const filteredSlotsByDay = this.slots.filter((slot) => {
                return daysOfWeek.some((day) => {
                    return slot.day ? this.datesOnSameDay(day, new Date(slot.day)) : !!slot;
                });
            })
            filteredSlotsByDay.forEach((slotsByDay) => {
                slots = slots.concat(slotsByDay.timeslots ? slotsByDay.timeslots : slotsByDay);
            });
            slots = slots.filter((v,i,a)=>a.findIndex(v2=>(v2._id===v._id))===i);
            slots.sort((a, b) => (a.start.getHours() * 60 + a.start.getMinutes())
                - (b.start.getHours() * 60 + b.start.getMinutes()));
            slots.forEach((slot) => {
                slot.timePickerStart = ('0' + slot.start.getHours()).slice(-2) + ':' + ('0' + slot.start.getMinutes()).slice(-2);
                slot.timePickerEnd = ('0' + slot.end.getHours()).slice(-2) + ':' + ('0' + slot.end.getMinutes()).slice(-2);
            });
            return slots;
        },

        canEdit() {
            return this.currentGroup !== null && this.currentGroup !== undefined;
        },
        nicelyFormattedWeekDayDate() {
            return (dayString) => {
                let shortDay = 'Mo., ';
                switch (dayString) {
                    case 'mon': shortDay = 'Mo., '; break;
                    case 'tue': shortDay = 'Di., '; break;
                    case 'wed': shortDay = 'Mi., '; break;
                    case 'thu': shortDay = 'Do., '; break;
                    case 'fri': shortDay = 'Fr., '; break;
                }

                const weekDayDate = this.getWeekDayDateWrapper(dayString, this.date);
                return shortDay + getDayDateLocaleString(weekDayDate);
            }
        },
    },
    watch: {
        editMode() {
            if(this.editMode === false) {
                this.stopCopyMode();
            } else if(this.accountRole === 'teacher' && !this.slots.some((slot) => slot.timePickerStart)) {
                this.slots.forEach((slot) => {
                    slot.timePickerStart = ('0' + slot.start.getHours()).slice(-2) + ':' + ('0' + slot.start.getMinutes()).slice(-2);
                    slot.timePickerEnd = ('0' + slot.end.getHours()).slice(-2) + ':' + ('0' + slot.end.getMinutes()).slice(-2);
                });
            }
        },
        currentGroup() {
            this.filterAppointments();
        },
        date() {
            this.filterAppointments();
        },
        allAppointments() {
            this.filterAppointments();
        },

    },
    async mounted() {
        this.localOpen = true;
        if(this.accountRole === "teacher") {
            await this.requestTeacher();
        } else if (this.accountRole === 'pupil') {
            this.mePupil = this.getMePupil();
        // } else if(this.accountRole === 'maintainer') {
        //     this.slots.forEach((slot) => {
        //         slot.timePickerStart = ('0' + slot.start.getHours()).slice(-2) + ':' + ('0' + slot.start.getMinutes()).slice(-2);
        //         slot.timePickerEnd = ('0' + slot.end.getHours()).slice(-2) + ':' + ('0' + slot.end.getMinutes()).slice(-2);
        //     });
        }
        if(this.setEditMode === true) {
            if(this.canEdit) {
                this.setEditMode(true);
            } else {
                this.showSnackbar({ message: 'Sie sind kein Klassenlehrer. Nur Klassenlehrer können die Stundenpläne ihrer Klasse bearbeiten.', color: 'error', timeout: 6500 });
            }
            this.$emit("resetEditModeChanger");
        }
        this.filterAppointments();
        // this.groupAppointments = this.allAppointments;
        // this.appointmentsThisWeek = this.groupAppointments.filter((el) => {
        //     const dayOfAppointment = new Date(el.day);
        //     return dayOfAppointment.getWeek() === this.date.getWeek()
        //         && dayOfAppointment.getFullYear() === this.date.getFullYear();
        // });
        //needed to populate Leaders because we need profilePicture and name
        this.displayedGroups = await this.setDisplayedGroups();
    },
    methods: {
        ...mapActions("appointments", ["getAppointments", "getAppointmentsParent", "getAppointmentRuleById"]),
        ...mapMutations('weeklyView', ['setEditMode']),
        ...mapActions("timeslots", ["getSlots", "editSlot", "createSlot", "deleteSlot"]),
        ...mapActions("teachers", ["getMeTeacher",'getProfilePicture']),
        ...mapActions("copypaste", ["turnOffCopyMode"]),
        ...mapActions('pupils', ['getMePupil']),
        ...mapMutations("snackbar", ["showSnackbar"]),
        scrollLeft(){
            const scrollbar = document.getElementById("vertretung-scroll").getElementsByClassName('simplebar-content-wrapper')[0];
            scrollbar.scrollLeft -= 100;
        },
        scrollRight(){
            const scrollbar = document.getElementById("vertretung-scroll").getElementsByClassName('simplebar-content-wrapper')[0];
            scrollbar.scrollLeft += 100;
        },
        filterAppointments() {
            if(this.currentGroup.name === 'Alle') {
                this.groupAppointments = this.allAppointments;
            } else {
                this.groupAppointments = this.allAppointments.filter((appointment) => (this.accountRole === 'maintainer' ? appointment.group : appointment.group._id) === this.currentGroup._id);
            }
            this.appointmentsThisWeek = this.groupAppointments.filter((el) => {
                const dayOfAppointment = new Date(el.day);
                return dayOfAppointment.getWeek() === this.date.getWeek()
                    && dayOfAppointment.getFullYear() === this.date.getFullYear();
            });
        },

        getTeacherIcon(teacherObj){
            const teacher = teacherObj;
            // return profile icon or take standard icon
            const teacherAcc = this.accountsById[teacher.account];
            const iconObject = { color: teacherAcc.profileColor ? teacherAcc.profileColor : '#ff6633', icon : teacherAcc.profilePicture ? 'mdi-' + teacherAcc.profilePicture : 'mdi-emoticon-happy-outline' }
            return iconObject;
        },
        getDisplayName(teacher){
            return teacher.name[0] + ' ' + teacher.lastName;
        },

        setNewTimeslotTimePickerStart(time) {
            this.newTimeslotTimePickerStart = time;
        },

        setNewTimeslotTimePickerEnd(time) {
            this.newTimeslotTimePickerEnd = time;
        },

        openCreateTimeslot(timeslot) {
            this.newTimeslotTimePickerStart = timeslot.timePickerStart;
            this.newTimeslotTimePickerEnd = timeslot.timePickerEnd;
            this.createTimeslotDialog = true;
        },

        resetCreateDialog() {
            this.createTimeslotDialog = false;
        },

        async createTimeslot() {
            const start = new Date();
            const end = new Date();
            start.setHours(parseInt(this.newTimeslotTimePickerStart.slice(0, 3)), parseInt(this.newTimeslotTimePickerStart.slice(3, 5)));
            end.setHours(parseInt(this.newTimeslotTimePickerEnd.slice(0, 3)), parseInt(this.newTimeslotTimePickerEnd.slice(3, 5)));
            const res = await this.createSlot({
                start,
                end,
                group: this.currentGroup._id,
            });
            if(typeof res === 'object') {
                this.showSnackbar({ message: 'Gespeichert!'});
            } else {
                this.showSnackbar({ message: 'Etwas ist schiefgelaufen!', color: 'error'});
            }
            await this.requestSlots(true);
            this.resetCreateDialog();
        },

        async deleteTimeslot() {
            await this.deleteSlot({
                id: this.timeslotToDelete._id,
                groupId: this.currentGroup._id,
            });
            await this.requestSlots(true);
            await this.requestAppointments();
            this.areYouSureDeleteDialog = false;
        },

        setTimeslotTime(timeslot, startTime, endTime) {
            let timePickerStart = timeslot.timePickerStart;
            let timePickerEnd = timeslot.timePickerEnd;
            if(startTime) {
                timePickerStart = startTime;
            }
            if(endTime) {
                timePickerEnd = endTime;
            }

            const startTimeMinutes = parseInt(timePickerStart.slice(0, 3)) * 60 + parseInt(timePickerStart.slice(3, 5));
            const endTimeMinutes = parseInt(timePickerEnd.slice(0, 3)) * 60 + parseInt(timePickerEnd.slice(3, 5));
            if(startTimeMinutes >= endTimeMinutes || endTimeMinutes <= startTimeMinutes) {
                this.setAndResetTimeslotTooltip(timeslot, "error");
            } else {
                timeslot.timePickerStart = timePickerStart;
                timeslot.timePickerEnd = timePickerEnd;
                timeslot.start.setHours(parseInt(timeslot.timePickerStart.slice(0, 3)), parseInt(timeslot.timePickerStart.slice(3, 5)));
                timeslot.end.setHours(parseInt(timeslot.timePickerEnd.slice(0, 3)), parseInt(timeslot.timePickerEnd.slice(3, 5)));
                this.setAndResetTimeslotTooltip(timeslot, "success");
            }
        },

        async setAndResetTimeslotTooltip(timeslot, state) {
            if(timeslot.timeout) {
                clearTimeout(timeslot.timeout);
                this.$set(timeslot, "tooltip", "");
                timeslot.timeout = setTimeout(async() => {
                    if(state === "success") {
                        await this.saveTimeslot(timeslot);
                        timeslot = this.slots.find(slot => slot._id === timeslot._id);
                    }
                    this.$set(timeslot, "tooltip", state);
                }, 50);
                timeslot.timeout = setTimeout(() => {
                    this.$set(timeslot, "tooltip", "");
                }, 5050);
            } else {
                if(state === "success") {
                    await this.saveTimeslot(timeslot);
                    // This is neccessary beacause timeslot reference won't exist anymore after we called
                    // this.requestSlots() in this.saveTimeslot()
                    timeslot = this.slots.find(slot => slot._id === timeslot._id);
                }
                this.$set(timeslot, "tooltip", state);
                timeslot.timeout = setTimeout(() => {
                    this.$set(timeslot, "tooltip", "");
                }, 5000);
            }
        },

        async saveTimeslot(timeslot) {
            await this.editSlot({
                _id: timeslot._id,
                group: this.currentGroup._id,
                start: timeslot.start,
                end: timeslot.end,
            });
            await this.requestSlots(true);
            await this.requestAppointments();
        },

        stopCopyMode() {
            this.copyMode = false;
            this.currentCopiedAppointment = null;
        },

        async setCopyLesson(appointment) {
            // Copy Copy Copy hehehe
            const deepCopyOfCopiedAppointment = JSON.parse(JSON.stringify(appointment));
            if(this.currentCopiedAppointment === null) {
                this.copyMode = true;
            }
            // Get rule object from rule id
            if(typeof deepCopyOfCopiedAppointment.rule === "string") {
                deepCopyOfCopiedAppointment.rule = await this.getAppointmentRuleById(deepCopyOfCopiedAppointment.rule);
            }
            this.currentCopiedAppointment = deepCopyOfCopiedAppointment;
        },

        getDateFromWeekday(dayString) {
            const weekDayDate = this.getWeekDayDateWrapper(dayString, this.date);
            return new Date(weekDayDate.getFullYear(), weekDayDate.getMonth(), weekDayDate.getDate());
        },

        openLessonOutside(appointmentDate, appointment) {
            this.$emit("fachClicked", appointment);
        },

        receivedEmit(callback) {
            this.$emit('start-bbb-tut', callback);
        },
        async requestTeacher() {
            this.teacher = await this.getMeTeacher();
        },
        getWeekDayDateWrapper(dayString, date) {
            return getWeekDayDate(dayString, date);
        },
        //WEEKLY VIEW Version | compare displayDate with today, return true if equal, to color current day
        isDisplayDateTodayShort(compareDate) {
            const heute = getDayDateLocaleString(this.today);
            return (compareDate.toString()).slice(5, compareDate.toString().length) === heute;
        },
        getAppointmentsByGroup(timeslotId, groupId) {
            return this.appointmentsThisWeek.filter((appointment) => {
                return this.datesOnSameDay(new Date(appointment.day), this.date) &&
                    (this.accountRole === 'maintainer' ? appointment.group : appointment.group._id) === groupId &&
                    (this.accountRole === 'maintainer' ? appointment.timeslot : appointment.timeslot._id) === timeslotId;
            })
        },
        getAppointmentsPerDay(slot, day) {
            day = getWeekDayDate(day, this.date);
            return this.appointmentsThisWeek.filter((el) => {
                return ((this.accountRole === 'teacher' ? el.timeslot._id : el.timeslot) === slot && this.datesOnSameDay(new Date(el.day), day));
            })
        },
        isTimeslotCurrentlyRunning(timeslot, day) {
            const currentTimeAndDay = this.today;
            const appointment = this.getAppointmentsPerDay(timeslot._id, day)[0];
            if (appointment) {
                const appointmentDate = new Date(appointment.day).toDateString();
                if (appointmentDate === currentTimeAndDay.toDateString()) {
                    // If we are in here that means the appointment is today
                    if (timeslot.start.toLocaleTimeString('de-DE') <= currentTimeAndDay.toLocaleTimeString('de-DE')
                        && timeslot.end.toLocaleTimeString('de-DE') > currentTimeAndDay.toLocaleTimeString('de-DE')) {
                        // Currenttime is between start and end of timeslot
                        return true;
                    }
                }
            }
            return false;
        },
        async loadLeaders(group){
            let leadersWithoutImage = group.leaders.map((leader) => this.teachersById[leader]);
                const leadersWithImage = await Promise.all(leadersWithoutImage.map(async(leader)=>({
                    ...leader,
                    profilePicture: await this.setProfilePicture(leader),
                })));
                this.allTeachers = leadersWithImage;
                return leadersWithImage;
        },
        async setDisplayedGroups(){
            let notSoLeanGroups = this.groups.filter(group => group.name !== 'Alle Klassen');

            const resultingGroups = await notSoLeanGroups.reduce(async (previousPromise, group) => {
                const previous = await previousPromise;
                const loadedLeaders = await this.loadLeaders(group);
                previous.push({
                   ...group,
                   leaders: loadedLeaders
                });
                return previous;
            }, Promise.resolve([]));

            return resultingGroups.sort((a, b) => a.name.localeCompare(b.name));
        },
        async setProfilePicture(user) {
            if (user) {
                if (user.profilePicture) {
                    const profilePictureBlob = await this.getProfilePicture(user._id);
                    return profilePictureBlob;
                } else {
                    // return profile icon or take standard icon
                    const teacherAcc = this.accountsById[user.account];
                    if (teacherAcc && teacherAcc.profilePicture) {
                        const iconObject = {
                            color: teacherAcc.profileColor ? teacherAcc.profileColor : '#ff6633',
                            icon: teacherAcc.profilePicture && teacherAcc.profilePicture.img ? teacherAcc.profilePicture.img
                                : teacherAcc.profilePicture && teacherAcc.profilePicture.text ? 'mdi-' + teacherAcc.profilePicture.text
                                    : 'mdi-emoticon-happy-outline'
                        }

                        return iconObject;
                    }
                }
            }
            return {
                color: '#ff6633',
                icon: 'mdi-emoticon-happy-outline'
            }
        },

        // since group is prop it cant be mutated directly, so we need this method to get
        //ProflePicture of Teacher from the list weher group leaders were muteaded
        getLeaderPictureGroup(groupId) {
            if(this.displayedGroups.length === 0) {
                return null;
            }
            const returnValue = this.displayedGroups.find(group => group._id === groupId);
            if(returnValue && returnValue.leaders && returnValue.leaders.length > 0) {
                return returnValue
            }
            return null;
        },

        async handleSickClick(appointment) {
            if (!appointment) {
                return;
            }
            this.markOrUnmarkAppointmentAsSick(appointment._id);
        },
        async handleUndoSick(appointment) {
            if (!appointment) {
                return;
            }
            this.markAppointmentToRemoveFromSickList(appointment._id);
        },

        sickAtWholeDay(dayString) {
            let foundAppointments = [];
            const array = this.accountRole === 'teacher' && !this.editMode ? this.teacherSlotsInWeek : this.slots;
            array.forEach((slot) => {
                foundAppointments = foundAppointments.concat(this.getAppointmentsPerSlotOnDay(slot._id, dayString));
            });
            foundAppointments = foundAppointments.filter((appointment) => {
                if(appointment.substitutions) {
                    return !appointment.substitutions.some(el => el.sickTeacher._id === this.person._id);
                }
                return true;
            });
            const markedAppointments = foundAppointments.filter((appointment) => this.sickAppointments.includes(appointment._id));
            return markedAppointments.length === foundAppointments.length;
        },

        getAppointmentsPerSlotOnDay(slot, day) {
            day = getWeekDayDate(day, this.date);
            return this.appointmentsThisWeek.filter((el) => {
                return ((this.accountRole === 'teacher' ? el.timeslot._id : el.timeslot) === slot && this.datesOnSameDay(new Date(el.day), day));
            })
        },
        async handleDayStringClick(dayString) {
            if (!this.sickMode) {
                return;
            }

            let foundAppointments = [];
            const array = this.accountRole === 'teacher' && !this.editMode ? this.teacherSlotsInWeek : this.slots
            array.forEach((slot) => {
                foundAppointments = foundAppointments.concat(this.getAppointmentsPerSlotOnDay(slot._id, dayString));
            });
            foundAppointments = foundAppointments.filter((appointment) => {
                if(appointment.substitutions) {
                    return !appointment.substitutions.some(el => el.sickTeacher._id === this.person._id);
                }
                return true;
            });
            this.markOrUnmarkAppointmentAsSick(foundAppointments.map(el => el._id));
        },
    }
}
</script>

<style lang="scss" scoped>
table{
    border-spacing: 0px;
}
table thead{
    position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
    position: sticky;
    top: 0px;
    z-index: 99; // any positive value, layer order is global
    border: none;
}
thead tr{
    height: 40px;
}
tr {
    height: 72px;
    border: none;
}
th {
    border: none;
}
td {
    border: none;
}
h1 {
    display: inherit;
    font-size: inherit;
    margin: inherit;
    font-weight: inherit;
}

h2 {
    display: inherit;
    font-size: inherit;
    margin: inherit;
    font-weight: inherit;
}

.fach {
    cursor: pointer;
}

.iconToBlue {
    filter: invert(61%) sepia(43%) saturate(515%) hue-rotate(175deg)
    brightness(87%) contrast(82%);
}

.badgeEventVisualize {
    animation: badgeAppointmentRipple 1000ms ease-in-out;
}

@keyframes badgeAppointmentRipple {
    0% {
        background-color: rgba(255, 255, 255, 0.0);
    }
    10% {
        background-color: rgba(245, 160, 50, 0.6);
    }
    75% {
        background-color: rgba(245, 160, 50, 0.6);
    }
    100% {
        background-color: rgba(255, 255, 255, 0.0);
    }
}

.planWidgetDisplayDate {
    display: inline-block;
    font-weight: normal;
    font-size: large;
}

.planWidgetTodayButtonActive {
    background-color: var(--v-timetableColor-base) !important;
    color: white;
    border-radius: 8px;
}

.planWidgetTodayButton {
    background-color: var(--v-hellgrau-base) !important;
    color: var(--v-dunkelgrau-base);
    border-radius: 8px;
    box-shadow: 1px 5px 5px silver;
}

.colHeader {
    color: var(--v-dunkelgrau-base);
    width: 18%;
}

.colHeaderCurrent {
    color: var(--v-timetableColor-base);
    width: 18%;
}

.greyDayHeader {
    background-color: var(--v-hellgrau-base);
    border-radius: 10px 10px 0 0;
}

.greyDayBody {
    background-color: var(--v-hellgrau-base);
}
.whiteDayBody {
    background-color: white;
}

.centerFach {
    padding-left: 1%;
}

.centerHeader {
    display: flex;
    align-content: center;
}

.addOrRemoveButton {
    width: 18px;
    height: 18px;
    opacity: 0.5;
    margin: 3px 8px 3px 0;
}

.errorTimepicker {
    background-color: var(--v-error-base);
    border-radius: 6px;
}

.addOrRemoveButton:hover {
    opacity: 1.0;
}

.columnDay {
    width: 17vw;
    min-width: 235px;
    padding: 0 1%;
}

.columnTime {
    width: 8%;
    background-color: white;
    z-index: 9;
}

.marginToTime {
    margin-left: 8%;
    width: 92%;
}

@media only screen and (max-width: 1850px) {
    .columnDay {
        padding: 0 4px;
    }
    .columnTime {
        min-width: 80px;
        width: 80px;
    }
    .marginToTime {
        margin-left: 80px;
    }
}

.columnDayAlle {
    min-width: 235px;
    padding: 0 4px;
}

@media only screen and (min-width: 1340px) {
    .columnDayAlle {
        width: 17vw;
    }
}

.columnTimeAlle {
    width: 45px;
    background-color: white;
    z-index: 9;
}
.marginToTimeAlle {
    margin-left: 80px;
}
.scrollContainer {
//necessary to set a maximum height to enable scrolling (can also just be height)
height: auto;
max-height: 40vh;
//necessary if we want to disable horizontal scroll
overflow-x: hidden;
}

@media only screen and (min-width: 901px) and (max-width: 1050px) {
    .calWeek {
        display: none;
    }
}

@media only screen and (max-width: 959px) {
    .clockIcon {
        display: none;
    }
}

@media only screen and (max-width: 900px) {
    .clockIcon {
        display: initial;
    }
}

// Don't delete this, this works
:deep(.ps__rail-x) {
    opacity: 1;
    height: 20px;
}

:deep(.ps__thumb-x) {
    opacity: 1;
    height: 14px;
}

:deep(.ps__rail-x:hover>.ps__thumb-x) {
    height: 18px;
    background-color: #999;
}
</style>
