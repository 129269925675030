<template>
    <v-dialog
        :value="showDialog"
        width="400"
        @input="closeDialog"
    >
        <v-card
            class="cardStyle"
            style="height: 80vh; overflow: hidden; border-radius: 16px"
        >
            <v-card-title
                class="d-flex align-center justify-space-between"
                style="height: 56px; background-color: var(--v-chatColor-base)"
            >
                <div>
                    <p
                        v-if="windowWidth > 324"
                        style="color: white"
                    >
                        Chat erstellen
                    </p>
                    <p
                        v-else
                        style="color: white"
                    >
                        Chat erst.
                    </p>
                </div>
                <div>
                    <v-tooltip
                        v-if="!isGroupChatCreatable"
                        bottom
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-if="!isGroupChatCreatable"
                                x-small
                                text
                                min-width="20"
                                v-bind="attrs"
                                style="margin-left: auto"
                                color="white"
                                v-on="on"
                                @click="createGroupChat"
                            >
                                <img
                                    :src="saveIcon"
                                    class="icon20 iconToWhite"
                                    alt="speichern"
                                >
                            </v-btn>
                        </template>
                        <span>Erstellen</span>
                    </v-tooltip>
                    <v-tooltip
                        bottom
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                id="closeButton"
                                x-small
                                text
                                elevation="0"
                                class="pa-0 pl-1"
                                style="padding: 5px"
                                v-bind="attrs"
                                v-on="on"
                                @click="closeDialog"
                            >
                                <img
                                    :src="cancelIcon"
                                    class="icon20 iconToWhite"
                                    alt="schließen"
                                >
                            </v-btn>
                        </template>
                        <span>Schließen</span>
                    </v-tooltip>
                </div>
            </v-card-title>
            <v-card-text
                class="group-list-container"
                style="height: calc(80vh - 55px - 30px)"
            >
                <div
                    class="d-flex flex-row align-center"
                >
                    <icon-picker
                        :current-color="selectedColor"
                        :callback="(color) => { this.selectedColor = color; }"
                        :current-icon="this.selectedIcon"
                        :callback-icon="(icon) => { this.selectedIcon = icon; }"
                        :group-icons="icons"
                        color-picker-usage="createGroupChat"
                        @changeIcon="(icon) => { this.selectedIcon = icon; }"
                    />
                    <div
                        class="d-flex flex-column pl-6"
                        style="width: 307px"
                    >
                        <h1 class="header">
                            Gruppenname<span class="red--text bold">*</span>
                        </h1>
                        <v-text-field
                            v-model="newGroupChatName"
                            filled
                            dense
                            hide-details
                            class="mb-2"
                            style="max-width: 500px"
                        />
                    </div>
                </div>
                <h1 class="header">
                    Teilnehmer hinzufügen (mind. 2)<span class="red--text bold">*</span>
                </h1>
                <div class="d-flex align-center">
                    <v-text-field
                        v-model="searchTreeView"
                        filled
                        dense
                        hide-details
                        clearable
                        style="max-width: 500px"
                    >
                        <template v-slot:prepend-inner>
                            <img
                                :src="searchIcon"
                                alt="Suche"
                                class="icon mr-2"
                            >
                        </template>
                    </v-text-field>
                </div>
                <div
                    data-simplebar
                    class="scrollContainer"
                >
                    <v-treeview
                        v-model="selectedAccountSetIds"
                        :items="selectableAccountSets"
                        item-text="displayName"
                        item-key="_id"
                        item-value="_id"
                        :search="searchTreeView"
                        selected-color="var(--v-chatColor-base)"
                        selectable
                        dense
                        class="mt-2"
                        style="font-size: 16px"
                    />
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import IconPicker from "@/components/Utils/IconPicker";

import searchIcon from "@/assets/Icons/lupe-18.svg";
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import saveIcon from '@/assets/Icons/FaceliftIcons/speichern.svg';
import avatar1 from "@/assets/Icons/Avatar1.svg";
import avatar2 from "@/assets/Icons/Avatar2.svg";
import avatar3 from "@/assets/Icons/Avatar3.svg";
import avatar4 from "@/assets/Icons/Avatar4.svg";
import avatar5 from "@/assets/Icons/Avatar5.svg";
import avatar6 from "@/assets/Icons/Avatar6.svg";
import avatar7 from "@/assets/Icons/Avatar7.svg";
import avatar8 from "@/assets/Icons/Avatar8.svg";
import avatar9 from "@/assets/Icons/Avatar9.svg";
import avatar10 from "@/assets/Icons/Avatar10.svg";
import avatar11 from "@/assets/Icons/Avatar11.svg";
import avatar12 from "@/assets/Icons/Avatar12.svg";
import avatar13 from "@/assets/Icons/Avatar13.svg";
import avatar14 from "@/assets/Icons/Avatar14.svg";
import groupIcon from "@/assets/Icons/group.svg";

export default {
    name: "ChatRoomCreateGroupChatDialog",
    components: {
        IconPicker,
    },
    data: () => ({
        showDialog: true,
        newGroupChatName: "",
        searchTreeView: "",
        searchIcon,
        cancelIcon,
        saveIcon,
        selectedAccountSetIds: [],
        selectedColor: 'var(--v-chatColor-base)',
        selectedIcon: groupIcon,

        icons: [
            {icon: groupIcon, text: "Gruppen-Icon"},
            {icon: avatar1, text: "avatar1"},
            {icon: avatar2, text: "avatar2"},
            {icon: avatar3, text: "avatar3"},
            {icon: avatar4, text: "avatar4"},
            {icon: avatar5, text: "avatar5"},
            {icon: avatar6, text: "avatar6"},
            {icon: avatar7, text: "avatar7"},
            {icon: avatar8, text: "avatar8"},
            {icon: avatar9, text: "avatar9"},
            {icon: avatar10, text: "avatar10"},
            {icon: avatar11, text: "avatar11"},
            {icon: avatar12, text: "avatar12"},
            {icon: avatar13, text: "avatar13"},
            {icon: avatar14, text: "avatar14"},
        ],
    }),
    computed: {
        ...mapGetters("accountSets", ["accountSets"]),
        ...mapGetters("accounts", ["accountsById"]),
        ...mapGetters("groups", ["groupsById"]),
        ...mapState("auth", ["account"]),
        ...mapState('util', ['windowWidth']),

        isGroupChatCreatable() {
            return (
                this.newGroupChatName.trim() === "" ||
                this.selectedAccountSetIds.length < 1
            );
        },
        selectableAccountSets() {
            const getAccountSetDisplayName = (accountSet) => {
                if (accountSet.type === "account") {
                    const account = this.accountsById[accountSet.account];
                    return account ? account.displayName : account._id;
                } else if (accountSet.type === "groupLeaders") {
                    const group = this.groupsById[accountSet.group];
                    return `Gruppe "${group?.name}" Leiter`;
                } else if (accountSet.type === "groupAppointmentLeaders") {
                    const group = this.groupsById[accountSet.group];
                    return `Gruppe "${group?.name}" Fachlehrer`;
                } else if (accountSet.type === "groupParticipants") {
                    const group = this.groupsById[accountSet.group];
                    return `Gruppe "${group?.name}" Mitglieder`;
                } else if (accountSet.type === "allTeachers") {
                    return "Alle Lehrer";
                } else if (accountSet.type === "allMaintainers") {
                    return "Verwaltung";
                } else if (accountSet.type === "allPupils") {
                    return "Alle Schüler";
                } else if (accountSet.type === "allParents") {
                    return "Alle Eltern";
                }
            };


            return this.accountSets
                .filter((accountSet) => accountSet._id !== this.myAccountSet?._id)
                .map((accountSet) => ({
                    ...accountSet,
                    displayName: getAccountSetDisplayName(accountSet),
                }))
                .filter((accountSet) => accountSet.displayName && !accountSet.displayName.includes("PersonAlle"))
                .filter((accountSet) => accountSet.type !== 'allMaintainers');
        },
        myAccountSet() {
            return this.accountSets.find(
                (accountSet) =>
                    accountSet.type === "account" &&
                    accountSet.account === this.account._id
            );
        },
    },
    methods: {
        ...mapActions("matrix", ["createCustomRoom"]),
        ...mapActions("chat", ["postChatRoom"]),

        async createGroupChat() {
            const memberships = this.selectedAccountSetIds.map((accountSet) => ({
                accountSet,
                role: "member",
            }));
            const myMembership = {
                accountSet: this.myAccountSet._id,
                role: "moderator",
            };

            await this.postChatRoom({
                memberships: [...memberships, myMembership],
                name: this.newGroupChatName,
                description: "",
                type: "groupChat",
                isOpen: true,
                icon: this.selectedIcon,
                color: this.selectedColor,
            });

            //   await this.createCustomRoom({
            //     groupName: this.newGroupChatName,
            //     members: this.newGroupChatMembers,
            //   });
            this.closeDialog();
        },

        closeDialog() {
            this.$emit("closeDialog");
        },
    },
};
</script>

<style lang="scss">
.cardStyle {
    .v-card__text {
        // unify border-radius
        .v-btn {
            border-radius: 8px;
        }
        .v-input__control > .v-input__slot {
            border-radius: 8px;
        }

        // remove border bottom line/underline from all the inputs in v-cards with this class
        .v-input__control > .v-input__slot:before,
        .v-input__control > .v-input__slot:after {
            border-style: none !important;
        }
    }
}
</style>
<style scoped lang="scss">
.chat-header {
    background-color: var(--v-chatColor-base);
    border-radius: 15px 15px 0 0;
    font-size: larger;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    color: white;
}

.group-list-container {
    flex: 1;
    flex-direction: column;
    overflow: hidden;
}

.scroll-area {
    flex: 1;
}

.header {
    font-size: 16px;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.icon {
    height: 20px;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.scrollContainer {
    //necessary to set a maximum height to enable scrolling (can also just be height)
    max-height: calc(55vh - 60px);
    //necessary if we want to disable horizontal scroll
    overflow-x: hidden;
}
</style>
