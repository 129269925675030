<template>
    <div
        :key="update"
        class="noselect"
        style="background-color: transparent; z-index: 2;"
        :style="'height:' + iconSize + '; width:' + iconSize"
        @click="translateAndRead"
    >
        <img
            v-if="iconColor === 'white'"
            :src="isReading ? lautsprecherAusWhite : flagIcon"
            alt="Vorlesen"
        >
        <img
            v-else
            :src="isReading ? lautsprecherAus : flagIcon"
            alt="Vorlesen"
        >
    </div>
</template>

<script>
import lautsprecher from '@/assets/Icons/lautsprecher-13.svg'
import lautsprecherAus from '@/assets/Icons/lautsprecher-aus-89.svg'
import lautsprecherWhite from '@/assets/Icons/lautsprecher-weiss-13.svg'
import lautsprecherAusWhite from '@/assets/Icons/lautsprecher-weiss-aus-89.svg'

import {mapActions, mapGetters} from 'vuex';
import {textToSpeech} from "@/util/textToSpeech";
import enFlagIcon from "@/assets/Icons/englisch-61.svg";
import frFlagIcon from "@/assets/Icons/französisch-60.svg";
import itFlagIcon from "@/assets/Icons/flag-italy.svg";
import ruFlagIcon from "@/assets/Icons/russisch.svg";
import roFlagIcon from "@/assets/Icons/flag-romania.svg";
import trFlagIcon from "@/assets/Icons/türkisch-97.svg";
import arFlagIcon from "@/assets/Icons/syrisch-98.svg";
import kuFlagIcon from "@/assets/Icons/flag-kurdistan.svg";
import ukFlagIcon from "@/assets/Icons/flag-ukraine.svg";

export default {
    name: "TTS",
    components: {
    },
    props: {
        iconSize: {required: false, type: String, default: '25px'},
        iconColor: {required: false, type: String},
    },
    data() {
        return {
            selectedText: '',
            update: false,
            // targetLang: 'de',

            lautsprecher,
            lautsprecherAus,
            lautsprecherWhite,
            lautsprecherAusWhite,

            enFlagIcon,
            frFlagIcon,
            itFlagIcon,
            ruFlagIcon,
            roFlagIcon,
            trFlagIcon,
            arFlagIcon,
            kuFlagIcon,
            ukFlagIcon,

            isReading: false,
            textSelected: false,
            tutText: false,
            showTextTimeout: null,
        };
    },
    computed: {
        ...mapGetters('translation', [ 'getTargetLang' ]),
        ...mapGetters('pupils', ['getTutorialProgress']),
        targetLang() {
            return this.getTargetLang;
        },
        flagIcon() {
            switch(this.targetLang) {
                case 'de':
                    return this.iconColor === 'white' ? this.lautsprecherWhite : this.lautsprecher;
                case 'en':
                    return this.enFlagIcon;
                case 'fr':
                    return this.frFlagIcon;
                case 'it':
                    return this.itFlagIcon;
                case 'ru':
                    return this.ruFlagIcon;
                case 'ro':
                    return this.roFlagIcon;
                case 'tr':
                    return this.trFlagIcon;
                case 'ar':
                    return this.arFlagIcon;
                case 'ku':
                    return this.kuFlagIcon;
                case 'uk':
                    return this.ukFlagIcon;
                default:
                    return this.iconColor === 'white' ? this.lautsprecherWhite : this.lautsprecher;
            }
        }
    },
    watch: {
        selectedText() {
            if(this.selectedText.length) {
                if(!this.textSelected) {
                    this.textSelected = true;
                }
            } else {
                if(this.textSelected) {
                    this.textSelected = false;
                }
            }
        },
    },
    async mounted() {

        setInterval(() => {
            let selection = JSON.parse(JSON.stringify(this.selectedText));
            if (window.getSelection) {
                selection = window.getSelection();
            }
            // document.getSelection
            else if (document.getSelection) {
                selection = document.getSelection();
            }
            // document.selection
            else if (document.selection) {
                selection =
                    document.selection.createRange().text;
            }
            if(selection.toString() !== this.selectedText) {
                this.update = !this.update;

            }
            this.selectedText = selection.toString();

        }, 100);

        this.tut();
        setInterval(() => {
            this.tut();
        }, 300000);
    },
    methods: {
        ...mapActions('pupils', ['setTutorialProgress']),
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation', 'translateToTargetLang']),

        async translateAndRead() {
            let textToTranslate = this.selectedText.toString()
            if(textToTranslate === "") {
                textToTranslate = "Markiere einen Text, klicke mich an und ich lese ihn dir vor.";
            }
            const translatedText = await textToSpeech(textToTranslate, this.targetLang, () => {
                this.isReading = true;
            }, () => {
                this.isReading = false;
            });
            if(typeof translatedText === "string") {
                this.setTranslatedText(translatedText);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)
            }
        },

        async tut() {
            this.tutText = true;

            setTimeout(() => {
                this.tutText = false;
            }, 5000);
        }
    },
};
</script>

<style lang="scss" scoped>
div[color] {
    background-color: $schueler-blau !important;
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
