<template>
    <div>
        <!-- Dialog for fullscreen item list -->
        <v-dialog
            v-model="openDialog"
            persistent
            fullscreen
            hide-overlay
            style="min-height:100%; min-width: 100%;"
            transition="dialog-bottom-transition"
            @keydown.esc="notifyCloseList"
        >
            <v-card style="background-color: #dadada">
                <div class="exchangeWidgetHeader">
                    <div
                        class="d-flex align-center justify-center"
                    >
                        <v-text-field
                            v-model="itemSearch"
                            prepend-icon="mdi-magnify"
                            label="Nach Gegenständen suchen"
                            single-line
                            hide-details
                            dark
                            class="pl-6 pt-0"
                            style="flex: 1; min-width: 300px; max-width: 60vw;"
                            :style="windowWidth < 600 ? 'max-width: none !important; min-width: none !important;' :''"
                        />
                    </div>
                    <v-spacer />
                    <div class="pa-2 d-flex justify-space-between align-center">
                        <div
                            v-if="windowWidth > 549"
                            class="pa-2 d-flex justify-space-between align-center"
                        >
                            <v-tooltip
                                v-if="selectedItems.length !== 0"
                                bottom
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-if="selectedItems.length !== 0"
                                        id="deleteItemButton"
                                        dark
                                        elevation="3"
                                        class="exchangeWidgetBaseButton ml-4"
                                        style="background-color: #e6221e80"
                                        text
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="askForConfirmationDeleteItems"
                                    >
                                        <img
                                            :src="entfernenIcon"
                                            alt="Ausgewählte Gegenstände löschen"
                                            style="filter: brightness(1000%); width: 25px; height: 25px"
                                        >
                                    </v-btn>
                                </template>
                                <span>Gegenstand löschen</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        elevation="3"
                                        class="exchangeWidgetBaseButton defaultWhite ml-4"
                                        text
                                        :disabled="selectedItems.length === 0"
                                        :style="selectedItems.length === 0 ? 'filter: brightness(50%)' : ''"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="openQrCodeDialog"
                                    >
                                        <img
                                            :src="druckenIcon"
                                            alt="Codes für ausgewählte Gegenstände drucken"
                                            style="width: 25px; height: 25px"
                                        >
                                    </v-btn>
                                </template>
                                <span>QR-Codes für Gegenstände erstellen</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        elevation="3"
                                        class="exchangeWidgetBaseButton defaultWhite ml-4"
                                        text
                                        v-on="on"
                                        @click="openExcelImportDialog"
                                    >
                                        <img
                                            :src="hochladenIcon"
                                            alt="Neue Gegenstände importieren"
                                            style="width: 25px; height: 25px"
                                        >
                                    </v-btn>
                                </template>
                                <span>Geräte per Excel importieren</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        dark
                                        elevation="3"
                                        class="exchangeWidgetBaseButton ml-4"
                                        style="background-color: var(--v-gruen-base)"
                                        text
                                        v-on="on"
                                        @click="openItemDialog()"
                                    >
                                        <img
                                            :src="hinzufuegenIcon"
                                            alt="Neuen Gegenstand anlegen"
                                            style="filter: brightness(1000%); width: 20px; height: 25px"
                                        >
                                    </v-btn>
                                </template>
                                <span>Gegenstände anlegen</span>
                            </v-tooltip>
                        </div>
                        <v-menu
                            v-if="windowWidth < 550"
                            offset-y
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    icon
                                    v-on="on"
                                >
                                    <img
                                        :src="kebabMenuIcon"
                                        alt="Optionen"
                                        class="icon30 iconToWhite"
                                    >
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    @click="openItemDialog()"
                                >
                                    <v-list-item-icon>
                                        <img
                                            :src="hinzufuegenIcon"
                                            alt="Neuen Gegenstand anlegen"
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Gegenstände anlegen
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                    v-if="selectedItems.length !== 0"
                                    :disabled="selectedItems.length === 0"
                                    @click="askForConfirmationDeleteItems"
                                >
                                    <v-list-item-icon>
                                        <img
                                            :src="entfernenIcon"
                                            alt="Ausgewählte Gegenstände löschen"
                                            style="filter: brightness(1000%); width: 25px; height: 25px"
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Gegendstand löschen
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                    :disabled="selectedItems.length === 0"
                                    @click="openQrCodeDialog"
                                >
                                    <v-list-item-icon>
                                        <img
                                            :src="druckenIcon"
                                            alt="Codes für ausgewählte Gegenstände drucken"
                                            style="width: 25px; height: 25px"
                                            :style="selectedItems.length === 0 ? 'opacity: 0.4':''"
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        QR-Codes für Gegenstände erstellen
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="openExcelImportDialog"
                                >
                                    <v-list-item-icon>
                                        <img
                                            :src="hochladenIcon"
                                            alt="Neue Gegenstände importieren"
                                            style="width: 25px; height: 25px"
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-title>Geräte per Excel importieren</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-btn
                            x-small
                            class="exchangeWidgetBaseButton"
                            elevation="0"
                            icon
                            @click="notifyCloseList"
                        >
                            <img
                                :src="abbrechenIcon"
                                alt="Gegenstandliste schließen"
                                style="height: 40px; filter: brightness(1000%)"
                            >
                        </v-btn>
                    </div>
                </div>
                <div class="sheet topSheet">
                    <v-data-table
                        :headers="fullscreenDataTableHeader"
                        :header-props="headerProps"
                        :items="items"
                        :search="itemSearch"
                        item-key="itemDataTableKey"
                        show-select
                        dense
                        fixed-header
                        disable-pagination
                        hide-default-footer
                        :height="windowWidth > 550 ? '90vh' : '80vh'"
                        locale="de-DE"
                        no-data-text="Keine Daten verfügbar"
                        no-results-text="Keine Gegenstände gefunden"
                    >
                        <!-- eslint-disable-next-line -->
                        <template v-slot:header.data-table-select>
                            <v-checkbox
                                v-model="allItemsSelected"
                                :indeterminate="atLeastOneButNotAllItemsSelected"
                                rounded
                                small
                                light
                                @change="headerCheckboxChanged"
                            />
                        </template>
                        <template v-slot:item="row">
                            <tr
                                class="exchangeTable"
                                :style="`background-color: ${row.item.color}22`"
                            >
                                <td>
                                    <v-checkbox
                                        v-model="selectedItems"
                                        :value="row.item.itemId"
                                        multiple
                                        rounded
                                        small
                                        light
                                    />
                                </td>
                                <td>
                                    <v-icon>
                                        {{ row.item.icon }}
                                    </v-icon>
                                </td>
                                <td>
                                    <v-btn
                                        text
                                        dense
                                        class="pa-0"
                                        style="text-transform: inherit;"
                                        @click="openItemDialogEdit(row.item)"
                                    >
                                        {{ row.item.name }}
                                    </v-btn>
                                </td>
                                <!-- Verliehen an -->
                                <td style="min-width: 80px;">
                                    {{ row.item.account ? `${row.item.account.name + ' ' +row.item.account.lastName}` : '' }}
                                </td>
                                <!-- Ausleihfrist -->
                                <td>
                                    <span :class="dateInPast(row.item.dateUntil) ? 'dueDateExceeded' : ''"> {{ row.item.dateUntil ? new Date(row.item.dateUntil).toLocaleDateString('de-DE') : '' }} </span>
                                </td>
                                <td>
                                    {{ row.item.description }}
                                </td>
                                <td>{{ row.item.itemId }}</td>
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                dark
                                                class="exchangeWidgetBaseButton"
                                                icon
                                                v-on="on"
                                                @click="openItemDialogEdit(row.item)"
                                            >
                                                <img
                                                    :src="bearbeitenIcon"
                                                    alt="Gegenstand bearbeiten"
                                                    style="width: 25px; height: 25px; filter: brightness(150%)"
                                                >
                                            </v-btn>
                                        </template>
                                        <span>Gegenstand anpassen</span>
                                    </v-tooltip>
                                </td>
                                <!-- <td>
                                    <v-checkbox
                                        v-model="row.item.selected"
                                        class="mx-2"
                                        rounded
                                        small
                                        light
                                    />
                                </td> -->
                            </tr>
                        </template>
                    </v-data-table>
                </div>
                <div>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                dark
                                elevation="3"
                                class="scanModeButton"
                                large
                                style="text-transform: inherit"
                                text
                                v-on="on"
                                @click="openScannerDialog"
                            >
                                <img
                                    :src="qrIcon"
                                    alt="Scan modus Icon"
                                    style="filter: brightness(1000%); width: 30px; height: 30px"
                                >
                                <p
                                    class="ml-2"
                                    style="font-size: larger"
                                >
                                    Ein-/Ausscanen
                                </p>
                            </v-btn>
                        </template>
                        <span>Scan Modus aktivieren</span>
                    </v-tooltip>
                </div>
            </v-card>
        </v-dialog>

        <!-- Dialog for creation of qrcodes -->
        <v-dialog
            v-model="createQrCodesDialog"
            transition="dialog-right-transition"
            @click:outside="closeQrCodeDialog"
        >
            <v-card class="createQrCodesCard">
                <v-card-title
                    class="exchangeWidgetHeader py-2 px-4"
                >
                    <p
                        v-if="windowWidth > 739"
                        style="color: var(--v-weiss-base)"
                    >
                        QR-Codes ausdrucken
                    </p>
                    <v-tooltip
                        v-if="windowWidth < 740"
                        bottom
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <img
                                :src="qrIcon"
                                alt="4x4 - Vorlage"
                                class="icon30 iconToWhite"
                                v-bind="attrs"
                                v-on="on"
                            >
                        </template>
                        <span>QR-Codes ausdrucken</span>
                    </v-tooltip>
                    <v-spacer />
                    <v-tooltip
                        bottom
                        max-width="350"
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                dark
                                elevation="0"
                                class="exchangeWidgetBaseButton mr-4"
                                icon
                                v-on="on"
                            >
                                <img
                                    :src="infoIcon"
                                    class="mx-2"
                                    alt="Was macht dieser Dialog überhaupt?"
                                    style="filter: brightness(1000%); width: 25px; height: 25px"
                                >
                            </v-btn>
                        </template>
                        <span>Wählen sie eine Vorlage oder bewegen Sie den Regler um die Größe der Qr-Codes auszuwählen, und laden Sie anschließend das PDF für den Druck herunter.</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                dark
                                elevation="3"
                                class="exchangeWidgetBaseButton mr-4"
                                style="text-transform: inherit; width: auto !important; background-color: var(--v-gruen-base)"
                                text
                                v-on="on"
                                @click="downloadQrCodePdf"
                            >
                                <img
                                    :src="runterladenIcon"
                                    class="mx-2"
                                    alt="QR-Codes herunterladen"
                                    style="filter: brightness(1000%); width: 25px; height: 25px"
                                >
                                <p
                                    v-if="!isSmartphoneUI"
                                    class="mr-2"
                                >
                                    PDF speichern
                                </p>
                            </v-btn>
                        </template>
                        <span>QR-Codes herunterladen</span>
                    </v-tooltip>
                    <v-btn
                        x-small
                        class="exchangeWidgetBaseButton"
                        elevation="0"
                        icon
                        @click="closeQrCodeDialog"
                    >
                        <img
                            :src="abbrechenIcon"
                            alt="QR Code erstellen schließen"
                            style="height: 40px; filter: brightness(1000%)"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text style="height: 80%">
                    <v-slider
                        v-model="qrCodeSize"
                        :disabled="choosingTemplate"
                        label="QR-Code Größe ändern"
                        max="240"
                        min="20"
                        thumb-label="always"
                        class="qrCodeSizeSlider"
                        @change="createQrCodesPdf"
                    />
                    <div class="d-flex flex-row justify-space-around align-center mb-2">
                        <p
                            class="mr-2"
                            style="font-size:large"
                        >
                            Vorlagen:
                        </p>
                        <div class="d-flex flex-row justify-space-around align-center">
                            <v-btn
                                color="primary"
                                style=" height: 25px;"
                                @click="create4x4QrPdf"
                            >
                                <img
                                    :src="qrIcon"
                                    alt="4x4 - Vorlage"
                                    class="icon20 iconToWhite"
                                >
                                <span>40x40 mm</span>
                            </v-btn>
                            <v-tooltip
                                bottom
                                max-width="350"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        dark
                                        elevation="0"
                                        class="exchangeWidgetBaseButton mr-4"
                                        icon
                                        v-on="on"
                                    >
                                        <img
                                            :src="infoIcon"
                                            alt="info"
                                            style=" width: 25px; height: 25px"
                                        >
                                    </v-btn>
                                </template>
                                <span>Vorlage für Herma 9642 QR-Code Etiketten</span>
                            </v-tooltip>
                        </div>
                        <div class="d-flex flex-row justify-space-around align-center">
                            <v-btn
                                color="primary"
                                style=" height: 25px;"
                                @click="create24x24QrPdf"
                            >
                                <img
                                    :src="qrIcon"
                                    alt="24x24 - Vorlage"
                                    class="icon20 iconToWhite"
                                >
                                <span>24x24 mm</span>
                            </v-btn>
                            <v-tooltip
                                bottom
                                max-width="350"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        dark
                                        elevation="0"
                                        class="exchangeWidgetBaseButton mr-4"
                                        icon
                                        v-on="on"
                                    >
                                        <img
                                            :src="infoIcon"
                                            alt="info"
                                            style=" width: 25px; height: 25px"
                                        >
                                    </v-btn>
                                </template>
                                <span>Vorlage für Herma 24x24 mm Etiketten</span>
                            </v-tooltip>
                        </div>
                        <v-spacer />
                    </div>
                    <object
                        type="application/pdf"
                        :data="qrCodesPdf"
                        style="width: 100%; height: 100%"
                    >
                        <p>
                            Ihr Browser hat kein PDF Plugin um die Vorschau der QR-Codes anzuzeigen.
                            Laden Sie die PDF herunter um Sie anzusehen und auszudrucken.
                            <br>
                            Sie können die Größe der QR-Codes trotzdem mit dem Regler verändern.
                        </p>
                    </object>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Dialog for creation or edit of items-->
        <exchange-item-dialog
            v-if="showItemDialog"
            :show-item-dialog="showItemDialog"
            :item="selectedItem"
            :exchange-accounts="exchangeAccounts"
            :smartphone-ui="isSmartphoneUI"
            @deleteItem="deleteItem"
            @refreshItems="refreshItems"
            @toggleItemDialog="showItemDialog = !showItemDialog"
        />

        <!-- Dialog for scanning items and user -->
        <v-dialog
            v-if="scannerDialog"
            v-model="scannerDialog"
            fullscreen
        >
            <div class="scannerContainer">
                <div class="scannedContentOverlay">
                    <v-btn
                        elevation="3"
                        class="scannerDialogClose"
                        @click="closeScannerDialog"
                    >
                        <img
                            :src="abbrechenIcon"
                            alt="Fenster schließen"
                            style="height: 40px; filter: brightness(1000%)"
                        >
                    </v-btn>
                    <div class="scannedContent">
                        <div style="display: flex; flex-direction: column">
                            <v-menu
                                offset-y
                                :close-on-content-click="false"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="dateRangeToString()"
                                        prepend-inner-icon="mdi-clock-time-three"
                                        class="mt-3"
                                        :class="`${requiredFlag && !dateRangeToString() ? 'requiredField' : ''}`"
                                        solo
                                        readonly
                                        hide-details
                                        placeholder="Ausleihfrist setzen"
                                        v-bind="attrs"
                                        v-on="on"
                                    />
                                </template>
                                <v-date-picker
                                    v-model="dateRange"
                                    range
                                    locale="de-DE"
                                    first-day-of-week="1"
                                    no-title
                                    show-week
                                    color="var(--v-blau-base)"
                                    style="min-width: 100%;"
                                    @input="updateDateForItem"
                                />
                            </v-menu>
                        </div>
                        <div>
                            <v-autocomplete
                                v-model="scannedAccount"
                                :items="exchangeAccounts"
                                :item-value="item => `${item.name} ${item.lastName}`"
                                :item-text="item => item.name + ' ' + item.lastName"
                                :background-color="scannedAccount ? 'var(--v-blau-base)' : ''"
                                :style="scannedAccount ? 'color: #FFFFFF !important' : ''"
                                class="mt-3"
                                solo
                                hide-details
                                return-object
                                append-icon=""
                                prepend-inner-icon="mdi-account"
                                placeholder="Ausgeliehen an"
                            >
                                <template v-slot:prepend-inner>
                                    <v-icon
                                        :color="scannedAccount ? 'white' : ''"
                                    >
                                        mdi-account
                                    </v-icon>
                                </template>
                                <template v-slot:item="{ item }">
                                    {{ item.name + ' ' + item.lastName + ` ${ item.groupNames ? item.groupNames : '' }` }}
                                </template>
                                <template v-slot:selection="{ item }">
                                    <span :style="scannedAccount ? 'color: white' : ''">
                                        {{ item.name + ' ' + item.lastName + ` ${ item.groupNames ? item.groupNames : '' }` }}
                                    </span>
                                </template>
                                <template v-slot:append>
                                    <v-icon
                                        :style="scannedAccount ? 'color: white' : ''"
                                        @click="scannedAccount = null"
                                    >
                                        {{ scannedAccount ? 'mdi-close-circle-outline' : '' }}
                                    </v-icon>
                                </template>
                            </v-autocomplete>
                        </div>
                        <div>
                            <v-autocomplete
                                v-model="scannedItem"
                                :items="items"
                                :item-text="item => `${item.name} (${item.itemId})`"
                                :item-value="item => item.itemId"
                                :background-color="scannedItem ? '#e8322c' : ''"
                                class="mt-3"
                                solo
                                hide-details
                                append-icon=""
                                placeholder="Gegenstand"
                                return-object
                            >
                                <template v-slot:prepend-inner>
                                    <v-icon
                                        :color="scannedItem ? 'white' : ''"
                                    >
                                        {{ scannedItem && scannedItem.icon ? scannedItem.icon : 'mdi-help' }}
                                    </v-icon>
                                </template>
                                <template v-slot:selection>
                                    <span
                                        :style="scannedItem ? 'color: white' : ''"
                                    >
                                        {{ scannedItem.name }}
                                    </span>
                                </template>
                            </v-autocomplete>
                        </div>
                    </div>
                    <v-snackbar
                        v-model="qrInstructionsSnackbar"
                        color="var(--v-headerblue-base)"
                    >
                        <template v-slot:action="{ attrs }">
                            <v-btn
                                text
                                v-bind="attrs"
                                small
                                @click="qrInstructionsSnackbar = false"
                            >
                                <img
                                    style="max-width: 20px"
                                    class="iconToWhite"
                                    alt="Schließen"
                                    :src="closeIcon"
                                >
                            </v-btn>
                        </template>
                        Scannen Sie den Schulausweis und den Gegenstandcode nacheinander zum Ver- und Entleihen, oder wählen Sie Nutzer und Gegenstände aus den Feldern links aus.
                        {{ qrInstructionsSnackbar }}
                    </v-snackbar>
                </div>
                <qrcode-stream
                    ref="qrCodeScanner"
                    class="qrCodeScanner"
                    @decode="onDecode"
                    @init="onInit"
                >
                    <div class="qrCodeVisorContainer">
                        <!-- <div class="qrCodeScanningOptions">
                           <v-icon
                               size="xx-large"
                               @click="showDebug = !showDebug"
                           >
                               mdi-camera-flip-outline
                           </v-icon>
                           <p v-if="showDebug">
                               {{ facingMode }}
                           </p>
                        </div> -->
                        <div class="qrCodeVisor">
                            <div>
                                <img
                                    :src="qrVisor"
                                    alt=""
                                >
                            </div>
                        </div>
                    </div>
                </qrcode-stream>
            </div>
        </v-dialog>
        <qrcode-vue
            v-for="(item, index) in selectedItems"
            v-show="false"
            :key="index"
            :ref="'qr' + item"
            :value="item"
        />
        <AreYouSurePopup
            v-if="showAreYouSureDelete"
            :show-dialog="showAreYouSureDelete"
            :header-color="'#343e55'"
            :content-text-prop="`Sie sind im Begriff ${itemsForDeletion.length} Geräte zu löschen. Wollen Sie diese Aktion wirklich ausführen? Dazu müssen sie ihr Passwort eingeben.`"
            ok-btn-color="frot"
            :usage="'exchange'"
            @close="showAreYouSureDelete = false"
            @ok="deleteItemsConfirmed"
        />
        <ExchangeDeviceExcelImport
            v-if="excelImportDialog"
            @close="() => { excelImportDialog = false }"
            @refreshItems="refreshItems"
        />
    </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import ExchangeItemDialog from "./ExchangeItemDialog.vue";
import {QrcodeStream} from "vue-qrcode-reader";
import {decompress} from "shrink-string";
import * as backend from "../../api/backend";
import {mapActions, mapMutations, mapState, mapGetters} from "vuex";
import {PDFDocument} from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

import abbrechenIcon from "../../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import bearbeitenIcon from '@/assets/Icons/bearbeiten-komplimentär-grau-88.svg';
import entfernenIcon from '@/assets/Icons/papierkorb-2.svg';
import druckenIcon from '@/assets/Icons/drucken-filled-blau.svg';
import hochladenIcon from '@/assets/Icons/hochladen-filled-blau.svg';
import hinzufuegenIcon from '@/assets/Icons/FaceliftIcons/plus_hinzufuegen_weiteres.svg';
import qrIcon from '@/assets/Icons/FaceliftIcons/qr_schulausweis.svg'
import runterladenIcon from '@/assets/Icons/FaceliftIcons/herunterladen.svg';
import infoIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg';
import qrVisor from "../../assets/Bilder/sample_qrvisor.png";
import downloadIcon from "@/assets/Icons/FaceliftIcons/herunterladen.svg"
import blankPdf from "../../assets/PDF/blank.pdf";
import exchangedSound from "../../assets/Sound/exchanged.mp3";
import popupSound from "../../assets/Sound/popup.mp3";
import roboto from "../../assets/Fonts/Roboto-Regular.ttf";
import AreYouSurePopup from "../../components/Utils/AreYouSurePopup.vue";
import ExchangeDeviceExcelImport from "@/components/Exchange/ExchangeDeviceExcelImport";
import kebabMenuIcon from "@/assets/Icons/FaceliftIcons/menu_mehr_weiteres_setup.svg";
import closeIcon from "@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import {checkResponseStatus} from "@/util/check";


export default {
name: "ExchangeApp",
    components: {
        ExchangeDeviceExcelImport,
        QrcodeVue,
        QrcodeStream,
        ExchangeItemDialog,
        AreYouSurePopup,
    },
    data: () => ({
        items: [],
        itemSearch: '', // Search string in fullscreen Dialog
        headerProps: {
            sortByText: 'Sortieren nach',
        },
        fullscreenDataTableHeader: [
            { text: '', value: 'icon', width: '30px' },
            { text: 'Gegenstand', value: 'name',width: '15%' },
            { text: 'Verliehen an', value: 'account',width: '15%' },
            { text: 'Ausleihfrist', value: 'date',width: '15%' },
            { text: 'Notiz', value: 'description',width: '15%' },
            { text: 'ID', value: 'itemId' ,width: '15%'},
            { text: 'Bearbeiten', sortable: false,width: '15%' },
        ], // Header for fullscreen data table
        selectedItems: [],
        itemsForDeletion: [],
        allItemsSelected: false,

        // Create QR Codes
        canEditQr: false,
        createQrCodesDialog: false, // Dialog that lets user create qr codes
        qrCodeSize: 20,
        qrCodesPdf: null,
        choosingTemplate: false,

        excelImportDialog: false,

        // Scanner dialog
        scannerDialog: false,
        scannedAccount: null,
        scannedItem: null,
        dateRange: [],
        facingMode: [],
        showDebug: false,

        showItemDialog: false,
        openDialog: true,
        showAreYouSureDelete: false,

        exchangeAccounts: [],
        selectedItem: null,

        // flag to toggle styling for required date
        requiredFlag: false,

        qrInstructionsSnackbar: false,

        today: null,

        // Imports
        blankPdf,
        roboto,

        abbrechenIcon,
        bearbeitenIcon,
        entfernenIcon,
        downloadIcon,
        hinzufuegenIcon,
        hochladenIcon,
        druckenIcon,
        qrIcon,
        runterladenIcon,
        infoIcon,
        qrVisor,
        kebabMenuIcon,
        closeIcon,

        // Sounds are used but eslint is too stupid
        // eslint-disable-next-line vue/no-unused-properties
        exchangedSound,
        // eslint-disable-next-line vue/no-unused-properties
        popupSound,
    }),
    computed: {
        ...mapState('util', ['windowWidth', 'windowHeight']),
        ...mapGetters("auth", ['accountRole']),

        atLeastOneButNotAllItemsSelected() {
            return this.selectedItems.length > 0 && this.selectedItems.length < this.items.length;
        },
        isSmartphoneUI() {
            return this.windowWidth < 600 || this.windowHeight < 600;
        }
    },
    watch: {
        async showItemDialog() {
          if(!this.showItemDialog) {
              this.items = [];
              await this.refreshItems();
          }
        },
        scannedAccount() {
            this.exchangeItem();
        },

        scannedItem() {
            this.exchangeItem();
        },

        selectedItems() {
            if(this.selectedItems.length === this.items.length) {
                this.allItemsSelected = true;
            } else if(this.selectedItems.length < this.items.length){
                this.allItemsSelected = false;
            }
        },
    },
    async mounted() {
        await this.refreshItems();
        await this.refreshAccounts();
        await this.addGroupNamesToPupilAccounts();
        this.today = new Date().setHours(0,0,0,0);
        if(this.accountRole === 'maintainer'){
            this.canEditQr = true;
        }
        let me = null;
        if(this.accountRole === 'teacher'){
            me = await this.getMeTeacher();
            this.canEditQr = me.canEditQrCodes;
        }
    },
    methods: {
        ...mapActions('groups', ['getGroup']),
        ...mapActions("teachers", ['getMeTeacher']),
        ...mapMutations('snackbar', ['showSnackbar']),
        dateInPast(date) {
            return this.today > new Date(date).setHours(0,0,0,0);
        },

        openScannerDialog() {
            this.scannerDialog = true;
            this.qrInstructionsSnackbar = true;
        },

        closeScannerDialog() {
            this.scannerDialog = false;
            this.scannedAccount = null;
            this.scannedItem = null;
            this.dateRange = [];
        },

        async updateDateForItem() {
            if(this.scannedAccount && this.scannedItem && this.dateRange[0] && this.dateRange[1]) {
                this.requiredFlag = false;
                await this.exchangeItem();
                // await backend.updateExchange(this.scannedItem._id, { account: this.scannedAccount._id, dateFrom: this.dateRange[0], dateUntil: this.dateRange[1] });
                // this.showSnackbar({ color: "success", timeout: 8000, message: "Ausleih Zeitraum: " + this.dateRangeToString(), closable: false });
                // await new Audio(exchangedSound).play();
            }
        },

        dateRangeToString() {
            if(this.dateRange[0] && this.dateRange[1]) {
                return this.dateRange[0].slice(8, 10) + '.' +
                    this.dateRange[0].slice(5, 7) + '.' +
                    this.dateRange[0].slice(0, 4) + ' - ' +
                    this.dateRange[1].slice(8, 10) + '.' +
                    this.dateRange[1].slice(5, 7) + '.' +
                    this.dateRange[1].slice(0, 4)
            }
        },

        headerCheckboxChanged() {
            if(this.allItemsSelected) {
                this.selectedItems = this.items.map((item) => item.itemId);
            } else {
                this.selectedItems = [];
            }
        },

        notifyCloseList() {
            this.$emit('closeList');
        },

        async onInit(promise) {
            try {
                const mediaTrackCapabilities = await promise;
                this.facingMode = mediaTrackCapabilities.capabilities;
            } catch (e) {
                console.error(e);
                if (e.name === 'NotReadableError') {
                    this.showSnackbar({ message: "Es konnte keine Kamera geöffnet werden. Bitte überprüfen Sie die Berechtigungen in ihrem Browser und stellen Sie sicher, dass keine andere Anwendung Ihre Kamera benutzt.", color: "error" });
                } else  if (e.name === 'NotAllowedError'){
                    this.showSnackbar({ message: `Fehler! Möglicherweise müssen Sie Ihrem Browser erst den Zugriff auf die Kamera gewähren.`, color: "error" });
                } else if (e.name === 'NotFoundError' || e.name === 'OverconstrainedError') {
                    this.showSnackbar({ message: "Das Gerät scheint keine nutzbare Kamera zu besitzen. Bitte schließe eine an oder wechsel das Gerät.", color: "error" });
                } else {
                    this.showSnackbar({ message: `Fehler! ${e.name}`, color: "error" });
                }
            }
        },

        async onDecode(result) {
            if (result.length > 0) {
                if(this.scannedCodeAccount(result)) {
                    const jsonResult = JSON.parse(result);
                    const temp = await decompress(jsonResult.name);
                    this.scannedAccount = this.exchangeAccounts.find((account) => account.accountName === temp);
                } else {
                    this.scannedItem = this.items.find((item) => item.itemId === result);
                }
                await new Audio(popupSound).play();
            } else if (result.length > 0 && !this.scannedCodeAccount(result)) {
                this.showSnackbar({ message: 'QRCode nicht gültig!', color: "error" });
            }
        },

        scannedCodeAccount(scannedCode) {
            try {
                const jsonCode = JSON.parse(scannedCode);
                if (jsonCode != null) {
                    return !!(jsonCode.name && jsonCode.pw);
                }
            } catch (e) {
                return false;
            }
        },

        async exchangeItem() {
            // Check if there is an account and an item selected/scanned
            if(this.scannedItem && this.scannedAccount) {
                let message = '';
                this.requiredFlag = false;

                if(this.scannedItem.account === null) {
                    // Item wasn't currently exchanged -> normal behavior
                    if (!this.dateRange.length) {
                        this.requiredFlag = true;
                        const message = 'Geben Sie eine Ausleihfrist an!'
                        this.showSnackbar({ color: "error", timeout: 8000, message, closable: false });
                        return;
                    }

                    await backend.updateExchange(this.scannedItem._id, {
                        account: this.scannedAccount._id,
                        dateFrom: this.dateRange[0],
                        dateUntil: this.dateRange[1]
                    });
                    message = "Gegenstand erfolgreich ausgeliehen!";
                } else if(this.scannedItem.account._id !== this.scannedAccount._id) {
                    // Item is currently exchanged and directly given to the next account
                    await backend.updateExchange(this.scannedItem._id, {
                        account: this.scannedAccount._id,
                        dateFrom: this.dateRange[0] || this.scannedItem.dateFrom,
                        dateUntil: this.dateRange[1] || this.scannedItem.dateUntil
                    });
                    message = 'Gegenstand wurde von ' + this.scannedItem.account.name + ' ' +
                        this.scannedItem.account.lastName + ' an ' + this.scannedAccount.name + ' ' +
                        this.scannedAccount.lastName + ' weitergegeben!';
                } else if(this.scannedItem.account._id === this.scannedAccount._id) {
                    // Item is given back from exchange
                    await backend.updateExchange(this.scannedItem._id, {
                        account: 'remove',
                        dateFrom: '',
                        dateUntil: ''
                    });
                    message = "Gegenstand erfolgreich zurückgegeben!";
                }
                this.showSnackbar({ color: "success", timeout: 8000, message, closable: false });
                await this.refreshItems();
                // play success sound
                await new Audio(exchangedSound).play();
            }
        },

        async refreshItems() {
            // init all Exchange Items
            const res = await backend.getExchangeAll();
            const rawItems = await res.json();
            this.items = rawItems.map(item => {
                item.itemDataTableKey = `${item.itemId}-${item.account ? (item.account._id || item.account) : 'free'}`
                return item;
            })
        },

        async refreshAccounts() {
            let res = await backend.getExchangeAccounts();
            this.exchangeAccounts = await res.json();
        },

        async addGroupNamesToPupilAccounts() {
            const pupilAccounts = this.exchangeAccounts.filter(account => account.role === 'pupil');
            await Promise.all(pupilAccounts.map(async (account) => {
                const itemGroups = await Promise.all(account.groups.map(async (group) => {
                    return await this.getGroup(group);
                }));
                if (itemGroups.length > 0) {
                    let groupsString = '(';
                    for (let i = 0; i < itemGroups.length; i++) {
                        groupsString += itemGroups[i].name;
                        if (i === itemGroups.length - 1) {
                            groupsString += ')'
                        } else {
                            groupsString += ', '
                        }
                    }
                    account.groupNames = groupsString;
                }
            }))
        },

        async openItemDialogEdit(el) {
            if(!this.canEditQr){
                this.showSnackbar({message: 'Sie haben keine Berechtigungen Qr-Codes anzulegen oder zu bearbeiten! Wenden Sie sich hierzu an die Verwaltung.', color: 'error'});
                return
            }
            this.selectedItem = el;
            this.showItemDialog = true;
        },

        async openItemDialog() {
            if(!this.canEditQr){
                this.showSnackbar({message: 'Sie haben keine Berechtigungen Qr-Codes anzulegen oder zu bearbeiten! Wenden Sie sich hierzu an die Verwaltung.', color: 'error'});
                return
            }
            this.selectedItem = null;
            this.showItemDialog = true;
        },

        async askForConfirmationDeleteItems() {
            if(!this.canEditQr){
                this.showSnackbar({message: 'Sie haben keine Berechtigungen Qr-Codes zu löschen! Wenden Sie sich hierzu an die Verwaltung.', color: 'error'});
                return
            }
            else if (this.selectedItems.length > 0) {
                this.itemsForDeletion = this.items.filter(item => this.selectedItems.includes(item.itemId));
                this.showAreYouSureDelete = true;
            }
        },

        async deleteItem(itemToDelete) {
            const res = await backend.deleteExchange(itemToDelete._id);
            if(res.status === 204) {
                this.showSnackbar({ color: "success", message: "Das Gerät " + itemToDelete.name + " wurde gelöscht.", closable: false });
            } else {
                this.showSnackbar({ color: "error", message: "Beim löschen ist etwas schiefgelaufen.", closable: false });
            }
            await this.refreshItems();
        },

        async deleteItemsConfirmed() {
            this.showAreYouSureDelete = false;
            await this.itemsForDeletion.reduce(async (previousPromise, currentItem) => {
                const previous = await previousPromise;
                const res = await backend.deleteExchange(currentItem._id);
                previous.push(res);
                return previous;
            }, Promise.resolve([]));
            await this.refreshItems();
        },

        async openQrCodeDialog() {
            if(!this.canEditQr){
            this.showSnackbar({message: 'Sie haben keine Berechtigungen Qr-Codes anzulegen oder zu bearbeiten! Wenden Sie sich hierzu an die Verwaltung.', color: 'error'});
                return
            }
            await this.createQrCodesPdf();
            this.createQrCodesDialog = true;
        },
        closeQrCodeDialog(){
            this.createQrCodesDialog = false;
            this.qrCodeSize = 20;
            this.choosingTemplate = false;
        },

        openExcelImportDialog() {
            if(!this.canEditQr){
            this.showSnackbar({message: 'Sie haben keine Berechtigungen Qr-Codes anzulegen oder zu bearbeiten! Wenden Sie sich hierzu an die Verwaltung.', color: 'error'});
                return
            }
            this.excelImportDialog = true;
        },
        async create4x4QrPdf(){
            this.choosingTemplate = true;
            this.qrCodeSize = 20;
            const existingPdfBytes = await fetch(this.blankPdf).then((res) =>
                res.arrayBuffer()
            );
            const pdfDoc = await PDFDocument.load(existingPdfBytes);
            pdfDoc.setTitle('QR-Codes')
            pdfDoc.registerFontkit(fontkit);

            const robotoBytes = await fetch(this.roboto).then((res) =>
                res.arrayBuffer()
            );

            const robotoFont = await pdfDoc.embedFont(robotoBytes);

            // Get the first page of the document
            const pages = pdfDoc.getPages();
            const firstPage = pages[0];

            // Get the width and height of the first page
            const {width, height} = firstPage.getSize();
            const convertToMM = 2.835
            const fontsize = 4 * convertToMM;
            let qrCodesHorizontal = 4;
            let qrCodesVertical = 6;
            this.qrCodeSize = 40 * convertToMM;  
            if(qrCodesHorizontal > this.selectedItems.length) {
                qrCodesHorizontal = this.selectedItems.length;
            }
            const spaceBetweenQrCodesHorizontal = 6 * convertToMM;
            
            let rowsNeeded = 6;
            const pagesNeeded = this.selectedItems.length % 24;
            let qrCodesOnOnePage = 24;
            const allPages = [firstPage];

            for(let p = 1; p < pagesNeeded; p++) {
                const [copiedPage] = await pdfDoc.copyPages(pdfDoc, [0]);
                pdfDoc.addPage(copiedPage);
                allPages.push(copiedPage);
            }
            let currentPage = firstPage;
            let currentPageIndex = 0;

            for(let i = 0, s = 0; i < this.selectedItems.length; i++, s++) {
                const qrCode = this.$refs['qr' + this.selectedItems[i]][0].$refs["qrcode-vue"].toDataURL(
                    "image/png"
                );
                const png = await pdfDoc.embedPng(qrCode);
                if(s === qrCodesOnOnePage) {
                    s = 0;
                    currentPageIndex++;
                    currentPage = pdfDoc.getPages()[currentPageIndex];
                }
                // x Pos of qr code. Ranges from 0 to qrCodesHorizontal.
                const xPos = s % qrCodesHorizontal;
                
                // y Pos of qr code. Ranges from 0 to qrCodesVertical.
                const yPos = Math.floor(s / qrCodesHorizontal);
                currentPage.drawImage(png, {
                    x: (xPos * spaceBetweenQrCodesHorizontal + xPos * this.qrCodeSize + 15 * convertToMM + 4*convertToMM -xPos*convertToMM) + convertToMM,
                    y:  height - 36 * convertToMM - (1-(yPos*convertToMM ))- (18*convertToMM + yPos * (40 *convertToMM + 6*convertToMM)), // pls dont ask why it works, alot of try and error
                    width: 36 * convertToMM, 
                    height: 36 * convertToMM,
                });

                currentPage.drawText(this.selectedItems[i], {
                    x: xPos * spaceBetweenQrCodesHorizontal + xPos * this.qrCodeSize + 16 * convertToMM + 4*convertToMM -xPos*convertToMM,
                    y:  height - 36 * convertToMM - (1-(yPos*convertToMM )) - (18*convertToMM + yPos * (40 *convertToMM + 6*convertToMM) + fontsize),
                    size: fontsize,
                    font: robotoFont,
                });
            }

            // Load a PDFDocument from the existing PDF bytes
            const pdfBytes = await pdfDoc.save();
            const blob = new Blob([pdfBytes], {type: "application/pdf"});
            this.qrCodesPdf = URL.createObjectURL(blob);
        },
        async create24x24QrPdf(){
            this.choosingTemplate = true;
            this.qrCodeSize = 20;
            const existingPdfBytes = await fetch(this.blankPdf).then((res) =>
                res.arrayBuffer()
            );
            const pdfDoc = await PDFDocument.load(existingPdfBytes);
            pdfDoc.setTitle('QR-Codes')
            pdfDoc.registerFontkit(fontkit);

            const robotoBytes = await fetch(this.roboto).then((res) =>
                res.arrayBuffer()
            );

            const robotoFont = await pdfDoc.embedFont(robotoBytes);

            // Get the first page of the document
            const pages = pdfDoc.getPages();
            const firstPage = pages[0];

            // Get the width and height of the first page
            const {width, height} = firstPage.getSize();
            const convertToMM = 2.835
            const fontsize = 2 * convertToMM;
            let qrCodesHorizontal = 7;
            let qrCodesVertical = 10;
            this.qrCodeSize = 24 * convertToMM;  
            if(qrCodesHorizontal > this.selectedItems.length) {
                qrCodesHorizontal = this.selectedItems.length;
            }
            const spaceBetweenQrCodesHorizontal = 3 * convertToMM;
            
            let rowsNeeded = 6;
            const pagesNeeded = this.selectedItems.length / 70;
            let qrCodesOnOnePage = 70;
            const allPages = [firstPage];

            for(let p = 1; p < pagesNeeded; p++) {
                const [copiedPage] = await pdfDoc.copyPages(pdfDoc, [0]);
                pdfDoc.addPage(copiedPage);
                allPages.push(copiedPage);
            }
            let currentPage = firstPage;
            let currentPageIndex = 0;

            for(let i = 0, s = 0; i < this.selectedItems.length; i++, s++) {
                const qrCode = this.$refs['qr' + this.selectedItems[i]][0].$refs["qrcode-vue"].toDataURL(
                    "image/png"
                );
                const png = await pdfDoc.embedPng(qrCode);
                if(s === qrCodesOnOnePage) {
                    s = 0;
                    currentPageIndex++;
                    currentPage = pdfDoc.getPages()[currentPageIndex];
                }
                // x Pos of qr code. Ranges from 0 to qrCodesHorizontal.
                const xPos = s % qrCodesHorizontal;
                
                // y Pos of qr code. Ranges from 0 to qrCodesVertical.
                const yPos = Math.floor(s / qrCodesHorizontal);
                currentPage.drawImage(png, {
                    x: (xPos * spaceBetweenQrCodesHorizontal + xPos * this.qrCodeSize + 13 * convertToMM + 6 * convertToMM) - 3.8 * convertToMM,
                    y:  height - 20 * convertToMM - (1-(yPos*convertToMM ))- (15*convertToMM + yPos * (24 *convertToMM + 4*convertToMM) + 1.8 * convertToMM), // pls dont ask why it works, alot of try and error
                    width: 20 * convertToMM, 
                    height: 20 * convertToMM,
                });

                currentPage.drawText(this.selectedItems[i], {
                    x: xPos * spaceBetweenQrCodesHorizontal + xPos * this.qrCodeSize + 13 * convertToMM + 6 * convertToMM - 3.8 * convertToMM,
                    y:  height - 20 * convertToMM - (1-(yPos*convertToMM )) - (15*convertToMM + yPos * (24 * convertToMM + 4 * convertToMM) + fontsize + convertToMM + 1.8 * convertToMM),
                    size: fontsize,
                    font: robotoFont,
                });
            }

            // Load a PDFDocument from the existing PDF bytes
            const pdfBytes = await pdfDoc.save();
            const blob = new Blob([pdfBytes], {type: "application/pdf"});
            this.qrCodesPdf = URL.createObjectURL(blob);
        },
        // Some magic to create a pdf with all selected qr codes
        async createQrCodesPdf() {
            const existingPdfBytes = await fetch(this.blankPdf).then((res) =>
                res.arrayBuffer()
            );

            // Load a PDFDocument from the existing PDF bytes
            const pdfDoc = await PDFDocument.load(existingPdfBytes);
            pdfDoc.setTitle('QR-Codes')
            pdfDoc.registerFontkit(fontkit);

            const robotoBytes = await fetch(this.roboto).then((res) =>
                res.arrayBuffer()
            );

            const robotoFont = await pdfDoc.embedFont(robotoBytes);

            // Get the first page of the document
            const pages = pdfDoc.getPages();
            const firstPage = pages[0];

            // Get the width and height of the first page
            const {width, height} = firstPage.getSize();

            const fontsize = this.qrCodeSize / 6;
            // Calculate how many qr codes fit horizontally
            let qrCodesHorizontal = Math.floor((width - 20) / (this.qrCodeSize * 1.125 + 10));
            if(qrCodesHorizontal > this.selectedItems.length) {
                qrCodesHorizontal = this.selectedItems.length;
            }
            const spaceBetweenQrCodesHorizontal = 10 + this.qrCodeSize / 8;
            // Calculate how many qr codes fit vertically
            let qrCodesVertical = Math.floor((height - 350) / (this.qrCodeSize + fontsize));
            const spaceBetweenQrCodesVertical = (height - qrCodesVertical * (this.qrCodeSize + fontsize)) / (qrCodesVertical + 1);
            const rowsNeeded = Math.ceil(this.selectedItems.length / qrCodesHorizontal);
            const pagesNeeded = Math.ceil(rowsNeeded / qrCodesVertical);
            const qrCodesOnOnePage = qrCodesHorizontal * qrCodesVertical;
            const allPages = [firstPage];
            // Add further pages if needed
            for(let p = 1; p < pagesNeeded; p++) {
                const [copiedPage] = await pdfDoc.copyPages(pdfDoc, [0]);
                pdfDoc.addPage(copiedPage);
                allPages.push(copiedPage);
            }

            let currentPage = firstPage;
            let currentPageIndex = 0;
            // Write them on pdf
            for(let i = 0, s = 0; i < this.selectedItems.length; i++, s++) {
                const qrCode = this.$refs['qr' + this.selectedItems[i]][0].$refs["qrcode-vue"].toDataURL(
                    "image/png"
                );
                const png = await pdfDoc.embedPng(qrCode);
                if(s === qrCodesOnOnePage) {
                    s = 0;
                    currentPageIndex++;
                    currentPage = pdfDoc.getPages()[currentPageIndex];
                }
                // x Pos of qr code. Ranges from 0 to qrCodesHorizontal.
                const xPos = s % qrCodesHorizontal;
                // y Pos of qr code. Ranges from 0 to qrCodesVertical.
                const yPos = Math.floor(s / qrCodesHorizontal);
                currentPage.drawImage(png, {
                    x: (xPos + 1) * spaceBetweenQrCodesHorizontal + xPos * this.qrCodeSize,
                    y: height - ((yPos + 1) * spaceBetweenQrCodesVertical + (yPos + 1) * this.qrCodeSize + yPos * fontsize),
                    width: this.qrCodeSize,
                    height: this.qrCodeSize,
                });
                currentPage.drawText(this.selectedItems[i], {
                    x: (xPos + 1) * spaceBetweenQrCodesHorizontal + xPos * this.qrCodeSize,
                    y: height - ((yPos + 1) * spaceBetweenQrCodesVertical + (yPos + 1) * this.qrCodeSize + yPos * fontsize + fontsize),
                    size: fontsize,
                    font: robotoFont,
                });
            }

            // Serialize the PDFDocument to bytes (a Uint8Array)
            const pdfBytes = await pdfDoc.save();
            const blob = new Blob([pdfBytes], {type: "application/pdf"});
            this.qrCodesPdf = URL.createObjectURL(blob);
        },

        downloadQrCodePdf() {
            const link = document.createElement("a");
            link.setAttribute('download', 'QR-Codes');
            link.href = this.qrCodesPdf;
            document.body.appendChild(link);
            link.click();
            link.remove();
            this.choosingTemplate = false;
        },
    }
}
</script>

<style scoped>
    .exchangeWidgetHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 60px;
        background-color: var(--v-headerblue-base);
    }

    .requiredField {
        border: 2px solid var(--v-error-base);
        border-radius: 2px;
    }

    .exchangeWidgetBaseButton {
        color: white;
        max-height: 40px;
        padding: 0 !important;
        border-radius: 8px;
        min-width: 40px !important;
        width: 40px;
        align-content: center;
        justify-content: center;
    }

    .defaultWhite {
        background-color: var(--v-weiss-base);
    }

    .scanModeButton {
        position: absolute;
        border-radius: 30px;
        bottom: 50px;
        right: 16px;
        background-color: var(--v-success-base);
    }

    .qrCodeIcon {
        height: 38px;
    }

    .createQrCodesCard {
        width: 70vw;
        height: 90vh;
        margin: auto;
    }

    .qrCodeSizeSlider {
        width: 100%;
        margin-top: 16px;
    }

    .sheet {
        border-radius: 8px;
        padding: 12px;
        background-color: white;
    }

    .topSheet {
        height: 40%;
    }

    .middleSheet {
        height: 40%;
    }

    .bottomSheet {
        height: 20%;
    }

    .tutorial {
        width: 70%;
        background-color: white;
    }

    .openScannerBtn {
        width: 30%;
        background-color: #3aaa33;
    }
</style>

// Classes for fullscreen scanner
<style scoped lang="scss">
    .scannerContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: white;
        width: 100vw;
        height: 100vh;
    }

    .scannedContentOverlay {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0.9;
        width: 100vw;
        height: 100vh;
        z-index: 9999;
    }

    .scannerDialogClose {
        position: absolute;
        right: 24px;
        top: 8px;
        min-width: 40px !important;
        max-width: 40px;
        min-height: 40px;
        max-height: 40px;
        background-color: #e6221e80 !important;
    }

    .scannedContent {
        display: flex;
        flex-direction: column;
        width: 22.5vw;
        margin: 1vh 0 0 1vw;
        min-width: 180px;
    }

    .scanFieldText {
        font-weight: bold;
    }

    .qrCodeScanner {
        height: 100vh;
        width: 100vw;
        margin: auto;
        overflow: hidden;
    }

    .qrCodeScanningOptions {
        position: absolute;
        top: 8px;
        right: 8px;
        color: white;
    }

    .qrCodeVisor {
        position: absolute;
        width: 30% !important;
        height: 0 !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        filter: opacity(50%);

        div {
            width: 100%;
            padding-top: 100%; /* 1:1 Aspect Ratio */
            position: relative; /* If you want text inside of it */
            transform: translate(0%, -25%);
            img {
                display: block;
                width: 100%;
                height: 100%;
                transform: translate(0%, -100%);
            }
        }
    }

    .dueDateExceeded {
        color: red;
    }
    .exchangeTable > td{
        padding: 0 6px !important;
    }
</style>
