<template>
    <div style="justify-content: flex-end; align-items: flex-end; position: absolute; pointer-events: none;">
        <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
                <v-btn
                    x-small
                    icon
                    elevation="0"
                    class="ml-3 pa-0 menuButton menuPosition mt-3"
                    :style="windowWidth < 900 ? 'margin-right: 1.5vw' : ''"
                    v-on="{ ...tooltip }"
                    @click="toggleMenu"
                >
                    <img
                        :src="menuIcon"
                        style="height: 40px; filter: brightness(1000%); opacity: 1;"
                        :alt="openMenu ? 'Menü schließen' : 'Menü öffnen'"
                    >
                </v-btn>
            </template>
            <span>Weitere Aktionen</span>
        </v-tooltip>
        <div
            v-if="openMenu"
            class="previewTileMenuContainer"
        >
            <div
                class="d-flex justify-center align-center mt-1"
                style="display: flex; flex-direction: column; overflow-x: hidden; overflow-y: auto; width: 170px;"
            >
                <v-btn
                    v-if="enableImageRotation"
                    elevation="0"
                    color="white"
                    class="pa-0 previewTileMenuElement no-uppercase"
                    @click="emitButtonPress('rotate')"
                >
                    <v-icon
                        class="previewTileMenuElementIcon"
                        alt=""
                    >
                        mdi-rotate-right
                    </v-icon>
                    <p
                        class="pl-2"
                    >
                        Bild drehen
                    </p>
                </v-btn>
                <v-btn
                    v-if="showSubmissions"
                    elevation="0"
                    color="white"
                    class="pa-0 no-uppercase previewTileMenuElement"
                    @click="emitButtonPress('openSubmissions')"
                >
                    <v-icon
                        class="previewTileMenuElementIcon"
                        aria-label="Abgaben einsehen"
                    >
                        mdi-folder-open
                    </v-icon>
                    <p
                        class="pl-2"
                    >
                        Abgaben einsehen
                    </p>
                </v-btn>
                <v-btn
                    v-if="audioCompatible"
                    elevation="0"
                    color="white"
                    class="pa-0 no-uppercase previewTileMenuElement"
                    @click="emitButtonPress('playAudio')"
                >
                    <img
                        class="pl-3"
                        :src="musikIcon"
                        style="height: 25px; opacity: 1"
                        alt=""
                    >
                    <p
                        class="pl-2"
                    >
                        Audiodatei anhören
                    </p>
                </v-btn>
                <v-btn
                    v-if="vfsUsage"
                    elevation="0"
                    color="white"
                    class="pa-0 text-capitalize previewTileMenuElement"
                    @click="emitButtonPress('notifyCopyOrMoveFile')"
                >
                    <v-icon
                        class="previewTileMenuElementIcon"
                        aria-label="Abgaben einsehen"
                    >
                        mdi-file-move-outline
                    </v-icon>
                    <p
                        class="pl-2"
                    >
                        Kopieren
                    </p>
                </v-btn>
                <v-btn
                    v-if="saveToFolder"
                    elevation="0"
                    color="white"
                    class="pa-0 no-uppercase previewTileMenuElement"
                    @click="emitButtonPress('openSaveToFolderDialog')"
                >
                    <v-icon
                        class="previewTileMenuElementIcon"
                        alt=""
                    >
                        mdi-file-move-outline
                    </v-icon>
                    <p
                        class="pl-2"
                    >
                        Im Ordner speichern
                    </p>
                </v-btn>
                <v-btn
                    v-if="downloadable"
                    elevation="0"
                    color="white"
                    class="pa-0 text-capitalize previewTileMenuElement"
                    @click="emitButtonPress('download')"
                >
                    <img
                        class="pl-3"
                        :src="herunterladenIcon"
                        style="height: 25px; opacity: 1"
                        alt="Herunterladen"
                    >
                    <p
                        class="pl-2"
                    >
                        Herunterladen
                    </p>
                </v-btn>
                <v-btn
                    elevation="0"
                    color="white"
                    class="pa-0 text-capitalize previewTileMenuElement"
                    @click="toggleMenu()"
                >
                    <v-icon
                        class="previewTileMenuElementIcon"
                        alt=""
                    >
                        fas fa-times
                    </v-icon>
                    <p
                        class="pl-2"
                    >
                        Abbrechen
                    </p>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import menuIcon from "@/assets/Icons/FaceliftIcons/menu_mehr_weiteres_setup.svg";
import herunterladenIcon from "@/assets/Icons/FaceliftIcons/herunterladen.svg";
import musikIcon from "@/assets/Icons/FaceliftIcons/f-musik.svg";
import {mapState} from "vuex";

export default {
    name: "FilePreviewTileMenu",
    props: {
        enableImageRotation: { required: false, type: Boolean, default: false },
        emitButtonPress: { required: true, type: Function },
        showSubmissions: { required: false, type: Boolean, default: false },
        audioCompatible: { required: false, type: Boolean, default: false },
        vfsUsage: { required: false, type: Boolean, default: false },
        downloadable: { required: false, type: Boolean, default: true },
        saveToFolder: { required: false, type: Boolean, default: false },
    },
    data: () => ({
        menuIcon,
        herunterladenIcon,
        musikIcon,

        openMenu: false
    }),
    computed: {
        ...mapState("util", ["windowWidth"])
    },
    methods: {
        toggleMenu() {
            this.openMenu = !this.openMenu;
            this.$emit('openMenu', this.openMenu);
        }
    }
}
</script>

<style scoped>
.menuPosition {
    right: 5vw;
}

.menuButton {
    height: 35px !important;
    width: 35px !important;
    min-width: 35px !important;
    background-color: transparent;
    position: fixed;
    pointer-events: all;
    box-shadow: 0 1px 1px rgba(0,0,0,0.11),
    0 2px 2px rgba(0,0,0,0.11),
    0 4px 4px rgba(0,0,0,0.11),
    0 8px 8px rgba(0,0,0,0.11),
    0 16px 16px rgba(0,0,0,0.11),
    0 32px 32px rgba(0,0,0,0.11);
    border-radius: 6px;
}

.previewTileMenuContainer {
    width: 90%;
    height: 100%;
    pointer-events: all;
    z-index: 900;
    background-color: var(--v-dunkelgrau-base);
    margin-right: 25px;
}

.previewTileMenuElement {
    width: 95%;
    padding: 5px;
    display: inline-flex;
    justify-content: flex-start;
    border-radius: 5px;
    margin: 1px;
    color: black;
    font-size: x-small;
}

.previewTileMenuElementIcon {
    width: 20px;
    color: var(--v-dunkelgrau-base) !important;
    margin-left: 15px;
}

.no-uppercase {
    text-transform: unset !important;
}
</style>