<template>
    <div>
        <div
            v-if="accountRole === 'teacher' || accountRole === 'maintainer'"
            class="d-flex justify-center align-center"
        >
            <v-btn
                elevation="0"
                class="text-none"
                style="margin: 6px; width: 228px"
                @click="openCropDialog"
            >
                <img
                    :src="bildIcon"
                    class="icon20 mr-2"
                >
                {{ usage === 'profilePicture' ? 'Profilbild hochladen' : 'Bild hochladen' }}
            </v-btn>
        </div>

        <div
            v-if="accountRole === 'teacher' || accountRole === 'maintainer'"
            class="d-flex justify-center align-center"
        >
            <v-btn
                v-if="activateDeleteBtn"
                id="deleteUploadedPictureBtn"
                elevation="0"
                class="text-none"
                style="margin: 6px; width: 228px; color: red"
                @click="clickDelete"
            >
                <img
                    :src="papierkorbIcon"
                    class="icon20 iconToRed mr-2"
                >
                {{ 'Bild löschen' }}
            </v-btn>
        </div>
        <v-dialog
            v-model="cropDialog"
            width="600"
        >
            <v-card>
                <v-card-title>
                    <h1>
                        {{ usage === 'profilePicture' ? 'Profilbild zuschneiden' : 'Eigenes Bild zuschneiden' }}
                    </h1>

                    <v-spacer />

                    <v-btn
                        x-small
                        elevation="0"
                        style="width: 30px; height: 30px"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon20"
                            alt="Dialog schließen"
                            @click="closeCropDialog"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <div class="d-flex justify-end align-center mb-2">
                        <v-spacer />

                        <v-btn
                            class="text-none"
                            elevation="0"
                            @click="closeCropDialog"
                        >
                            Abbrechen
                        </v-btn>

                        <v-btn
                            class="text-none ml-2"
                            elevation="0"
                            :disabled="!fileSelected"
                            @click="clickCropperSaveBtn"
                        >
                            Hochladen
                        </v-btn>
                    </div>

                    <vue-anka-cropper
                        :options="{
                            aspectRatio: 1,
                            closeOnSave: true,
                            cropArea: 'box',
                            croppedHeight: croppedHeight,
                            croppedWidth: croppedWidth,
                            cropperHeight: 800,
                            dropareaMessage: 'Bild hier ablegen oder Knopf nutzen',
                            layoutBreakpoint: 900,
                            maxFileSize: 5000000,
                            selectButtonLabel: 'Bild auswählen',
                            skin: 'eklara',
                            showPreview: false
                        }"
                        @cropper-saved="processData"
                        @cropper-file-selected="fileSelected = true"
                    />

                    <p class="pt-4 text-center red--text">
                        <b>Stellen Sie sicher, dass Sie die Nutzungsrechte am Bild besitzen.
                            Das Bild können andere Nutzer sehen. Die Dateigröße ist auf maximal 5 MB beschränkt.</b>
                    </p>
                </v-card-text>
            </v-card>
        </v-dialog>
        <are-you-sure-popup
            :show-dialog="showDeleteDialog"
            :content-text-prop="deleteText"
            :usage="'ImageUpload'"
            :headerColor="'#343e55'"
            ok-btn-color="frot"
            @ok="confirmDelete"
            @close="showDeleteDialog = false"
        />
    </div>
</template>
<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import vueAnkaCropper from 'vue-anka-cropper';
import * as backend from "@/api/backend";
import cancelIcon from "@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import bildIcon from "@/assets/Icons/FaceliftIcons/bild.svg";
import papierkorbIcon from "@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg";
import AreYouSurePopup from './AreYouSurePopup.vue';

export default {
    name: 'ImageUpload',
    components: { vueAnkaCropper, AreYouSurePopup },
    props:{
        usage: { type: String, required: false, default: '' },
        iconToDelete: { type: String, required: false },
        activateDeleteBtn: { type: Boolean, required: false, default: false },
    },

    data: () => ({
        cancelIcon,
        bildIcon,
        papierkorbIcon,
        profilePictureFile: null,
        profilePicXmlHttpRequest: null,
        cropDialog: false,
        profilePictureBlob: null,
        showProfilePicUploadProgress: false,
        profilePicUploadProgress: 0.0,
        fileSelected: false,
        account: null,
        showDeleteDialog: false,
        deleteText: 'Icon in Verwendung. Löschen führt zur Löschung an allen Orten und zum Ersatz durch Beispiel-Icon. Trotzdem löschen? ',
        croppedHeight: 400,
        croppedWidth: 400,
    }),
    computed: {
        ...mapGetters('auth', ['accountRole']),
        ...mapGetters('teachers', ['teachersByAccountId']),
    },
    watch: {
    },
    async mounted() {
        this.account = await this.getAccount();
        if(this.usage === 'pinboardUsage') {
            this.croppedHeight = 150;
            this.croppedWidth = 150;
        }
    },
    methods:{
        ...mapActions('auth', ['getAccount']),
        ...mapActions('avatar', ['setAvatarPickerOpen', 'setAvatarChanged']),
        ...mapActions('maintainers', ['getMaintainers', 'getMaintainerProfilePicture', 'deleteMaintainerProfilePicture']),
        ...mapActions('teachers', ['getTeachers', 'getProfilePicture', 'deleteProfilePicture']),
        ...mapMutations('snackbar', ['showSnackbar']),

        clickCropperSaveBtn() {
            const collection = document.getElementsByClassName("ankaCropper__saveButton");
            collection[0].click();
        },
        async processData(cropData){
            this.profilePictureFile = cropData.croppedFile;
             this.profilePictureFile.originalname = cropData.originalFile.name;

             if(this.usage==='profilePicture'){
                await this.uploadFile(this.profilePictureFile);
             }else{
                await this.uploadIconFile(this.profilePictureFile);

             }
        },
        async uploadFile(data) {
            // this.profilePictureFile = cropData.croppedFile;
            // this.profilePictureFile.originalname = cropData.originalFile.name;

            const date = new Date().toISOString().substring(0, 10).replaceAll('-', '');

            if (data) {
                const formData = new FormData();
                // Cropper has a default mime type of jpg so file extension is .jpg here
                    formData.append("file", data, `${this.account._id}ProfilePicture${date}.jpg`);

                let userId;
                    if (this.accountRole === 'teacher') {
                        const thisTeacher = this.teachersByAccountId[this.account._id];
                        userId = thisTeacher._id;

                        this.profilePicXmlHttpRequest = backend.postTeacherPicture(
                            userId,
                            data
                        );
                    } else if (this.accountRole === 'maintainer') {
                        let allMaintainers = await this.getMaintainers();
                        let thisMaintainer = allMaintainers.find(el => el.account === this.account._id);
                        userId = thisMaintainer._id;
                        this.profilePicXmlHttpRequest = backend.postMaintainerProfilePicture(
                            userId,
                            data
                        );
                    }
                this.profilePicXmlHttpRequest.onerror = (e) => {
                    console.error("Upload error:", e);
                    this.showProfilePicUploadProgress = false;
                    this.profilePicXmlHttpRequest = null;
                };

                this.profilePicXmlHttpRequest.onabort = (e) => {
                    console.warn("Upload aborted");
                    this.showProfilePicUploadProgress = false;
                    this.profilePicXmlHttpRequest = null;
                };

                this.profilePicXmlHttpRequest.upload.addEventListener("progress", (e) => {
                    this.profilePicUploadProgress = (e.loaded / e.total) * 100;
                });

                this.profilePicXmlHttpRequest.addEventListener("load", (e) => {
                    if (this.profilePicXmlHttpRequest.status !== 201)
                        console.error(
                            "Profile picture upload failed:",
                            this.profilePicXmlHttpRequest.response
                        );
                    this.showProfilePicUploadProgress = false;
                    this.showSnackbar({ message: 'Gespeichert!'});
                    this.profilePicXmlHttpRequest = null;
                });

                this.profilePicUploadProgress = 0.0;
                this.showProfilePicUploadProgress = true;
                this.profilePicXmlHttpRequest.send(formData);
                this.$emit('uploaded');
            }
            this.closeCropDialog();
            this.setAvatarChanged(true);
        },
        async clickDelete(){
            this.usage==='profilePicture' ? await this.deleteProfilePic() :
            await this.deleteIcon()
        },
        async deleteProfilePic() {
            let res;
            if (this.accountRole === 'teacher') {
                const thisTeacher = this.teachersByAccountId[this.account._id];
                res = await this.deleteProfilePicture(thisTeacher._id);
            } else if (this.accountRole === 'maintainer') {
                let allMaintainers = await this.getMaintainers();
                let thisMaintainer = allMaintainers.find(el => el.account === this.account._id);
                res = await this.deleteMaintainerProfilePicture(thisMaintainer._id);
            }
            if (res === 204) {
                this.showSnackbar({ message: 'Hochgeladenes Profilbild erfolgreich gelöscht'});
                this.profilePictureBlob = null;
                this.$emit('deleted');
            } else {
                this.showSnackbar({ message: 'Beim Löschen des Profilbilds ist ein Fehler aufgetreten', color: "error" });
            }
        },
        async uploadIconFile(iconFile){
            const today = new Date();
            const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date+' '+time;
            if (iconFile) {
                //warum geht hier name verloren?
                const res = await backend.postIconUploadFile(iconFile);
                const fileInfo = await res.json();

                const uploadResponse = await backend.postIconUpload({
                    title: iconFile.name,
                    uploadDate: dateTime,
                    file: fileInfo._id,
                    uploader: this.account._id,
                    });
                if(uploadResponse.status === 201){
                    this.showSnackbar({ message: 'Icon erfolgreich hochgeladen', color: "success" });
                    const res = await uploadResponse.json()
                    this.$emit('imageUpload', res);
                }else {
                    this.showSnackbar({ message: 'Beim hochladen des Icons ist etwas schiefgelaufen! Bitte neu laden', color: "error"  });
                }
                this.closeCropDialog();
            }
        },
        async deleteIcon() {
            if (this.iconToDelete !== "defaultIcon") {
                const iconId = this.iconToDelete;
                const res = await backend.checkForDelete(iconId);
                if(res.status === 200){
                    await this.confirmDelete();
                } else {
                    this.showDeleteDialog = true;
                }
            } else {
                this.showSnackbar({ message: 'Standard Icons können nicht gelöscht werden', color: "error"  });
            }

        },
        async confirmDelete() {
            const deleteRes = await backend.deleteIconUpload( this.iconToDelete);
             if (deleteRes.status === 204) {
                this.showSnackbar({ message: 'Icon erfolgreich gelöscht'});
                this.$emit('iconDeleted');
            } else {
                this.showSnackbar({ message: 'Beim Löschen des Icons ist ein Fehler aufgetreten', color: "error" });
            }
            this.showDeleteDialog = false;
        },
        openCropDialog() {
            this.cropDialog = true;
            this.$emit('upload');
        },
        closeCropDialog() {
            this.cropDialog = false;
            this.$emit('finished');
        },
    }
}
</script>
