<template>
    <div class="d-flex flex-row">
        <div
            v-if="contentCard.position_x === 0 && dragColumnMode"
            style="width:1.3rem;"
        />
        <div
            :key="forceRerender"
            class="d-flex"
        >
            <!-- <div
                class="d-flex"
                :class="isSelected(contentCard._id)? 'selectedBorder':''"
            > -->
            <v-card
                v-click-outside="enableFilePreview ? {} : clickOutsideCardElement"
                class="pinboardCardBody "
                :class="visitorMode ? 'defaultCursor' : contentCard.position_x === 0 && dragColumnMode ? 'pinboardCardBodyPositionX0':''"
                :style="`background-color: ${contentCardColor};`"
                style="cursor: pointer; min-height: 200px"
                elevation="0"
                :draggable="dragMode"
                :ripple="!visitorMode"
                @dragstart="dragElementAction"
                v-on="enableClick ? { click: () => {!dragMode ? editPinboardCard(false) : {} } } : {}"
            >
                <v-icon
                    v-if="dragMode && isSelected(contentCard._id)"
                    color="white"
                    style="position:absolute; right: 0.25rem; top:0.25rem;"
                    large
                >
                    mdi-cursor-move
                </v-icon>
                <v-card-title class="d-flex flex-row-reverse cardTitle">
                    <div v-if="editContent">
                        <input
                            id="fileUpload"
                            ref="fileUpload"
                            type="file"
                            hidden
                            @change="uploadInputChange"
                        >
                    </div>
                </v-card-title>
                <v-card-text style="font-size: 30px !important; margin-top: -15px">
                    <div class="ql-editor">
                        <!-- eslint-disable vue/no-v-html -->
                        <p
                            v-if="!editContent && cardContent !== ''"
                            :class="visitorMode ? 'defaultCursor' : ''"
                            style="overflow: visible; color: black; font-size: 18px"
                            v-html="sanitizeHtml(urlify(cardContent))"
                        />
                        <!-- eslint-enable vue/no-v-html -->
                        <p
                            v-if="!editContent && cardContent === ''"
                            :class="visitorMode ? 'defaultCursor' : ''"
                            style="overflow: visible; color: black; font-size: 18px"
                        >
                            Notiz
                        </p>
                    </div>
                    <v-row
                        v-if="filePreviews.length > 0 && !editContent"
                        class="d-flex justify-flex-start mt-2"
                        style="align-items:center; margin-left: 2px !important"
                    >
                        <div
                            v-for="file in filePreviews"
                            :key="file.thumbnail"
                            :class="file.isDeleted ? 'outdated-image' : ''"
                            style="width: 100%; height: 100%"
                        >
                            <div
                                v-if="(['wmv', 'flv', 'mpg'].includes(file.originalname.split('.').pop().toLowerCase()) || file['mimetype'].split('/')[0] === 'video')"
                                class="py-0 justify-center"
                                style="margin-bottom: 5px; margin-left: -8px"
                            >
                                <video
                                    :src="file.thumbnail"
                                    width="98%"
                                    height="202px"
                                    controls
                                    @play="enableClick = false"
                                    @pause="enableClick = true"
                                    @ended="enableClick = true"
                                />
                            </div>
                            <div
                                v-else-if="['wav', 'mp3', 'wma', 'm4a', 'acc', 'ogg'].includes(file.originalname.split('.').pop().toLowerCase())"
                                class="py-0 justify-center"
                                style="margin-bottom: 5px; overflow-x:hidden; margin-left: -8px"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-flex
                                            align-self-start
                                            class="d-flex flex-row displayFileContainer"
                                            @click="() => { playAudio(file); $emit('closeMenu'); }"
                                            @mouseover="enableClick = false"
                                            @mouseleave="enableClick = true"
                                            v-on="on"
                                        >
                                            <v-icon
                                                class="mb-2"
                                                color="#414141"
                                                size="45px"
                                                contain
                                            >
                                                mdi-music-note
                                            </v-icon>
                                            <p class="fileName">
                                                {{ file.isDeleted ? 'Gelöscht' : '' + file.uploadedName }}
                                            </p>
                                        </v-flex>
                                    </template>
                                    <p>{{ file.isDeleted ? 'Gelöscht' : '' + file.uploadedName }}</p>
                                </v-tooltip>
                            </div>
                            <div
                                v-else-if="isFileThumbnailCompatibleExtension(file) && !file.mimetype.includes('video')"
                                class="justify-center thumbnailDisplayNormal"
                                style="margin-bottom: 5px; overflow:hidden; width: 100%; height: 100%;"
                            >
                                <v-img
                                    v-if="file.hasThumbnail"
                                    :src="file.thumbnail"
                                    :lazy-src="dateiIcon"
                                    style="border-radius: 5px; min-width: 30px; min-height: 30px"
                                    @mouseover.native="enableClick = false"
                                    @mouseleave.native="enableClick = true"
                                    @click="() => loadFullscreenPreview(file)"
                                >
                                    <div class="fill-height bottom-gradient" />
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height fill-width ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <!-- <v-progress-circular
                                                indeterminate
                                                color="white"
                                                size="30"
                                            /> -->
                                        </v-row>
                                    </template>
                                </v-img>
                                <v-img
                                    v-else
                                    :src="dateiIcon"
                                    style="border-radius: 5px; width: 100%; height: 100%;"
                                    @mouseover.native="enableClick = false"
                                    @mouseleave.native="enableClick = true"
                                    @click="() => loadFullscreenPreview(file)"
                                />
                            </div>
                            <div
                                v-else
                                class="py-0 justify-center"
                                style="margin-bottom: 5px; overflow-x:hidden; margin-left: -8px"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-flex
                                            align-self-start
                                            class="d-flex flex-row displayFileContainer"
                                            @click="() => clickDownload(file)"
                                            @mouseover="enableClick = false"
                                            @mouseleave="enableClick = true"
                                            v-on="on"
                                        >
                                            <v-icon
                                                class="mb-2"
                                                color="#414141"
                                                size="45px"
                                                contain
                                            >
                                                mdi-file
                                            </v-icon>
                                            <p class="fileName">
                                                {{ file.uploadedName }}
                                            </p>
                                        </v-flex>
                                    </template>
                                    <p>{{ file.uploadedName }}</p>
                                </v-tooltip>
                            </div>
                        </div>
                    </v-row>
                    <div v-if="editContent">
                        <advanced-quill-editor
                            v-model="cardContent"
                            :pinboard-card="true"
                            style="width: 100%; min-height: 180px; overflow: auto;"
                            class="quillEditorPinboardCardTextSize"
                            placeholder="Text eingeben"
                            @linkClicked="showLinkExplanation"
                        />
                        <div
                            v-for="(item, index) in filePreviews"
                            :key="index"
                            class="uploadPreview"
                        >
                            <div class="uploadPreviewHeader ellipsis">
                                <p
                                    class="ellipsis"
                                    style="color: white"
                                >
                                    {{ item.uploadedName }}
                                </p>

                                <v-btn
                                    height="25"
                                    width="25"
                                    icon
                                    dark
                                    @click="removeUploadedFile(item)"
                                >
                                    <img
                                        class="iconToGrey icon20"
                                        :src="cancelIcon"
                                        alt="schließen"
                                    >
                                </v-btn>
                            </div>
                            <v-img
                                v-if="['pdf'].includes(item.originalname.split('.').pop().toLowerCase())"
                                class="pa-1 thumbnailDisplayNormal mx-auto"
                                style="min-height: 50px;"
                                @click="() => loadFullscreenPreview(item)"
                            >
                                <v-icon
                                    class="mb-2 iconToDunkelgrau "
                                    size="100px"
                                    contain
                                >
                                    mdi-file-pdf-box
                                </v-icon>
                            </v-img>
                            <div
                                v-else-if="(item['mimetype'].split('/')[0] === 'image')"
                                class="displayWholeThumbnail"
                                style="overflow:hidden"
                                @click="() => loadFullscreenPreview(item)"
                            >
                                <v-img
                                    :src="item.thumbnail"
                                >
                                    <div class="fill-height bottom-gradient" />
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height fill-width ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <!-- <v-progress-circular
                                                indeterminate
                                                color="grey"
                                            /> -->
                                        </v-row>
                                    </template>
                                </v-img>
                            </div>
                            <div
                                v-else-if="(['wmv', 'flv', 'mpg'].includes(item.originalname.split('.').pop().toLowerCase()) || item['mimetype'].split('/')[0] === 'video')"
                                class="pa-1 thumbnailDisplayNormal fill-height bottom-gradient mx-auto"
                                style="margin-bottom: 5px; overflow:hidden"
                            >
                                <v-img
                                    :src="videoIcon"
                                    class="mb-2 iconToDunkelgrau"
                                    alt="Video"
                                    contain
                                    max-height="120px"
                                />
                            </div>
                            <div
                                v-else-if="['wav', 'mp3', 'wma', 'm4a', 'acc', 'ogg'].includes(item.originalname.split('.').pop().toLowerCase())"
                                class="pa-1 thumbnailDisplayNormal fill-height bottom-gradient mx-auto"
                                style="margin-bottom: 5px; overflow:hidden"
                            >
                                <v-icon
                                    class="mb-2 iconToDunkelgrau "
                                    size="100px"
                                    contain
                                >
                                    mdi-music-note
                                </v-icon>
                            </div>
                            <div
                                v-else-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(item.originalname.split('.').pop().toLowerCase())"
                                class="pa-1 thumbnailDisplayNormal fill-height bottom-gradient mx-auto"
                                style="margin-bottom: 5px; overflow:hidden"
                            >
                                <v-icon
                                    class="mb-2 iconToDunkelgrau "
                                    size="100px"
                                    contain
                                >
                                    {{ uploadIcon(item.originalname) }}
                                </v-icon>
                            </div>
                        </div>
                        <div
                            v-for="(item, index) in filesToUpload"
                            :key="index"
                            class="uploadPreview"
                        >
                            <div class="uploadPreviewHeader ellipsis">
                                <p
                                    class="ellipsis"
                                    style="color: white"
                                >
                                    {{ item.name }}
                                </p>

                                <v-btn
                                    height="25"
                                    width="25"
                                    icon
                                    dark
                                    @click="removeFileToUpload(item.lastModified)"
                                >
                                    <img
                                        class="iconToGrey icon20"
                                        :src="cancelIcon"
                                        alt="Datei entfernen"
                                    >
                                </v-btn>
                            </div>
                            <v-img
                                v-if="item.thumbnail"
                                :src="getThumb(item.thumbnail)"
                                class="pa-1 thumbnailDisplayNormal mx-auto"
                            >
                                <div class="fill-height bottom-gradient" />
                                <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height fill-width ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <!-- <v-progress-circular
                                            indeterminate
                                            color="grey"
                                        /> -->
                                    </v-row>
                                </template>
                            </v-img>
                            <div
                                v-else-if="(item['type'].split('/')[0] === 'image')"
                                class="displayWholeThumbnail"
                                style="overflow:hidden"
                            >
                                <v-img
                                    :src="loadPicture(item)"
                                >
                                    <div class="fill-height bottom-gradient" />
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height fill-width ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <!-- <v-progress-circular
                                                indeterminate
                                                color="grey"
                                            /> -->
                                        </v-row>
                                    </template>
                                </v-img>
                            </div>
                            <div
                                v-else-if="(['wmv', 'flv', 'mpg'].includes(item.name.split('.').pop().toLowerCase()) || item['type'].split('/')[0] === 'video')"
                                class="pa-1 thumbnailDisplayNormal fill-height bottom-gradient mx-auto"
                                style="margin-bottom: 5px; overflow:hidden"
                            >
                                <v-img
                                    :src="videoIcon"
                                    class="mb-2 iconToDunkelgrau"
                                    alt="Video"
                                    contain
                                />
                            </div>
                            <div
                                v-else-if="['wav', 'mp3', 'wma', 'm4a', 'acc', 'ogg'].includes(item.name.split('.').pop().toLowerCase())"
                                class="pa-1 thumbnailDisplayNormal fill-height bottom-gradient mx-auto"
                                style="margin-bottom: 5px; overflow:hidden"
                            >
                                <v-icon
                                    class="mb-2 iconToDunkelgrau "
                                    size="100px"
                                    contain
                                >
                                    mdi-music-note
                                </v-icon>
                            </div>
                            <div
                                v-else-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(item.name.split('.').pop().toLowerCase())"
                                class="pa-1 thumbnailDisplayNormal fill-height bottom-gradient mx-auto"
                                style="margin-bottom: 5px; overflow:hidden"
                            >
                                <v-icon
                                    class="mb-2 iconToDunkelgrau"
                                    size="100px"
                                    contain
                                >
                                    {{ uploadIcon(item.name) }}
                                </v-icon>
                            </div>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
            <!-- </div> -->
            <v-card
                v-if="!editContent"
                class="pinboardCardButtonBodyNoEdit mt-3 buttonWindow"
                elevation="0"
            >
                <v-tooltip
                    v-if="membershipRole !== 'visitor'"
                    class="buttonWindow"
                    top
                >
                    <template
                        v-slot:activator="{ on, attrs }"
                    >
                        <v-btn
                            icon
                            class="buttonWindow"
                            v-bind="attrs"
                            v-on="on"
                            @click="editPinboardCard(true)"
                        >
                            <img
                                class="iconToGrey buttonWindow"
                                style="max-width: 20px"
                                alt="Notiz bearbeiten"
                                :src="stiftIconDark"
                            >
                        </v-btn>
                    </template>
                    <span
                        class="buttonWindow"
                    >Notiz bearbeiten</span>
                </v-tooltip>
            </v-card>
            <v-card
                v-else
                class="pinboardCardButtonBody mt-3 buttonWindow"
                :style="editorAccess && (accountRole === 'pupil' || accountRole === 'parent') ? 'height: 210px' : editorAccess || (accountRole === 'pupil' || accountRole === 'parent') ? 'height: 183px' : 'height: 109px'"
                elevation="0"
            >
                <v-tooltip
                    v-if="editorAccess"
                    top
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            class="buttonWindow"
                            v-bind="attrs"
                            v-on="on"
                            @click="openEmptyEdit"
                        >
                            <img
                                :src="neuesABIcon"
                                class="buttonWindow"
                                style="height: 25px"
                                alt="Leeren Editor öffnen"
                            >
                        </v-btn>
                    </template>
                    <span>Datei im Editor erstellen</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            class="buttonWindow"
                            v-bind="attrs"
                            v-on="on"
                            @click="clickUpload"
                        >
                            <img
                                :src="paperclipIcon"
                                class="iconToDunkelgrau pa-0 buttonWindow"
                                style="max-width: 23px"
                                alt="Hochladen"
                            >
                        </v-btn>
                    </template>
                    <span>Datei hochladen</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            class="buttonWindow"
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteThisCard()"
                        >
                            <img
                                :src="papierkorbIcon"
                                class="iconToRed pa-0 buttonWindow"
                                style="max-width: 23px; margin-left: 2px"
                                alt="Notiz löschen"
                            >
                        </v-btn>
                    </template>
                    <span>Notiz löschen</span>
                </v-tooltip>
                <div
                    :id="`colorPickerContainer${position_x}${position_y}`"
                    style="display: inline"
                    class="buttonWindow"
                >
                    <PinboardColorPicker
                        class="buttonWindow"
                        :attach="`colorPickerContainer${position_x}${position_y}`"
                        :change-card-color="changeCardColor"
                        :card-color="contentCardColor"
                    />
                </div>
                
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            class="buttonWindow"
                            v-bind="attrs"
                            v-on="on"
                            @click="openEditMode()"
                        >
                            <v-icon color="grey">
                                mdi-cursor-move
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Notiz verschieben</span>
                </v-tooltip>
                <v-tooltip
                    :disabled="!showTooltips"
                    top
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-if="accountRole === 'pupil' || accountRole === 'parent'"
                            icon
                            class="buttonWindow"
                            style="margin-left: 2px"
                            v-on="on"
                            @click="readText(sanitizeHtml(urlify(cardContent)))"
                        >
                            <img
                                :src="usedSpeakerIcon"
                                class="icon20"
                                alt="Vorlesen"
                            >
                        </v-btn>
                    </template>
                    <span>Vorlesen</span>
                </v-tooltip>
            </v-card>
        </div>
        <div
            v-if="showLastEdited && contentCard.lastEdited"
            class="d-flex flex-row justify-space-between "
            style="width: 80%; margin-left: -32px; margin-top:-5px; max-height: 30px;"
        >
            <PinboardLastEdited
                :card="contentCard"
                class="pinboardLastEditedWrapper"
            />
        </div>
        <v-overlay
            v-if="audioUrl"
            opacity="0.8"
            z-index="95"
        >
            <div class="audioDialogChild">
                <audio
                    :src="audioUrl"
                    controls
                    :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }"
                >Your browser does not support the audio tag.
                </audio>
                <div class="audioFullScreenOptionsContainer audioMargin">
                    <img
                        :src="cancelIcon"
                        alt="Vollbild schließen"
                        class="iconToWhite pointer optionsIcon"
                        @click="audioUrl = false; audioToDownload = ''"
                    >
                    <img
                        :src="downloadIcon"
                        alt="Audio herunterladen"
                        class="iconToWhite pointer optionsIcon"
                        @click="() => clickDownload(audioToDownload)"
                    >
                </div>
            </div>
        </v-overlay>
        <FilePreviewDialog
            v-if="enableFilePreview"
            ref="previewDialog"
            :files="filePreviews"
            :is-user-author="false"
            :enable-fullscreen-preview="enableFilePreview"
            :preview-selection="previewSelection"
            :preview-information="previewSelection"
            :preview-selection-index="previewSelectionIndex"
            :thumbnail-url="previewSelection"
            :enable-editing="editorAccess"
            @close="resetFilePreview"
            @cycleNext="cycleNext"
            @cyclePrev="cyclePrevious"
            @download="downloadFromFilePreviewDialog(previewSelection._id, previewSelection.uploadedName)"
            @openEditor="openEditor(previewSelection)"
            @openLibre="openLibre(previewSelection)"
        />
    </div>
</template>

<script>
import stiftIconDark from "@/assets/Icons/FaceliftIcons/stift_bearbeiten_.svg";
import papierkorbIcon from "@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg";
import videoIcon from '@/assets/Icons/video.svg';
import cancelIcon from "@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import paperclipIcon from "@/assets/Icons/FaceliftIcons/anhang_bueroklammer.svg";
import downloadIcon from '@/assets/Icons/FaceliftIcons/herunterladen.svg';
import neuesABIcon from "@/assets/Icons/neues-interaktives-arbeitsblatt.svg";
import dateiIcon from '@/assets/Icons/FaceliftIcons/datei_blatt.svg';

import sanitizeHtml from '../../../util/sanitizeHtml';
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import { isFileThumbnailCompatibleExtension } from "../../../util/MimetypeHelper.js"
import AdvancedQuillEditor from "@/components/Utils/AdvancedQuillEditor";
import PinboardColorPicker from "@/components/Pinboard/PinboardCardElements/PinboardColorPicker";
import FilePreviewDialog from "@/components/Utils/FilePreviewDialog";
import {generateThumbnailWithoutFile} from "@/util/tempThumbnailGen";
import EventBus from "../../../util/eventBus";
import * as backend from "../../../api/backend";

import {textToSpeech} from "@/util/textToSpeech";
import lautsprecherAusIcon from "@/assets/Icons/lautsprecher-aus-89.svg";
import lautsprecherIcon from "@/assets/Icons/lautsprecher-13.svg";
import PinboardLastEdited from './PinboardLastEdited.vue';
import urlify from '../../../util/urlify';

export default {
    name: 'PinboardCardElement',
    components: {
        AdvancedQuillEditor,
        PinboardColorPicker,
        FilePreviewDialog,
        PinboardLastEdited,
    },
    props: {
        contentCard: { required: true, type: Object },
        // eslint-disable-next-line
        position_y: { required: true, type: Number },
        // eslint-disable-next-line
        position_x: { required: true, type: Number },
        updateCard: { required: true, type: Function },
        areYouSureCard: { required: true, type: Function },
        contentCardColor: { required: false, type: String, default: '#3cb371' },
        files: {required: true, type: Array },
        pinboard: { required: true, type: Object },
        membershipRole: { required: true, type: String } 
    },
    data: () => ({
        // Icons
        stiftIconDark,
        papierkorbIcon,
        videoIcon,
        cancelIcon,
        paperclipIcon,
        downloadIcon,
        neuesABIcon,
        dateiIcon,

        urlify,
        sanitizeHtml,
        isFileThumbnailCompatibleExtension,

        // PinboardCardElement
        cardContent: '',
        editContent: false,
        enableClick: true,
        filesToUpload: [],
        file: '',
        uploadedFiles: [],
        filePreviews: [],
        audioUrl: null,
        audioToDownload: '',
        pdfToDisplay: '',
        currentCardContent: '',

        // FilePreviewDialog
        enableFilePreview: false,
        previewSelection: { name: 'Vorschau' },
        previewSelectionIndex: 0,
        tempFiles: [],

        forceRerender: 0,

        currentFileThumbnailUrl: '',

        lautsprecherIcon,
        lautsprecherAusIcon,
        usedSpeakerIcon: lautsprecherIcon,
        showLastEdited: false,
    }),
    computed: {
        ...mapState('util', ['windowWidth']),
        ...mapGetters('auth', ['accountRole']),
        ...mapState('translation', ['targetLang']),
        ...mapState("tooltips", ["shortcutTooltips", "showTooltips"]),
        ...mapGetters('pinboard',['dragMode','selected','dragColumnMode']),
        editorAccess() {
            return this.accountRole !== 'maintainer' && this.accountRole !== 'parent' && this.membershipRole !== 'visitor';
        },
        visitorMode(){
            //when dragMode is on, we also want to not edit cards directly so then cards should behave like in visitorRole
            return this.membershipRole === 'visitor' || this.dragMode ;
        }
    },
    watch: {
        async files() {
            this.filePreviews = await Promise.all(this.files.map(async (file) => {
                if (file.hasThumbnail || file.thumbnail) {
                    return file;
                } else {
                    return await this.loadFilePreview(file);
                }
            }));
        },
        contentCard() {
            this.cardContent = this.contentCard.content;
            this.uploadedFiles = this.contentCard.files;
        },
        editContent(newVal){
            if(newVal && newVal === true){
                this.showLastEdited = true;
            }
            if(newVal === false){
                this.showLastEdited = false;
            }
        }
    },
    async mounted() {
        /*
        EventBus.$off('new-thumbnail');
        EventBus.$on('new-thumbnail', async (fileId) => {
            console.log('NEW THUMBNAIL', fileId)
            const foundFile = this.uploadedFiles.find((file) => file._id === fileId);
            if (!foundFile) {
                console.log('NO FILE FOUND', fileId);
                console.log('UPLOADED FILES!', this.uploadedFiles);
                return;
            }
            console.log('FOUND FILE', foundFile);
            this.removeConverterThumbnail(fileId);
            foundFile.hasThumbnail = false;
            const betterFile = await this.loadFilePreview(foundFile);
            this.filePreviews = this.filePreviews.map((file) => {
                if (file._id === betterFile._id) {
                    return betterFile;
                }
                return file;
            });
            console.log('DONE!!', this.filePreviews);
        });
        */
        this.cardContent = this.contentCard.content;
        this.uploadedFiles = this.contentCard.files;
        this.filePreviews = await this.loadFilePreviews(this.contentCard._id);

    },
    beforeDestroy() {
        EventBus.$off('new-thumbnail');
    },
    methods: {
        ...mapMutations('snackbar', ['showSnackbar']),
        ...mapMutations('pinboard', ['removeConverterThumbnail',]),
        ...mapActions('virtualFileSystem', ['getVFSAppFolderByNameV2']),
        ...mapActions('mimeTypes', ['validateMimeType', 'getFileExtension']),
        ...mapActions("pinboard", ['toggleDragMode','getPinboardCardUpload', 'deletePinboardCardUpload', 'getPinboardUploadThumbnail', 'toggleOpenPinboard','selectCard','removeSelectedCard']),
        ...mapActions('translation', ['setTranslatedText', 'showTranslation', 'translateToTargetLang']),
         openEditMode(){
            this.toggleDragMode();
            this.chooseCardToSelect(this.contentCard);
        },
        dragElementAction(e){
        if(this.selected){
            e.dataTransfer.setData("text/plain", this.selected._id);
            e.dataTransfer.dropEffect = "move";
        }

        },
        isSelected(cardId){
            if(this.selected){
                return this.selected._id === cardId;
            }
            return false;
        },
        chooseCardToSelect(card){
            console.log('CARD',card);
            if(this.selected && this.selected._id === card._id){
                this.removeSelectedCard();
                this.forceRerender +=1;
                return
            }
            if(!this.selected){
                this.selectCard(card);
                this.forceRerender+=1;
            this.$emit('selectedCard',card);
            }
        },
        cycleNext() {
            this.previewSelectionIndex++;
            if (this.tempFiles.length === this.previewSelectionIndex) {
                this.previewSelectionIndex = 0;
            }
            this.previewSelection = this.tempFiles[this.previewSelectionIndex];
        },
        cyclePrevious() {
            this.previewSelectionIndex--;
            if (this.previewSelectionIndex < 0) {
                this.previewSelectionIndex = this.tempFiles.length - 1;
            }
            this.previewSelection = this.tempFiles[this.previewSelectionIndex];
        },

        async openLibre (selectedFile) {
            let res = await backend.getLibreDocFromFile(selectedFile._id);
            const doc = await res.json();

            const libreAccessToken = doc.accessToken;
            const libreDocId = doc.libreDoc._id;
            const file = doc.file;
            const fileType = await this.getFileExtension(file.mimetype);

            const query = {
                ... this.$route.query,
                fileId: file._id,
                libreDocId: libreDocId,
                accessToken: libreAccessToken,
                fileAccessToken: selectedFile.accessToken ? selectedFile.accessToken : selectedFile.fileAccessToken,
                fileType: fileType,
                parentComponent: 'pinboard',
                mode: 'default',
            };

            await this.$router.push({
                name: 'libreoffice',
                query,
            });
        },
        deleteThisCard() {
            return this.areYouSureCard(this.contentCard);
        },

        editPinboardCard(button) {
            if ((button)|| (!this.visitorMode)) {
                this.currentCardContent = sanitizeHtml(this.urlify(this.cardContent));
                this.enableClick = false;
                this.editContent = true;
            }
        },

        async clickOutsideCardElement(event) {
            let isClickInsidePinboardLastEdited = event.target.closest('.pinboardLastEditedWrapper');
            if (this.enableClick === false && !isClickInsidePinboardLastEdited && !event.target.classList.contains("buttonWindow") && !event.target.classList.contains("v-btn__content")) {
                this.enableClick = true;
                this.editContent = false;
                if (this.currentCardContent !== this.cardContent || this.filesToUpload.length !== 0) {
                    await this.updateCard(this.cardContent, this.position_x, this.position_y, this.contentCard._id, this.filesToUpload, this.contentCardColor);
                    this.uploadedFiles = this.contentCard.files;
                    this.filesToUpload = [];
                    this.filePreviews = await this.loadFilePreviews(this.contentCard._id);
                }
            }
        },

        clickUpload() {
            document.getElementById("fileUpload").click();
        },

        async uploadInputChange() {
            const fileArray = Array.from(this.$refs.fileUpload.files);
            if (await this.validateMimeType(fileArray)) {
                if (fileArray[0].type.split('/')[0] === 'image') {
                    if (fileArray[0].size > '10000000') { // Prohibit uploading images larger than 10MB to prevent performance issues
                        this.showSnackbar({
                            message: "Die Datei ist zu groß. Bitte versuchen Sie es mit einem anderen Format oder lassen Sie die Datei komprimieren.",
                            color: "error"
                        });
                        return;
                    }
                }
                if ((fileArray[0]['type'] === 'application/pdf')) {
                    await generateThumbnailWithoutFile(fileArray[fileArray.length - 1]).then(r => fileArray[fileArray.length - 1].thumbnail = r);
                }
                this.filesToUpload.push(fileArray[fileArray.length - 1]);
                this.showSnackbar({ message: 'Datei wird hochgeladen.', color: 'success'});
            }
        },

        uploadIcon(name) {
            var icon;
            if (['doc', 'docx', 'txt'].includes(name.split('.').pop().toLowerCase())) {
                icon = "mdi-file-document-outline";
            }
            if (['ppt', 'pptx'].includes(name.split('.').pop().toLowerCase())) {
                icon = "mdi-presentation";
            }
            if (['xls', 'xlsx'].includes(name.split('.').pop().toLowerCase())) {
                icon = "mdi-file-table-outline";
            }
            return icon;
        },

        async clickDownload(file) {
            const url = await this.getPinboardCardUpload({
                cardId: this.contentCard._id,
                fileId: file._id
            });
            const a = document.createElement("a");
            a.href = url;
            a.download = file.uploadedName;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            setTimeout(function () {
                a.remove();
                window.URL.revokeObjectURL(url);
            }, 1000);
        },

        async playAudio(file) {
            const isFileDeleted = this.checkForDeletion(file);
            if (isFileDeleted) return;

            this.audioToDownload = file;
            this.audioUrl = await this.getPinboardCardUpload( { cardId: this.contentCard._id, fileId: file._id } );
        },

        removeFileToUpload(fileId) {
            this.filesToUpload = this.filesToUpload.filter(obj => obj.lastModified !== fileId);
        },

        async removeUploadedFile(file) {
            const response = await this.deletePinboardCardUpload( { cardId: this.contentCard._id, fileId: file._id } );
            if (response.status !== 204) return;
            this.uploadedFiles.splice(this.uploadedFiles.indexOf(file), 1);
            this.filePreviews = await this.loadFilePreviews(this.contentCard._id);
        },
        getThumb(blob) {
            const newFile = URL.createObjectURL(blob);
            this.file = newFile;
            return newFile;
        },

        loadPicture(file) {
            const zwischenBlob = URL.createObjectURL(file);
            return zwischenBlob;
        },
        async loadFilePreview(file) {
            if (file.hasThumbnail) {
                return;
            }
            if (!this.contentCard) {
                return;
            }
            if (file.mimetype.includes('video') || file.mimetype.includes('gif')) {
                const res = await this.getPinboardCardUpload({
                    cardId: this.contentCard._id,
                    fileId: file._id
                });
                file.hasThumbnail = true;
                file.thumbnail = res;
                return file;
            }
            const res = await this.getPinboardUploadThumbnail({
                fileId: file._id,
                pinboardCardId: this.contentCard._id,
                pinboardId: this.pinboard._id
            });
            file.hasThumbnail = res.hasThumbnail;
            file.thumbnail = res.url;
            return file;
        },

        async loadFilePreviews(cardId) {
            return await Promise.all(this.uploadedFiles.map(async (file) => {
                if (file.mimetype.includes('video') || file.mimetype.includes('audio') || file.mimetype.includes('gif')) {
                    file.thumbnail = await this.getPinboardCardUpload({
                        cardId,
                        fileId: file._id
                    });
                    if (file.thumbnail) {
                        file.hasThumbnail = true;
                    }
                } else {
                    const res = await this.getPinboardUploadThumbnail({
                        fileId: file._id,
                        pinboardCardId: cardId,
                        pinboardId: this.pinboard._id
                    });
                    if (res && !res.thumbnail && !res.url) { // If thumbnail was already in store
                        file.hasThumbnail = true;
                        file.thumbnail = res;
                    } else {
                        file.hasThumbnail = res.hasThumbnail;
                        file.thumbnail = res.url;
                    }
                }
                return file;
            }));
        },

        async changeCardColor(color) {
            await this.updateCard(this.cardContent, this.position_x, this.position_y, this.contentCard._id, 'no files', color);
        },

        async openEmptyEdit() {
            let mode;
            if (this.accountRole === 'maintainer' || this.accountRole === 'teacher') {
                mode = 'creator';
            } else {
                mode = 'pupil';
            }

            // get id of users editor app folder (dont hate pls)
            if (!this.editorFolderId) {
                const appFolder = await this.getVFSAppFolderByNameV2({
                    appFolderName: 'Pinnwand'
                });
                this.editorFolderId = appFolder._id;
            }
            this.toggleOpenPinboard(this.pinboard);
            const query = {
                ...this.$route.query,
                color: '#e79a42',
                sharing: false,
                title: 'Pinnwand ' + new Date().toLocaleDateString(),
                pagetype: 'empty',
                targetFolder: this.editorFolderId,
                pinboardCardId: this.contentCard._id
            };
            // Mode is always creator because we open a new file
            await this.$router.push({
                name: 'editor',
                params: {mode: mode},
                query
            });
        },

        async openEditor(pdfToDisplay) {
            let mode;
            if (this.accountRole === 'maintainer' || this.accountRole === 'teacher') {
                mode = 'creator';
            } else {
                mode = 'pupil';
            }

            if (!this.editorFolderId) {
                const appFolder = await this.getVFSAppFolderByNameV2({
                    appFolderName: 'Pinnwand'
                });
                this.editorFolderId = appFolder._id;
            }
            this.toggleOpenPinboard(this.pinboard);
            const query = {
                ...this.$route.query,
                title: pdfToDisplay.uploadedName,
                pinboardCardId: this.contentCard._id,
                fId: pdfToDisplay._id,
                targetFolder: this.editorFolderId,
                ug: 'pinboard'
            };
            await this.$router.push({
                name: 'editor',
                params: {mode: mode},
                query
            });
        },

        loadFullscreenPreview(el) {
            const isFileDeleted = this.checkForDeletion(el);
            if (isFileDeleted) return;

            this.pdfToDisplay = el;
            this.previewSelection = el;
            for (let i = 0; i < this.filePreviews.length; i++) {
                if (el == this.filePreviews[i]) {
                    this.previewSelectionIndex = i;
                }
            }
            this.tempFiles = this.filePreviews;
            this.currentFileThumbnailUrl = el.thumbnail;
            this.enableFilePreview = true;
            setTimeout(() => {
                // this.$refs.previewDialog.$el.focus();
            }, 150);
        },

        resetFilePreview() {
            this.pdfToDisplay = '';
            this.previewSelection = { name: 'Vorschau' };
            this.previewSelectionIndex = 0;
            this.enableFilePreview = false;
        },

        async downloadFromFilePreviewDialog(id, name) {
            const url = await this.getPinboardCardUpload({
                cardId: this.contentCard._id,
                fileId: id
            });
            const a = document.createElement("a");
            a.href = url;
            a.download = name;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            setTimeout(function () {
                a.remove();
                window.URL.revokeObjectURL(url);
            }, 1000);
        },

        checkForDeletion(file) {
            if (file.isDeleted) {
                this.showSnackbar({
                    message: "Aufgrund 30 Tagen Inaktivität ist die Datei nicht mehr verfügbar.",
                    color: "error"
                });
                return true;
            }
            return false;
        },

        async readText(text) {  
            let txt;

            if (text) {
                let tmp = document.createElement("DIV");
                tmp.innerHTML = text;
                txt = tmp.textContent || tmp.innerText || "";
            } else {
                txt = 'Diese Notiz hat keinen Inhalt.';
            }

            if (this.targetLang !== 'de') {
                txt = await this.translateToTargetLang({
                    targetLang: this.targetLang,
                    textToTranslate: txt
                });

                this.setTranslatedText(txt);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)
            }

            await textToSpeech(txt, this.targetLang, () => {
                this.usedSpeakerIcon = this.lautsprecherAusIcon;
            }, () => {
                this.usedSpeakerIcon = this.lautsprecherIcon;
            });
        },
        showLinkExplanation(){
            this.showSnackbar({message: 'Markieren Sie einen Text, klicken Sie auf das Link-Symbol und fügen Sie Ihren Link hinzu.'} );
        },
    }
}
</script>

<style>
.quillEditorPinboardCardTextSize p {
    font-size: 18px !important;
    padding-top: 10px;
}
</style>

<style scoped lang="scss">
.pinboardCardBody {
    background-color: mediumseagreen;
    color: whitesmoke;
    font-size: 30px;
    width: 220.5px;
}
.pinboardCardBodyPositionX0{
    background-color: mediumseagreen;
    color: whitesmoke;
    font-size: 30px;
    width: calc(220.5px - 1.3rem);
}
.pinboardCardButtonBody {
    background-color: white;
    color: rgb(0, 0, 0);
    margin-left: 5px;
    width: 37px;
    box-shadow: 0 0 13px gray !important;
    border-radius: 8px;
}

.pinboardCardButtonBodyNoEdit {
    background-color: transparent;
    height: 75px;
    margin-left: 5px;
    width: 37px;
}

.cardTitle {
    margin-top: -12px;
    margin-right: -7px;
}

.uploadPreview {
    width: 94%;
    border-radius: 8px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #b9b9b9;
    margin-bottom: 3%;
    max-height: 177px;
}

.uploadPreviewHeader {
    display: flex;
    justify-content: space-between;
    padding: 3%;
    background-color: var(--v-dunkelgrau-base);
    font-size: 14px;
    border-radius: 8px 8px 0 0;
    height: 50px;
}

@media only screen and (max-width: 501px) {
    .uploadPreviewHeader {
        width: 100%;
    }
}

.ellipsis * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (max-width: 900px) {
    .thumbnailDisplayNormal {
        max-width: 20vmax;
        max-height: 20vmax;
    }
}

@media only screen and (max-width: 500px) {
    .PDFSize {
        min-width: 380px;
        min-height: 450px;
    }
}

@media only screen and (min-width: 900px) {
    .PDFSize {
        min-width: 800px;
        min-height: 90vh;
    }
}

.iOS_ResponsiveHack {
    width: 1px;
    min-width: 100%;
    *width: 100%;
    padding-left: 10%;
    padding-right: 10%;
}

.thumbnailDisplayNormal {
    width: 100%;
    max-width: 210px;
    height: 100%;
    max-height: 198px;
    margin-left: -8px;
    cursor: pointer;
    flex: auto;
    justify-content: center;
}

.thumbnailDisplayNormalPDF {
    max-width: 30vmax;
    max-height: 39vmax;
    display: flex;
    justify-content: center;
}

.fileName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 140px;
    font-size: 20px;
}

.displayFileContainer {
    align-items: center;
    color: black;
    border-radius: 6px 6px 6px 6px;
    min-width: 202px;
    cursor: pointer;
}

iconToGrey {
    filter: brightness(0) saturate(100%) invert(81%) sepia(11%) saturate(12%) hue-rotate(251deg) brightness(103%) contrast(104%);
}

@media only screen and (max-width: 599px) {
    .pinboardCardBody {
        width: 72vw;
    }
    .thumbnailDisplayNormal {
        width: 62vw;
    }
    .displayFileContainer {
        min-width: 62vw;
    }
    .fileName {
        max-width: 40vw;
    }
}

.displayWholeThumbnail {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    aspect-ratio: 1/1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    border-radius: 0px 0px 8px 8px;
    max-height: 127px;
}

.fullScreenOptionsContainer {
    display: flex;
    flex-direction: column;
    padding-left: 4px;
}

.audioFullScreenOptionsContainer {
    display: flex;
    flex-direction: column;
    padding-left: 4px;
    margin-top: -25px;
    margin-left: 5px;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.optionsIcon {
    height: 50px;
    cursor: pointer;
}

.dialogChild {
    display: flex;
    flex-direction: row;
    position: relative;
    max-height: 90%;
}

.audioDialogChild {
    display: flex;
    flex-direction: row;
    position: relative;
    max-height: 90%;
}

.pdfMargin {
    margin-left: -70px;
}

.defaultCursor {
    cursor: default !important;
    user-select: none;
}

@media only screen and (max-width: 380px) {
    .audioDialogChild {
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        max-height: 90%;
    }

    .audioFullScreenOptionsContainer {
        display: flex;
        flex-direction: row-reverse;
        padding-left: 4px;
        margin-top: 0px;
        margin-bottom: 10px;
        margin-left: 5px;
    }
}
.selectedBorder {
    border: 5px solid red;
    border-radius: 8px;
}
</style>
