import * as backend from '../../api/backend'
import { checkResponseStatus } from "@/util/check";

const actions = {
    // get all of pupils lateTimes
    async getPupilLateTimes({ commit, dispatch }, params) {
        try {
            const res = await backend.getPupilLateTimes(params);
            await checkResponseStatus(200, res);
            return await res.json();
        }
        catch (err) {
            //console.error(err);
            return err?.response?.status;
        }
    },

    async getPupilLateTimesForDates({ commit, dispatch }, params) {
        try {
            const { pupilId, startDate, endDate } = params;
            const res = await backend.getPupilLateTimes(pupilId, startDate, endDate);
            await checkResponseStatus(200, res);
            return await res.json();
        }
        catch (err) {
            //console.error(err);
            return err?.response?.status;
        }
    },

    // post new lateTime
    async postLateTime({ commit, dispatch, }, params) {
      try {
          const res = await backend.postLateTime(params);
          await checkResponseStatus(201, res);
          const newLateTime = await res.json();
          commit('updateLateTimes',newLateTime);
          return newLateTime;
      }
      catch (err) {
          console.error(err);
          return err?.response?.status;
      }
    },

    async updateLateTime({ commit, dispatch, },params) {
      try {
        const id = params.lateTimeId
        delete params.lateTimeId;
          const res = await backend.updateLateTime(id,params);
          await checkResponseStatus(200, res);
          const updatedLateTime = await res.json();
          commit('updateLateTimes', updatedLateTime);
          return updatedLateTime;
      }
      catch (err) {
          console.error(err);
          return err?.response?.status;
      }
    },
    async toggleExcuseLateTime({ commit, dispatch, },params) {
      try {
        const res = await backend.toggleExcuseLateTime(params);
        await checkResponseStatus(200, res);
        const updatedLateTime = await res.json();
        commit('updateLateTimes',updatedLateTime);
        return updatedLateTime;
      }
      catch (err) {
          console.error(err);
          return err?.response?.status;
      }
    },

    async getLateTimes({ commit }) {
        try {
            const res = await backend.getLateTimes();
            const lateTimes = await res.json();
            await checkResponseStatus(200, res);
            commit('setLateTimes', lateTimes);
            commit('setLateTimesWithAppointments', lateTimes);
        }
        catch (err) {
            //console.error(err);
            return err?.response?.status;
        }
    },

    async deleteLateTime({ commit, dispatch, },id) {
      try {
        const res = await backend.deleteLateTime(id);
        await checkResponseStatus(204, res);
        const lateTimes = state.lateTimes;
        const index = lateTimes.findIndex((lateTime) => lateTime._id === id);
        delete lateTimes[index];
        const lateTimesWithAppointments = state.lateTimes;
        const indexAppointment = lateTimesWithAppointments.findIndex((lateTime) => lateTime._id === id);
        delete lateTimes[indexAppointment];

        return true;
      }
      catch (err) {
          console.error(err);
          return false;
      }
    },
};

const mutations = {
    setLateTimes(state, lateTimes) {
        state.lateTimes = lateTimes;
    },
    setLateTimesWithAppointments(state, lateTimes) {
        state.lateTimesWithAppointments = lateTimes.filter(lateTime => lateTime.appointment)
    },
    updateLateTimes(state, updatedLateTime) {
        //update lateTimes state, check if late time exists, if yes update, otherwise, push new
        const index = state.lateTimes.findIndex(lateTime => lateTime._id === updatedLateTime._id);
        if(index !== -1){
          state.lateTimes.splice(index, 1, updatedLateTime);
        }else{
          state.lateTimes.push(updatedLateTime);
        }

         //update lateTimesWithAppointments state, check if late time has appointment if yes update, otherwise, push new
         // if updated lateTime has no appointment remove it from state
        if (updatedLateTime.appointment) {
          const indexWithAppt = state.lateTimesWithAppointments.findIndex(lateTime => lateTime._id === updatedLateTime._id);
          if (indexWithAppt !== -1) {
            state.lateTimesWithAppointments.splice(indexWithAppt, 1, updatedLateTime);
          } else {
            state.lateTimesWithAppointments.push(updatedLateTime);
          }
        } else {
          const indexWithoutAppt = state.lateTimesWithAppointments.findIndex(lateTime => lateTime._id === updatedLateTime._id)
          if (indexWithoutAppt !== -1) {
            state.lateTimesWithAppointments.splice(indexWithoutAppt, 1);
          }
        }
      }
};

const getters = {
    getPupilsWithLateTimes(state, getters, rootState, rootGetters) {
        const pupils = rootGetters['pupils/pupils'] // Accessing the 'pupils' getter from the 'pupil' module
        const lateTimes = state.lateTimes;
        const pupilsWithLateTimes = [];
        // Loop through all pupils
        for (const pupil of pupils) {
          const pupilLateTimes = lateTimes.filter((lateTime) => lateTime.pupil === pupil._id)
          // If the pupil has at least one late time, add the pupil object and the calculated time to the result array
          if (pupilLateTimes.length > 0) {
            pupilsWithLateTimes.push({
              pupil: pupil,
              lateTimes: pupilLateTimes.map((lateTime) => ({
                ...lateTime,

              })),

                totalLateTime: pupilLateTimes.reduce((acc, curr) => {
                  return acc + curr.missingTime;
                }, 0),

                excusedLateTime: pupilLateTimes.reduce((acc, curr) => {
                  let currTime = 0;
                  if(curr.excused){
                    currTime =  curr.missingTime;
                  }
                  return acc + currTime
                }, 0),

                unexcusedLateTime: pupilLateTimes.reduce((acc, curr) => {
                  let currTime = 0;
                  if (!curr.excused) {
                    currTime = curr.missingTime;
                  }
                  return acc + currTime;
                }, 0),

                totalMissingDays: pupilLateTimes.reduce((acc, curr) => {
                  return acc + curr.missingDays;
                }, 0),

                excusedMissingDays:pupilLateTimes.reduce((acc, curr) => {
                  let missingDays = 0;
                  if(curr.excused){
                   missingDays =  curr.missingDays;
                  }
                  return acc + missingDays;
                }, 0),
                unexcusedMissingDays: pupilLateTimes.reduce((acc, curr) => {
                  let missingDays = 0;
                  if(!curr.excused){
                   missingDays =  curr.missingDays;
                  }
                  return acc + missingDays;
                }, 0),
            })
          }
        }
        return pupilsWithLateTimes
    },
    getLateTimesByAppointmentId: state => state.lateTimesWithAppointments.reduce((app, lateTime) => {
      if (!app[lateTime.appointment]) {
        app[lateTime.appointment] = [];
      }
      app[lateTime.appointment].push(lateTime);
      return app;
    }, {})

};

const state = {
    lateTimes: [],
    lateTimesWithAppointments: []
};

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}
