<template>
    <div>
        <!-- External Rooms dialog -->
        <v-dialog
            :value="dialogActive"
            color
            width="600"
            :scrollable="false"
            style="padding-top: 50px; z-index: 100; overflow-y: hidden;"
            @input="onDialogActiveChange"
        >
            <v-card>
                <!-- Popup header -->
                <div
                    class="px-1 align-start"
                    style="display: inline-flex; width: 100%; background-color: var(--v-headerblue-base); font-size: larger; padding-top: 10px; padding-bottom: 14px; color: white;"
                >
                    <div
                        class="mt-1"
                        style="margin-left: 10px; display:flex; align-items: center; justify-content: center; width: 100%"
                    >
                        <img
                            :src="videoIcon"
                            class="mx-2 videoIconPopup"
                            alt="Video-Icon"
                        >
                        <p
                            class="ma-0"
                            style="display: inline-block; font-size: 20px !important"
                        >
                            Externe Gäste
                        </p>
                        <v-spacer />
                        <v-btn
                            x-small
                            elevation="0"
                            class="d-flex align-center mr-2 ml-2"
                            style="background-color: transparent; width: 35px; height: 35px;"
                            @click="closeDialog()"
                        >
                            <img
                                :src="cancelIcon"
                                class="boardIconDialog"
                                alt="abbrechen-Icon"
                            >
                        </v-btn>
                    </div>
                </div>

                <!-- Popup body -->
                <v-card-text>
                    <h3 class="mb-1 mt-4 mb-2">
                        Neuen Raum anlegen
                    </h3>
                    <div :class="windowWidth < 500 ? 'flex-column' : '' + ' d-flex mb-3'">
                        <v-text-field
                            v-model="inputName"
                            dense
                            outlined
                            hide-details
                            placeholder="Raumname"
                            class="mr-4"
                            style="align-self: center"
                            type="text"
                            @keydown.enter="clickCreateExternalBbbRoom"
                        />
                        <v-btn
                            color="gruen"
                            elevation="0"
                            class="createRoomButton text-none"
                            :class="windowWidth < 500 ? 'mt-1' : ''"
                            style="height: 40px"
                            @click="clickCreateExternalBbbRoom"
                        >
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            Raum anlegen
                        </v-btn>
                    </div>
                    <h3 class="mt-6 mb-1">
                        Bestehende Räume
                    </h3>

                    <v-row
                        v-for="(externalRoom, i) in externalRooms"
                        :key="externalRoom._id"
                        :class="externalRooms.length > i+1 ? 'externalRoomItem' : ''"
                    >
                        <v-col
                            class="d-flex align-center"
                            style="cursor: pointer; overflow-x: auto"
                            cols="4"
                            @click="joinBbb(externalRoom)"
                        >
                            {{ externalRoom.name }}
                        </v-col>

                        <v-col
                            cols="8"
                            class="d-flex justify-end"
                        >
                            <v-text-field
                                :value="getLink(externalRoom)"
                                readonly
                                hide-details
                                style="padding: 0; margin: 0;"
                                class="mr-2"
                            />
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="button mr-2"
                                        v-on="on"
                                        @click="clickCopyMeetingExternalJoinUrl(externalRoom)"
                                    >
                                        <img
                                            :src="copyIcon"
                                            class="iconToDunkelgrau"
                                            :alt="externalRoom.isOpen ? 'Raum schließen' : 'Raum öffnen'"
                                        >
                                    </v-btn>
                                </template>
                                <span>Link für Externe kopieren</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="button mr-2"
                                        v-on="on"
                                        @click="()=>{ deleteDialog=true; deleteRoom=externalRoom;}"
                                    >
                                        <img
                                            :src="deleteIcon"
                                            class="iconToRed"
                                            alt="Löschen"
                                        >
                                    </v-btn>
                                </template>
                                <span>Löschen</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="button mr-2"
                                        v-on="on"
                                        @click="clickToggleIsOpen(externalRoom)"
                                    >
                                        <img
                                            :src="externalRoom.isOpen ? unlockedIcon : lockIcon"
                                            class="iconToDunkelgrau"
                                            :alt="externalRoom.isOpen ? 'Raum schließen' : 'Raum öffnen'"
                                        >
                                    </v-btn>
                                </template>
                                <span v-if="externalRoom.isOpen">Raum offen</span>
                                <span v-else>Raum geschlossen</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="button"
                                        :color="externalRoom.isOpen ? 'gruen' : 'fgrau'"
                                        v-on="on"
                                        @click="joinBbb(externalRoom)"
                                    >
                                        <img
                                            :src="videoIcon"
                                            alt="Videokonferenz beitreten"
                                        >
                                    </v-btn>
                                </template>
                                <span>Meeting beitreten</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <span v-if="externalRooms.length === 0">Keine Räume angelegt.</span>
                </v-card-text>
                <!-- Create extra container for copying data into clipboard-->
                <!-- Because it doesn't work outside the modal -->
                <div
                    ref="copyUrlContainer"
                    type="text"
                />
            </v-card>
        </v-dialog>

        <VideoTutorial
            v-if="videoTutorial && showVideoTutorial && !videoTutorialClosed"
            :video-tutorial="videoTutorial"
            @close="onVideoTutorialClose"
        />
        <AreYouSurePopup
            v-if="deleteDialog"
            :show-dialog="deleteDialog"
            :content-text-prop="'Soll dieser Raum endgültig gelöscht werden?'"
            ok-btn-color="frot"
            :header-color="'var(--v-headerblue-base)'"
            @close="deleteDialog = false"
            @ok="clickDeleteExternalBbbRoom(deleteRoom);"
        />
    </div>
</template>

<script>
import {mapActions, mapState, mapMutations, mapGetters} from 'vuex';
import * as backendApi from '../../api/backend.js';

import VideoTutorial from "../../components/Tutorial/VideoTutorial.vue";
import AreYouSurePopup from "@/components/Utils/AreYouSurePopup";

import videoIcon from '@/assets/Icons/video.svg';
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import deleteIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg'
import lockIcon from '@/assets/Icons/FaceliftIcons/schloss_zu.svg';
import unlockedIcon from '@/assets/Icons/FaceliftIcons/schloss_offen.svg';
import copyIcon from '@/assets/Icons/FaceliftIcons/kopieren_datei_.svg';

export default {
    name: "JoinExternalDialog",
    components: {
        VideoTutorial,
        AreYouSurePopup,
    },
    data: () =>  ({
        videoIcon,
        cancelIcon,
        deleteIcon,
        lockIcon,
        unlockedIcon,
        copyIcon,

        inputName: "",
        externalRooms: [],

        dialogActive: true,
        videoTutorial: false,
        showVideoTutorial: false,                       // Set to true when button is clicked
        videoTutorialClosed: false,                     // Set to false when VideoTutorial is closed
        videoTutorialOnCloseJoinExternalRoom: null,
        deleteRoom: false,
        deleteDialog: false,
    }),
    computed: {
        ...mapState('util', ['windowWidth']),
        ...mapGetters('auth', ['accountRole']),
        ...mapGetters('parents', ['currentChild']),
        ...mapGetters('videoTutorial', [ 'videoTutorialsByFilename' ]),
    },
    async mounted() {
        this.loadExternalBbbRooms();
        this.videoTutorial = this.videoTutorialsByFilename['Tutorial_bbb_Lehrer.mp4'];
    },
    methods: {
        ...mapActions('util', ['toggleLoading']),
        ...mapMutations('snackbar', ['showSnackbar']),

         onVideoTutorialClose() {
            this.videoTutorial = false;
            this.videoTutorialClosed = true;
            this.joinBbb(this.videoTutorialOnCloseJoinExternalRoom)
        },
        async clickCopyMeetingExternalJoinUrl(externalBbbRoom) {
            const domain = `${location.protocol}//${location.hostname}${(location.port ? ':' + location.port : '')}`;
            const tempInput = document.createElement("input");
            tempInput.style = "position: absolute; left: -1000px; top: -1000px";
            tempInput.value = `${domain}/join-external/${externalBbbRoom._id}?token=${externalBbbRoom.token}`;
            this.$refs.copyUrlContainer.appendChild(tempInput);
            tempInput.select();
            document.execCommand("copy");
            this.$refs.copyUrlContainer.removeChild(tempInput);
            this.showSnackbar({message: "Link kopiert!"});
        },
        async clickToggleIsOpen(externalBbbRoom) {
            await this.patchExternalBbbRoom(externalBbbRoom._id,
                {
                    ...externalBbbRoom,
                    isOpen: !externalBbbRoom.isOpen,
                });
            await this.loadExternalBbbRooms();
        },
        async loadExternalBbbRooms() {
            const response = await backendApi.getExternalBbbRooms();
            this.externalRooms = await response.json();
        },
        async postExternalBbbRoom({name = "example", isOpen = true} = {}) {
            const response = await backendApi.postExternalBbbRoom({name, isOpen});
            const postedRoom = await response.json();
        },
        async patchExternalBbbRoom(externalBbbRoomId, externalBbbRoom = {}) {
            const response = await backendApi.patchExternalBbbRoom(externalBbbRoomId, externalBbbRoom);
            const patchedRoom = await response.json();
        },
        async deleteExternalBbbRoom(externalBbbRoom) {
            const response = await backendApi.deleteExternalBbbRoom(externalBbbRoom._id);

        },
        async clickDeleteExternalBbbRoom(externalBbbRoomId) {
            await this.deleteExternalBbbRoom(externalBbbRoomId);
            await this.loadExternalBbbRooms();
            this.deleteDialog = false
        },
        async clickCreateExternalBbbRoom() {
            await this.postExternalBbbRoom({name: this.inputName});
            await this.loadExternalBbbRooms();
            this.inputName = '';
        },
        onDialogActiveChange(newValue) {
            if (!newValue) {
                this.closeDialog();
            }
        },

        async joinBbb(externalBbbRoom) {
            let res;
            if (this.accountRole === 'parent') {
                res = await backendApi.getExternalBbbRoomJoinAsModeratorUrl(
                    externalBbbRoom._id,
                    this.currentChild._id,
                );
            } else {
                res = await backendApi.getExternalBbbRoomJoinAsModeratorUrl(
                    externalBbbRoom._id
                );
            }
            this.toggleLoading(true);
            const {redirectUrl} = await res.json();
            if (res.status === 409) {
                this.showSnackbar({message: "Aktuell ist der Raum geschlossen!", color: "error"});
                this.toggleLoading(false);
                return;
            }

            if (this.videoTutorial) {
                // Open video tutorial and after try to exec joinClassroom again.
                this.showVideoTutorial = true;
                this.videoTutorialOnCloseJoinExternalRoom = externalBbbRoom;
                return;
            }
            window.location.href = redirectUrl;
            this.toggleLoading(false);

        },

        closeDialog() {
            this.$emit('closeDialog');
        },

        getLink(externalRoom) {
            const domain = `${location.protocol}//${location.hostname}${(location.port ? ':' + location.port : '')}`;
            const fullLink = `${domain}/join-external/${externalRoom._id}?token=${externalRoom.token}`;
            return fullLink;
        },
    }
}
</script>
<style scoped lang="scss">
.externalRoomItem {
    border-width: 1px;
    border-bottom-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
}

.createRoomButton {
    color: white;
    margin-left: auto;
}

.ellipsis {
    text-overflow: ellipsis;

    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.errorInput {
    border: 2px solid red !important;
}

.roundIcon {
    border-radius: 50%;
}

.boardIconDialog {
    height: 25px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.popUpRow {
    width: 95%;
    margin: auto;
}

.videoIconPopup {
    height: 25px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

@media only screen and (max-width: 900px) {
    .popUpRow {
        margin-left: 33%;
        margin-right: 66%;
    }
}

.button {
    height: 36px;
    min-width: 36px !important;
    width: 38px;
    box-shadow: none;

    img {
        height: 20px !important;
        width: 20px !important;
        margin: 0 !important;
        filter: brightness(1000%);
    }
}

.iconToDunkelgrau {
    filter: brightness(0) saturate(100%) invert(46%) sepia(0%) saturate(0%) hue-rotate(163deg) brightness(92%) contrast(82%) !important;
}

.iconToRed {
    filter: brightness(0) saturate(100%) invert(15%) sepia(95%) saturate(5829%) hue-rotate(348deg) brightness(79%) contrast(111%) !important;
}
</style>
