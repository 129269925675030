<template>
    <div class="d-flex align-center">
        <div
            :ref="`menuBtns${chatRoomEvent._id}`"
            class="hideMenuBtns"
            style=" position: absolute; top: 30px; left: -30px;"
        >
            <ChatRoomEventListItemDeleteButton
                v-if="eventFromMe || chatRoomMembershipRole === 'moderator'"
                :chat-room-event="chatRoomEvent"
            />
        </div>
        <div
            style="line-height: 20px; width: 100%; display: flex; align-items: center"
        >
            <div
                class="d-flex align-center pointer"
                style="min-height: 24px; width: 100%"
            >
                <v-tooltip
                    :disabled="!showTooltips"
                    top
                    style="z-index: 9;"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            :aria-hidden="true"
                            elevation="2"
                            class="pa-0 prchatRoomEventColorInvert chatMessageBtn"
                            v-on="on"
                            @keypress.enter="() => { audioPlaying ? pauseAudio() : playAudio() }"
                            @click="() => { audioPlaying ? pauseAudio() : playAudio() }"
                        >
                            <img
                                tabindex="0"
                                :src="audioPlaying ? pauseIcon : playIcon"
                                alt="Sprachnachricht anhören"
                                class="icon"
                                v-on="on"
                            >
                        </v-btn>
                    </template>
                    <span>Abspielen</span>
                </v-tooltip>
                <p
                    v-if="!audio"
                    tabindex="0"
                    class="mb-0 mx-2 pointer"
                    :style="{'color': fontColor}"
                    @click="handleMsgClick(chatRoomEvent)"
                >
                    Sprachnachricht
                </p>
                <div
                    v-else
                    style="width: 100%; margin: 0 4px; display: flex; flex-direction: row; align-items: center"
                >
                    <v-progress-linear
                        v-model="audioCurrentTime"
                        rounded
                        color="white"
                        style="width: 100%; height: 10px; margin-right: 8px"
                        @change="changeCurrentTimeBySlider"
                    />
                    <span style="color: white">
                        {{ audioProgress() }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import * as backend from "../../../../api/backend";

import ChatRoomEventListItemDeleteButton from "./ChatRoomEventListItemDeleteButton";

import playIcon from "@/assets/Icons/Play-114.svg";
import pauseIcon from "../../../../assets/Icons/Pause-115.svg";


export default {
    name: "ChatRoomEventListAudioItem",
    components: {
        ChatRoomEventListItemDeleteButton,
    },
    props: {
        chatRoomEvent: { type: Object, required: true },
        chatRoomMembershipRole: { required: true, type: String },
        fontColor: { required: true, type: String },
    },
    data: () => ({
        loadedAudio: null,
        audioPlaying: false,
        testId: null,
        audio: null,
        audioCurrentTime: 0,
        audioDuration: 0,
        pauseIcon,
        playIcon,
    }),
    computed: {
        ...mapState("auth", ["account"]),
        ...mapState("tooltips", ["showTooltips"]),
        eventFromMe() {
            return this.chatRoomEvent.author === this.account._id;
        },
    },
    async mounted() {
        this.loadedAudio = await this.loadAudioFile(this.chatRoomEvent._id);
        this.testId = this.chatRoomEvent._id;
    },
    unmount() {
        if (this.loadedAudio) URL.revokeObjectURL(this.loadedAudio);
    },
    methods: {
        ...mapActions("chat", ["getChatRoomEventFile"]),

        audioProgress() {
            const audioDuration = this.audioDuration;
            const audioDurationFormattedminutes = Math.floor(audioDuration / 60);
            const audioDurationFormattedseconds = Math.floor(audioDuration - audioDurationFormattedminutes * 60);

            const currentTime = this.audio.currentTime;
            const minutes = Math.floor(currentTime / 60);
            const seconds = Math.floor(currentTime - minutes * 60);
            return ('0' + Math.floor(audioDurationFormattedminutes - minutes)).slice(-2) + ":" + ('0' + Math.floor(audioDurationFormattedseconds - seconds)).slice(-2);
        },

        changeCurrentTimeBySlider(event) {
            this.audio.currentTime = event / 100 * this.audio.duration;
        },

        playAudio() {
            // safari has a delay when playing audio
            // explanation: https://stackoverflow.com/a/9811483
            if(!this.audio) {
                try {
                    // Load audio file if necessary
                    this.audio = new Audio(this.loadedAudio);
                    this.audio.onerror = () => {
                        console.error(
                            `Error ${this.audio.error.code}; details: ${this.audio.error.message}`
                        );
                        this.audioPlaying = false;
                    };
                    this.audio.onended = () => {
                        this.audioPlaying = false;
                    };

                    this.audio.addEventListener('timeupdate', () => {
                        this.audioDuration = this.audio.duration;
                        this.audioCurrentTime = (this.audio.currentTime / this.audio.duration) * 100;
                    })
                } catch (e) {
                    if (e.name === "NotSupportedError") {
                        alert("Diese Datei konnte nicht abgespielt werden.");
                    } else {
                        throw e;
                    }
                }
            }
            this.audio.play();
            this.audioPlaying = true;
        },

        pauseAudio() {
            if (this.audio) {
                this.audio.pause();
                this.audioPlaying = false;
            }
        },

        async loadAudioFile(chatRoomEventId) {
            const blob = await this.getChatRoomEventFile(chatRoomEventId);
            return URL.createObjectURL(blob);
        },
        handleMsgClick(event){
            const element = this.$refs[`menuBtns${event._id}`];
            if (element) {
                element.classList.remove('hideMenuBtns');
                setTimeout(() => {
                    element.classList.add('hideMenuBtns');
                }, 3000);
            }
        }

    },
};
</script>
<style scoped>
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.icon {
    height: 20px;
}
.chatMessageBtn{
    min-width: 30px !important;
    max-width: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
    background-color:white !important;
}
.hideMenuBtns {
    display: none !important;
}
</style>
