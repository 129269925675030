<template>
    <div style="position: relative">
        <!-- Header Row with Folder Path and Space Indicator-->
        <div
            class="px-7 pt-2"
            style="width: 100%"
        >
            <!-- Path -->
            <div style="display: flex; flex-direction: row; flex-wrap: wrap">
                <div
                    class="directoryPath"
                    style="min-height: 25px"
                    tabindex="0"
                    @click="diveInFolderFromHeader(rootFolder._id)"
                    @keypress.enter="diveInFolderFromHeader(rootFolder._id)"
                >
                    <v-icon>mdi-folder-home</v-icon>
                    <span style="margin-left: 2px; margin-right: 2px">{{ 'Online Bibliothek' }}</span>
                </div>
                <span>/</span>
                <div
                    v-for="(el, index) in folderPath"
                    :key="el._id + index + 'documentRegistry'"
                    style="display: flex; flex-direction: row"
                >
                    <div
                        class="directoryPath"
                        tabindex="0"
                        @click="diveInFolderFromHeader(el._id)"
                        @keypress.enter="diveInFolderFromHeader(el._id)"
                    >
                        <v-icon style="margin-right: -2px; margin-left: 1px">
                            mdi-folder
                        </v-icon>
                        <span style="margin-left: 2px; margin-right: 2px">{{ el.name }}</span>
                    </div>
                    <span>/</span>
                </div>
            </div>
        </div>

        <div
            v-for="(el, index) in filesAndFolders"
            :key="el._id + index + 'documentRegistry'"
            :style="'width: ' + vfsGridWidthPctLibrary + '%; float: left'"
            style="position: relative"
        >
            <FileSystemListElement
                :type="el.type"
                :index="index"
                :item="el"
                :folder-type="folderType"
                :screen-x="screenX"
                :screen-y="screenY"
                :view-file-function="() => { $emit('viewFile', el) }"
                :index-loading-permission="indexLoadingPermission"
                :notify-loading="notifyLoading"
                :save-to-folder="folderType === 'documentRegistry'"
                @openFolder="() => diveInFolder(el.value)"
                @openDeleteDialog="() => openDeleteDialog(el)"
                @startedEditing="() => startedEditing(el)"
                @canceledEditing="canceledEditing(el)"
                @folderEdited="(newFolderCreated) => folderEdited(el, newFolderCreated)"
                @download="clickDownloadFolderUpload"
                @notifyMoveFile="() => notifyMoveFile(el, 'private')"
                @loadFullscreenPreview="loadFullscreenPreview"
                @finishedLoading="(index) => bufferThumbLoading(index)"
                @openSaveToFolderDialog="openSaveToFolderDialog(el)"
            />
            <!-- @objectURL="(url) => FSLE_URL_Collection.push(url)" -->
        </div>
        <span
            style="visibility: hidden"
            min-height="500px"
        >
            .
            <br>.
            <br>.
            <br>.
            <br>.
            <br>.
            <br>.
            <br>.
            <br>.
            <br>.
            <br>
        </span>

        <!-- popups, overlays and snackbars -->
        <v-dialog
            v-model="deleteDialog"
            max-width="350px"
        >
            <v-card
                class="cardStyle"
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title class="d-flex align-center justify-space-between">
                    <p>Löschen bestätigen</p>
                    <v-btn
                        x-small
                        text
                        elevation="0"
                        class="pa-0"
                        @click="deleteDialog = false"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon20"
                            alt="schließen"
                        >
                    </v-btn>
                </v-card-title>

                <v-card-text>{{ deleteInfoText }}</v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        class="mr-2 text-capitalize"
                        color="rot"
                        dark
                        @click="confirmDelete"
                    >
                        <img
                            :src="recycleBinIcon"
                            style="height: 20px; filter: brightness(1000%)"
                            class="mr-2"
                        >
                        Löschen
                    </v-btn>

                    <v-btn
                        class="mr-2 text-capitalize"
                        color="gruen"
                        dark
                        @click="deleteDialog = false"
                    >
                        <img
                            :src="cancelIcon"
                            style="height: 20px; filter: brightness(1000%)"
                            class="mr-2"
                        >
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <SaveToFolderDialog
            v-if="showSaveToFolder"
            :file="saveToFile"
            :mode="'save'"
            @close="closeSaveToFolderDialog"
            @saveFileToDestination="saveFileToSelectedFolder"
        />
        <FilePreviewDialog
            v-if="fullscreenPreviewEnabled"
            ref="previewDialog"
            :files="files"
            :preview-selection="previewSelection"
            :preview-information="previewSelection"
            :enable-fullscreen-preview="fullscreenPreviewEnabled"
            :enable-editing="folderType === 'privateFolder' && validMimetype"
            :downloadable="(folderType === 'teacherFolder' || !validMimetype) || folderType === 'documentRegistry'"
            :save-to-folder="folderType === 'documentRegistry'"
            :show-save-to-folder="showSaveToFolder"
            @close="fullscreenPreviewEnabled = false"
            @download="clickDownloadFolderUpload(previewSelection)"
            @cyclePrev="cyclePreview(-1)"
            @cycleNext="cyclePreview(1)"
            @openEditor="openEditor"
            @openSaveToFolderDialog="openSaveToFolderDialog(previewSelection)"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import * as backend from "@/api/backend";
import FilePreviewDialog from "../Utils/FilePreviewDialog";
import recycleBinIcon from '../../assets/Icons/FaceliftIcons/loeschen_muelleimer.svg'
import cancelIcon from "../../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import img_eklaraLogo from '@/assets/EditorLibraryMock/eklara_logo.png';
import FileSystemListElement from "@/components/FileArchive/FileSystemListElement";
import SaveToFolderDialog from "@/components/FileArchive/SaveToFolderDialog";

export default {
    name: "VirtualFileSystemDocumentRegistry",
    components: {
        FileSystemListElement,
        SaveToFolderDialog,
        FilePreviewDialog
    },
    props: {
        folderType: { required: true, type: String },
        folderDepth: { required: true, type: Number },
        refreshToggle: { required: false, type: String, default: '' },
        privateFolderFilled: { required: false, type: Number, default: 1 },
        uploadLocalFileFunction: { required: false, type: Function },
        uploadFileFromSharedFunction: { required: false, type: Function },
        uploadFileFromPrivateFunction: { required: false, type: Function },
        //End of vfs reached, load more files
        scrolledToEnd: { required: true, type: Boolean, default: false },

        // Everything from document registry
        initalFolderIdTree: { required: false, type: Array }
    },
    data: () => ({
        folders:[],
        newFolders: [], // For displaying newly created Folders in the same list
        files: [],
        folderIdTree: [],
        currentFolderId: "",
        folderName: "",
        vfs: null,
        rootFolder: null,

        deleteDialog: false,
        elementToDelete: null,
        deleteMethod: '',
        deleteInfoText: '',

        patchingFile: null,
        sortAlphabeticalDescending: false,
        validMimetype: true,
        previewPDF: false,
        showSaveToFolder: false,
        saveToFile: null,

        traveledFromPath: false,

        fullscreenPreviewEnabled: false,
        previewSelection: { name: 'Vorschau' },
        previewSelectionIndex: 0,
        previewURL: null,

        FSLE_URL_Collection: [],
        indexLoadingPermission: [],
        notifyLoading: false,

        // vue-custom-scrollbar settings
        // settings: {
        //     suppressScrollY: false,
        //     suppressScrollX: true,
        //     wheelPropagation: true,
        // },

        //  Icons
        recycleBinIcon,
        cancelIcon,
        img_eklaraLogo,

        screenX: 1920,
        screenY: 1080,
    }),
    computed: {
        ...mapState("auth", ["token"]),
        ...mapGetters('auth', ['accountRole']),
        ...mapGetters('util', ['currentUploadGroup']),
        ...mapGetters('vfs', ['privateFolderSortDescending', 'teacherFolderSortDescending', 'docRegistryFolderSortDescending', 'vfsGridWidthPctLibrary']),

        shownItems() {
            return this.folders;
        },
        folderPath() {
            // let path = [];
            // for (let i = 0; i < this.folderIdTree.length; i++) {
            //     if (!this.folderIdTree[i].root) {
            //         path.push(this.folderIdTree[i]);
            //     }
            // }
            // return path;
            return this.folderIdTree;
        },
        filesAndFolders() {
            let all = [];
            let folders = [];
            let files = [];

            const items = this.vfs?.items || [];
            for (let i = 0; i < items.length; i++) {
                let item = { ...items[i] };

                let data = null;
                if (item.itemType === 'Folder') {
                    data = this.folders.find(folder => folder._id === item.value);
                    if (Object.keys(data).length) {
                        item.type = 'folder';
                        item.items = data.items;
                        item.name = data.name;
                        item.parent = data.parent;
                        folders.push(item);
                    }
                } else {
                    data = { ...this.files.find(file => file._id === item.value) };
                    if (Object.keys(data).length) {
                        delete data._id;
                        item = { ...item, ...data };
                        item.type = 'file';
                        files.push(item);
                    }
                }
            }

            folders.sort((a, b) => {
                a = a.name.toLowerCase();
                b = b.name.toLowerCase();
                if (this.sortAlphabeticalDescending) {
                    return (a < b) ? 1 : (a > b) ? -1 : 0;
                } else {
                    return (a < b) ? -1 : (a > b) ? 1 : 0;
                }
            })
            files.sort((a, b) => {
                a = (a.originalname || a.name).toLowerCase();
                b = (b.originalname || b.name).toLowerCase();
                if (this.sortAlphabeticalDescending) {
                    return (a < b) ? 1 : (a > b) ? -1 : 0;
                } else {
                    return (a < b) ? -1 : (a > b) ? 1 : 0;
                }
            });

            all = folders.concat(files);

            return all;
        },
    },
    watch: {
        filesAndFolders(newVal, oldVal) {
            if (this.folderType === 'privateFolder') {
                this.setPrivateFolderSelection(newVal.length);
            } else if (this.folderType === 'teacherFolder') {
                this.setTeacherFolderSelection(newVal.length);
            } else if (this.folderType === 'documentRegistry') {
                this.setDocRegistryFolderSelection(newVal.length);
            }
        },
        privateFolderSortDescending: {
            handler(val, oldVal) {
                if (this.folderType === 'privateFolder') {
                    this.sortAlphabeticalDescending = this.privateFolderSortDescending;
                }
            }
        },
        teacherFolderSortDescending: {
            handler(val, oldVal) {
                if (this.folderType === 'teacherFolder') {
                    this.sortAlphabeticalDescending = this.teacherFolderSortDescending;
                }
            }
        },
        docRegistryFolderSortDescending: {
            handler(val, oldVal) {
                if (this.folderType === 'documentRegistry') {
                    this.sortAlphabeticalDescending = this.docRegistryFolderSortDescending;
                }
            }
        },
        currentUploadGroup(newVal) {
            if (!newVal) {
                this.refreshCurrentFolder();
            }
        },
        refreshToggle(newVal) {
            if (newVal === this.folderType) {
                this.refreshCurrentFolder();
            }
        },
        // this watcher actually made me go insane looking for weird behaviour
        // when implementing code to jump larger distances inside the folderTree
        folderDepth(newVal, oldVal) {
            if (newVal - oldVal === -1) {
                if (this.traveledFromPath) {
                    this.traveledFromPath = false;
                    return;
                }
                this.leaveCurrentFolder();
            }
        },
    },
    mounted() {

        this.initRootDir();
        this.evalScreenSize();
        this.initLoadingPermission();
    },
    methods: {
        ...mapActions("teacherFolder", [
            "getVFSFolderUploads",
            "getVFSFolderUpload",
            "createVFSFolder",
            "deleteVFSFolderUpload",
            "updateVFSFolder",
            "updateVFSFolderUpload",
            "deleteVFSFolder"
        ]),
        ...mapMutations('vfs', ['setPrivateFolderSelection', 'setTeacherFolderSelection', 'setDocRegistryFolderSelection']),
        ...mapActions('subjects', ['getThumbnail']),
        ...mapMutations("snackbar", ["showSnackbar"]),

        folderEdited(el, newFolderCreated) {
            if (el.type === 'file') {
                this.patchFolderUpload(el);
            } else if (el.type === 'folder') {
                this.createFolder(el, newFolderCreated);
            }
        },

        canceledEditing(el) {

            if (el.type === 'file') {
                this.cancelPatchUpload(el);
            } else if (el.type === 'folder') {
                this.cancelFolderCreation(el)
            }
        },

        startedEditing(el) {
            if (el.type === 'file') {
                this.startPatchUpload(el);
            } else if (el.type === 'folder') {
                this.switchUIToEditMode(el)
            }
        },

        initLoadingPermission() {
            for (let i=0; i < this.filesAndFolders.length; i++) {
                // loads up to 3 images at a time
                // tweaking this value impacts processing lag when browsing and scrolling the vfs container
                if (i<3) {
                    this.indexLoadingPermission[i] = 'proceed';
                } else {
                    this.indexLoadingPermission[i] = 'waiting';
                }
            }
        },

        loadFullscreenPreview(el) {
            this.previewSelection = el;
            if (typeof el.thumbnail === 'object') {
                this.previewSelection.thumbnail = URL.createObjectURL(el.thumbnail);
            }
            for (let i = 0; i < this.filesAndFolders.length; i++) {
                if (el == this.filesAndFolders[i]) {
                    this.previewSelectionIndex = i;

                }
            }
            this.fullscreenPreviewEnabled = true;
            setTimeout(() => {
                this.$refs.previewDialog.$el.focus();
            }, 150);
        },

        // to prevent memory leaks, enforces a limit on maximum available URLs
        // generated through FileSystemListElements
        // this should be called whenever diving or leaving a folder
        clean_URLCollection() {
            for (let i = this.FSLE_URL_Collection.length; i > 15; i--) {
                URL.revokeObjectURL(this.FSLE_URL_Collection.shift());
            }
        },

        bufferThumbLoading(index) {
            this.indexLoadingPermission[index] = 'loaded';
            this.indexLoadingPermission[this.indexLoadingPermission.findIndex(el => el === 'waiting')] = 'proceed';
            this.notifyLoading = !this.notifyLoading;
        },

        async initThumbnail() {
            this.validateMimetype();
            if (this.previewURL && this.previewURL !== this.img_eklaraLogo) {
                URL.revokeObjectURL(this.previewURL);
            }
            // request thumbnail from Document Registry
            const thumbnailResponse = await backend.getDocumentRegistryDocumentFile(
                this.previewSelection.value,
                this.previewSelection.files[0]);
            let thumb = await thumbnailResponse.blob();
            this.previewPDF = false;

            if (thumb) {
                this.previewURL = URL.createObjectURL(thumb);
                if (thumb.type === 'application/pdf') {
                    this.previewPDF = true;
                }
            } else {
                this.previewURL = this.img_eklaraLogo;
            }
        },

        cyclePreview(direction) {
            this.previewSelectionIndex += direction;
            if (this.previewSelectionIndex < 0) {
                this.previewSelectionIndex = this.filesAndFolders.length - 1;
            } else
                if (this.previewSelectionIndex >= this.filesAndFolders.length) {
                    this.previewSelectionIndex = 0;
                }
            this.previewSelection = this.filesAndFolders[this.previewSelectionIndex];
            if (this.previewSelection.type !== 'file') {
                this.cyclePreview(direction);
                return;
            }
            this.initThumbnail();
        },

        validateMimetype() {
            // we can assume filetypes in Document Registry consist only of eklara documents
            // if (['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(this.previewSelection.uploadedName.split('.').pop().toLowerCase())) {
            //     this.validMimetype = true;
            // } else {
            //     this.validMimetype = false;
            // }
            if (this.previewSelection.itemType === 'Document') {
                this.validMimetype = true;
            } else {
                this.validMimetype = false;
            }
        },

        openEditor() {
            this.$emit('viewFile', this.previewSelection);
        },

        evalScreenSize() {
            let win = window;
            let doc = document;
            let docElem = doc.documentElement;
            let body = doc.getElementsByTagName('body')[0];
            this.screenX = win.innerWidth || docElem.clientWidth || body.clientWidth;
            this.screenY = win.innerHeight || docElem.clientHeight || body.clientHeight;
            // if(this.screenX < 800 || this.screenY < 800) {
            //     this.smallUI = true;
            // }
            // alert(this.screenX + ' × ' + this.screenY);
        },

        async notifyFolderSelection(direction) {
            if (!direction && direction != 0) {
                direction = 1;
            }
            this.$emit("currentFolder", this.vfs, direction);
            this.initLoadingPermission();
        },

        async notifyMoveFile(el) {
            let destination = 'teacher';
            if (this.folderType === 'teacherFolder') {
                destination = 'private';
            }
            this.$emit("notifyMoveFile", el, destination);
        },

        async copyDocumentToBackend(el) {
            let destination = 'private';
            this.$emit('copyDocumentToBackend', el, this.previewURL, destination);
            this.fullscreenPreviewEnabled = false;
        },

        async clickDownloadFolderUpload(file) {
            const thumbnailResponse = await backend.getDocumentRegistryDocumentFile(
                file.value,
                file.files[0]);
            let blob = await thumbnailResponse.blob();
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = file.name;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            setTimeout(function () {
                a.remove();
                window.URL.revokeObjectURL(url);
            }, 1000);
        },

        async initRootDir() {

            if (!this.initalFolderIdTree) {
                // No path to inital folder has been given so load root
                // use default root folder


                this.folderIdTree = [];
                this.vfs = await this.getVFSFolderUploads({ folderType: this.folderType });
                this.files = this.vfs.files;
                this.folders = this.vfs.folders;
                this.currentFolderId = this.vfs._id;
                this.folderIdTree.push(this.vfs);
                this.notifyFolderSelection(0);
                this.$emit('mutateFolderDepth', 1);
            } else {

                this.folderIdTree = [];

                this.vfs = this.initalFolderIdTree[0].root;
                this.rootFolder = this.initalFolderIdTree[0].root;

                this.files = this.initalFolderIdTree[0].files;
                this.folders = this.initalFolderIdTree[0].folders;
                this.currentFolderId = this.initalFolderIdTree[0].root._id;
                this.notifyFolderSelection(0);
                this.$emit('mutateFolderDepth', this.folderIdTree.length);
                this.$emit('resetInitalFolderIdTree');
            }
        },
        // Folder Creation Bits
        switchUIToEditMode(el) {
            el.isMoving = true;
            this.newFolders = [el];
            const elIndex = this.shownItems.findIndex(item => item === el);
            this.shownItems.splice(elIndex, 1);
        },
        async createFolder(folder, newFolderCreated = true) {
            if (newFolderCreated) {
                let params = {
                    name: folder.name,
                    currentFolderId: this.currentFolderId,
                    folderType: this.folderType, // other values here will default to privateFolder
                };
                await this.createVFSFolder(params);
                this.folderName = "";
                this.newFolders = [];
            } else {
                folder.parentFolderId = this.currentFolderId;
                await this.updateVFSFolder(folder);
                this.newFolders = [];
            }
            await this.refreshCurrentFolder();
        },
        cancelFolderCreation() {
            this.newFolders = [];
            this.refreshCurrentFolder();
        },
        //Patch uploads Bits
        startPatchUpload(file) {
            this.patchingFile = { ...file, isMoving: true };
            const elIndex = this.files.findIndex(item => item === file);
            this.files.splice(elIndex, 1);
        },
        cancelPatchUpload(file) {
            this.patchingFile = null;
            this.refreshCurrentFolder();
        },
        async patchFolderUpload(file) {
            let body = {
                fileId: file._id,
                name: file.name,
                parentFolderId: this.currentFolderId
            }


            await this.updateVFSFolderUpload(body);
            await this.refreshCurrentFolder();
            this.patchingFile = null;
        },

        async clickDeleteFolder(folderId) {
            await this.deleteVFSFolder(folderId);
            this.refreshCurrentFolder();
        },

        async clickDeleteFolderUpload(file) {
            await this.deleteVFSFolderUpload(file)
            this.refreshCurrentFolder();
        },

        openDeleteDialog(el) {
            const id = el._id;
            let method;
            if (el.type === 'file') {
                method = 'shared file'
            } else if (el.type === 'folder') {
                method = 'shared folder'
            }
            this.deleteDialog = true;
            this.elementToDelete = id;
            this.deleteMethod = method;
            switch (method) {
                case 'shared file':
                    this.deleteInfoText = 'Wollen Sie die Datei wirklich unwiderruflich löschen?';
                    break;
                case 'shared folder':
                    this.deleteInfoText = 'Wollen Sie den Ordner wirklich unwiderruflich löschen? Es werden sämtliche Unterordner und Dateien gelöscht.';
                    break;
                default:
                    console.warn('undefined deleteMethod selection in openDeleteDialog VirtualFileSystem');
            }
        },

        confirmDelete() {
            switch (this.deleteMethod) {
                case 'shared file':
                    this.clickDeleteFolderUpload(this.elementToDelete);
                    break;
                case 'shared folder':
                    this.clickDeleteFolder(this.elementToDelete);
                    break;
                default:
                    console.warn('something went wrong in the confirmDelete method');
            }
            this.deleteDialog = false;
            this.elementToDelete = null;
            this.deleteMethod = '';
        },

        async diveInFolderFromHeader(folderId) {
            this.clean_URLCollection();
            const newFolder = this.folderIdTree.find(folderTree => folderTree._id === folderId);
            const index = this.folderIdTree.indexOf(newFolder);
            let directionCounter = 1 - (
                this.folderIdTree.length - index
            );
            this.folderIdTree.splice(index + 1);

            this.vfs = this.folders.find(folder => folder._id === folderId);
            // this.folders = newFolder.folders;
            // this.files = newFolder.files;
            this.currentFolderId = folderId;
            this.traveledFromPath = true;
            // this.folderDepth = this.folderIdTree.length;
            await this.notifyFolderSelection(directionCounter);
        },

        async diveInFolder(folderId) {

            this.clean_URLCollection();
            // this.vfs = await this.getVFSFolderUploads({ folderType: this.folderType, folderId });
            // this.folderIdTree.push(this.vfs);
            // this.folders = this.vfs.folders;
            // this.files = this.vfs.files;
            // this.currentFolderId = this.vfs._id;
            this.vfs = this.folders.find(folder => folder._id === folderId);
            this.folderIdTree.push({
                name: this.vfs.name,
                _id: this.vfs._id,
            })

            this.currentFolderId = this.vfs._id;
            this.notifyFolderSelection();
        },

        leaveCurrentFolder() {
            if (!this.vfs.root && this.vfs.parent) {
                this.clean_URLCollection();
                this.folderIdTree.pop();
                const parent = this.folders.find(folder => folder._id === this.vfs.parent);
                // this.folders = parent.folders;
                // this.files = parent.files;
                this.currentFolderId = parent._id;
                this.vfs = parent;
                this.notifyFolderSelection(0);
            }
        },

        async refreshCurrentFolder() {
            this.vfs = await this.getVFSFolderUploads({ folderType: this.folderType, folderId: this.currentFolderId });
            this.folderIdTree.pop();
            this.folderIdTree.push(this.vfs);
            this.folders = this.vfs.folders;
            this.files = this.vfs.files;
            this.currentFolderId = this.vfs._id;
            this.initLoadingPermission();
        },

        openSaveToFolderDialog(file) {
            this.saveToFile = file;
            this.showSaveToFolder = true;
        },

        async saveFileToSelectedFolder(selectedName, selectedFolderId) {
            const thumbnailResponse = await backend.getDocumentRegistryDocumentFile(
                this.saveToFile.value,
                this.saveToFile.files[0]);
            const blob = await thumbnailResponse.blob();
            if (blob.type === 'application/pdf') {
                selectedName += 'pdf';
            } else if (blob.type === 'image/jpeg') {
                selectedName += 'jpg';
            }
            const file = new File([blob], selectedName, { type: blob.type });
            const res = await backend.postOnlineLibraryFileToVFS(selectedFolderId, selectedName, file);
            const resJson = await res.json();
            const path = await resJson.folderPath;
            if (res.status === 200) {
                this.showSnackbar({
                    message: `Datei wurde erfolgreich in Dateien unter ${path} gespeichert!`
                });
                this.showSaveToFolder = false;
            } else {
                this.showSnackbar({
                    message: 'Ein unerwarteter Fehler ist beim Speichern aufgetreten.',
                    color: 'error',
                })
            }
        },

        closeSaveToFolderDialog() {
            this.saveToFile = null;
            this.showSaveToFolder = false;
        }
    }
}
</script>

<style scoped lang="scss">
.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    width: 35px !important;
    height: 35px !important;
}

.directoryPath:hover {
    background-color: #e4e3e3;
}

.textTransformNone {
    text-transform: none;
}

.directoryPath {
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
}

.fileSystemElement {
    display: flex;
    position: relative;
    border-radius: 4px;
    color: #707070;
    box-shadow: 1px 2px 3px silver;
    width: 90%;
    white-space: nowrap;
    background-color: #fff;
}

.fileSystemElementName {
    cursor: pointer;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex;
    align-items: center;
}

.fileWidgetButton {
    height: 30px !important;
    width: 30px !important;
    min-width: 30px !important;
}

#hintBox {
    display: inline-flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    color: grey;
}
</style>
