<template>
    <v-card style="border-radius: 16px">
        <v-card-title
            :style="`background-color: ${headerColor}`"
            class="d-flex justify-space-between align-center pa-2 "
        >
            <div class="d-flex justify-space-around">
                <v-btn
                    icon
                    color="white"
                    @click="()=> { $emit('stopEvent') }"
                >
                    <v-icon>mdi-undo</v-icon>
                </v-btn>
                <p style="color: white">
                    Neuen Lehrer anlegen
                </p>
            </div>
            <v-btn
                class="text-none"
                color="success"
                elevation="0"
                :disabled="!valid"
                :loading="loadingSave"
                @click="save"
            >
                Speichern
            </v-btn>
        </v-card-title>

        <v-card-text class="pa-4">
            <div class="innerContainer">
                <p class="mb-1">
                    <b>Lehrerdaten</b>
                </p>

                <v-text-field
                    v-model="firstName"
                    outlined
                    dense
                    hide-details
                    label="Vorname"
                    placeholder="Vorname eingeben"
                    class="inputField mb-2"
                    :class="{ errorInput: fields.firstName }"
                    @input="() => { generateUsername(true); validateInput(); }"
                />

                <v-text-field
                    v-model="lastName"
                    outlined
                    dense
                    hide-details
                    label="Nachname"
                    placeholder="Nachname eingeben"
                    class="inputField mb-4"
                    :class="{ errorInput: fields.lastName }"
                    @input="() => { generateUsername(true); validateInput(); }"
                />
                <v-select
                    v-model="selectedSubjects"
                    :items="filteredSubjects"
                    item-text="name"
                    :menu-props="{ offsetY: true }"
                    label="Fächer"
                    placeholder="Fächer auswählen"
                    no-data-text="Keine Fächer vorhanden"
                    class="inputField mb-4"
                    hide-details
                    dense
                    outlined
                    multiple
                    return-object
                    @change="()=>{ searchSubjects= '';}"
                >
                    <template v-slot:prepend-item>
                        <v-list-item
                            class="px-2"
                            @mousedown.prevent
                        >
                            <v-text-field
                                v-model="searchSubjects"
                                style="background-color: white;"
                                solo
                                dense
                                flat
                                placeholder="suchen"
                                hide-details
                            >
                                <template v-slot:prepend-inner>
                                    <img
                                        :src="searchIcon"
                                        class="icon20"
                                        alt="suchen"
                                    >
                                </template>
                            </v-text-field>
                        </v-list-item>
                        <!-- Should this be removed? Cant be opened in Popup -->
                        <v-list-item
                            hide-details
                            @click="createSubjectDialog = true"
                        >
                            <v-list-item-action>
                                <v-icon>
                                    mdi-plus-circle
                                </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Neues Fach
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                    </template>
                </v-select>

                <v-textarea
                    v-model="note"
                    outlined
                    dense
                    hide-details
                    auto-grow
                    label="Notiz"
                    placeholder="Notiz eingeben"
                    class="inputField mb-4"
                />
                <v-tooltip
                    top
                >
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            v-bind="attrs"
                            style="display: flex; flex-direction: row; justify-content:space-between; width: fit-content mb-1"
                            v-on="on"
                        >
                            <span>QR-Anmeldung</span>
                            <v-switch
                                v-model="invertedQrLoginDeactivated"
                                inset
                                hide-details
                                color="success"
                                class="mt-0"
                            />
                        </div>
                    </template>
                    <span>Aktivieren Sie dieses Feld, um die Qr-Anmeldung für diesen Nutzer zu aktivieren.</span>
                </v-tooltip>
                <div v-if="!qrLoginDeactivated">
                    <span
                        style="color: var(--v-frot-base); font-size: 14px;"
                    >
                        Bei der QR-Code Anmeldung ist die Zwei-Faktor-Authentifizierung zu verwenden.
                    </span>
                </div>
                <div style="width: 100%">
                    <div class="d-flex justify-space-between align-center mb-1">
                        <div class="d-flex align-center mb-1">
                            <b>Persönliche PIN </b>

                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <img
                                        :src="questionMarkIcon"
                                        class="icon20 pl-1"
                                        alt="Erklärung"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                </template>
                                <!-- Disable v-html xss checking accessibility.explanation is provided only by developers -->
                                <!-- eslint-disable vue/no-v-html -->
                                <p style="max-width: 250px">
                                    4-stellige  Persönliche PIN wird vom Nutzer selbst unter Profileinstellungen vergeben.
                                </p>
                            </v-tooltip>
                        </div>
                    </div>
                </div>
                <div style="width: 100%">
                    <div class="d-flex justify-space-between align-center mb-1 ml-3">
                        <div class="d-flex align-center mb-1">
                            <span>Anmeldung</span>
                        </div>

                        <v-switch
                            v-model="loginPinActivated"
                            inset
                            hide-details
                            color="success"
                            class="mt-0"
                        />
                    </div>
                </div>
                <div style="width: 100%">
                    <div class="d-flex justify-space-between align-center mb-1 ml-3">
                        <div class="d-flex align-center mb-1">
                            <span>Klassenbuch </span>
                        </div>

                        <v-switch
                            v-model="pinActivated"
                            inset
                            hide-details
                            color="success"
                            class="mt-0"
                        />
                    </div>
                </div>
                <div style="width: 100%">
                    <div class="d-flex justify-space-between align-center mb-1 ml-3">
                        <div class="d-flex align-center mb-1">
                            <span>Elternbrief</span>
                        </div>

                        <v-switch
                            v-model="messagesPinActivated"
                            inset
                            hide-details
                            color="success"
                            class="mt-0"
                        />
                    </div>
                </div>
                <div style="width: 100%">
                    <div class="d-flex justify-space-between align-center mb-1">
                        <div class="d-flex align-center mb-1">
                            <span>Darf Timeslots bearbeiten</span>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <img
                                        :src="questionMarkIcon"
                                        class="icon20 pl-1"
                                        alt="Erklärung"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                </template>
                                <!-- Disable v-html xss checking accessibility.explanation is provided only by developers -->
                                <!-- eslint-disable vue/no-v-html -->
                                <p style="max-width: 250px">
                                    Nur wenn ausgewählt darf Klassenlehrer Timeslots eigenständig bearbeiten.
                                </p>
                            </v-tooltip>
                        </div>
                            
                        <v-switch
                            v-model="canEditTimeslot"
                            inset
                            hide-details
                            color="success"
                            class="mt-0"
                        />
                    </div>
                </div>
                <div style="width: 100%">
                    <div class="d-flex justify-space-between align-center mb-1">
                        <div class="d-flex align-center mb-1">
                            <span>Darf Ausleih-App managen</span>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <img
                                        :src="questionMarkIcon"
                                        class="icon20 pl-1"
                                        alt="Erklärung"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                </template>
                                <!-- Disable v-html xss checking accessibility.explanation is provided only by developers -->
                                <!-- eslint-disable vue/no-v-html -->
                                <p style="max-width: 250px">
                                    Nur wenn ausgewählt darf der Lehrer Qr-Codes für Geräte erstellen und löschen.
                                </p>
                            </v-tooltip>
                        </div>
                            
                        <v-switch
                            v-model="canEditQrCodes"
                            inset
                            hide-details
                            color="success"
                            class="mt-0"
                        />
                    </div>
                </div>
                <div class="d-flex justify-space-between align-center">
                    <p><b>Login</b></p>
                </div>

                <v-text-field
                    v-model="username"
                    outlined
                    dense
                    hide-details
                    label="Nutzername"
                    placeholder="Nutzername eingeben"
                    class="inputField mb-2"
                    :class="{ errorInput: fields.username }"
                    @input="() => {this.fields.usernameGenerated = false; validateInput()}"
                />
                <span
                    v-if="fields.usernameStrangeLetters"
                    style="color: var(--v-frot-base)"
                >
                    Es werden nur die Buchstaben A-Z (ohne Umlaute) und Zahlen unterstüzt.
                </span>
                <span
                    v-if="fields.usernameDuplicate"
                    style="color: var(--v-frot-base)"
                >
                    Der Nutzer existiert bereits, passen Sie den Nutzernamen an.
                </span>
                <span
                    v-if="fields.usernameGenerated"
                    style="color: var(--v-forange-base)"
                >
                    Der Nutzer existiert bereits, der Nutzername wurde automatisch angepasst.
                </span>

                <v-text-field
                    v-model="password"
                    outlined
                    dense
                    hide-details
                    label="Passwort"
                    placeholder="Passwort eingeben"
                    class="inputField mb-4"
                    type="password"
                    :class="{ errorInput: fields.password }"
                    @input="validateInput"
                />
                <span
                    v-if="fields.password"
                    style="color: var(--v-frot-base)"
                >
                    Mind. 8 Buchstaben und 1 Zahl
                </span>
            </div>
        </v-card-text>

        <v-card-actions>
            <v-spacer />
        </v-card-actions>

        <v-dialog
            v-if="saveDialog"
            v-model="saveDialog"
            persistent
            max-width="400"
        >
            <v-card style="border-radius: 16px">
                <v-card-title
                    :style="`background-color: ${headerColor}`"
                    class="pa-2 px-2 pl-4 d-flex justify-space-between align-center"
                >
                    <div style="color: white">
                        Accounterstellung
                    </div>
                    <img
                        :src="closeIcon"
                        class="icon20 iconToWhite mr-2"
                        style="cursor: pointer"
                        @click="finish()"
                    >
                </v-card-title>
                <v-card-text class="pa-4">
                    <b>WICHTIG:</b> Laden Sie direkt die Zugangsdaten für den Lehrer herunter. Dies ist nachträglich aus Datenschutzgründen nicht mehr möglich.
                    <br> <br>
                    Insofern der Lehrer die Zugangsdaten vergessen oder verloren hat, klicken Sie auf "Zugangsdaten neu generieren", um die neuen Zugangsdaten zu erhalten.

                    <br> <br>
                    <div>
                        <v-row
                            class="mt-5"
                            align="center"
                            justify="center"
                        >
                            <div style="width: 200px">
                                <div
                                    style="width: 200px; gap: 4px"
                                    class="d-flex flex-column align-center ma-0"
                                >
                                    <v-btn
                                        class="text-none"
                                        color="gruen"
                                        elevation="0"
                                        dark
                                        @click="pdf()"
                                    >
                                        Download Zugangsdaten Lehrer
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        v-if="false"
                        class="text-none"
                        elevation="0"
                        color="gruen"
                        dark
                        @click="finish"
                    >
                        Fertig
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="createSubjectDialog"
            max-height="1000px"
            max-width="400"
        >
            <FachErstellen
                v-if="createSubjectDialog"
                :teacher-creation="true"
                @addSubject="addNewSubject"
                @stopEvent="createSubjectDialog = false"
            />
        </v-dialog>
    </v-card>
</template>

<script>
import FachErstellen from "@/components/Verwaltung/FachErstellen";
import closeIcon from '../../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import {mapActions, mapMutations, mapGetters} from "vuex";
import searchIcon from '@/assets/Icons/suche-26.svg';
import questionMarkIcon from '@/assets/Icons/redesignV3/questionmark.svg';
import {PDFDocument} from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
// import pdfFile from "@/assets/PDF/Schueler-QR-Vorlage.pdf";
import pdfFile from "@/assets/PDF/QR-Vorlage-Schueler-Lehrer.pdf";
import roboto from "@/assets/Fonts/Roboto-Regular.ttf";
import robotoBold from "@/assets/Fonts/Roboto-Bold.ttf";
import QrcodeVue from "qrcode.vue";
import {compress} from "shrink-string";

import LoginPdf from "@/util/loginPdf";
import defaultProfilePicture from "/src/assets/Icons/Avatar3.svg";


export default {
    name: "CreateTeacher",
    components: {
        FachErstellen,
    },
    props: {
        headerColor: { type: String, required: false, default: '#467abb' },
        addNewTeacher: {type: Function, required: false, default: () => {}},
        subjects: {type: Array, required: true, default: null},
        groupName:{type: String, default:''},
    },
    data: () => ({
        searchIcon,
        closeIcon,
        defaultProfilePicture,
        questionMarkIcon,

        saveDialog: false,
        savedTeacher: null,

        firstName: '',
        lastName: '',
        note: '',
        username: '',
        password: '',
        qrPassword: '',

        createSubjectDialog: false,
        selectedSubjects: [],
        searchSubjects: '',
        loadingSave: false,
        canEditTimeslot: false,
        canEditQrCodes: false,

        fields: {
            firstName: false,
            lastName: false,
            username: false,
            usernameStrangeLetters: false,
            usernameDuplicate: false,
            usernameGenerated: false,
            password: false,
        },
        valid: false,
        pinActivated:false,
        messagesPinActivated:false,
        qrLoginDeactivated: true,
        loginPinActivated: false,
    }),
    computed: {
        ...mapGetters('subjects', ['getSubjectInvalidStatus']),
        filteredSubjects() {
            return this.subjects.filter((subject) => subject.name.includes(this.searchSubjects));
        },
        invertedQrLoginDeactivated: {
            get() {
                return !this.qrLoginDeactivated;
            },
            // The setter updates the actual data model based on the inverse of the input
            set(value) {
                this.qrLoginDeactivated = !value;
            }
        },
    },
    watch:{
        invertedQrLoginDeactivated(newVal){
            if(newVal === true){
                this.loginPinActivated = true;
            }
        },
    },
    mounted() {
        this.generatePassword();
        this.valid = false;
    },
    methods: {
        ...mapActions('auth', ['isAccountNameAvailable']),
        ...mapActions('teachers', ['createTeacher']),
        ...mapMutations('snackbar', ['showSnackbar']),
        ...mapActions('subjects', ['editSubject']),

        addNewSubject(subject) {
            this.subjects.push(subject);
            this.$emit("addedSubject");
            this.selectedSubjects.push(subject);
        },

        // result = false indicates a problem, if a field is true it indicates a problem with this specific field
        async validateInput() {
            let result = true;

            this.fields.firstName = false;
            this.fields.lastName = false;
            this.fields.username = false;
            this.fields.usernameStrangeLetters = false;
            this.fields.usernameDuplicate = false;
            this.fields.password = false;

            if (this.firstName.replace(/\s/g, "") === "")  {
                this.fields.firstName = true;
                result = false;
            }
            if (this.lastName.replace(/\s/g, "") === "") {
                this.fields.lastName = true;
                result = false;
            }

            // With strange letters we highlight the field and also show a warning about naming convention
            if (this.username.replace(/\s/g, "") === ""
                || this.username.trim().indexOf(" ") > -1
                || this.containsUsernameStrangeLetters(this.username)
            ) {
                this.fields.username = true;
                this.fields.usernameStrangeLetters = true;
                result = false;
            }
            let available = await this.checkIdAvailability(this.username);
            if (!available) {
                this.fields.usernameDuplicate = true;
                result = false;
            }

            this.valid = result;
            return result;
        },

        async checkIdAvailability(loginId) {
            return await this.isAccountNameAvailable(loginId);
        },

        containsUsernameStrangeLetters(username) {
            // eslint-disable-next-line no-useless-escape
            const format = /[!@#$äüö%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
            return format.test(username);
        },

        async generateUsername() {
            this.fields.usernameGenerated = false;
            let id = this.firstName.slice(0, 1).toLowerCase() + this.lastName.toLowerCase();

            let available = await this.isAccountNameAvailable(id);

            if (available) {
                this.username = id;
            } else {
                this.fields.usernameGenerated = true;
                let i = 1;
                this.username = id + i;
                for (let i = 1; !available; i++) {
                    id = this.firstName.slice(0, 1).toLowerCase() + this.lastName.toLowerCase() + i;

                    available = await this.isAccountNameAvailable(id);
                    this.username = id;
                }
            }
            this.username = this.username.replaceAll('ü', 'ue');
            this.username = this.username.replaceAll('Ü', 'UE');
            this.username = this.username.replaceAll('ö', 'oe');
            this.username = this.username.replaceAll('Ö', 'OE');
            this.username = this.username.replaceAll('ä', 'ae');
            this.username = this.username.replaceAll('Ä', 'AE');
        },

        generatePassword() {
            let result = "";
            let qrResult = "";
            let allCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
            let numbers = "0123456789";
            let numberCount = Math.floor(Math.random() * 6 + 1);

            for (let i = 0; i < 8 - numberCount; i++) {
                result += characters.charAt(
                    Math.floor(Math.random() * characters.length)
                );
            }
            for (let i = 0; i < numberCount; i++) {
                result += numbers.charAt(Math.floor(Math.random() * numbers.length));
            }
            for (let i = 0; i < 16; i++) {
                qrResult += allCharacters.charAt(
                    Math.floor(Math.random() * allCharacters.length)
                );
            }

            this.password = result;
            this.qrPassword = qrResult;
        },

        async save() {
            let valid = await this.validateInput();
            // If an input is not valid (change only fires when input is no longer focused)
            // so we do this to prevent: changing an input field to an invalid input, then clicking save
            if (!valid) { return; }
            this.loadingSave = true;

            this.showLoadingOverlay = true;
            let givenDefaultPic = defaultProfilePicture;
            try {
                /**  set avatar.svg as profile picture string */
                const split1 = defaultProfilePicture.split('/');
                const split2 = split1[split1.length - 1].split('.')[0];
                givenDefaultPic = {
                    img: `/${split2}.svg`,
                }
            } catch (e) {
                console.warn(e);
            }

            let data = {
                name: this.firstName,
                lastName: this.lastName,
                gender: '',
                note: this.note,
                accountName: this.username,
                password: this.password,
                qrPassword: this.qrPassword,
                simpleEditor: false,
                profilePicture: givenDefaultPic,
                canEditTimeslot: this.canEditTimeslot,
                canEditQrCodes: this.canEditQrCodes,
                pinActivated: this.pinActivated,
                messagesPinActivated: this.messagesPinActivated,
                qrLoginDeactivated: this.qrLoginDeactivated,
                loginPinActivated: this.loginPinActivated,
            };

            let response = await this.createTeacher(data);
            this.savedTeacher = response;

            if (Number.isInteger(response)) {
                let message = 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!';
                if (response === 504) {
                    message = 'Der Server braucht länger als erwartet. Der Lehrer wurde dennoch angelegt.'
                }
                this.showSnackbar({
                    message,
                    color: 'error'
                });
                this.loadingSave = false;
                return;
            } else {
                let responseSubject;
                if (this.selectedSubjects.length > 0) {
                    for (let i = 0; i < this.selectedSubjects.length; i++) {
                        let newTeachers = this.selectedSubjects[i].teachers.concat(response['_id']);
                        let data = {
                            _id: this.selectedSubjects[i]._id,
                            teachers: newTeachers,
                        }
                        responseSubject = await this.editSubject(data);

                    }
                }
                if (Number.isInteger(responseSubject)) {
                    this.showSnackbar({
                        message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                        color: 'error'
                    });
                    this.loadingSave = false;
                    return;
                }
                this.saveDialog = true;
                this.showSnackbar({ message: 'Gespeichert!'});
            }

            this.showLoadingOverlay = false;
            this.loadingSave = false;
        },

        async pdf() {
            const pdfBytes = await LoginPdf.getNormalLoginPdf({
                firstName: this.firstName,
                lastName: this.lastName,
                accountName: this.username,
                password: this.password,
                qrPassword:  this.qrPassword,
                role: 'teacher'
            });
            this.saveByteArray(`${this.groupName}_LuL_${this.username}.pdf`, pdfBytes);
        },

        saveByteArray(reportName, byte) {
            let blob = new Blob([byte], {type: "application/pdf"});
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = reportName;
            link.click();
        },

        finish() {
            this.saveDialog = false;
            this.$emit('stopEvent', this.savedTeacher);
            this.clearInputs();
        },
        clearInputs(){
            this.firstName = '';
            this.lastName = '';
            this.note = '';
            this.username = '';
            this.selectedSubjects = [];
        }
    },
}
</script>

<style lang="scss" scoped>
table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

table tr th:first-child,
table tr td:first-child {
    width: 10px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}
table, tr, td {
    border: none;
}

input[type="date"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.6rem;
}

.input {
    display: block;
    width: 100%;
    max-width: 100%;
    border-radius: 5px;
    border-width: 0;
    color: black;
    padding: 1px 5px 1px 5px;
    border: none !important;
    background-color: var(--v-hellgrau-base);
    overflow: hidden;
    appearance:none
}

.errorInput {
    border: 2px solid red !important;
}

.innerContainer {
    background-color: #ffffff;
    border-radius: 15px;
}

.inputField {
    border-radius: 8px;
}
</style>
