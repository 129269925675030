import {
    createFileXMLHttpRequest,
    createFileXmlPatchRequest,
    deleteRequest,
    filePostRequest,
    getRequest,
    patchRequest,
    postRequest, request,
    foreignRequest,
} from "./request";

// a getRequest should NOT have a body, use a postRequest instead
const resetLockedAccountRequest = async (body) => postRequest('/account/resetLockedAccount', body);
const verifyResetOTP = async (body) => postRequest('/account/verifyResetOTP', body);
const checkBrowser = async () => getRequest('/account/checkBrowser');
const checkWebsocketConnections = async (accountId) => postRequest(`/account/${accountId}/checkWebSocket`);
const forceLogout = async (accountId) => postRequest(`/account/${accountId}/forceLogout`);
const getAccounts = async () => getRequest('/account');
const loginAccount = async (body) => postRequest('/account/login', body);
const has2FA = async (body) => postRequest(`/2FA/get/exist`, body);
const get2FA = async (body) => postRequest(`/2FA/get/send`, body);
const verify2FA = async (accountId, body) => postRequest(`/2FA/${accountId}`, body);
const getActivate2Fa = async (accountId) => getRequest(`/2FA/${accountId}/activate`);
const activate2FA = async (accountId, body) => postRequest(`/2FA/${accountId}/activate`, body);
const patchAccount = async (accountId, body) => patchRequest(`/account/${accountId}`, body);
const subscribe = async (body) => postRequest('/account/subscribe', body);
const unsubscribe = async (body) => postRequest('/account/unsubscribe', body);
const getCurrentAccount = async () => getRequest('/account/get');
const getSecurityQuestions = async () => getRequest('/account/securityQuestions');
const acceptTermsOfService = async () => postRequest('/account/accepttos');
const getAVVSigned = async() => getRequest('/account/getAVVSigned');
const isAVVSigned = async() => getRequest('/account/isAVVSigned');
const getAVVTemplate = async() => getRequest('/account/getAVVTemplate');
const getAVVForm = async() => getRequest('/account/getAVVForm');
const getVTTForm = async() => getRequest('/account/getVTTForm');
const getFragebogenForm = async() => getRequest('/account/getFragebogenForm');
const getDevices = async(accountId) => getRequest(`/account/${accountId}/devices`);
const deleteDevice = async(accountId, deviceId) => deleteRequest(`/account/${accountId}/devices/${deviceId}`);
const toggleUsageAgreement = async(accountId) => patchRequest(`/account/${accountId}/toggleUsageAgreement`);
const getVTTTemplate = async() => getRequest('/account/getVTTFile');
const getWordTemplate = async() => getRequest('/account/getWordTemplate');
const getNutzungsbedingungenDocx = async() => getRequest('/account/getNutzungsbedingungen');
const getRollenkonzeptDocx = async() => getRequest('/account/getRollenkonzept');
const resetLoginTries = async(accountId) => patchRequest(`/account/${accountId}/resetTries`);
const updateLastChatAccess = async(accountId) => patchRequest(`/account/${accountId}/lastChatAccess`);
const updateAccountPin = async(accountId, body) => patchRequest(`/account/${accountId}/changePin`, body);
const isPinValid = async(accountId, body) => postRequest(`/account/${accountId}/checkPin`, body);
const accountHasPin = async(accountId) => getRequest(`/account/${accountId}/hasPin`);
const resetPin = async(accountId) => patchRequest(`/account/${accountId}/resetPin`);


const getAccountSets = async() => getRequest(`/account-set`);

const patchAccessibility = async(accountId, body) => patchRequest(`/a11y/${accountId}`, body)

const patchPreferences = async(accountId, body) => patchRequest(`/accountPreferences/${accountId}`, body)

//#region matrix
const deleteMessage = async (params) => deleteRequest(`/matrix/message/${params.roomId}/${params.eventId}/${params.txnId}`, params);
const getCurrentMatrixUser = async () => getRequest('/matrix/user/me');
const getMatrixUsersInRoom = async (roomId) => getRequest(`/matrix/users/room/${roomId}`);
const getMatrixUsersFullNameByRoomId = async (roomId) => getRequest(`/matrix/users/name/${roomId}`);
const getMatrixName = async (accountId) => getRequest(`/matrix/user/name/${accountId}`);
const postVoiceMessage = (file, roomId) => createFileXMLHttpRequest(`/matrix/${roomId}/voice`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const deleteVoiceMessage = async (roomId, fileId) => deleteRequest(`/matrix/${roomId}/voice/${fileId}`);
const postFileMessage = (file, roomId) => createFileXMLHttpRequest(`/matrix/${roomId}/file`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const deleteFileMessage = async (roomId, fileId) => deleteRequest(`/matrix/${roomId}/voice/${fileId}`);
const getFileMessage = async (roomId, fileId) => getRequest(`/matrix/${roomId}/file/${fileId}`);
const postCustomRoom = async (body) => postRequest('/matrix/room/custom', body);
const getMatrixRoomLockStatus = async () => getRequest(`/matrix/room/lock`);
const getMatrixRoomLockStatusById = async (roomId) => getRequest(`/matrix/room/lock/${roomId}`);
const setMatrixRoomLockStatus = async (roomId, body) => postRequest(`/matrix/room/lock/${roomId}`, body);
const deleteMatrixRoom = async (roomId) => deleteRequest(`/matrix/room/${roomId}`);
//#endregion

//#region deactivateFeature
const getDeactivatedFeature = async () => getRequest('/deactivatedFeature');
const toggleDeactivateFeature = async (body) => patchRequest('/deactivateFeature/toggle', body); 
//#endregion
const postNameAvailable = async (body) => postRequest('/account/available', body);

//#region timeslots
const getTimeslots = async () => getRequest('/timeslot');
const getTimeslotLength = async (timeslotId) => getRequest(`/timeslot/${timeslotId}`);
const getTimeslotsByGroups = async (body) => postRequest('/timeslot/groups', body);
const postTimeslot = async (body) => postRequest('/timeslot', body);
const patchTimeslot = async (timeslotId, body) => patchRequest(`/timeslot/${timeslotId}`, body);
const deleteTimeslot = async (timeslotId, groupId) => deleteRequest(`/timeslot/${groupId}/${timeslotId}`);
//#endregion

//#region groups
const getGroups = async (appointmentId) => getRequest(`/group${appointmentId ? `?appointmentId=${appointmentId}` : ''}`);
const getGroupsInfo = async (appointmentId) => getRequest(`/group/lean${appointmentId ? `?appointmentId=${appointmentId}` : ''}`);
const getGroupsInfoWithLeaders = async (appointmentId) => getRequest(`/group/lean/leaders${appointmentId ? `?appointmentId=${appointmentId}` : ''}`);
const getGroup = async (groupId) => getRequest(`/group/${groupId}`);
const postGroup = async (body) => postRequest('/group', body);
const patchGroup = async (groupId, body) => patchRequest(`/group/${groupId}`, body);
const updateGroupTimeslots = async (groupId, timeslotId) => patchRequest(`/group/${groupId}/timeslot/${timeslotId}`)
const deleteGroup = async (groupId) => deleteRequest(`/group/${groupId}`);
//#endregion

//#region maintainers
const getMaintainers = async () => getRequest('/maintainer');
const getMaintainer = async (maintainerId) => getRequest(`/maintainer/${maintainerId}`);
const getMeMaintainer = async () => getRequest('/maintainer/me');
const postMaintainer = async (body) => postRequest('/maintainer', body);
const patchMaintainer = async (maintainerId, body) => patchRequest(`/maintainer/${maintainerId}`, body);
const maintainerFirstLogin = async (maintainerId, body) => patchRequest(`/maintainer/${maintainerId}/firstLogin`);
const deleteMaintainer = async (maintainerId) => deleteRequest(`/maintainer/${maintainerId}`);
const confirmMaintainerPassword = async (body) => postRequest(`/maintainer/confirm`, body);
const postMaintainerAVV = (file, accountId) => createFileXMLHttpRequest(`/maintainer/avv/${accountId}`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const postMaintainerAppendAVV = (contractId, body) => postRequest(`/maintainer/avv/${contractId}/append`, body);
const postMaintainerProfilePicture = (maintainerId, file) => createFileXMLHttpRequest(`/maintainer/${maintainerId}/profilePicture`, file);
const getMaintainerProfilePicture = (maintainerId) => getRequest(`/maintainer/${maintainerId}/profilePicture`);
const deleteMaintainerProfilePicture = (maintainerId) => deleteRequest(`/maintainer/${maintainerId}/profilePicture`);
const deleteAVV = async (maintainerId)  => deleteRequest(`/maintainer/${maintainerId}/deleteAVVSigned`);
const resetMaintainerPassword = async (body)=> postRequest('/maintainer/resetMaintainerPassword', body);
//#endregion

//#region pupils
const getPupils = async () => getRequest('/pupil');
const getPupil = async (pupilId) => getRequest(`/pupil/${pupilId}`);
const getMePupil = async () => getRequest(`/pupil/me`);
const postPupil = async (body) => postRequest('/pupil', body);
const patchPupil = async (pupilId, body) => patchRequest(`/pupil/${pupilId}`, body);
const deletePupil = async (pupilId) => deleteRequest(`/pupil/${pupilId}`);
const removeParentFromPupil = async (pupilId, parentId) => patchRequest(`/pupil/${pupilId}/parent/${parentId}`);

//#endregion

//#region parent
const getParents = async () => getRequest('/parent');
const getParent = async (parentId, body) => getRequest(`/parent/${parentId}`, body);
const getMeParent = async () => getRequest('/parent/me');
const postParent = async (body) => postRequest('/parent', body);
const patchParent = async (parentId, body) => patchRequest(`/parent/${parentId}`, body);
const deleteParent = async (parentId) => deleteRequest(`/parent/${parentId}`);
const getChildTeacher = async (childId) => getRequest(`/parent/getTeacher/${childId}`);
//#endregion

//#region parentalLetter
const getAllParentalLetters = async (body) => postRequest('/parentalLetter/list', body);
const getParentalLetterList = async () => getRequest('/parentalLetter/list');
const getParentalLetterById = async (parentalLetterId) => getRequest(`/parentalLetter/${parentalLetterId}`);
const createParentalLetter = async (body) => postRequest('/parentalLetter', body);
const setSeenBy = async (parentalLetterId, body) => postRequest(`/parentalLetter/${parentalLetterId}/seen`, body);
const patchParentalLetter = async (parentalLetterId, body) => patchRequest(`/parentalLetter/${parentalLetterId}`, body);
const deleteParentalLetter = async (parentalLetterId) => deleteRequest(`/parentalLetter/${parentalLetterId}`);
const postParentalLetterFile = (parentalLetterId, file) => createFileXMLHttpRequest(`/parentalLetter/${parentalLetterId}/file`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const getParentalLetterFile = async (parentalLetterId, fileId) => getRequest(`/parentalLetter/${parentalLetterId}/file/${fileId}`);
const deleteParentalLetterFile = async (parentalLetterId, fileId) => deleteRequest(`/parentalLetter/${parentalLetterId}/file/${fileId}`);

const createResponse = (parentalLetterId, file, body) => createFileXMLHttpRequest(`/parentalLetterResponse/${parentalLetterId}`, file, body); // Returning XMLHttpRequest object. You've to call ".send(data)".
const getSignature = async (parentalLetterResponseId) => getRequest(`/parentalLetterResponse/${parentalLetterResponseId}/signature`);
const deleteSignature = async (parentalLetterResponseId) => deleteRequest(`/parentalLetterResponse/${parentalLetterResponseId}/signature`);
//#endregion

//#region sickNote
const createSickNote = async (body) => postRequest('/sickNote', body);
const getSickNotes = async () => getRequest('/sickNote');
const getArchivedSickNotes = async () => getRequest('/archivedSickNote');
const getArchivedSickNoteCount = async () => getRequest('/archivedSickNoteCount');
const getSickNote = async (sickNoteId) => getRequest(`/sickNote/${sickNoteId}`);
const postSickNoteFile = (sickNoteId, file) => createFileXMLHttpRequest(`/sickNote/${sickNoteId}/file`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const getSickNoteFile = async (sickNoteId, fileId) => getRequest(`/sickNote/${sickNoteId}/file/${fileId}`);
const acceptSickNote = (sickNoteId) => postRequest(`/sickNote/${sickNoteId}/accept`);
const declineSickNote = (sickNoteId) => postRequest(`/sickNote/${sickNoteId}/decline`);
const toggleSickNote = (sickNoteId) => postRequest(`/sickNote/${sickNoteId}/toggle`);
const archiveSickNote = (sickNoteId) => postRequest(`/sickNote/${sickNoteId}/archive`);
const restoreSickNote = (sickNoteId) => postRequest(`/sickNote/${sickNoteId}/restore`);
const autoArchiveSickNotes = async () => patchRequest('/sickNote/autoArchive');

const uploadSickNoteSignature = (sickNoteId, file, body) => createFileXMLHttpRequest(`/sickNote/${sickNoteId}/signature`, file, body); // Returning XMLHttpRequest object. You've to call ".send(data)".
const getSickNoteSignature = async (sickNoteId) => getRequest(`/sickNote/${sickNoteId}/signature`);
//#endregion

//#region schoolSubjects
const getSchoolSubjects = async () => getRequest('/schoolsubject');
const getSchoolSubject = async (schoolSubjectId) => getRequest(`/schoolsubject/${schoolSubjectId}`);
const postSchoolSubject = async (body) => postRequest('/schoolsubject', body);
const patchSchoolSubject = async (schoolSubjectId, body) => patchRequest(`/schoolsubject/${schoolSubjectId}`, body);
const deleteSchoolSubject = async (schoolSubjectId) => deleteRequest(`/schoolsubject/${schoolSubjectId}`);
//#endregion

//#region archives
const getMyArchive = async () => getRequest('/archive');
const postSetFileInArchive = async (fileId, body) => postRequest(`/archive/${fileId}`, body);
//#endregion

//#region schoolSubjectAppointments
const patchAppointmentUploadSeenBy = async (uploadId, body) => patchRequest( `/schoolSubjectAppointment/upload/${uploadId}/setSeen`, body);
const getSchoolSubjectAppointments = async () => getRequest('/schoolsubjectappointment');
const getSchoolSubjectAppointmentsForWeek = async (weekId, firstLoad, pupilId) => getRequest(`/schoolsubjectappointment/week/${weekId}?firstLoad=${firstLoad}${pupilId ? `&pupilId=${pupilId}` : ''}`);
const getSchoolSubjectAppointmentsForRoomAndWeek = async (roomId, weekId, firstLoad) => getRequest(`/schoolsubjectappointment/room/${roomId}/week/${weekId}?firstLoad=${firstLoad}}`);
const getMultipleSchoolSubjectAppointments = async (appointmentIds) => getRequest(`/schoolsubjectappointment?id=${appointmentIds}`);
const getSchoolSubjectAppointment = async (appointmentId) => getRequest(`/schoolsubjectappointment/${appointmentId}`);
const getSchoolSubjectAppointmentWithId = async (appointmentId) => getRequest(`/schoolsubjectappointment/get/${appointmentId}`);
const getSchoolSubjectAppointmentsParent = async (pupilId) => getRequest(`/schoolsubjectappointment?pupilId=${pupilId}`);
const postSchoolSubjectAppointment = async (body) => postRequest('/schoolsubjectappointment', body);
const patchSchoolSubjectAppointment = async (schoolSubjectAppointmentId, body) => patchRequest(`/schoolsubjectappointment/${schoolSubjectAppointmentId}`, body);
const patchAppointmentNoteSeen = async (schoolSubjectAppointmentId) => patchRequest(`/schoolsubjectappointment/${schoolSubjectAppointmentId}/noteSeen`);
const deleteSchoolSubjectAppointment = async (schoolSubjectAppointmentId) => deleteRequest(`/schoolsubjectappointment/${schoolSubjectAppointmentId}`);
const deleteSchoolSubjectAppointmentRule = async (ruleId) => deleteRequest(`/schoolsubjectappointment/rule/${ruleId}`);
const deleteSchoolSubjectAppointmentFromRule = async (appointmentId, ruleId) => deleteRequest(`/schoolsubjectappointment/${appointmentId}/rule/${ruleId}`);
const getSchoolSubjectAppointmentRuleFromId = async (ruleId) => getRequest(`/schoolSubjectAppointment/rule/${ruleId}`);
const joinSchoolSubjectAppointmentBbbMeetingUrl = async (schoolSubjectAppointmentId) => getRequest(`/schoolsubjectappointment/${schoolSubjectAppointmentId}/joinBbbMeetingUrl`);
const joinTeachersRoomBbbMeetingUrl = async () => getRequest(`/teacher/joinTeachersRoomUrl`);
const postTeacherUpload = (appointmentId, file) => createFileXMLHttpRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const postTeacherUploadEdit = async (appointmentId, fileId, file) => filePostRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/${fileId}/edit`, file);
const postTeacherUploadMove = async (appointmentId, fileId) => postRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/${fileId}/move`);
const postTeacherUploadFromFileWidget = async (appointmentId, body) => postRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/postFromFileWidget`, body);
const patchTeacherUploadPip = async (appointmentId, fileId, args) => patchRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/${fileId}/pip`, args);
const patchTeacherUploadFile = async (appointmentId, fileId, newFile) => createFileXmlPatchRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/${fileId}/file`, newFile);
const getTeacherUpload = async (appointmentId, fileId) => getRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/${fileId}`);
const getTeacherUploadThumbnail = async (appointmentId, fileId) => getRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/thumb/${fileId}`);
const deleteUpload = async (uploadId) => deleteRequest(`/schoolSubjectAppointment/upload/${uploadId}`);
const postPupilUpload = (appointmentId, file) => createFileXMLHttpRequest(`/schoolSubjectAppointment/${appointmentId}/pupilUploads`, file);
const postPupilUploadFromFileWidget = async (appointmentId, body) => postRequest(`/schoolSubjectAppointment/${appointmentId}/pupilUploads/postFromFileWidget`, body);
const postPupilUploadEdit = async (appointmentId, fileId, file) => filePostRequest(`/schoolSubjectAppointment/${appointmentId}/pupilUploads/${fileId}/edit`, file);
const getPupilUpload = async (appointmentId, fileId) => getRequest(`/schoolSubjectAppointment/${appointmentId}/pupilUploads/${fileId}`);
const getPupilUploadThumbnail = async (appointmentId, fileId) => getRequest(`/schoolSubjectAppointment/${appointmentId}/pupilUploads/thumb/${fileId}`);
const postThumbnail = async (fileId, file) => filePostRequest(`/schoolSubjectAppointment/${fileId}/thumbnail`, file);
const getThumbnail = async (fileId) => getRequest(`/schoolSubjectAppointment/${fileId}/thumbnail`);
const postTeacherUploadFromExistingFile = async (schoolSubjectAppointmentId, fileId, body) => postRequest(`/schoolSubjectAppointment/teacher/${schoolSubjectAppointmentId}/file/${fileId}`, body);
const postPupilUploadFromExistingFile = async (fileId, appointmentId) => getRequest(`/schoolSubjectAppointment/pupil/${appointmentId}/file/${fileId}`);
const postPupilUploadEditFromExistingFile = async (fileId, appointmentId, body) => postRequest(`/schoolSubjectAppointment/teacher/${appointmentId}/edit/${fileId}`, body);
const patchCorrectionUploadSeen = async (uploadId, body) => patchRequest(`/schoolSubjectAppointment/upload/${uploadId}/setSeen`, body);
const getTeacherAppointmentAtTimeslotAndDate = async (teacherId, timeslotId, date) => getRequest(`/schoolsubjectappointment/${teacherId}/timeslot/${timeslotId}/date/${date}`);
const getGroupsAppointmentsOnDay = async (groupId, day) => getRequest(`/schoolSubjectAppointment/group/${groupId}/day/${day}`);
//#endregion

//#region teachers
const getTeachers = async () => getRequest('/teacher');
const getTeacher = async (teacherId) => getRequest(`/teacher/${teacherId}`);
const getProfilePicture = async (teacherId) => getRequest(`/teacher/${teacherId}/profilePicture`);
const deleteProfilePicture = async (teacherId) => deleteRequest(`/teacher/${teacherId}/profilePicture`);
const getMeTeacher = async () => getRequest(`/teacher/me`);
const postTeacher = async (body) => postRequest('/teacher', body);
const postTeacherPicture = (teacherId, file) => createFileXMLHttpRequest(`/teacher/${teacherId}/profilePicture`, file);
const patchTeacher = async (teacherId, body) => patchRequest(`/teacher/${teacherId}`, body);
const patchTeacherProfile = async (teacherId, body) => patchRequest(`/teacher/${teacherId}/profile`, body);
const deleteTeacher = async (teacherId) => deleteRequest(`/teacher/${teacherId}`);
const getClassTeachers = async (pupilId) => getRequest(`/teacher/${pupilId}/classTeacher`);
const getTeachersFromGroupByAppointment = async (groupId) => getRequest(`/group/${groupId}/teachers`);
//#endregion

//#region substitution
const getSubstitutions = async () => getRequest('/substitution');
const postSubstitution = async (body) => postRequest('/substitution', body);
const postSubstitutionNotification = async (body) => postRequest('/substitution/notification', body);
const postSubstitutionNotificationMaintainer = async (body) => postRequest('/substitution/maintainerNotification',body);
const patchSubstitution = async (substitutionId, body) => patchRequest(`/substitution/${substitutionId}`, body);
const deleteSubstitution = async (substitutionId) => deleteRequest(`/substitution/${substitutionId}`);
//#endregion

//#region rooms
const getRooms = async () => getRequest('/room');
const getRoomsForTimeslot = async (timeslotId, day) => getRequest(`/room/timeslot/${timeslotId}/${day}`);
const getRoomById = async (roomId) => getRequest(`/room/${roomId}`);
const postRoom = async (body) => postRequest('/room', body);
const patchRoom = async (roomId, body) => patchRequest(`/room/${roomId}`, body);
const deleteRoom = async (roomId) => deleteRequest(`/room/${roomId}`);
//#endregion

const getExternalBbbRooms = async () => getRequest('/externalBbbRoom');
const getExternalBbbRoomById = async (externalBbbRoomId) => getRequest(`/externalBbbRoom/${externalBbbRoomId}`);
const postExternalBbbRoom = async (externalBbbRoom) => postRequest('/externalBbbRoom', externalBbbRoom);
const patchExternalBbbRoom = async (externalBbbRoomId, externalBbbRoom) => patchRequest(`/externalBbbRoom/${externalBbbRoomId}`, externalBbbRoom);
const deleteExternalBbbRoom = async (externalBbbRoomId) => deleteRequest(`/externalBbbRoom/${externalBbbRoomId}`);
const getExternalBbbRoomGuestData = async (externalBbbRoomId, token) => getRequest(`/externalBbbRoom/${externalBbbRoomId}/guestData?token=${token}`);
const getExternalBbbRoomJoinAsModeratorUrl = async (externalBbbRoomId, pupilId) => getRequest(`/externalBbbRoom/${externalBbbRoomId}/joinAsModeratorUrl/${pupilId}`);
const getExternalBbbRoomJoinAsGuestUrl = async (externalBbbRoomId, token, username, pupilId) => getRequest(`/externalBbbRoom/${externalBbbRoomId}/joinAsGuestUrl/${pupilId}?token=${token}&username=${username}`);

//#region blackboard
const getBlackboard = async () => getRequest('/blackboard');
const getParentBlackboard = async (pupilId) => getRequest(`/blackboard/${pupilId}`);
const postBlackboard = async (body) => postRequest('/blackboard', body);
const postBlackboardFile = (blackboardId, file) => createFileXMLHttpRequest(`/blackboard/${blackboardId}/file`, file);
const getBlackboardUpload = async (blackboardId, fileId) => getRequest(`/blackboard/${blackboardId}/file/${fileId}`);
// const getBlackboardUploadThumbnail = async (fileId) => getRequest(`/blackboard/file/thumb/${fileId}`);
const patchBlackboard = async (blackboardId, body) => patchRequest(`/blackboard/${blackboardId}`, body);
const patchBlackboardSeen = async (blackboardId) => patchRequest(`/blackboard/${blackboardId}/seen`,);
const toggleFixEntry = async (blackboardId) => patchRequest(`/blackboard/${blackboardId}/fixEntry`);
const hideBlackboardEntry = async (blackboardId) => patchRequest(`/blackboard/${blackboardId}/hideEntry`);
const deleteBlackboard = async (blackboardId, body) => deleteRequest(`/blackboard/${blackboardId}`, body);
const deleteBlackboardUpload = async (blackboardId, fileId) => deleteRequest(`/blackboard/${blackboardId}/file/${fileId}`);
//#endregion

const postUploadMessage = (file) => filePostRequest(`/uploadMessage`, file);
const postUploadMessages = async (body) => postRequest('/uploadMessages', body);
const patchUploadMessages = async (uploadMessageId, body) => patchRequest(`/uploadMessages/${uploadMessageId}`, body);
const postUploadMessagesPairing = async (body) => postRequest('/uploadMessages/pairing', body);
const getUploadMessages = async (fileId) => getRequest(`/uploadMessages/${fileId}`);
const getUploadMessage = async (fileId) => getRequest(`/uploadMessage/${fileId}`);
const getUploadMessageEntry = async (uploadMessageId) => getRequest(`/uploadMessage/entry/${uploadMessageId}`);

const postSubjectFolderUpload = (subjectId, file) => createFileXMLHttpRequest(`/subjectFolder/${subjectId}`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const getSubjectFolderUploads = async (subjectId, teacherId) => getRequest(`/subjectFolder/${subjectId}/${teacherId}`);
const getSubjectFolderUpload = async (subjectId, teacherId, fileId) => getRequest(`/subjectFolder/${subjectId}/${teacherId}/${fileId}`);
const deleteSubjectFolderUpload = async (subjectId, teacherId, fileId) => deleteRequest(`/subjectFolder/${subjectId}/${teacherId}/${fileId}`);


//#region VFS v1 (deprecated)
const postVFSFolderUpload = (file, folderType, folderId) => createFileXMLHttpRequest(`/virtualFileSystem/file/${folderType}/${folderId ? `${folderId}` : ''}`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const getVFSFolderUploads = async (folderType, folderId) => getRequest(`/virtualFileSystem/${folderType}/${folderId ? `${folderId}` : ''}`);
const getVFSFolderUpload = async (fileId) => getRequest(`/virtualFileSystem/file/download/${fileId}`);
const deleteVFSFolderUpload = async (fileId) => deleteRequest(`/virtualFileSystem/file/${fileId}`);
const postVFSFolderUploadFromFileWidget = async (body) => postRequest('/virtualFileSystem/copy/copyFromFileWidget', body);
const createVFSFolder = async (folderId, body) => postRequest(`/virtualFileSystem${folderId ? `/${folderId}` : ''}`, body);
const updateVFSFolder = async (folderId, body) => patchRequest(`/virtualFileSystem/${folderId}`, body);
const updateVFSFolderUpload = async (fileId, body) => patchRequest(`/virtualFileSystem/file/${fileId}`, body);
const deleteVFSFolder = async (folderId) => deleteRequest(`/virtualFileSystem/${folderId}`);
const searchVFS = async (body) => postRequest(`/virtualFileSystem/search/all`, body);
const getVFSSharing = async (folderId) => getRequest(`/virtualFileSystem/sharing/${folderId}/all`);
const updateVFSFolderUpload_Legacy = async (fileId, newFileData) => createFileXmlPatchRequest(`/virtualFileSystem/file/legacy/${fileId}`, newFileData);

const updateVFSSetActiveTask = (fileId, body) => patchRequest(`/virtualFileSystem/sharing/setActiveTask/${fileId}`, body);
const postVFSSubmission = (fileId, file) => filePostRequest(`/virtualFileSystem/sharing/submission/${fileId}`, file);
//#endregion

//#region VFS v2
const postVFSFolderUploadV2 = (file, folderId) => createFileXMLHttpRequest(`/virtualFileSystemV2/file/${folderId ? `${folderId}` : ''}`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const getVFSFolderUploadsV2 = async (folderId) => getRequest(`/virtualFileSystemV2/${folderId ? `${folderId}` : ''}`);
const getVFSAppFolderV2 = async (appFolderName) => getRequest(`/virtualFileSystemV2/appFolder/${appFolderName}`);
const getVFSFolderUploadV2 = async (fileId) => getRequest(`/virtualFileSystemV2/file/download/${fileId}`);
const deleteVFSFolderUploadV2 = async (fileId) => deleteRequest(`/virtualFileSystemV2/file/${fileId}`);
const createVFSFolderV2 = async (folderId, body) => postRequest(`/virtualFileSystemV2${folderId ? `/${folderId}` : ''}`, body);
const updateVFSFolderV2 = async (folderId, body) => patchRequest(`/virtualFileSystemV2/${folderId}`, body);
const updateVFSFolderUploadV2 = async (fileId, body) => patchRequest(`/virtualFileSystemV2/file/${fileId}`, body);
const deleteVFSFolderV2 = async (folderId) => deleteRequest(`/virtualFileSystemV2/${folderId}`);
const searchVFSV2 = async (body) => postRequest(`/virtualFileSystemV2/search/all`, body);
const getVFSSharingV2 = async (folderId) => getRequest(`/virtualFileSystemV2/sharing/${folderId}/all`);
const updateVFSFolderUpload_LegacyV2 = async (fileId, newFileData) => createFileXmlPatchRequest(`/virtualFileSystemV2/file/legacy/${fileId}`, newFileData);
const updateVFSSetActiveTaskV2 = (fileId, body) => patchRequest(`/virtualFileSystemV2/sharing/setActiveTask/${fileId}`, body);
const postVFSSubmissionV2 = (fileId, file) => filePostRequest(`/virtualFileSystemV2/sharing/submission/${fileId}`, file);
const postVFSSubmissionLibre = (fileId) => postRequest(`/virtualFileSystemV2/sharing/submission/${fileId}/libre`);
const postExistingFileToVFS = async (fileId, folderId, body) => postRequest(`/virtualFileSystemV2/add/${fileId}/${folderId}`, body);
const postOnlineLibraryFileToVFS = async (folderId, fileName, file) => filePostRequest(`/virtualFileSystemV2/onlineLibrary/${folderId}/${fileName}`, file);
const markFileSeen = async (fileId) => getRequest(`/virtualFileSystemV2/seen/file/${fileId}`);
const markLearningPathSeen = async (learningPathId) => getRequest(`/virtualFileSystemV2/seen/learnPath/${learningPathId}`);
const getUsedDiskSpaceForAccount = async () => getRequest(`/virtualFileSystemV2/used/space`);
//#endregion

//#region file update
const patchImageRotateByDeg = async (fileId, body) => patchRequest(`/virtualFileSystem/image/rotate/${fileId}`, body);
//#endregion

const getServerTime = async (body) => getRequest('/time', body);
const getVersionNumber = async () => getRequest('/version');
const getBonusFunctions = async (body) => getRequest('/bonusfunctions');
const getBackend = async () => getRequest('/backend');
const getPublicVapidKey = async () => getRequest('/public-vapid-key');

const teacherClassroom = async () => getRequest('/teacherClassroom');
const joinClassroom = async (classroomId) => getRequest(`/teacherClassroom/${classroomId}`);
const changeRoomStatus = async (classroomId, body) => postRequest(`/teacherClassroom/${classroomId}`, body);

const getMyConferenceTeacher = async () => getRequest('/teacherConferenceRoom');
const getMyConferenceParent = async (pupilId) => getRequest(`/teacherConferenceRoom/${pupilId}`);
const joinConferenceRoom = async (roomId, childrenId) => getRequest(`/teacherConferenceRoom/bbb/${roomId}${childrenId ? `/${childrenId}` : ''}`);
const changeConferenceRoomStatus = async (roomId, body) => postRequest(`/teacherConferenceRoom/${roomId}`, body);

const translateToTargetLang = async (body) => postRequest('/translate', body)

const getSubtitle = async (fileId) => getRequest(`/subtitle/${fileId}`);

const getImageLibraryIndex = async () => getRequest(`/image-library`);
const getImageLibraryImage = async (imageId) => getRequest(`/image-library/${imageId}`);

const getDocumentRegistryDocuments = async () => getRequest('/document-registry/document');
const getDocumentRegistryDocument = async (documentId) => getRequest(`/document-registry/document/${documentId}`);
const postDocumentRegistryDocument = async (body) => postRequest('/document-registry/document', body);
const postDocumentRegistryDocumentFromBackend = async (documentId, body) => postRequest(`/document-registry/document/${documentId}`, body);
const patchDocumentRegistryDocument = async (documentId, body) => patchRequest(`/document-registry/document/${documentId}`, body);
const postDocumentRegistryDocumentFile = async (documentId, file) => createFileXMLHttpRequest(`/document-registry/document/${documentId}/file`, file);
const getDocumentRegistryDocumentFile = async (documentId, fileId) => getRequest(`/document-registry/document/${documentId}/file/${fileId}`);
const deleteDocumentRegistryDocument = async (documentId) => deleteRequest(`/document-registry/document/${documentId}`);

const getDocumentRegistryFolders = async () => getRequest('/document-registry/folder');
const getDocumentRegistryRootFolder = async () => getRequest('/document-registry/folder/root');
const getDocumentRegistryFolder = async (folderId) => getRequest(`/document-registry/folder/${folderId}`);
const postDocumentRegistryFolder = async (body) => postRequest('/document-registry/folder', body);
const patchDocumentRegistryFolder = async (folderId, body) => patchRequest(`/document-registry/folder/${folderId}`, body);
const deleteDocumentRegistryFolder = async (folderId) => deleteRequest(`/document-registry/folder/${folderId}`);

const getDocumentRegistryTags = async () => getRequest('/document-registry/tag');
const getDocumentRegistryTag = async (tagId) => getRequest(`/document-registry/tag/${tagId}`);
const postDocumentRegistryTag = async (body) => postRequest('/document-registry/tag', body);
const deleteDocumentRegistryTag = async (tagId) => deleteRequest(`/document-registry/tag/${tagId}`);

// #region Document Drafts
const postDocumentDraft = (file) => createFileXMLHttpRequest('/document-draft', file);
const postDocumentDraftFile = (draftId, file) => filePostRequest(`/document-draft/${draftId}/file`, file);
const patchDocumentDraft = async (draftId, draftPayload) => patchRequest(`/document-draft/${draftId}`, draftPayload);
const getDocumentDraft = async (draftId) => getRequest(`/document-draft/${draftId}`);
const getDocumentDraftFile = async (draftId) => getRequest(`/document-draft/${draftId}/file`);
const getDocumentDraftThumbnail = async (draftId) => getRequest(`/document-draft/${draftId}/thumbnail`);
const deleteDocumentDraft = async (draftId) => deleteRequest(`/document-draft/${draftId}`);
// #endregion

//#region poll
const createPoll = async (body) => postRequest('/poll', body);
const getPoll = async (pollId) => getRequest(`/poll/${pollId}`);
const vote = async (pollId, body) => postRequest(`/poll/${pollId}/vote`, body);
const createOption = async (pollId, body) => postRequest(`/poll/${pollId}/option`, body);
//#endregion

//#region videoTutorial
const getVideoTutorialUserProperties = async (videoTutorialFilename, body) => postRequest(`/videoTutorialUserProperties/${videoTutorialFilename}`, body);
const patchVideoTutorialUserProperties = async (videoTutorialFilename, body) => patchRequest(`/videoTutorialUserProperties/${videoTutorialFilename}`, body);
//#endregion

//#region diary
const getDay = async (body) => postRequest('/diary/get', body);
const postDay = async (body) => postRequest('/diary', body);
const getAllLateTotals = async () => getRequest('/diary/lateTotal');
const getPupilsLateTimes = async (pupilId) => getRequest(`/diary/${pupilId}`);
//#endregion

//#region lateTimes
const getLateTimes = async () => getRequest('/lateTime');
const getSickNoteLateTime = async (sickNoteId) => getRequest(`/lateTime/sickNote/${sickNoteId}`);
const getPupilLateTimes = async (pupilId, startDate = null, endDate = null) => getRequest(`/lateTime/${pupilId}${startDate && endDate ? `?start=${startDate}&end=${endDate}`:''}`);
const postLateTime  = async (body) => postRequest('/lateTime', body);
const updateLateTime = async (lateTimeId, body) => patchRequest(`/lateTime/${lateTimeId}`, body);
const toggleExcuseLateTime = (lateTimeId) => patchRequest(`/lateTime/${lateTimeId}/excuse`);
const deleteLateTime = async (lateTimeId) => deleteRequest(`/lateTime/${lateTimeId}`);
//#endregion

//#region school
const getSchool = async () => getRequest('/school');
const createAndUpdateSchool = async (body) => postRequest('/school', body);
const postSchoolLogo = (schoolId, file) => createFileXMLHttpRequest(`/school/${schoolId}/logo`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const getSchoolLogo = async (schoolId, logoId) => getRequest(`/school/${schoolId}/logo/${logoId}`);
//#endregion

const createNewSchoolYear = async (body) => postRequest('/change-school-year', body);
const getWebSocketConnectionToken = async () => getRequest('/ws/connection-token');

//#region chatRoom

const getChatRooms = async (sinceUpdatedAt) => getRequest(`/chat-room${sinceUpdatedAt ? `?sinceUpdatedAt=${sinceUpdatedAt}` : ''}`);
const postChatRoom = async (body) => postRequest('/chat-room/', body);
const patchChatRoom = async (chatRoomId, body) => patchRequest(`/chat-room/${chatRoomId}`, body);
const muteChatRoom = async (chatRoomId) => patchRequest(`/chat-room/${chatRoomId}/mute`);
const addDraftMessage = async (chatRoomId, body) => patchRequest(`/chat-room/${chatRoomId}/draft`,body);

const unmuteChatRoom = async (chatRoomId) => patchRequest(`/chat-room/${chatRoomId}/unmute`);
const deleteChatRoom = async (chatRoomId) => deleteRequest(`/chat-room/${chatRoomId}`);
const leaveChatRoom = async (chatRoomId) => postRequest(`/chat-room/${chatRoomId}/leave`);
const updateChatRoomLastSeenTimestamp =  async (chatRoomId) => postRequest(`/chat-room/${chatRoomId}/lastSeenTimestamp`);

const getChatRoomEvents = async (sinceUpdatedAt) => getRequest(`/chat-room-event${sinceUpdatedAt ? `?sinceUpdatedAt=${sinceUpdatedAt}` : ''}`);
const postChatRoomEvent = async (body) => postRequest('/chat-room-event/', body);
const redactChatRoomEvent = async (chatRoomEventId) => postRequest(`/chat-room-event/${chatRoomEventId}/redact`);

const getChatRoomEventFile = async (chatRoomEventId) => getRequest(`/chat-room-event/${chatRoomEventId}/file`);
const postChatRoomEventFile = async (chatRoomEventId, file) => createFileXMLHttpRequest(`/chat-room-event/${chatRoomEventId}/file`, file);
//#endregion

//#region censorExpressions
const getCensoredExpressions = async () => getRequest('/censoredExpressions');
const postExpression = async (body) => postRequest('/censoredExpression', body);
const deleteExpression = async (expressionId) => deleteRequest(`/censoredExpression/${expressionId}`);
const updateExpression =  async (expressionId, body) => patchRequest(`/censoredExpression/${expressionId}`, body);
//#endregion

//#region Exchange
const getExchangeAll = async () => getRequest('/exchange');
const getExchangeReservations = async (exchangeId) => getRequest(`/exchangeReservation/${exchangeId}`);
const getExchangeAccounts = async () => getRequest('/exchange/accounts');
const getExchangeFile = async (exchangeId, fileId, fileAccessToken) => getRequest(`/exchange/${exchangeId}/file/${fileId}/${fileAccessToken}`);
const postExchangeReservation = async (exchangeId,body) => postRequest(`/exchangeReservation/${exchangeId}`, body);
const postExchange = async (body) => postRequest('/exchange', body);
const updateExchange = async (exchangeId, body) => patchRequest(`/exchange/${exchangeId}`, body);
const appendExchangeFile = async (exchangeId, file) => filePostRequest(`/exchange/${exchangeId}/file`, file);
const deleteExchange = async (exchangeId) => deleteRequest(`/exchange/${exchangeId}`);
const deleteExchangeFile = async (exchangeId, fileId) => deleteRequest(`/exchange/${exchangeId}/file/${fileId}`);
//#endregion

//#region IconUpload
const getIconUploads = async () => getRequest('/iconUpload');
const getIconUpload = async (iconId) => getRequest(`/iconUpload/${iconId}`);
const postIconUpload = async (body) => postRequest('/iconUpload',body);
const postIconUploadFile = async (file) => filePostRequest(`/iconUploadFile`, file);
const deleteIconUpload = async (iconId) => deleteRequest(`/iconUpload/${iconId}`);
const checkForDelete = async (iconId) => getRequest(`/iconUploadDeleteCheck/${iconId}`);
//#endregion

//#region pinboard
const getPinboards = async () => getRequest('/pinboard');
const getPinboard = async (pinboardId) => getRequest(`/pinboard/${pinboardId}`);
const getInvitationPinboard = async (invitationCode) => getRequest(`/pinboard/invitation/${invitationCode}`);
const postPinboard = async (body) => postRequest('/pinboard', body);
const postCard = async (body) => postRequest('/pinboard/card', body);
const patchPinboard = async (pinboardId, body) => patchRequest(`/pinboard/${pinboardId}`, body);
const patchPinboardInfo = async (pinboardId, body) => patchRequest(`/pinboard/pinboardInfo/${pinboardId}`, body);
const patchCard = async (cardId, body) => patchRequest(`/pinboard/card/${cardId}`, body);
const deletePinboard = async (pinboardId) => deleteRequest(`/pinboard/${pinboardId}`);
const deleteCard = async (cardId) => deleteRequest(`/pinboard/card/${cardId}`)
const markPinboardAsSeen = async (pinboardId, userId) => patchRequest(`/pinboard/seen/${pinboardId}/${userId}`);
const hasUserSeenPinboard = async (pinboardId, userId) => postRequest(`/pinboard/hasseen/${pinboardId}/${userId}`);
const postPinboardCardUpload = async (cardId, file) => filePostRequest(`/pinboard/card/${cardId}/file`, file);
const getPinboardCardUpload = async (cardId, fileId) => getRequest(`/pinboard/card/${cardId}/file/${fileId}`);
const patchPinboardCardUpload = async (cardId, fileId, body) => createFileXmlPatchRequest(`/pinboard/card/${cardId}/file/${fileId}`, body);
const deletePinboardCardUpload = async (cardId, fileId) => deleteRequest(`/pinboard/card/${cardId}/file/${fileId}`);
const postInvitation = async (pinboardId, body) => postRequest(`/pinboard/invitation/${pinboardId}`, body);
const deleteInvitation = async (invitationCode) => deleteRequest(`/pinboard/invitation/${invitationCode}`)
const postPinboardNotification = async (pinboardId) => patchRequest(`/pinboard/notifications/${pinboardId}`)
const postPinboardLastEdited = async (pinboardId) => patchRequest(`/pinboard/lastEdited/${pinboardId}`)

//#endregion

const isValidPassword = async (accountId, body) => postRequest(`/account/${accountId}/checkpassword`, body);

//#region externalApp
const getAllExternalApps = async() => getRequest('/externalapp/');
const postExternalApp = async(body) => postRequest('/externalapp/create/', body);
const deleteExternalApp = async(externalAppId) => deleteRequest(`/externalapp/delete/${externalAppId}`);
const patchExternalApp = async(externalAppId, body) => patchRequest(`/externalapp/patch/${externalAppId}`, body);
const getExternalApps = async(userId) => postRequest(`/externalapp/get/${userId}`);
const markExternalAppSeen = async (externalAppId) => getRequest(`/externalapp/seen/${externalAppId}`);


//#region calendar
const getCalendarEvents = async () => getRequest('/calendar');
const getCalendarEventById = async (eventId) => getRequest(`/calendar/${eventId}`);
const getCalendarEventsFromSerie = async (serie) => getRequest(`/calendar/serie/${serie}`);
const getCalendarEventsForMonth = async (month) => getRequest(`/calendar/time/${month}`);
const postNewCalenderEvent = async (body) => postRequest('/calendar', body);
const postNewRepeatingEvent = async (body) => postRequest('/calendar/repeat', body);
const postNewCalendarEventFile = (eventId, file) => filePostRequest(`/calendar/${eventId}/file`, file);
const postNewRepeatingEventFile = (serie, file) => filePostRequest(`/calendar/repeat/${serie}/file`, file);
const patchCalendarEvent = async (eventId, body) => patchRequest(`/calendar/${eventId}`, body);
const patchRepeatingEvent = async (serie, body) => patchRequest(`/calendar/repeating/${serie}`, body);
const patchRepeatingEventData = async (serie, data) => patchRequest(`/calendar/repeating/data/${serie}`, data);
const setCalenderEventSeen = async (eventIds) => patchRequest(`/calendar/seen/eventIds`, eventIds);
const deleteCalendarEvent = async (eventId) => deleteRequest(`/calendar/${eventId}`);
const deleteRepeatingEvent = async (serie) => deleteRequest(`/calendar/repeat/${serie}`);
const getUnseenEventsForUser = async () => getRequest('/calendar/user');
const getCalendarEventFile = async (fileId, fileAccessToken) => getRequest(`/calendar/file/${fileId}/${fileAccessToken}`);
const getCalendarEventFileInfo = async (fileId, fileAccessToken) => getRequest(`/calendar/file/${fileId}/${fileAccessToken}/info`);
const deleteEventFile = async (eventId, fileId) => deleteRequest(`/calendar/event/${eventId}/file/${fileId}`);
const getCalendarUpload = async (eventId, fileId) => getRequest(`/calendar/event/${eventId}/upload/${fileId}`);
const createCalendarReminder = async (body) => postRequest('/calendar/reminder', body);
const patchCalendarReminder = async (reminderId, body) => patchRequest(`/calendar/reminder/${reminderId}`, body);
const deleteCalendarReminder = async (eventId) => deleteRequest(`/calendar/reminder/${eventId}`);

//#endregion

//#region libreoffice
const getLibreDocFromFile = async (fileId) => getRequest(`/files/get/${fileId}`);
const createLibreTemplate= async (fileFormat, userId) => getRequest(`/files/template/${fileFormat}/${userId}`);
const getFile = async (fileId, accessToken) => getRequest(`/files/file/${fileId}/${accessToken}`);
const getFileBlob = async (fileId, accessToken) => getRequest(`/files/${fileId}/${accessToken}/blob`);
const getFileInformation = async (fileId, accessToken) => getRequest(`/files/file/information/${fileId}/${accessToken}`);
const getLibreDoc = async (libreDocId) => getRequest(`/files/libredoc/${libreDocId}`);
const getCopyOfFile = async (fileId, accessToken) => postRequest(`/files/copy/${fileId}/${accessToken}`);

//#region thumbnails
const getVFSUploadThumbnail = async (fileId, fileAccessToken) => getRequest(`/vfs/thumbnail/${fileId}/${fileAccessToken}`);
const getSchoolSubjectAppointmentUploadThumbnail = async (fileId, appointmentId) => getRequest(`/schoolsubjectappointment/thumbnail/${fileId}/${appointmentId}`);
const getBlackboardUploadThumbnail = async (fileId, blackboardId, pupilId) => getRequest(`/blackboard/thumbnail/${fileId}/${blackboardId}/${pupilId}`);
const getPinboardUploadThumbnail = async (fileId, pinboardCardId, pinboardId) => getRequest(`/pinboard/thumbnails/${fileId}/${pinboardCardId}/${pinboardId}`);
const getChatFileThumbnail = async (chatRoomId, chatRoomEventId) => getRequest(`/chat/thumbnail/${chatRoomId}/${chatRoomEventId}`);
const getThumbnailForParentalFile = async (parentalId, fileId, accessToken) => getRequest(`/parental/thumbnail/${parentalId}/${fileId}/${accessToken}`);
const getCalendarFileThumbnail = async (fileId, fileAccessToken, calendarId) => getRequest(`/calendar/thumbnail/${fileId}/${fileAccessToken}/${calendarId}`);
const getExchangeFileThumbnail = async (fileId, fileAccessToken, exchangeId) => getRequest(`/exchange/thumbnail/${fileId}/${fileAccessToken}/${exchangeId}`);
//#endregion

//#region toDoList/toDoTask
const getToDoLists = async () => getRequest('/toDoList');
const getToDoList = async (toDoListId) => getRequest(`/toDoList/${toDoListId}`);
const getToDoTasks = async () => getRequest('/toDoList');
const getToDoTask = async (toDoTaskId) => getRequest(`/toDoTask/${toDoTaskId}`);
const postToDoTask = async (body) => postRequest('/toDoTask', body);
const postToDoList = async (body) => postRequest(`/toDoList`, body);
const updateToDoTask = async (toDoTaskId, body) => patchRequest(`/toDoTask/${toDoTaskId}`, body);
const updateToDoList = async (toDoListId, body) => patchRequest(`/toDoList/${toDoListId}`, body);
const deleteToDoTask = async (toDoTaskId) => deleteRequest(`/toDoTask/${toDoTaskId}`);
const deleteToDoList = async (toDoListId) => deleteRequest(`/toDoList/${toDoListId}`);

//#endregion

//#region notifications
const setNotificationSeenByMe = async (body) => patchRequest('/notification/seenByMe', body);
// #endregion

//#region imageRights
const getImageRights = async () => getRequest('/imageRights');
const getImageRight = async (imageRightId) => getRequest(`/imageRights/${imageRightId}`);
const postImageRight = async (body) => postRequest('/imageRights', body);
const toggleImageRight = async (imageRightId, body) => patchRequest(`/imageRights/${imageRightId}`,body);
//#endRegion

// #region loginHistory
const postLoginHistory = async (body) => postRequest('/loginHistory',body);
const getLoginHistory = async () => getRequest('/loginHistory');

//#region externalAgreements
const postExternalUsageAgreement = async (body) => postRequest('/externalAgreement',body);
const getExternalAgreements = async () => getRequest('/externalAgreement');

//#region locationHistory
const getLocations = async () => getRequest('/location');
const postLocation = async (body) => postRequest('/location', body);
const patchLocation = async (locationId, body) => patchRequest(`/location/${locationId}`, body);
const deleteLocation = async (locationId, body) => deleteRequest(`/location/${locationId}`, body);

export {
    getLocations,
    postLocation,
    patchLocation,
    deleteLocation,
    
    postExternalUsageAgreement,
    getExternalAgreements,
    
    postLoginHistory,
    getLoginHistory,

    getVFSUploadThumbnail,
    getSchoolSubjectAppointmentUploadThumbnail,
    getPinboardUploadThumbnail,
    getChatFileThumbnail,
    getThumbnailForParentalFile,
    getCalendarFileThumbnail,
    getExchangeFileThumbnail,

    patchAppointmentUploadSeenBy,
    getSchoolSubjectAppointmentWithId,
    postPupilUploadFromExistingFile,

    postPupilUploadEditFromExistingFile,
    getCopyOfFile,
    getLibreDoc,
    createLibreTemplate,
    getLibreDocFromFile,
    getFileBlob,
    getFile,
    getFileInformation,

    markExternalAppSeen,
    getExternalApps,
    patchExternalApp,
    deleteExternalApp,
    getAllExternalApps,
    postExternalApp,

    checkWebsocketConnections,
    checkBrowser,
    resetLockedAccountRequest,
    verifyResetOTP,
    forceLogout,
    getAccounts,
    loginAccount,
    resetLoginTries,
    updateLastChatAccess,
    has2FA,
    get2FA,
    verify2FA,
    getActivate2Fa,
    activate2FA,
    patchAccount,
    subscribe,
    unsubscribe,
    getCurrentAccount,
    getSecurityQuestions,
    acceptTermsOfService,
    getAVVSigned,
    isAVVSigned,
    getAVVTemplate,
    getAVVForm,
    getVTTForm,
    getFragebogenForm,
    getDevices,
    deleteDevice,
    toggleUsageAgreement,
    getVTTTemplate,
    getWordTemplate,
    getNutzungsbedingungenDocx,
    getRollenkonzeptDocx,
    updateAccountPin,
    isPinValid,
    accountHasPin,
    resetPin,

    getAccountSets,

    patchAccessibility,

    patchPreferences,

    deleteMessage,
    getCurrentMatrixUser,
    getMatrixUsersInRoom,
    getMatrixUsersFullNameByRoomId,
    getMatrixName,
    postVoiceMessage,
    postFileMessage,
    deleteFileMessage,
    getFileMessage,
    deleteVoiceMessage,
    postCustomRoom,
    getMatrixRoomLockStatus,
    getMatrixRoomLockStatusById,
    setMatrixRoomLockStatus,
    deleteMatrixRoom,

    getDeactivatedFeature,
    toggleDeactivateFeature,

    postNameAvailable,

    getMyArchive,
    postSetFileInArchive,

    getGroups,
    getGroupsInfo,
    getGroupsInfoWithLeaders,
    getGroup,
    postGroup,
    patchGroup,
    deleteGroup,
    updateGroupTimeslots,

    getMaintainers,
    getMaintainer,
    getMeMaintainer,
    postMaintainer,
    patchMaintainer,
    maintainerFirstLogin,
    deleteMaintainer,
    confirmMaintainerPassword,
    postMaintainerAVV,
    postMaintainerAppendAVV,
    postMaintainerProfilePicture,
    getMaintainerProfilePicture,
    deleteMaintainerProfilePicture,
    deleteAVV,
    resetMaintainerPassword,

    getPupils,
    getPupil,
    getMePupil,
    postPupil,
    patchPupil,
    deletePupil,
    removeParentFromPupil,

    getParents,
    getParent,
    getMeParent,
    postParent,
    patchParent,
    deleteParent,
    getChildTeacher,

    getAllParentalLetters,
    getParentalLetterList,
    getParentalLetterById,
    createParentalLetter,
    setSeenBy,
    patchParentalLetter,
    deleteParentalLetter,
    postParentalLetterFile,
    getParentalLetterFile,
    deleteParentalLetterFile,

    createSickNote,
    getSickNotes,
    getSickNote,
    postSickNoteFile,
    getSickNoteFile,
    acceptSickNote,
    declineSickNote,
    toggleSickNote,
    uploadSickNoteSignature,
    getSickNoteSignature,
    getArchivedSickNotes,
    getArchivedSickNoteCount,
    archiveSickNote,
    restoreSickNote,
    autoArchiveSickNotes,

    createResponse,
    getSignature,
    deleteSignature,

    getSchoolSubjects,
    getSchoolSubject,
    postSchoolSubject,
    patchSchoolSubject,
    deleteSchoolSubject,

    getSchoolSubjectAppointments,
    getSchoolSubjectAppointmentsForWeek,
    getSchoolSubjectAppointmentsForRoomAndWeek,
    getSchoolSubjectAppointment,
    getMultipleSchoolSubjectAppointments,
    getSchoolSubjectAppointmentsParent,
    postSchoolSubjectAppointment,
    patchSchoolSubjectAppointment,
    postTeacherUploadFromExistingFile,
    patchAppointmentNoteSeen,
    deleteSchoolSubjectAppointment,
    deleteSchoolSubjectAppointmentRule,
    deleteSchoolSubjectAppointmentFromRule,
    getSchoolSubjectAppointmentRuleFromId,
    joinSchoolSubjectAppointmentBbbMeetingUrl,
    postTeacherUpload,
    postTeacherUploadEdit,
    postTeacherUploadMove,
    postTeacherUploadFromFileWidget,
    patchTeacherUploadPip,
    patchTeacherUploadFile,
    getTeacherUpload,
    getTeacherUploadThumbnail,
    deleteUpload,
    postPupilUpload,
    postPupilUploadFromFileWidget,
    postPupilUploadEdit,
    getPupilUpload,
    getPupilUploadThumbnail,
    postThumbnail,
    getThumbnail,
    patchCorrectionUploadSeen,
    getTeacherAppointmentAtTimeslotAndDate,
    getGroupsAppointmentsOnDay,

    joinTeachersRoomBbbMeetingUrl,

    getTeachers,
    getTeacher,
    getProfilePicture,
    deleteProfilePicture,
    getMeTeacher,
    postTeacher,
    postTeacherPicture,
    patchTeacher,
    patchTeacherProfile,
    deleteTeacher,
    getClassTeachers,
    getTeachersFromGroupByAppointment,

    getSubstitutions,
    postSubstitution,
    postSubstitutionNotification,
    postSubstitutionNotificationMaintainer,
    patchSubstitution,
    deleteSubstitution,

    getRooms,
    getRoomsForTimeslot,
    getRoomById,
    postRoom,
    patchRoom,
    deleteRoom,

    getTimeslots,
    getTimeslotsByGroups,
    getTimeslotLength,
    postTimeslot,
    patchTimeslot,
    deleteTimeslot,

    getBlackboard,
    getParentBlackboard,
    postBlackboard,
    postBlackboardFile,
    getBlackboardUpload,
    getBlackboardUploadThumbnail,
    deleteBlackboardUpload,
    patchBlackboard,
    patchBlackboardSeen,
    toggleFixEntry,
    hideBlackboardEntry,
    deleteBlackboard,

    getExternalBbbRooms,
    getExternalBbbRoomById,
    postExternalBbbRoom,
    patchExternalBbbRoom,
    deleteExternalBbbRoom,
    getExternalBbbRoomGuestData,
    getExternalBbbRoomJoinAsModeratorUrl,
    getExternalBbbRoomJoinAsGuestUrl,

    postUploadMessage,
    postUploadMessages,
    postUploadMessagesPairing,
    patchUploadMessages,
    getUploadMessages,
    getUploadMessage,
    getUploadMessageEntry,

    postSubjectFolderUpload,
    getSubjectFolderUploads,
    getSubjectFolderUpload,
    deleteSubjectFolderUpload,

    postVFSFolderUpload,
    getVFSFolderUploads,
    getVFSFolderUpload,
    deleteVFSFolder,
    deleteVFSFolderUpload,
    postVFSFolderUploadFromFileWidget,
    createVFSFolder,
    updateVFSFolder,
    updateVFSFolderUpload,
    searchVFS,
    getVFSSharing,
    updateVFSFolderUpload_Legacy,

    updateVFSSetActiveTask,
    postVFSSubmission,

    postVFSFolderUploadV2,
    getVFSFolderUploadsV2,
    getVFSAppFolderV2,
    getVFSFolderUploadV2,
    deleteVFSFolderV2,
    deleteVFSFolderUploadV2,
    createVFSFolderV2,
    updateVFSFolderV2,
    updateVFSFolderUploadV2,
    postExistingFileToVFS,
    postOnlineLibraryFileToVFS,
    searchVFSV2,
    getVFSSharingV2,
    updateVFSFolderUpload_LegacyV2,
    markFileSeen,
    markLearningPathSeen,
    getUsedDiskSpaceForAccount,

    patchImageRotateByDeg,

    updateVFSSetActiveTaskV2,
    postVFSSubmissionV2,
    postVFSSubmissionLibre,

    getServerTime,
    getVersionNumber,
    getBonusFunctions,
    getBackend,
    getPublicVapidKey,

    teacherClassroom,
    joinClassroom,
    changeRoomStatus,

    getMyConferenceTeacher,
    getMyConferenceParent,
    joinConferenceRoom,
    changeConferenceRoomStatus,

    translateToTargetLang,

    getSubtitle,

    getImageLibraryIndex,
    getImageLibraryImage,

    getDocumentRegistryDocuments,
    getDocumentRegistryDocument,
    postDocumentRegistryDocument,
    postDocumentRegistryDocumentFromBackend,
    patchDocumentRegistryDocument,
    postDocumentRegistryDocumentFile,
    getDocumentRegistryDocumentFile,
    deleteDocumentRegistryDocument,

    getDocumentRegistryFolders,
    getDocumentRegistryRootFolder,
    getDocumentRegistryFolder,
    postDocumentRegistryFolder,
    patchDocumentRegistryFolder,
    deleteDocumentRegistryFolder,

    getDocumentRegistryTags,
    getDocumentRegistryTag,
    postDocumentRegistryTag,
    deleteDocumentRegistryTag,

    postDocumentDraft,
    postDocumentDraftFile,
    patchDocumentDraft,
    getDocumentDraft,
    getDocumentDraftFile,
    getDocumentDraftThumbnail,
    deleteDocumentDraft,

    createPoll,
    getPoll,
    vote,
    createOption,

    getVideoTutorialUserProperties,
    patchVideoTutorialUserProperties,

    createNewSchoolYear,
    getWebSocketConnectionToken,
    getDay,
    postDay,
    getAllLateTotals,
    getPupilsLateTimes,

    getLateTimes,
    getSickNoteLateTime,
    getPupilLateTimes,
    postLateTime,
    updateLateTime,
    toggleExcuseLateTime,
    deleteLateTime,

    getSchool,
    createAndUpdateSchool,
    postSchoolLogo,
    getSchoolLogo,

    getChatRooms,
    postChatRoom,
    patchChatRoom,
    muteChatRoom,
    addDraftMessage,
    unmuteChatRoom,
    deleteChatRoom,
    leaveChatRoom,
    updateChatRoomLastSeenTimestamp,
    getChatRoomEvents,
    postChatRoomEvent,
    redactChatRoomEvent,
    getChatRoomEventFile,
    postChatRoomEventFile,

    getCensoredExpressions,
    postExpression,
    deleteExpression,
    updateExpression,

    getExchangeAll,
    getExchangeReservations,
    getExchangeAccounts,
    getExchangeFile,
    postExchange,
    postExchangeReservation,
    updateExchange,
    appendExchangeFile,
    deleteExchange,
    deleteExchangeFile,

    getIconUpload,
    getIconUploads,
    postIconUpload,
    deleteIconUpload,
    postIconUploadFile,
    checkForDelete,

    isValidPassword,

    getPinboards,
    getPinboard,
    getInvitationPinboard,
    postPinboard,
    postCard,
    patchPinboard,
    patchPinboardInfo,
    patchCard,
    deletePinboard,
    deleteCard,
    markPinboardAsSeen,
    hasUserSeenPinboard,
    postPinboardCardUpload,
    getPinboardCardUpload,
    patchPinboardCardUpload,
    deletePinboardCardUpload,
    postInvitation,
    deleteInvitation,
    postPinboardNotification,
    postPinboardLastEdited,

    getCalendarEvents,
    getCalendarEventById,
    getCalendarEventsForMonth,
    postNewCalenderEvent,
    postNewRepeatingEvent,
    patchCalendarEvent,
    patchRepeatingEvent,
    patchRepeatingEventData,
    deleteCalendarEvent,
    deleteRepeatingEvent,
    getUnseenEventsForUser,
    setCalenderEventSeen,
    postNewCalendarEventFile,
    postNewRepeatingEventFile,
    getCalendarEventFile,
    getCalendarEventFileInfo,
    deleteEventFile,
    getCalendarEventsFromSerie,
    getCalendarUpload,
    createCalendarReminder,
    patchCalendarReminder,
    deleteCalendarReminder,

    getToDoLists,
    getToDoList,
    getToDoTasks,
    getToDoTask,
    postToDoTask,
    postToDoList,
    updateToDoTask,
    updateToDoList,
    deleteToDoTask,
    deleteToDoList,

    setNotificationSeenByMe,

    getImageRights,
    getImageRight,
    postImageRight,
    toggleImageRight,
};

