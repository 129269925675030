<template>
    <v-dialog
        content-class="elevation-0"
        opacity="0.8"
        :value="dialogModel"
        style="overflow: hidden; z-index: 203"
        transition="dialog-bottom-transition"
        width="96vw"
        height="90vh"
        @keydown="handleKeydown"
    >
        <div>
            <div
                class="d-flex"
                style="flex-direction: column"
            >
                <div
                    style="position: sticky; top: 0; display:flex; justify-content: space-between; align-items: center; z-index: 10; height: 5vh"
                >
                    <span
                        style="padding-top: 3px; color: white; font-size: 27px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
                    >
                        {{ chatMode ? fileName : previewSelection.uploadedName }}
                    </span>
                    <div>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <img
                                    class="iconToWhite"
                                    style="height: 40px; cursor: pointer"
                                    :src="closeIcon"
                                    alt="Schließen"
                                    v-on="on"
                                    @click="closeFullscreenDialog"
                                >
                            </template>
                            <span>Schließen</span>
                        </v-tooltip>
                    </div>
                </div>
            </div>
            <div
                id="previewDialog"
                ref="previewDialog"
                v-click-outside="closeFullscreenDialog"
                style="display: flex; flex-direction: column; max-height: 86vh"
            >
                <v-card
                    class="d-flex flex-column"
                    style="background-color: var(--v-dunkelgrau-base); overflow: auto; flex-direction: row"
                >
                    <div
                        v-if="file && file.mimetype === 'application/pdf'" 
                        class="d-flex flex-row justify-space-around align-center"
                    >
                        <p style="font-size:20px; color: white">
                            PDF-Vorschau. Um die ganze Datei zu sehen, laden Sie diese bitte herunter.
                        </p>
                    </div>
                    <v-card-text
                        class="d-flex"
                        style="justify-content: center; height: 80vh"
                        :style="previewPDF ? 'display: flex; justify-content: space-around' : ''"
                    >
                        <v-btn
                            v-if="files.length > 1 && !fachInfo"
                            fab
                            small
                            icon
                            color="#eaeaea"
                            style="width: 35px"
                            class="align-self-center mx-1 mb-8"
                            @click="cyclePrev"
                        >
                            <img
                                style="position: relative; margin: auto; width: 30px"
                                alt="Pfeil nach links"
                                :src="leftIcon"
                                class="iconToWhite"
                            >
                        </v-btn>
                        <v-img
                            v-if="!previewURL"
                            ref="previewImage"
                            :src="fileIcon"
                            style="height: 50vh !important; margin-top: 15vh !important"
                            contain
                            :aspect-ratio="calculateAspectRatio"
                            :alt="previewSelection.name || previewSelection.uploadedName || fileName"
                            :lazy-src="fileIcon"
                        />
                        <v-img
                            v-else-if="!previewPDF"
                            ref="previewImage"
                            :src="previewURL"
                            contain
                            :aspect-ratio="calculateAspectRatio"
                            :alt="previewSelection.name || previewSelection.uploadedName || fileName"
                            :lazy-src="fileIcon"
                        />
                        <v-btn
                            v-if="files.length > 1 && !fachInfo"
                            fab
                            small
                            icon
                            color="#eaeaea"
                            style="width: 35px"
                            class="align-self-center mx-1 mb-8"
                            @click="cycleNext"
                        >
                            <img
                                style="position: relative; margin: auto; width: 30px"
                                alt="Pfeil nach links"
                                :src="rightIcon"
                                class="iconToWhite"
                            >
                        </v-btn>
                    </v-card-text>
                    <!-- For Preview in Stundenplan FachInfo -->
                    <div
                        v-if="fachInfo"
                        class="fachInfoButtonsContainer"
                    >
                        <FileListEditButton
                            v-if="!previewSelection.isMovedPupilUpload && buttonConditions.editButtonCondition(previewSelection, previewSelection.usage)"
                            :file="previewSelection"
                            :primary-color="primaryColor"
                            :person="person"
                            :account-role="accountRole"
                            :button-size="54"
                            @editInEditor="$emit(previewSelection.usage === 'toCorrectTeacher' ? 'correctInEditor' : 'editInEditor', previewSelection)"
                            @editInLibre="$emit(previewSelection.usage === 'toCorrectTeacher' ? 'correctInLibre' : 'editInLibre', previewSelection)"
                            @editDraft="$emit(previewSelection.usage === 'toCorrectTeacher' ? 'editCorrectionDraft' : 'editDraft', previewSelection)"
                        />
                        <FileListDoneButton
                            v-if="buttonConditions.doneButtonCondition(previewSelection, accountRole, previewSelection.usage)"
                            :file="previewSelection"
                            :primary-color="primaryColor"
                            :button-size="54"
                            @doneFile="$emit('doneFile', previewSelection)"
                        />
                        <!-- This info button displays user notes when given by the teacher -->
                        <FileListInfoButton
                            v-if="buttonConditions.infoButtonCondition('userNote', previewSelection.usage, previewSelection, accountRole, person)"
                            :usage="'userNote'"
                            :file="previewSelection"
                            :primary-color="primaryColor"
                            :person="person"
                            :button-size="54"
                        />
                        <!-- This info button displays that the file can't be edited within eklara -->
                        <FileListInfoButton
                            v-if="buttonConditions.infoButtonCondition('fileFormat', previewSelection.usage, previewSelection, accountRole, person)"
                            :usage="'fileFormat'"
                            :file="previewSelection"
                            :primary-color="primaryColor"
                            :person="person"
                            :button-size="54"
                        />
                        <!-- This info button displays that the file was given back to the teacher without an answer -->
                        <FileListInfoButton
                            v-if="buttonConditions.infoButtonCondition('noAnswer', previewSelection.usage, previewSelection, accountRole, person)"
                            :usage="'noAnswer'"
                            :file="previewSelection"
                            :primary-color="primaryColor"
                            :person="person"
                            :button-size="54"
                        />
                        <FileListEyeButton
                            v-if="previewSelection.isMovedPupilUpload || buttonConditions.fileListEyeButtonCondition(previewSelection, previewSelection.usage, accountRole)"
                            :file="previewSelection"
                            :primary-color="primaryColor"
                            :button-size="54"
                            @openInEditor="$emit('openInEditor', previewSelection)"
                        />
                        <FileListOptionsMenuButton
                            v-if="!previewSelection.isMovedPupilUpload && buttonConditions.fileListOptionsMenuCondition(previewSelection, previewSelection.usage)"
                            :default-options="buttonConditions.fileListOptionsMenuCondition(previewSelection)"
                            :file="previewSelection"
                            :account-role="accountRole"
                            :person="person"
                            :icon-white="true"
                            :button-size="54"
                            @downloadFile="$emit(previewSelection.usage === 'toCorrectTeacher' ? 'downloadCorrectionFile' : 'downloadFile', previewSelection)"
                            @deleteFile="$emit('deleteFile', previewSelection)"
                            @deleteDraft="$emit('deleteDraft', previewSelection)"
                            @uploadAnswer="$emit('uploadAnswer', previewSelection)"
                            @downloadMainVideo="$emit('downloadMainVideo', previewSelection)"
                            @downloadDGSVideo="$emit('downloadDGSVideo', previewSelection)"

                            @playVideo="$emit('playVideo', previewSelection)"
                            @playAudio="$emit('playAudio', previewSelection)"
                            @editInEditor="$emit('editInEditor', previewSelection)"
                            @editInLibre="$emit('editInLibre', previewSelection)"
                            @openInEditor="$emit('openInEditor', previewSelection)"
                            @videoInVideoClicked="$emit('videoInVideoClicked', previewSelection)"
                        />
                    </div>

                    <v-tooltip
                        v-else-if="enableEditing && !disableEditor && (isEditorFile || isLibreFile)"
                        bottom
                    >
                        <template v-slot:activator="{ on }">
                            <v-icon
                                class="stiftButton"
                                elevation="0"
                                large
                                role="button"
                                v-on="on"
                                @click="openEditor"
                            >
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Im Editor öffnen</span>
                    </v-tooltip>
                    <v-tooltip
                        v-else-if="saveToFolder"
                        bottom
                    >
                        <template v-slot:activator="{ on }">
                            <v-icon
                                class="stiftButton"
                                elevation="0"
                                large
                                role="button"
                                v-on="on"
                                @click="emitButtonPress('openSaveToFolderDialog')"
                            >
                                mdi-file-move-outline
                            </v-icon>
                        </template>
                        <span>Im Ordner speichern</span>
                    </v-tooltip>
                    <FilePreviewTileMenu
                        v-if="!fachInfo"
                        class="menuPosition"
                        style="z-index: 1; margin-top: 20px !important"
                        :enable-image-rotation="enableImageRotation"
                        :emit-button-press="emitButtonPress"
                        :show-submissions="showSubmissions"
                        :audio-compatible="audioCompatible"
                        :vfs-usage="vfsUsage"
                        :downloadable="downloadable"
                        :save-to-folder="saveToFolder"
                        @openMenu="(value) => { openMenu = value }"
                        @openSubmissions="emitButtonPress"
                        @playAudio="emitButtonPress"
                        @notifyCopyOrMoveFile="emitButtonPress"
                        @openSaveToFolderDialog="emitButtonPress"
                    />
                </v-card>  
                <!--                <div
                    style="position: sticky; bottom: 0px; display:flex; justify-content: space-between; align-items: center; background-color: white; height: 7vh"
                    width="100%"
                    class="pa-5"
                >
                    &lt;!&ndash; <v-btn
                    v-if="folderType === 'groupFolder' && validMimetype && isTask(this.previewSelection) && accountRole === 'teacher'"
                    class="textTransformNone"
                    :x-small="screenX < 450"
                    style="background-color: #F49F31"
                    dark
                    @click="openSubmissions(previewSelection)"
                >
                    <span v-if="screenX > 600">Abgaben öffnen</span>
                    <v-icon v-if="screenX <= 600">
                        mdi-folder-open
                    </v-icon>
                </v-btn> &ndash;&gt;
                    &lt;!&ndash; <v-btn
                    v-if="folderType === 'groupFolder' && validMimetype && isTaskActive(this.previewSelection) && accountRole === 'pupil'"
                    class="textTransformNone"
                    :x-small="screenX < 450"
                    style="background-color: #F49F31"
                    dark
                    @click="openEditor"
                >
                    <span v-if="screenX > 600">Aufgabe bearbeiten</span>
                    <v-icon v-if="screenX <= 600">
                        mdi-brush
                    </v-icon>
                </v-btn> &ndash;&gt;
                    &lt;!&ndash; <div v-else-if="folderType === 'teacherFolder' || !validMimetype">
                    <v-btn
                        class="textTransformNone"
                        :small="screenX < 450"
                        style="background-color: #F49F31"
                        dark
                        @click="clickDownloadFolderUpload(previewSelection)"
                    >
                        <span v-if="screenX > 800">Herunterladen</span>
                        <v-icon :style="screenX > 800 ? 'margin-left: 10px' : ''">
                            mdi-download
                        </v-icon>
                    </v-btn>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="textTransformNone"
                                :small="screenX < 450"
                                style="background-color: #F49F31; position: relative; margin-left: 10px"
                                dark
                                v-on="on"
                                @click="notifyMoveFile(previewSelection)"
                            >
                                <span v-if="screenX > 600">Privat kopieren</span>
                                <v-icon
                                    :style="screenX > 600 ? 'margin-left: 10px' : ''"
                                >
                                    mdi-file-move-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>
                            Eine Kopie der Datei wird in ihrem privaten Ordner hinterlegt.
                            <br>So können auch eklara-Dokumente im Editor geöffnet werden.
                        </span>
                    </v-tooltip>
                </div> &ndash;&gt;
                </div>-->
            </div>
        </div>
        <!-- Loading overlay -->
        <v-overlay
            absolute
            :value="showLoadingOverlay"
            style="width: 100% !important; z-index: 500 !important;"
        >
            <v-progress-circular
                v-if="showLoadingOverlay"
                indeterminate
                size="100"
            />
        </v-overlay>
    </v-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {
    allowedMimeTypesForLibre,
    allowedMimeTypesForEditor,
}from "@/constants/allowedMimeTypes"
import * as backend from "../../api/backend";
import closeIcon from '../../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import FilePreviewTileMenu from "./FilePreviewTileMenu";
import rightIcon from '@/assets/Icons/FaceliftIcons/vor_pfeil.svg';
import leftIcon from '@/assets/Icons/FaceliftIcons/zurueck_pfeil.svg';
import fileIcon from '@/assets/Icons/file-white.svg';
import FileListEditButton from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListEditButton";
import FileListInfoButton from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListInfoButton";
import FileListOptionsMenuButton
    from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListOptionsMenuButton";
import FileListDoneButton from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListDoneButton";
import FileListEyeButton from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListEyeButton";
import * as buttonConditions from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListConditions"

export default {
    name: "FilePreviewDialog",
    components: {
        FilePreviewTileMenu,
        FileListOptionsMenuButton,
        FileListInfoButton,
        FileListEditButton,
        FileListDoneButton,
        FileListEyeButton,
    },
    props: {
        files: { required: true },
        enableFullscreenPreview: { required: true, type: Boolean },
        previewSelection: { required: true, default: { name: 'Vorschau', uploadedName: 'Vorschau' }},
        previewInformation: { required: true },
        previewSelectionIndex: { required: false, default: 0},
        enableEditing: { required: false, type: Boolean, default: false },
        disableEditor: { required: false, type: Boolean, default: false },
        chatMode: { required: false, type: Boolean, default: false },
        fileName: { required: false, type: String, default: '' },
        isUserAuthor: { required: false, type: Boolean, default: false },
        showSubmissions: { required: false, type: Boolean, default: false },
        audioCompatible: { required: false, type: Boolean, default: false },
        vfsUsage: { required: false, type: Boolean, default: false },
        downloadable: { required: false, type: Boolean, default: true },
        saveToFolder: { required: false, type: Boolean, default: false },
        showSaveToFolder: { required: false, type: Boolean, default: false }, // "Im Ordner speichern"-Dialog
        // Stundenplan FachInfo Stuff
        fachInfo: { required: false, type: Boolean, default: false },
        person: { required: false, type: Object },
        accountRole: { required: false, type: String },
    },
    data: () => ({
        validMimetype: true,
        previewPDF: false,
        showLoadingOverlay: false,

        file: null,

        closeIcon,
        leftIcon,
        rightIcon,
        fileIcon,

        allowedMimeTypesForLibre,
        allowedMimeTypesForEditor,

        fileExtension: '',
        isLibreFile: false,
        isEditorFile: false,
        openMenu: false,
        previewURL: null,

        screenX: 1920,
        screenY: 1080,

        // Stundenplan FachInfo Stuff
        buttonConditions,
        primaryColor: '#706f6f',
    }),
    computed: {
        ...mapState('util', ['windowWidth', 'windowHeight']),
        dialogModel() {
            return this.enableFullscreenPreview;
        },
        enableImageRotation() {
            // also check for author
            return this.previewURL && !this.previewPDF && this.isUserAuthor;
        },
        calculateAspectRatio() {
            if (this.windowWidth < 600) {
                return '1';
            } else if (this.windowWidth < 900) {
                return '1.4';
            } else if (this.windowWidth < 1500) {
                return '2.0';
            } else {
                return '2.6';
            }
        }
    },
    watch: {
        previewSelection: {
          async handler() {
              await this.initFile();
              this.initThumbnail();
              this.initFileType();
          }  ,
        },
        enableFullscreenPreview: {
            handler() {
                if (this.enableFullscreenPreview === true) {
                    this.initThumbnail();
                }
            },
        },
        previewSelectionIndex: {
            handler() {
                if (this.enableFullscreenPreview === true) {
                    this.initThumbnail();
                }
            },immediate: true,
        },
    },
    async mounted() {
        this.evalScreenSize();
        await this.initFile();
        await this.initThumbnail();
        await this.initFileType();
    },
    methods: {
        ...mapActions('subjects', ['getThumbnail']),
        ...mapActions('files', ['rotateGivenImageByGivenDeg']),
        ...mapActions('mimeTypes', ['getFileExtension']),
        async initFile() {
            const res = await backend.getFileInformation(
                this.previewInformation.file
                    ? this.previewInformation.file._id
                        ? this.previewInformation.file._id
                        : this.previewInformation.file
                    : this.previewInformation._id,
                this.previewInformation.fileAccessToken
                    ? this.previewInformation.fileAccessToken
                    : this.previewInformation.accessToken
            );
            this.file = await res.json();
            this.isLibreFile = false;
            this.isEditorFile = false;
            if (this.file && this.allowedMimeTypesForEditor.includes(this.file.mimetype)) {
                this.isEditorFile = true;
            } else if (this.file && this.allowedMimeTypesForLibre.includes(this.file.mimetype)) {
                this.isLibreFile = true;
            }
        },
        async initFileType() {
          if (!this.isLibreFile) {
            return;
          }
          this.fileExtension = await this.getFileExtension(this.file.mimetype);
        },
        async initThumbnail() {
            // The associated attribute isn't used at the moment, so I commented this method out temporarily
            // this.validateMimetype();
            this.previewURL = this.previewSelection.thumbnail ? this.previewSelection.thumbnail : this.previewSelection;
        },

        async rotateImage() {
            if (this.enableImageRotation) {
                this.showLoadingOverlay = true;
                const fileId = this.chatMode ? this.previewSelection : this.previewSelection._id;
                const angle = 90;
                // write backend route to rotate image (need to do this in store)
                // (Todo for later: backend automatically creates thumbnail)
                await this.rotateGivenImageByGivenDeg({ fileId: this.previewInformation.file, angle });
                // rotate image style (90 deg at a time)
                let currentRotation = this.$refs.previewImage.$el.style.transform;
                if (!currentRotation.length) {
                    currentRotation = "rotate(90deg)";
                } else {
                    // get only number
                    const currentAngle = Number.parseInt(currentRotation.replace(/[()a-z]/gm, ''));
                    const finalAngle = (currentAngle + angle) >= 360 ? 0 : (currentAngle + angle);
                    currentRotation = `rotate(${finalAngle}deg)`
                }
                this.$refs.previewImage.$el.style.transform = currentRotation;
                // todo for later: debounce update the file
                this.$emit('updateFile');
                this.showLoadingOverlay = false;
            }
        },

        validateMimetype() {
            if (this.chatMode) {
                if (allowedMimeTypesForEditor.includes(this.fileName.split('.').pop().toLowerCase())) {
                    this.validMimetype = true;
                } else if (allowedMimeTypesForLibre.includes(this.fileName.split('.').pop().toLowerCase())) {
                    this.validMimetype = true;
                } else {
                    this.validMimeType = false;
                }
            } else {
                const fileName = this.previewSelection.uploadedName ? this.previewSelection.uploadedName : this.previewSelection.title;
                if (allowedMimeTypesForEditor.includes(fileName.split('.').pop().toLowerCase())) {
                    this.validMimetype = true;
                } else if (allowedMimeTypesForLibre.includes(fileName.split('.').pop().toLowerCase())) {
                    this.validMimetype = true;
                } else {
                    this.validMimetype = false;
                }
            }
        },

        evalScreenSize() {
            let win = window;
            let doc = document;
            let docElem = doc.documentElement;
            let body = doc.getElementsByTagName('body')[0];
            this.screenX = win.innerWidth || docElem.clientWidth || body.clientWidth;
            this.screenY = win.innerHeight || docElem.clientHeight || body.clientHeight;
            // if(this.screenX < 800 || this.screenY < 800) {
            //     this.smallUI = true;
            // }
            // alert(this.screenX + ' × ' + this.screenY);
        },

        handleKeydown(event) {
                        switch (event.code) {
                case 'ArrowLeft':
                    this.cyclePrev();
                    break;
                case 'KeyA':
                    this.cyclePrev();
                    break;
                case 'ArrowRight':
                    this.cycleNext();
                    break;
                case 'KeyD':
                    this.cycleNext();
                    break;
                case 'Enter':
                    if (this.validMimetype) {
                        //this.openEditor();
                    }
                    break;
                case 'Space':
                    if (this.validMimetype) {
                        //this.openEditor();
                    }
                    break;
                default:
                    break;
            }
        },
        closeFullscreenDialog() {
            if (!this.showSaveToFolder) {
                this.$emit('close');
            }
        },
        cyclePrev() {
            this.$emit('cyclePrev');
        },
        cycleNext() {
            this.$emit('cycleNext');
        },
        openEditor() {
          if (this.isEditorFile) {
            this.$emit('openEditor');
          } else if (this.isLibreFile) {
            this.$emit('openLibre');
          }
        },
        emitButtonPress(emit) {
            switch (emit) {
                case "rotate":
                    this.rotateImage();
                    break;
                case "download":
                    this.$emit('download');
                    break;
                case "openSubmissions":
                    this.$emit('openSubmissions');
                    break;
                case "playAudio":
                    this.$emit('playAudio');
                    break;
                case "notifyCopyOrMoveFile":
                    this.$emit('notifyCopyOrMoveFile');
                    break;
                case "openSaveToFolderDialog":
                    this.$emit('openSaveToFolderDialog');
                    break;
                default:
                    console.error('No action found.')
            }
        }
    }
}
</script>

<style scoped lang="scss">
.fachInfoButtonsContainer {
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 1%;
    right: 1%;
}
.stiftButton {
    color: white;
    border-radius: 6px;
    font-size: 40px !important;
    background: var(--v-chatColor-base);
    position: fixed;
    z-index: 1;
    height: 50px;
    width: 50px;
    margin-top: 24px;
    right: 10vw;
}

.textTransformNone {
    text-transform: none;
}

.sidebarButton {
    z-index: 3;
    width: 49px !important;
    height: 49px !important;
    border-radius: 8px;
    pointer-events: all;
    margin-left: 2vw;
    margin-top: 3vh;
    position: fixed;

    p {
        line-height: 0;
        font-size: 55px;
        margin-bottom: 0;
        font-weight: 300;
        color: #333;
    }
}

@media only screen and (max-width: 900px) {
    .stiftButton {
        right: 13vw !important;
    }
}

@media only screen and (max-width: 600px) {
    .stiftButton {
        right: 18vw !important;
    }
}
</style>
