import pdfFile from "../assets/PDF/Schueler-QR-Vorlage.pdf";
import pdfPupilFile from "../assets/PDF/QR-Vorlage-Schueler.pdf";
import pdfTeacherFile from "../assets/PDF/QR-Vorlage-Lehrer.pdf";
import pdfParentFile from "../assets/PDF/Eltern-QR-Vorlage.pdf";
import pdfParentFilev2 from "../assets/PDF/QR-Vorlage-Eltern.pdf";
import kuhRFile from "../assets/PDF/Faltkuh.pdf";
import {PDFDocument, StandardFonts} from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

import QrCode from "./qrCode";
import {compress} from "shrink-string";

import roboto from "../assets/Fonts/Roboto-Regular.ttf";
import robotoBold from "../assets/Fonts/Roboto-Bold.ttf";

const qrCodeString = async ({ name, pw }) => {
    const compressedName = await compress(name);
    const compressedPW = await compress(pw);

    return JSON.stringify({
        name: compressedName,
        pw: compressedPW,
    });
};

const getKuhRLoginPdf = async ({ accountName, name, lastName, qrPassword }) => {
    const existingPdfBytes = await fetch(kuhRFile).then((res) =>
        res.arrayBuffer()
    );

    const qrCodeData = await qrCodeString({ name: accountName, pw: qrPassword });
    const qrCode = QrCode.encodeAsQrCodeImage({ value: qrCodeData });

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    pdfDoc.registerFontkit(fontkit);

    const pngImage = await pdfDoc.embedPng(qrCode);
    // Embed the Helvetica font
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const robotoBytes = await fetch(roboto).then((res) =>
        res.arrayBuffer()
    );
    const robotoFont = await pdfDoc.embedFont(robotoBytes);

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    firstPage.drawImage(pngImage, {
        x: 338,
        y: 522,
        width: 82,
        height: 82,
    });

    firstPage.drawImage(pngImage, {
        x: 338,
        y: 335,
        width: 82,
        height: 82,
    });

    firstPage.drawText(name + " " + lastName.slice(0, 1) + ".", {
        x: 180,
        y: 733,
        size: 16,
        font: robotoFont,
    });

    firstPage.drawText(window.location.hostname, {
        x: 180,
        y: 695,
        size: 12,
        font: robotoFont,
    });

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
};

const getParentLoginPdf = async ({ accountName, name, password, qrPassword}) => {
    const existingPdfBytes = await fetch(pdfParentFile).then((res) =>
        res.arrayBuffer()
    );

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    pdfDoc.registerFontkit(fontkit);

    const robotoBytes = await fetch(roboto).then((res) =>
        res.arrayBuffer()
    );
    const robotoBoldBytes = await fetch(robotoBold).then((res) =>
        res.arrayBuffer()
    );

    const robotoFont = await pdfDoc.embedFont(robotoBytes);
    const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const form = pdfDoc.getForm();
    const button = form.createButton("some.button.field");

    // Get the width and height of the first page
    const {width, height} = firstPage.getSize();


    const qrCodeData = await qrCodeString({ name: accountName, pw: qrPassword });
    const qrCodeParent = QrCode.encodeAsQrCodeImage({ value: qrCodeData });
    const pngImage = await pdfDoc.embedPng(qrCodeParent);

    firstPage.drawText(accountName, {
        x: 100.7,
        y: 403,
        size: 11,
        font: robotoBoldFont,
    });

    firstPage.drawText(password, {
        x: 100.7,
        y: 358,
        size: 11,
        font: robotoBoldFont,
    });

    firstPage.drawText(window.location.hostname, {
        x: 100.7,
        y: 314,
        size: 11,
        font: robotoBoldFont,
    });

    //QR1
    firstPage.drawImage(pngImage, {
        x: 47,
        y: 56,
        width: 70,
        height: 70,
    });

    firstPage.drawText("Nutzername", {
        x: 200,
        y: 125,
        size: 10,
        font: robotoFont,
    });

    firstPage.drawText(accountName, {
        x: 200,
        y: 110,
        size: 10,
        font: robotoBoldFont,
    });

    firstPage.drawText("Passwort", {
        x: 200,
        y: 90,
        size: 10,
        font: robotoFont,
    });

    firstPage.drawText(password, {
        x: 200,
        y: 75,
        size: 10,
        font: robotoBoldFont,
    });

    firstPage.drawText("Internetseite", {
        x: 42,
        y: 33,
        size: 10,
        font: robotoFont,
    });

    firstPage.drawText(window.location.hostname, {
        x: 102,
        y: 33,
        size: 10,
        font: robotoBoldFont,
    });

    //QR2
    firstPage.drawImage(pngImage, {
        x: 330,
        y: 56,
        width: 70,
        height: 70,
    });

    firstPage.drawText("Nutzername", {
        x: 482,
        y: 125,
        size: 10,
        font: robotoFont,
    });

    firstPage.drawText(accountName, {
        x: 482,
        y: 110,
        size: 10,
        font: robotoBoldFont,
    });

    firstPage.drawText("Passwort", {
        x: 482,
        y: 90,
        size: 10,
        font: robotoFont,
    });

    firstPage.drawText(password, {
        x: 482,
        y: 75,
        size: 10,
        font: robotoBoldFont,
    });

    firstPage.drawText("Internetseite", {
        x: 325,
        y: 33,
        size: 10,
        font: robotoFont,
    });

    firstPage.drawText(window.location.hostname, {
        x: 385,
        y: 33,
        size: 10,
        font: robotoBoldFont,
    });

    // Serialize the PDFDocument to bytes (a Uint8Array)
    return await pdfDoc.save();
};

const getPupilLoginPdf = async ({ accountName, name, password, qrPassword}) => {
    const existingPdfBytes = await fetch(pdfFile).then((res) =>
        res.arrayBuffer()
    );

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    pdfDoc.registerFontkit(fontkit);

    const robotoBytes = await fetch(roboto).then((res) =>
        res.arrayBuffer()
    );
    const robotoBoldBytes = await fetch(robotoBold).then((res) =>
        res.arrayBuffer()
    );

    const robotoFont = await pdfDoc.embedFont(robotoBytes);
    const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const form = pdfDoc.getForm();
    const button = form.createButton("some.button.field");

    const qrCodeData = await qrCodeString({ name: accountName, pw: qrPassword })
    const qrCode = QrCode.encodeAsQrCodeImage({ value: qrCodeData });
    const pngImage = await pdfDoc.embedPng(qrCode);

    // Get the width and height of the first page
    const {width, height} = firstPage.getSize();

    // Draw a string of text diagonally across the first page
    firstPage.drawText(accountName + ",", {
        x: 168,
        y: 536,
        size: 11,
        font: robotoFont,
    });

    firstPage.drawText(accountName, {
        x: 100.7,
        y: 412,
        size: 11,
        font: robotoBoldFont,
    });

    firstPage.drawText(password, {
        x: 100.7,
        y: 368,
        size: 11,
        font: robotoBoldFont,
    });

    firstPage.drawText(window.location.hostname, {
        x: 100.7,
        y: 325,
        size: 11,
        font: robotoBoldFont,
    });

    //QR1
    firstPage.drawImage(pngImage, {
        x: 47,
        y: 56,
        width: 70,
        height: 70,
    });

    firstPage.drawText("Nutzername", {
        x: 200,
        y: 125,
        size: 10,
        font: robotoFont,
    });

    firstPage.drawText(accountName, {
        x: 200,
        y: 110,
        size: 10,
        font: robotoBoldFont,
    });

    firstPage.drawText("Passwort", {
        x: 200,
        y: 90,
        size: 10,
        font: robotoFont,
    });

    firstPage.drawText(password, {
        x: 200,
        y: 75,
        size: 10,
        font: robotoBoldFont,
    });

    firstPage.drawText("Internetseite", {
        x: 42,
        y: 33,
        size: 10,
        font: robotoFont,
    });

    firstPage.drawText(window.location.hostname, {
        x: 102,
        y: 33,
        size: 10,
        font: robotoBoldFont,
    });

    //QR2
    firstPage.drawImage(pngImage, {
        x: 330,
        y: 56,
        width: 70,
        height: 70,
    });

    firstPage.drawText("Nutzername", {
        x: 482,
        y: 125,
        size: 10,
        font: robotoFont,
    });

    firstPage.drawText(accountName, {
        x: 482,
        y: 110,
        size: 10,
        font: robotoBoldFont,
    });

    firstPage.drawText("Passwort", {
        x: 482,
        y: 90,
        size: 10,
        font: robotoFont,
    });

    firstPage.drawText(password, {
        x: 482,
        y: 75,
        size: 10,
        font: robotoBoldFont,
    });

    firstPage.drawText("Internetseite", {
        x: 325,
        y: 33,
        size: 10,
        font: robotoFont,
    });

    firstPage.drawText(window.location.hostname, {
        x: 385,
        y: 33,
        size: 10,
        font: robotoBoldFont,
    });

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();

    return pdfBytes;
};

const getNormalLoginPdf = async({ firstName, lastName, accountName, password, qrPassword, hidePassword, groupName, role }) => {
    const pdfFile = role === 'pupil' ? pdfPupilFile : pdfTeacherFile;
    const existingPdfBytes = await fetch(pdfFile)
        .then((res) => res.arrayBuffer());

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    pdfDoc.registerFontkit(fontkit);
    const robotoBytes = await fetch(roboto).then((res) =>
        res.arrayBuffer()
    );
    const robotoBoldBytes = await fetch(robotoBold).then((res) =>
        res.arrayBuffer()
    );
    const robotoFont = await pdfDoc.embedFont(robotoBytes);
    const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);

    // Load qrCodes
    const urlText = `https://${window.location.hostname}`;
    const urlQrCode = QrCode.encodeAsQrCodeImage({ value: urlText });
    const urlImage = await pdfDoc.embedPng(urlQrCode);

    const qrCodeData = await qrCodeString({ name: accountName, pw: qrPassword })
    const qrCode = QrCode.encodeAsQrCodeImage({ value: qrCodeData });
    const pngImage = await pdfDoc.embedPng(qrCode);

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // Start filling
    // Header
    firstPage.drawText(`${firstName} ${lastName}`, {
        x: 100.7,
        y: 690,
        size: 11,
        font: robotoFont,
    });
    if (groupName) {
        firstPage.drawText(`${groupName}`, {
            x: 100.7,
            y: 670,
            size: 11,
            font: robotoFont,
        });
    }
    firstPage.drawText(`${role === 'pupil' ? 'Schüler' : 'Lehrer'}`, {
        x: 100.7,
        y: groupName ? 650 : 670,
        size: 11,
        font: robotoFont,
    });

    // Greeting
    firstPage.drawText(`${firstName},`, {
        x: 146,
        y: 534,
        size: 11,
        font: robotoFont,
    });

    // Username and password
    firstPage.drawText(accountName, {
        x: 101,
        y: 405,
        size: 11,
        font: robotoBoldFont,
    });
    firstPage.drawText(hidePassword ? 'Das alte Passwort ist weiterhin gültig. \nDieser Ausdruck dient nur zur Vergabe eines neuen QR-Codes.' : password, {
        x: 101,
        y: 345,
        size: 11,
        font: robotoBoldFont,
    });

    // URL and URL QRCode of website
    firstPage.drawText(window.location.hostname, {
        x: 141,
        y: 282,
        size: 14,
        font: robotoBoldFont,
    });

    firstPage.drawImage(urlImage, {
        x: 101.5,
        y: 272,
        width: 30,
        height: 30,
    });

    for (let i = 0; i < 2; i++) {
        //big qr energy
        firstPage.drawImage(pngImage, {
            x: 176 + i*282,
            y: 85,
            width: 82,
            height: 82,
        });

        firstPage.drawText(accountName, {
            x: 48 + i*282,
            y: 150,
            size: 10,
            font: robotoBoldFont,
        });

        firstPage.drawText(hidePassword ? '' : password, {
            x: 48 + i*282,
            y: 118,
            size: 10,
            font: robotoBoldFont,
        });

        // smol URL QRCode of website on card
        firstPage.drawImage(urlImage, {
            x: 48 + i*282,
            y: 78,
            width: 19,
            height: 19,
        });

        firstPage.drawText(window.location.hostname, {
            x: 69 + i*282,
            y: 78.5,
            size: 10,
            font: robotoBoldFont,
        });
    }

    // Serialize the PDFDocument to bytes (a Uint8Array)
    return await pdfDoc.save();
};

const getParentLoginPdfv2 = async({ fullChildName, accountName, password, qrPassword, hidePassword }) => {

    const existingPdfBytes = await fetch(pdfParentFilev2)
        .then((res) => res.arrayBuffer());

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    pdfDoc.registerFontkit(fontkit);
    const robotoBytes = await fetch(roboto).then((res) =>
        res.arrayBuffer()
    );
    const robotoBoldBytes = await fetch(robotoBold).then((res) =>
        res.arrayBuffer()
    );
    const robotoFont = await pdfDoc.embedFont(robotoBytes);
    const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);

    // Load qrCodes
    const urlText = `https://${window.location.hostname}`;
    const urlQrCode = QrCode.encodeAsQrCodeImage({ value: urlText });
    const urlImage = await pdfDoc.embedPng(urlQrCode);

    const qrCodeData = await qrCodeString({ name: accountName, pw: qrPassword })
    const qrCode = QrCode.encodeAsQrCodeImage({ value: qrCodeData });
    const pngImage = await pdfDoc.embedPng(qrCode);

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // Start filling
    // Header
    firstPage.drawText(`Eltern von ${fullChildName}`, {
        x: 100.7,
        y: 690,
        size: 11,
        font: robotoFont,
    });

    // Username and password
    firstPage.drawText(accountName, {
        x: 101,
        y: 390,
        size: 11,
        font: robotoBoldFont,
    });
    firstPage.drawText(hidePassword ? 'Das alte Passwort ist weiterhin gültig. \nDieser Ausdruck dient nur zur Vergabe eines neuen QR-Codes.' : password, {
        x: 101,
        y: 330,
        size: 11,
        font: robotoBoldFont,
    });

    // URL and URL QRCode of website
    firstPage.drawText(window.location.hostname, {
        x: 141,
        y: 267,
        size: 14,
        font: robotoBoldFont,
    });

    firstPage.drawImage(urlImage, {
        x: 101.5,
        y: 257,
        width: 30,
        height: 30,
    });

    for (let i = 0; i < 2; i++) {
        //big qr energy
        firstPage.drawImage(pngImage, {
            x: 176 + i*282,
            y: 85,
            width: 82,
            height: 82,
        });

        firstPage.drawText(accountName, {
            x: 48 + i*282,
            y: 150,
            size: 10,
            font: robotoBoldFont,
        });

        firstPage.drawText(password, {
            x: 48 + i*282,
            y: 118,
            size: 10,
            font: robotoBoldFont,
        });

        // smol URL QRCode of website on card
        firstPage.drawImage(urlImage, {
            x: 48 + i*282,
            y: 78,
            width: 19,
            height: 19,
        });

        firstPage.drawText(window.location.hostname, {
            x: 69 + i*282,
            y: 78.5,
            size: 10,
            font: robotoBoldFont,
        });
    }

    // Serialize the PDFDocument to bytes (a Uint8Array)
    return await pdfDoc.save();
};

export default {
    getKuhRLoginPdf,
    getParentLoginPdf,
    getParentLoginPdfv2,
    getPupilLoginPdf,
    getNormalLoginPdf,
}
