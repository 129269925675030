<template>
    <!-- teacher -->
    <v-btn
        id="schlossBtn"
        icon
        small
        @click.stop.prevent="toggleChatRoomOpen"
    >
        <img
            v-if="isOpen"
            :src="schlossOffenIcon"
            class="iconToWhite"
            alt="Chat schließen"
            style="width: 20px; height: 20px; opacity: 0.4"
        >
        <img
            v-else
            :src="schlossZuIcon"
            class="iconToRed"
            alt="Chat öffnen"
            style="width: 20px; height: 20px; opacity: 0.4"
        >
    </v-btn>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import * as backend from "../../api/backend.js";

import schlossOffenIcon from "../../assets/Icons/FaceliftIcons/schloss_offen.svg";
import schlossZuIcon from "../../assets/Icons/FaceliftIcons/schloss_zu.svg";

export default {
    name: "ToggleChatRoomOpenButton",
    props: {
        chatRoom: { required: true, type: Object },
    },
    data: () => ({
        schlossOffenIcon,
        schlossZuIcon,
    }),
    computed: {
        ...mapGetters("auth", ["accountRole"]),
        ...mapState("translation", ["targetLang"]),
        ...mapState("util", ["windowWidth"]),
        isOpen() {
            return this.chatRoom.isOpen;
        },
    },

    methods: {
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapActions("chat", ["patchChatRoom"]),
        async toggleChatRoomOpen() {
            const res = await this.patchChatRoom({ chatRoomId: this.chatRoom._id, body: {
                    isOpen: !this.chatRoom.isOpen,
                }});
            if (res.status === 200 && !this.chatRoom.isOpen) {
                this.showSnackbar({
                    message: "Der Chat ist wieder geöffnet.",
                });
            } else if (res.status === 200 && this.chatRoom.isOpen) {
                this.showSnackbar({
                    message:
                        "Der Chat ist ist jetzt geschlossen. Es können keine weiteren Nachrichten gesendet werden.",
                });
            } else {
                this.showSnackbar({
                    message: "Unerwarteter Fehler beim Schließen/Öffnen des Chats. Bitte wenden Sie sich an den Support.",
                    color: "error",
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.v-btn {
    border-radius: 8px;
}



#schlossBtn {
    img {
        width: 24px;
        height: 24px;
    }
}

</style>
