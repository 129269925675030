import translation from '../store/modules/translation'
import store from "@/store";

const isSupported = () => {
    return 'speechSynthesis' in window;
};

const textToSpeech = async (text, language = 'de', onStartCallback = null, onEndCallback = null) => {
    if (isSupported && text) {
        if (window.speechSynthesis.speaking) {
            window.speechSynthesis.cancel();
            if (onEndCallback) {
                onEndCallback();
            }
        } else {
            const msg = new SpeechSynthesisUtterance();
            msg.text = text;

            if (language !== 'de') {
                // Not the best call in the world, but it works
                msg.text = await translation.actions.translateToTargetLang(
                    {
                        commit: null,
                        dispatch: null
                    },
                    {
                        targetLang: language,
                        textToTranslate: text
                });
            }

            msg.onstart = () => {
                if (onStartCallback)
                    onStartCallback(msg.text);
            };
            msg.onend = () => {
                if (onEndCallback)
                    onEndCallback();
            };
            if(navigator.userAgent.includes('Edg/')){
                msg.lang = language;
                window.speechSynthesis.speak(msg);
            }else{
            // Watch out: getVoices() is returning an empty array when called to early because it loads asynchronous to
            // the page itself. When you want to call textToSpeech method on load of the page then call it from the
            // window.speechSynthesis.onvoiceschanged event that is fired when the voices are loaded
            const supportedVoices = window.speechSynthesis.getVoices();
            if(supportedVoices.some((el) => {
                return el.lang.slice(0, 2) === language;
            })) {
                msg.lang = language;
                window.speechSynthesis.speak(msg);
            } 
            //use russian package for ukrainian lang
            else if(language === 'uk'){
                msg.lang = 'ru';
                window.speechSynthesis.speak(msg);
            }
            //use english package for other languages that have latin alphabet
            else if(/*language === 'ku' ||*/ language === 'ro' ||language ===  'tr'){
                msg.lang = 'en'
                window.speechSynthesis.speak(msg);
            }
            else {
                let snackbarText = "Dein Browser besitzt kein Sprachpaket für die ausgewählte Sprache. " +
                    "Bitte lade das passende Sprachpaket herunter oder verwende den Microsoft Edge Browser."
                if (language !== 'de') {
                    // Not the best call in the world, but it works
                    snackbarText = await translation.actions.translateToTargetLang(
                        {
                            commit: null,
                            dispatch: null
                        },
                        {
                            targetLang: language,
                            textToTranslate: snackbarText,
                        });
                }
                store.commit('snackbar/showSnackbar', {
                    color: "error",
                    message: snackbarText,
                    timeout: 8000,
                }, { root: true });
                return false;
            }
        }
            return msg.text;
        }
        return true;
    }
    return false;
};

export {
    textToSpeech,
    isSupported,
}
