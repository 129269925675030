<template>
    <div class="letters">
        <!-- <v-row
            cols="12"
            class="dialogRow"
        >
            <v-col
                cols="10"
                class="sectionHeaderCol d-flex align-center"
            >
                <img
                    :src="toDoIcon"
                    class="icon"
                    alt=""
                >
                <p class="pl-2 mb-0 font-weight-bold">
                    Aktueller Elternbrief
                </p>
            </v-col>

            <v-col
                cols="2"
                class="sectionHeaderCol mr-0"
            >
                <v-btn
                    height="24"
                    width="24"
                    size="24"
                    x-small
                    color="rgba(248, 248, 248, 0.4)"
                    @click="collapseLetterLocal = !collapseLetterLocal"
                >
                    <img
                        :src="leftIcon"
                        :style="collapseLetterLocal ? 'transform: rotate(270deg)' : 'transform: rotate(90deg)'"
                        :alt="collapseLetterLocal ? 'Brief einklappen' : 'Brief aufklappen'"
                    >
                </v-btn>
            </v-col>
        </v-row> -->

        <!-- letter and content -->
        <!-- <vue-custom-scrollbar
            class="scroll-area-currentLetter"
            :settings="{suppressScrollY: false, suppressScrollX: true, wheelPropagation: true}"
        > -->
        <div class="activeLetter pointer px-2">
            <!-- title and creation date -->
            <div
                class="d-flex flex-column whiteBg "
                style="border-radius: 0 0 8px 8px;"
            >
                <!-- Disable v-html xss checking entry.content is provided only by developers -->
                <!-- eslint-disable vue/no-v-html -->
                <p
                    class="mb-0"
                    style="padding-bottom: 5px; padding-top: 15px; color: black"
                    v-html="sanitizeHtml(urlify(currentLetter.content))"
                />
                <!-- eslint-enable vue/no-v-html -->

                <!-- poll -->
                <div v-if="currentLetter.poll">
                    <hr class="my-1">
                    <poll :poll-id="currentLetter.poll" />
                </div>

                <div v-if="currentLetter.files.length > 0">
                    <hr>
                    <h1 class="greyText font-weight-bold">
                        Anhang
                    </h1>
                    <v-row
                        class="d-flex flex-row align-center mt-2"
                    >
                        <div
                            v-for="file in filePreviews"
                            :key="file.thumbnail"
                            class="previewContainer mb-2"
                        >
                            <div
                                class="previewHeader ellipsis"
                            >
                                <p
                                    class="ellipsis"
                                    style="width:90%; color:white"
                                >
                                    {{ file.uploadedName }}
                                </p>

                                <v-btn
                                    class=""
                                    x-small
                                    style="background-color: transparent"
                                    elevation="0"
                                    @click="downloadAttachment(currentLetter._id, file._id)"
                                >
                                    <img
                                        :src="downloadIcon"
                                        class="icon iconToWhite"
                                        alt="Datei herunterladen"
                                    >
                                </v-btn>
                            </div>
                            <div
                                v-if="file.mimetype==='application/pdf'"
                                class="thumbnailDisplayNormal"
                                @click="handlePreview(file.thumbnail, 'pdf')"
                            >
                                <object
                                    :data="file.thumbnail + '#toolbar=0&navpanes=0&scrollbar=0&view=fitH'"
                                    type="application/pdf"
                                    class="iOS_ResponsiveHack"
                                    style="cursor: pointer; pointer-events: none"
                                />
                            </div>
                            <div
                                v-else-if="file['mimetype'].split('/')[0] === 'image'"
                                class="displayWholeThumbnail"
                                style="overflow:hidden"
                                @click="handlePreview(file.thumbnail, 'image')"
                            >
                                <v-img
                                    :src="file.thumbnail"
                                    align="center"
                                >
                                    <div class="fill-height bottom-gradient" />
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height fill-width ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <!-- <v-progress-circular
                                                    indeterminate
                                                    color="grey"
                                                /> -->
                                        </v-row>
                                    </template>
                                </v-img>
                            </div>
                            <div
                                v-else-if="(file['mimetype'].split('/')[0] === 'video')"
                                class="displayWholeThumbnail"
                                style="overflow: hidden"
                                @click="handlePreview(file.thumbnail, 'video')"
                            >
                                <v-img
                                    :src="videoIcon"
                                    class="mb-2 iconToDunkelgrau"
                                    alt="Video"
                                    contain
                                />
                            </div>
                            <div
                                v-else-if="(file['mimetype'].split('/')[0] === 'audio')"
                                class="displayWholeThumbnail"
                                style="overflow: hidden"
                                @click="handlePreview(file.thumbnail, 'audio')"
                            >
                                <v-img
                                    :src="audioIcon"
                                    class="mb-2 iconToDunkelgrau"
                                    alt="Audio"
                                    contain
                                />
                            </div>
                            <div
                                v-else
                                class="displayWholeThumbnail"
                                style="overflow: hidden"
                            >
                                <v-icon
                                    style="font-size: 70px"
                                >
                                    mdi-file
                                </v-icon>
                            </div>
                        </div>
                    </v-row>
                </div>
                <div
                    v-if="!isIncoming && currentLetter.requiresSignature"
                    class="d-flex justify-space-between"
                    style="align-items:center"
                >
                    <p class=" mb-0 font-weight-bold">
                        Rückmeldungen
                    </p>
                    <div
                        class="d-flex justify-space-between"
                    >
                        <v-btn
                            icon
                            large
                            color="black"
                            @click="clickCollapse"
                        >
                            <img
                                :src="leftIcon"
                                class="icon20"
                                :style="collapseLetterLocal ? 'transform: rotate(90deg)' : 'transform: rotate(270deg)'"
                                :alt="collapseLetterLocal ? 'Rückmeldungen aufklappen' : 'Rückmeldungen einklappen '"
                            >
                        </v-btn>
                    </div>
                </div>
                <div
                    v-if="collapseLetterLocal"
                    class="d-flex flex-column"
                >
                    <div
                        v-if="currentLetter.receivingAccounts.length >
                            (currentLetter.requiresSignature ? currentLetter.parentalLetterResponses.length : currentLetter.seenBy.length)"
                        class=" px-2 mx-1"
                    >
                        <div
                            class="d-flex justify-space-between"
                        >
                            <div class="d-flex align-center">
                                <p class="d-flex align-center mb-0 font-weight-bold">
                                    <img
                                        :src="missingDocIcon"
                                        class="icon mr-2"
                                        alt=""
                                    >
                                    Nicht {{ currentLetter.requiresSignature ? 'unterschrieben' : 'gesehen' }}
                                </p>
                            </div>
                        </div>

                        <div
                            v-for="account in notSignedCurrentLetter"
                            :key="account._id"
                        >
                            <div>
                                <div class="notSigned d-flex align-center px-2 mb-1">
                                    <p
                                        v-if="account.role === 'pupil'"
                                        class="greyText"
                                    >
                                        {{ 'Eltern von ' + account.name + ' ' + account.lastName.slice(0, 1) + '.' }}
                                    </p>
                                    <p
                                        v-else-if="account.role === 'teacher'"
                                        class="greyText"
                                    >
                                        {{ account.name.slice(0, 1) + '. ' + account.lastName }}
                                    </p>
                                    <p
                                        v-else-if="currentLetter.requiresSignature"
                                        class="greyText"
                                    >
                                        Signatur kann von der Verwaltung nicht gefordert werden.
                                    </p>
                                    <p
                                        v-else
                                        class="greyText"
                                    >
                                        {{ account.name }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="currentLetter.requiresSignature && signedCurrentLetter.length > 0"
                        class="px-2 mx-1"
                    >
                        <p class="d-flex align-center mb-0 font-weight-bold">
                            <img
                                :src="docIcon"
                                class="icon mr-2"
                                alt=""
                            >
                            Unterschrieben
                        </p>

                        <div
                            v-for="response in signedCurrentLetter"
                            :key="response.account"
                            class="pointer"
                            @click="previewSignature(response)"
                        >
                            <div class="signed d-flex justify-space-between align-center px-2 mb-1">
                                <div class="d-flex align-center justify-space-around">
                                    <img
                                        :src="previewIcon"
                                        class="icon20 mr-2 iconToDunkelgrau"
                                        alt="Vorschau"
                                    >
                                    <p class="greyText ellipsis">
                                        {{ 'Eltern von '+ response.name + ' '+ response.lastName.slice(0, 1) + '.' }}
                                    </p>
                                </div>

                                <p
                                    class="greyText"
                                    style="height: 1.5em"
                                >
                                    {{ createDate(currentLetter.createdAt) }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        v-else-if="currentLetter.seenBy.length > 0 && seenCurrentLetter.length > 0"
                        class="px-2 mx-1"
                    >
                        <p class="d-flex align-center mb-0 font-weight-bold">
                            <img
                                :src="docIcon"
                                class="icon mr-2"
                                alt=""
                            >
                            Gesehen
                        </p>

                        <div
                            v-for="response in seenCurrentLetter"
                            :key="response.account"
                            class="pointer"
                        >
                            <div class="signed d-flex justify-space-between  px-2 mb-1">
                                <p
                                    v-if="response.role === 'pupil'"
                                    class="greyText"
                                >
                                    {{ 'Eltern von ' + response.name + ' ' + response.lastName.slice(0, 1) + '.' }}
                                </p>
                                <p
                                    v-else-if="response.role === 'teacher'"
                                    class="greyText"
                                >
                                    {{ response.name.slice(0, 1) + '. ' + response.lastName }}
                                </p>
                                <p
                                    v-else
                                    class="greyText"
                                >
                                    {{ response.name }}
                                </p>

                                <p
                                    class="greyText"
                                    style="height: 1.5em"
                                >
                                    {{ createDate(currentLetter.createdAt) }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div 
            ref="end"
        />
        <!-- </vue-custom-scrollbar> -->

        <v-dialog
            v-model="signaturePreview"
            width="400"
        >
            <v-card v-if="signaturePreview">
                <v-card-title class="header">
                    Unterschrift für {{ letterResponse.name.slice(0,1) + '. ' + letterResponse.lastName }}
                    <v-spacer />
                    <v-btn
                        class="optionBtn ml-2"
                        x-small
                        color="rgba(230, 45, 45, 0.4)"
                        @click="signaturePreview = false"
                    >
                        <img
                            :src="schliessenIcon"
                            class="icon iconToWhite"
                            alt="schließen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <img
                        :src="signatureToPreview"
                        style="width: 352px !important"
                        alt="Unterschrift"
                    >
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        class="optionBtn ml-2 text-capitalize"
                        x-small
                        color="green"
                        @click="downloadSignature(letterResponse._id)"
                    >
                        <img
                            :src="downloadIcon"
                            class="icon iconToWhite"
                            alt="herunterladen"
                        >
                        <p
                            class="ml-2"
                            style="color: white"
                        >
                            Herunterladen
                        </p>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import sanitizeHtml from '../util/sanitizeHtml';
import {mapActions, mapGetters} from "vuex";
import Poll from "@/components/Poll"
import VueCustomScrollbar from "vue-custom-scrollbar"

import docIcon from '@/assets/Icons/bearbeitet-17.svg';
import downloadIcon from "@/assets/Icons/runterladen-14.svg";
import leftIcon from '@/assets/Icons/FaceliftIcons/zurueck_pfeil.svg';
import missingDocIcon from '@/assets/Icons/kein-dokument-68.svg';
import schliessenIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import videoIcon from '@/assets/Icons/video.svg';
import audioIcon from '@/assets/Icons/EditorRedesign/audio.svg';
import previewIcon from "@/assets/Icons/fas-fa-eye.svg";
import urlify from '../util/urlify';

export default {
    name: 'MessagesLetter',
    components: {
    Poll,
     //VueCustomScrollbar
     },
    props: {
        //collapseLetter: {type: Boolean, required: false}, not needed anymore?
        currentLetter: {required: true},
        currentSickNote: {required: false},
        notSignedCurrentLetter: {required: false},
        signedCurrentLetter: {required: false},
        seenCurrentLetter: {required: false},
        settings: {required: true},
        signature: {required: false},
        isIncoming: {required: false, default: false,}
    },
    data() {
        return {
            
            docIcon,
            downloadIcon,
            leftIcon,
            missingDocIcon,
            schliessenIcon,
            videoIcon,
            audioIcon,
            previewIcon,

            collapseLetterLocal: false,

            signatureToPreview: null,
            signaturePreview: false,
            letterResponse: null,
            sanitizeHtml,

            currentLetterFiles:[],
            filePreviews:[],

            urlify,

        }
    },
    computed: {
        ...mapGetters('auth', [ 'accountRole' ]),
    },
    watch: {
    },
    async mounted() {
        this.currentLetterFiles = this.currentLetter.files;
        this.filePreviews = await this.loadFilePreviews(this.currentLetter._id);
        console.log('decision')
    },
    methods: {
        ...mapActions('parentalLetter', ['getSignature', 'getParentalLetterFile']),

        // Creates date of format DD.MM.YY with leading zeros
        createDate(date) {
            return new Date(date).toLocaleDateString([], {year: '2-digit', month: '2-digit', day: '2-digit'});
        },

        async previewSignature(response) {
            this.letterResponse = response;
            const res = await this.getSignature(response._id);
            let blob = await res.blob();
            this.signatureToPreview = window.URL.createObjectURL(blob);
            this.signaturePreview = true;
        },

        async downloadSignature(responseId) {
            try {
                const res = await this.getSignature(responseId);
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = 'unterschrift';
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                setTimeout(function () {
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }, 1000);

            } catch (e) {
                console.error(e);
            }
        },

     

        async downloadAttachment(letterId, fileId) {
            const file = this.currentLetter.files.filter(file => file._id === fileId);
            try {
                const url = await this.getParentalLetterFile({letterId, fileId});
                var a = document.createElement('a');
                a.href = url;
                a.download = file.uploadedName;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                setTimeout(function () {
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }, 1000);
            } catch (e) {
                console.error(e);
            }
        },
        async loadFilePreviews(letterId){
            const tempfiles = [];
            await this.currentLetterFiles.forEach(async(file) => {
                file.thumbnail = await this.getParentalLetterFile({letterId: letterId, fileId: file._id});
                tempfiles.push(file);
            });
            return tempfiles;
        },
        handlePreview(thumbnail, type) {
            switch (type) {
                case 'audio':
                    this.$emit("playAudio", thumbnail);
                    break;
                case 'image':
                    this.$emit('openImage', thumbnail);
                    break;
                case 'pdf':
                    this.$emit("openPdf", thumbnail);
                    break;
                case 'video':
                    this.$emit("playVideo", thumbnail);
                    break;
                default:
                    console.warn('Type not supported for preview')
                    break;
            }
        },
        clickCollapse(){
            this.collapseLetterLocal = !this.collapseLetterLocal;
            // const element = this.$refs['end'];
            // if(element){
            //     element.scrollIntoView({behavior: 'smooth'});
            // }
           
        }
    },
}
</script>
<style lang="scss" scoped>

// custom disabled button, it uses an id and important as the regular vuetify button styling already uses disabled
// https://stackoverflow.com/a/63762909

.icon {
    height: 20px;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.greyText {
    color: var(--v-dunkelgrau-base);
}

.pointer {
    cursor: pointer;
}

.notSigned {
    background-color: rgba(212, 0, 0, 0.3);
}

.signed {
    background-color: rgba(43, 171, 109, 0.3);
}

.scroll-area-currentLetter {
    position: relative;
    margin: auto;
}


.ellipsis {
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.sectionHeaderCol {
    padding-top: 0;
    padding-bottom: 0;
}

.letters {
    p {
        color: var(--v-dunkelgrau-base);
    }
}

.activeLetter {
    border-radius: 12px;
}

.actionBtn {
    color: white;
    border-radius: 8px;
    min-width: 30px !important;
    width: 30px !important;
    height: 30px !important;
}

.thinHr {
    height: 0;
    border: none;
    border-bottom: 1px solid;
    color: var(--v-dunkelgrau-base);
    background-color: var(--v-dunkelgrau-base);
}

.greyLine {
    color: var(--v-hellgrau-base);
    background-color: var(--v-hellgrau-base);
    border: 1px solid;
}
.whiteBg {
    background-color: #ffffff;
}

.dialogRow {
    width: 99%;
    padding: 0 8px;
    margin: 0.5em auto 0.5em auto;
}

.header {
    background-color: var(--v-parentLetterPurple-base);
    border-radius: 15px 15px 0 0;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
}

.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    height: 35px !important;
}
.thumbnailDisplayNormal {
    width: 100%;
    height: 154px;
    aspect-ratio: 1/1;
    cursor: pointer;
    display: flex;
    justify-content: center;
}
.previewHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3%;
    background-color: var(--v-dunkelgrau-base);
    font-size: 14px;
    border-radius: 8px 8px 0px 0px;
    height:40px;
}
.previewContainer {
    width: 194px;
    border-radius: 0px 0px 8px 8px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #b9b9b9;
    border-radius: 8px 8px 8px 8px;
}
@media only screen and (max-width: 501px) {
    .previewContainer{
        width:100%
    }
    .previewHeader{
        width: 100%
    }
}
.iOS_ResponsiveHack{
    width: 1px;
    min-width: 100%;
    *width: 100%;
    padding-left: 10%;
    padding-right: 10%;
}
.displayWholeThumbnail {
    width: -webkit-fill-available;
    height: 154px;
    aspect-ratio: 1/1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    border-radius: 0px 0px 8px 8px;
}
</style>
