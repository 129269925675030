<template>
    <v-dialog
        v-model="usageAgreementNotSigned"
        width="90vw"
        height="90vh"
        persistent
        style="border-radius: 8px"
    >
        <div style="background-color: white; overflow: hidden; border-radius: 8px">
            <div
                class="d-flex flex-row justify-flex-start align-center"
                style="
          padding: 1rem;
          box-shadow: 1px 3px 3px silver;
          background-color: #343e55;
        "
            >
                <img
                    :src="unterschriftIcon"
                    height="20px"
                    class="iconToWhite"
                    alt
                >
                <div
                    style="font-size: large; color: white"
                    class="ml-2"
                >
                    Einverständniserklärung
                </div>
            </div>
            <!-- <v-card-text

                @scroll="scrollUsageAgreement"
            > -->
            <vue-custom-scrollbar
                class="scroll-area"
                :settings="settings"
                style="box-shadow: 1px 3px 3px silver"
            >
                <div
                    id="nutzungserklärung"
                    class="pa-4"
                >
                    <h3
                        id="Nutzungsordnung"
                        class="d-flex flex-row justify-space-around align-center"
                    >
                        Nutzungsordnung
                    </h3>
                    <div v-if="accountRole === 'teacher'">
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-align:center;line-height:18.0pt;">
                            <strong>f&uuml;r Lehrkr&uuml;fte bei</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-align:center;line-height:18.0pt;">
                            <strong>Anwendung der digitalen Anwendung EKLARA&nbsp;</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <strong>&nbsp;</strong>
                        </p>
                        <div
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
              "
                        >
                            <p>
                                <strong><u><span style="font-size: 16px">1. Vorwort</span></u></strong>
                            </p>
                        </div>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            Diese Nutzungsordnung informiert Sie als
                            <strong>Lehrkr&auml;fte&nbsp;</strong>&uuml;ber Pflichten und
                            Handlungsleitlinien bei Nutzung von EKLARA.&nbsp;
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
              &nbsp;
                        </p>
                        <div
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
              "
                        >
                            <p>
                                <strong><u><span style="font-size: 16px">2. Geltungsbereich</span></u></strong>
                            </p>
                        </div>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            Diese Nutzungsordnung regelt die schulische Nutzung von EKLARA
                            durch die Lehrkr&auml;fte.
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                text-indent: -21.3pt;
                line-height: 18pt;
              "
                        >
              &nbsp;
                        </p>
                        <div
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
              "
                        >
                            <p>
                                <strong><u><span
                                    style="font-size: 16px"
                                >3. Nutzungsberechtigte</span></u></strong>
                            </p>
                        </div>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            Nutzungsberechtigte nach dieser Nutzungsordnung sind die
                            Lehrkr&auml;fte der Schulen.
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                text-indent: -21.3pt;
                line-height: 18pt;
              "
                        >
              &nbsp;
                        </p>
                        <div
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
              "
                        >
                            <p>
                                <strong><u><span
                                    style="font-size: 16px"
                                >4. Zwecke der Nutzung</span></u></strong>
                            </p>
                        </div>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 150%;
              "
                        >
                            Durchf&uuml;hrung des digitalen Unterrichts (synchron per
                            Videokonferenz und nicht synchron), per EKLARA-Plattform sowie der
                            schulbezogenen Kommunikation und Organisation zur Wahrnehmung des
                            staatlichen Bildungs- und Erziehungsauftrags insbesondere unter
                            dem Aspekt der Barrierefreiheit f&uuml;r Sch&uuml;lerinnen und
                            Sch&uuml;ler der sonderp&auml;dagogischen Bildungs- und
                            Beratungszentren (auch F&ouml;rder- und Grundschulen genannt).
                            Hierbei werden personenbezogene Daten von Sch&uuml;lerInnen,
                            Lehrkr&auml;ften, Schulleitung/ Verwaltung, Erziehungsberechtigen
                            und Externen verarbeitet.
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                text-indent: -21.3pt;
                line-height: 18pt;
              "
                        >
              &nbsp;
                        </p>
                        <div
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
              "
                        >
                            <p>
                                <strong><u><span
                                    style="font-size: 16px"
                                >5. Normative Vorgaben f&uuml;r die Verarbeitung
                                    personenbezogener Daten</span></u></strong>
                            </p>
                        </div>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            <u>Grunds&auml;tzliches</u>
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            Auch im Rahmen der Nutzung von EKLARA m&uuml;ssen die geltenden
                            gesetzlichen Regelungen beachtet werden (unter anderem das
                            Datenschutzrecht, das Urheberrecht, das Strafrecht, das
                            b&uuml;rgerliche Recht). Eine Gef&auml;hrdung und
                            Beeintr&auml;chtigung Dritter (beispielsweise von
                            Mitsch&uuml;lerinnen und Mitsch&uuml;lern) ist zu
                            unterlassen.&nbsp;Unzul&auml;ssig ist ebenfalls die Weitergabe von
                            Inhalten (inklusive z.B. Screenshots von Chats), die den
                            Nutzerinnen und Nutzern &uuml;ber EKLARA zug&auml;nglich sind. Die
                            Lehrkr&auml;fte tragen die Verantwortung f&uuml;r die Inhalte der
                            von ihnen verarbeiteten Dokumente.&nbsp;Die Lehrkr&auml;fte sind
                            nicht zu einer &Uuml;bermittlung von Daten aus dem
                            Lernmanagementsystem an Dritte befugt. Das bedeutet,
                            personenbezogene Daten d&uuml;rfen den Bereich einer Schule nicht
                            verlassen.&nbsp;Eine Ver&ouml;ffentlichung oder Verbreitung von
                            Fotos erfordert die Einwilligung des oder der Betroffenen/
                            Inhabers.&nbsp;Die Klassenlehrkr&auml;fte kl&auml;ren im Rahmen
                            des Unterrichts die Sch&uuml;lerinnen und Sch&uuml;ler
                            altersgerecht &uuml;ber die Pflichten und Handlungsleitlinien
                            f&uuml;r die Nutzung von EKLARA auf. Eltern unterst&uuml;tzen ihre
                            Kinder ebenfalls beim Verst&auml;ndnis und bei der Einhaltung
                            normativer Vorgaben. Die hiesige Nutzungsordnung ist durch die
                            zust&auml;ndige Schule an ihre Sch&uuml;lerinnen und Sch&uuml;ler
                            bekanntzugeben.
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            <u>Verarbeitung personenbezogener Daten</u>
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            Die Verarbeitung personenbezogener Daten findet gem&auml;&szlig;
                            der Verwaltungsvorschrift des Kultusministeriums &uuml;ber den
                            Datenschutz an &ouml;ffentlichen Schulen vom 4. Juli 2019 - Az.:
                            13-0557.0/106 - statt (im Folgenden: Die Verwaltungsvorschrift).
                            Personenbezogene Daten d&uuml;rfen mittels EKLARA verarbeitet
                            werden, soweit dies f&uuml;r die Aufgabenwahrnehmung der
                            jeweiligen Schule zur Unterrichtsvorbereitung,
                            Unterrichtsdurchf&uuml;hrung und Unterrichtsnachbereitung
                            erforderlich ist.&nbsp;
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
              &nbsp;
                        </p>
                        <div
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
              "
                        >
                            <p>
                                <strong><u><span
                                    style="font-size: 16px"
                                >6. Umgang mit Zugangsdaten und Passw&ouml;rtern</span></u></strong>
                            </p>
                        </div>
                        <p
                            style="
                margin-top: 0cm;
                margin-right: 0cm;
                margin-bottom: 0cm;
                margin-left: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            Die Lehrkr&auml;fte erhalten zur Inbetriebnahme von EKLARA
                            individuelle Zugangsdaten zur Inbetriebnahme. Diese Zugangsdaten
                            sind vertraulich zu behandeln. Die zus&auml;tzliche
                            QR-Code-Anmeldefunktion durch die Verwaltung/ Administrator
                            deaktiviert. Bei der Aktivierung der QR-Code-Anmeldefunktion wird
                            eine zus&auml;tzliche 2-Faktor-Authentifizierung (&uuml;ber
                            Emailcode und/ oder zus&auml;tzlich pers&ouml;nliche
                            4-Stellige-PIN) erforderlich.&nbsp;
                        </p>
                        <p
                            style="
                margin-top: 0cm;
                margin-right: 0cm;
                margin-bottom: 0cm;
                margin-left: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            Die Nutzerin oder der Nutzer ist selbst daf&uuml;r verantwortlich,
                            dass nur sie oder er Zugriff auf beziehungsweise Kenntnis von
                            ihren / seinen Anmeldedaten hat. Wer fremde Zugangsdaten
                            erf&auml;hrt, ist verpflichtet, dies umgehend der betreffenden
                            Person oder der Schulleitung mitzuteilen. Sollten die eigenen
                            Zugangsdaten durch ein Versehen anderen Personen bekannt geworden
                            sein, sind Sie verpflichtet, sofort Ma&szlig;nahmen zum Schutz des
                            eigenen Zugangs zu ergreifen. Falls noch m&ouml;glich, sind
                            Zugangspassw&ouml;rter zu &auml;ndern. Ist dies nicht
                            m&ouml;glich, ist die Schuladministration zu informieren. So
                            k&ouml;nnen die Zugangsdaten dann von den Zust&auml;ndigen
                            ge&auml;ndert oder erneuert werden. Bei einer Verwendung von
                            EKLARA &uuml;ber einen Webbrowser
                            <strong>d&uuml;rfen Kennw&ouml;rter nicht im Webbrowser gespeichert
                                werden.</strong>
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                text-indent: -21.3pt;
                line-height: 18pt;
              "
                        >
              &nbsp;
                        </p>
                        <div
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
              "
                        >
                            <p>
                                <strong><u><span
                                    style="font-size: 16px"
                                >7. Verarbeitung personenbezogener Daten auf privaten
                                    Datenverarbeitungsger&auml;ten</span></u></strong>
                            </p>
                        </div>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            Gem&auml;&szlig; der Anlage 1 zur Verwaltungsvorschrift steht die
                            Verarbeitung personenbezogener Daten auf privaten Ger&auml;ten
                            durch die Lehrkr&auml;fte unter dem Vorbehalt der Genehmigung
                            durch die Schulleitung und unterliegen den Regelungen der
                            Verwaltungsvorschrift zum m&ouml;glichen Umfang der Verarbeitung
                            personenbezogener Daten auf privaten Ger&auml;ten.
                            Gem&auml;&szlig; der Verwaltungsvorschrift d&uuml;rfen
                            insbesondere besonders schutzw&uuml;rdige Daten, etwa &uuml;ber
                            Krankheiten oder Erziehungs- und Ordnungsma&szlig;nahmen
                            gegen&uuml;ber Sch&uuml;lerinnen und Sch&uuml;lern,
                            <u>nicht auf dem privaten Datenverarbeitungsger&auml;t
                                gespeichert</u>
                            werden.&nbsp;
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                text-indent: -21.3pt;
                line-height: 18pt;
              "
                        >
              &nbsp;
                        </p>
                        <div
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
              "
                        >
                            <p>
                                <strong><u><span
                                    style="font-size: 16px"
                                >8. Speicherdauer und Pflichten der Nutzer/innen zur
                                    L&ouml;schung</span></u></strong>
                            </p>
                        </div>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            Gem&auml;&szlig; der hiesigen Nutzungsordnung sind Sie
                            verpflichtet, personenbezogene Daten aus Ihrem Account nach den
                            Vorgaben der Verwaltungsvorschrift zu l&ouml;schen. Hiernach sind
                            zur Aufgabenerf&uuml;llung der jeweiligen Schule verarbeitete
                            personenbezogene Daten insbesondere dann zu l&ouml;schen, wenn
                            deren weitere Verarbeitung, insbesondere durch Speicherung,
                            f&uuml;r die Schule zur Erf&uuml;llung ihrer p&auml;dagogischen
                            Aufgaben nicht mehr erforderlich ist und es keinen weiteren
                            Rechtsgrund f&uuml;r die weitere Verarbeitung gibt. Die
                            m&ouml;gliche Vorhaltedauer und die korrespondierende
                            Verpflichtung zur L&ouml;schung verschiedener Dokumentenkategorien
                            k&ouml;nnen der Verwaltungsvorschrift entnehmen. Unabh&auml;ngig
                            von Ihrer eigenverantwortlichen L&ouml;schung werden die
                            eingestellten Inhalte, Telemetrie- und Protokolldaten
                            gem&auml;&szlig; den Informationen in der
                            Datenschutzinformation f&uuml;r Lehrkr&auml;fte gel&ouml;scht.
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                text-indent: -21.3pt;
                line-height: 18pt;
              "
                        >
              &nbsp;
                        </p>
                        <div
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
              "
                        >
                            <p>
                                <strong><u><span
                                    style="font-size: 16px"
                                >9. Schuladministration</span></u></strong>
                            </p>
                        </div>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            Die Schulleitung benennt zur Administration von EKLARA schriftlich
                            die Administratoren sowie deren Aufgaben. Die Administratoren
                            d&uuml;rfen Protokolldaten ausschlie&szlig;lich f&uuml;r die
                            Systembetreuung nutzen (siehe unten Ziffer 13). Eine Verhaltens-
                            und Leistungskontrolle der Lehrkr&auml;fte ist nicht
                            zul&auml;ssig. Das Administrationskonto ist f&uuml;r die
                            Bereitstellung und Einstellungen von EKLARA auf Schulebene
                            vorgesehen und darf nicht f&uuml;r die unterrichtliche Arbeit
                            eingesetzt werden.&nbsp;
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            <br>
                        </p>
                        <div
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
              "
                        >
                            <p>
                                <strong><u><span
                                    style="font-size: 16px"
                                >10. Kommunikation &uuml;ber EKLARA</span></u></strong>
                            </p>
                        </div>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
              "
                        >
                            Die Kommunikationsm&ouml;glichkeiten von EKLARA mit Ausnahme der
                            Videokonferenz, Chat, Schwarzes Brett, Elternbriefe und Pinwand
                            werden an der Schule nicht genutzt.&nbsp;
                        </p>
                        <p
                            style="
                margin-top: 0cm;
                margin-right: 0cm;
                margin-bottom: 0cm;
                margin-left: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
              &nbsp;
                        </p>
                        <div
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
              "
                        >
                            <p>
                                <strong><u><span
                                    style="font-size: 16px"
                                >11. Kommunikation &uuml;ber Videokonferenz
                                    (BigBlueButton), Chat, Elternbriefe, Schwarzes Brett und
                                    Pinnwand</span></u></strong>
                            </p>
                        </div>
                        <p
                            style="
                margin-top: 0cm;
                margin-right: 0cm;
                margin-bottom: 0cm;
                margin-left: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            <u>Verbot von Aufzeichnungen und &ouml;ffentlicher Teilnahme</u>
                        </p>
                        <p
                            style="
                margin-top: 0cm;
                margin-right: 0cm;
                margin-bottom: 0cm;
                margin-left: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            Es ist grunds&auml;tzlich verboten, Gespr&auml;che, Texte, Inhalte
                            und &Uuml;bertragungen mitzuschneiden, aufzuzeichnen oder zu
                            speichern. Dies gilt auch f&uuml;r die Anwendung jeder Art von
                            Drittsoftware oder beispielsweise Handycams. Der Mitschnitt einer
                            Videokonferenz, Chats, Mitteilungen von geteilten Inhalten kann
                            rechtliche Konsequenzen haben. Es ist unzul&auml;ssig, dass Dritte
                            (auch Eltern, Freunde oder Geschwister) bei der Videokonferenz und
                            im Chat, im Schwarzen Brett und in der Pinwand zuh&ouml;ren,
                            zusehen oder auf andere Art und Weise Einblick in die
                            Kommunikation erhalten. Dar&uuml;ber hinaus ist die Nutzung in
                            &ouml;ffentlich zug&auml;nglichen R&auml;umen wie beispielsweise
                            ein Caf&eacute; oder ein Restaurant untersagt, da hierbei
                            personenbezogene Daten durch unbeteiligte Dritte wahrgenommen oder
                            gar aufgezeichnet werden k&ouml;nnten. Auch die Schulen
                            d&uuml;rfen keine Aufzeichnungen von Sch&uuml;lerinnen und
                            Sch&uuml;lern machen.
                        </p>
                        <p
                            style="
                margin-top: 0cm;
                margin-right: 0cm;
                margin-bottom: 0cm;
                margin-left: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
              &nbsp;
                        </p>
                        <p
                            style="
                margin-top: 0cm;
                margin-right: 0cm;
                margin-bottom: 0cm;
                margin-left: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            <u>Schutz der eigenen Privatsph&auml;re</u>
                        </p>
                        <p
                            style="
                margin-top: 0cm;
                margin-right: 0cm;
                margin-bottom: 0cm;
                margin-left: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            W&auml;hlen Sie einen passenden, m&ouml;glichst neutralen Ort
                            f&uuml;r die Videokonferenz soweit eine &Uuml;bertragung von Bild
                            und Ton aus Ihrem h&auml;uslichen Umfeld stattfindet. Auf diese
                            Weise k&ouml;nnen Sie vermeiden, dass andere Teilnehmerinnen und
                            Teilnehmer Ihr privates Umfeld im Hintergrund sehen k&ouml;nnen.
                            Bestenfalls w&auml;hlen Sie eine aufger&auml;umte Arbeitsumgebung
                            oder als neutralen Hintergrund eine wei&szlig;e Wand und nutzen
                            Sie geschlossene Kopfh&ouml;rer mit einem integrierten Mikrofon
                            bei einer Video- und Ton&uuml;bertragung.
                        </p>
                        <p
                            style="
                margin-top: 0cm;
                margin-right: 0cm;
                margin-bottom: 0cm;
                margin-left: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
              &nbsp;
                        </p>
                        <div
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
              "
                        >
                            <p>
                                <strong><u><span
                                    style="font-size: 16px"
                                >12. Nutzung im Rahmen des geltenden Rechts</span></u></strong>
                            </p>
                        </div>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            Die Nutzung von EKLARA darf nur im Rahmen des geltenden Rechts
                            erfolgen. Zu beachten sind unter anderem das Jugendschutzrecht,
                            das Urheberrecht und das Datenschutzrecht. Pflichten, die sich aus
                            dem &ouml;ffentlich-rechtlichen Dienstverh&auml;ltnis ergeben,
                            gelten auch im Rahmen der hiesigen Nutzung.
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
              &nbsp;
                        </p>
                        <div
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
              "
                        >
                            <p>
                                <strong><u><span
                                    style="font-size: 16px"
                                >13. Protokollierung von Aktivit&auml;ten und
                                    &Uuml;berpr&uuml;fung der Kommunikation</span></u></strong>
                            </p>
                        </div>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            Die Verarbeitung der in der Datenschutzinformation f&uuml;r
                            Lehrkr&auml;fte dargestellten protokollierten Daten soll die
                            <span
                                style="color: black"
                            >Funktionsf&auml;higkeit der Anwendungen und Dienste
                                gew&auml;hrleisten. Dies dient den Gew&auml;hrleistungszielen
                                der Datensicherheit und der Datenverf&uuml;gbarkeit. Dies
                                umfasst die Zwecke der Gew&auml;hrleistung einer rechtskonformen
                                Datenverarbeitung wie die Ermittlung von sch&auml;digenden
                                Ma&szlig;nahmen und Missbrauchsversuchen. Der Zugriff auf die
                                protokollierten Daten findet systemseitig bei EKLARA
                                statt.&nbsp;</span>Diese protokollierten Daten werden gespeichert und automatisiert
                            nach Ablauf einer erforderlichen Aufbewahrungsfrist von 30 Tagen
                            bei EKLARA gel&ouml;scht (Datensicherheit und
                            Datenverf&uuml;gbarkeit). Im konkreten Verdachtsfall erfolgt die
                            Speicherung solange, wie dies zum Umgang mit etwaigen
                            Missbrauchsf&auml;llen erforderlich ist.&nbsp;Nur in
                            begr&uuml;ndeten F&auml;llen wie die missbr&auml;uchliche Nutzung
                            werden diese protokollierten Daten gegebenenfalls eingesehen und
                            ausgewertet. Falls es notwendig ist, wird auch die Polizei
                            eingeschaltet. Der Zugriff auf die Protokolldaten ist auf das
                            technische Personal begrenzt, das f&uuml;r den Netzbetrieb und die
                            Bereitstellung der verf&uuml;gbaren Dienste zust&auml;ndig ist.
                            Die Einsicht in Protokolldaten ist zweckgebunden.&nbsp;Eine
                            Verhaltens- und Leistungskontrolle der Lehrkr&auml;fte ist nicht
                            zul&auml;ssig.
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
              &nbsp;
                        </p>
                        <div
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
              "
                        >
                            <p>
                                <strong><u><span
                                    style="font-size: 16px"
                                >14. Dienstliche und Private Nutzung</span></u></strong>
                            </p>
                        </div>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            Eine private Nutzung von EKLARA ist ausgeschlossen. Die Schule
                            unterliegt daher nicht den Pflichten eines Dienstanbieters im
                            Sinne des Telekommunikationsgesetzes (TKG).
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
              &nbsp;
                        </p>
                        <div
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
              "
                        >
                            <p>
                                <strong><u><span
                                    style="font-size: 16px"
                                >15. Alternative Kommunikationsm&ouml;glichkeiten f&uuml;r
                                    Sch&uuml;lerinnen und Sch&uuml;ler</span></u></strong>
                            </p>
                        </div>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            Lehrkr&auml;fte m&uuml;ssen gew&auml;hrleisten, dass den an der
                            Kommunikation zu beteiligenden Sch&uuml;lerinnen und Sch&uuml;lern
                            keine Nachteile entstehen. Somit m&uuml;ssen - sofern der
                            Sch&uuml;ler oder die Sch&uuml;lerin keinen Zugang zu einem
                            Digitalen Endger&auml;t besitzt oder &uuml;ber keine
                            (ausreichende) Internetanbindung im Falle des Lernens von zu Hause
                            verf&uuml;gt - alternative M&ouml;glichkeiten zur Teilhabe am
                            Unterricht durch die Lehrkraft er&ouml;ffnet werden (wie
                            beispielsweise die &Uuml;bermittlung der Unterrichtsmaterialien
                            per Post). Es muss gew&auml;hrleistet werden, dass wesentliche
                            Informationen s&auml;mtliche Sch&uuml;lerinnen und Sch&uuml;ler
                            erreichen, das hei&szlig;t auch diejenigen, die am Unterricht
                            nicht in digitaler Form teilnehmen <u>k&ouml;nnen</u>.
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                text-indent: -21.3pt;
                line-height: 18pt;
              "
                        >
              &nbsp;
                        </p>
                        <div
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
              "
                        >
                            <p>
                                <strong><u><span
                                    style="font-size: 16px"
                                >16. Folgen missbr&auml;uchlicher Nutzung</span></u></strong>
                            </p>
                        </div>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            Grunds&auml;tzlich gilt zum Schutz der Vertraulichkeit des Wortes
                            das Verbot der unbefugten Aufnahme und der Verbreitung,
                            beispielsweise, wenn solche Bild- und/oder Tonaufnahmen ins
                            Internet gestellt werden oder per Messaging-App geteilt werden,
                            und das Verbot des Mitschauens durch Dritte, wenn hierzu eine
                            Erlaubnis nicht vorliegt.&nbsp;
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
              &nbsp;
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            Verst&ouml;&szlig;e k&ouml;nnen durch die vor&uuml;bergehende
                            Sperrung des Accounts bis hin zum endg&uuml;ltigen Entzug des
                            Zugangs zur Folge haben. Bei Sch&uuml;lerinnen und Sch&uuml;lern
                            k&ouml;nnen zus&auml;tzliche Ordnungs- und
                            Erziehungsma&szlig;nahmen eingeleitet werden und im Extremfall
                            sogar zu einem Schulausschluss f&uuml;hren. Dies gilt auch
                            f&uuml;r die missbr&auml;uchliche Nutzung dieser Anwendung
                            entgegen dieser Nutzungsordnung oder der Anweisung durch die
                            Lehrkraft im Unterricht.&nbsp;
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
              &nbsp;
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            Dar&uuml;ber hinaus sind bei Verletzung des allgemeinen
                            Pers&ouml;nlichkeitsrechts durch unerlaubte Bildaufnahmen
                            (&bdquo;Recht am eigenen Bild&ldquo;) zivilrechtliche
                            Anspr&uuml;che m&ouml;glich, die gegebenenfalls geltend gemacht
                            werden k&ouml;nnen. Werden gesetzliche Verst&ouml;&szlig;e von
                            Sch&uuml;lerinnen und Sch&uuml;lern bekannt oder gemeldet, hat die
                            jeweils zust&auml;ndige Lehrkraft die Inhalte zu sichern und den
                            etwaigen Versto&szlig; zu pr&uuml;fen. Liegt aus Sicht der
                            Lehrkraft ein gravierender Versto&szlig; vor, informiert sie die
                            Schulleitung.&nbsp;
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
              &nbsp;
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            Der Schulleitung obliegen gegebenenfalls weitere Ma&szlig;nahmen.
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                text-indent: -21.3pt;
                line-height: 18pt;
              "
                        >
              &nbsp;
                        </p>
                        <div
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
              "
                        >
                            <p>
                                <strong><u><span
                                    style="font-size: 16px"
                                >17. Informationen zur Verarbeitung der Daten von
                                    Nutzerinnen und Nutzern und deren Rechten</span></u></strong>
                            </p>
                        </div>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            Informationen zu den bei einer Nutzung von EKLARA verarbeiteten
                            Daten sowie den damit verbundenen Rechten sind in der
                            Datenschutzinformation f&uuml;r Lehrkr&auml;fte
                            dargestellt.&nbsp;
                        </p>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                text-indent: -21.3pt;
                line-height: 18pt;
              "
                        >
                            <strong>&nbsp;</strong>
                        </p>
                        <div
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
              "
                        >
                            <p>
                                <strong><u><span
                                    style="font-size: 16px"
                                >18. Schlussbestimmungen</span></u></strong>
                            </p>
                        </div>
                        <p
                            style="
                margin: 0cm;
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                line-height: 18pt;
              "
                        >
                            Sollten einzelne Bestimmungen dieser Nutzungsordnung ganz oder
                            teilweise unwirksam sein oder werden, ber&uuml;hrt dies nicht die
                            Wirksamkeit der &uuml;brigen Bestimmungen.
                        </p>
                    </div>
                    <div v-else-if="accountRole === 'pupil'">
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-align:center;line-height:18.0pt;">
                            <strong>f&uuml;r  Sch&uuml;lerinnen und Sch&uuml;lern bei</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-align:center;line-height:18.0pt;">
                            <strong>Anwendung der digitalen Anwendung EKLARA&nbsp;</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <strong>&nbsp;</strong>
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">1. Vorwort</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Diese Nutzungsordnung informiert Sie als <strong>Sch&uuml;lerinnen und Sch&uuml;ler</strong> &uuml;ber Pflichten und Handlungsleitlinien bei Nutzung von EKLARA.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">2. Geltungsbereich</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Diese Nutzungsordnung regelt die schulische Nutzung von EKLARA durch die Sch&uuml;lerinnen und Sch&uuml;ler.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-indent:-21.3pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">3. Nutzungsberechtigte</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Nutzungsberechtigte nach dieser Nutzungsordnung sind die Sch&uuml;lerinnen und Sch&uuml;ler der Schule
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-indent:-21.3pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">4. Zwecke der Nutzung</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
                            Durchf&uuml;hrung des digitalen Unterrichts (synchron per Videokonferenz und nicht synchron), per EKLARA-Plattform sowie der schulbezogenen Kommunikation und Organisation zur Wahrnehmung des staatlichen Bildungs- und Erziehungsauftrags insbesondere unter dem Aspekt der Barrierefreiheit f&uuml;r Sch&uuml;lerinnen und Sch&uuml;ler der sonderp&auml;dagogischen Bildungs- und Beratungszentren (auch F&ouml;rder- und Grundschulen genannt). Hierbei werden personenbezogene Daten von Sch&uuml;lerInnen, Lehrkr&auml;ften, Schulleitung/ Verwaltung, Erziehungsberechtigen und Externen verarbeitet.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-indent:-21.3pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">5. Normative Vorgaben f&uuml;r die Nutzung von EKLARA</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Auch im Rahmen der Nutzung von EKLARA m&uuml;ssen die geltenden gesetzlichen Regelungen beachtet werden (unter anderem das Datenschutzrecht, das Urheberrecht, das Strafrecht, das b&uuml;rgerliche Recht). Eine Gef&auml;hrdung und Beeintr&auml;chtigung Dritter (beispielsweise von Mitsch&uuml;lerinnen und Mitsch&uuml;lern) ist zu unterlassen.&nbsp;Unzul&auml;ssig ist ebenfalls die Weitergabe von Inhalten (inklusive z.B. Screenshots von Chats), die den Nutzerinnen und Nutzern &uuml;ber EKLARA zug&auml;nglich sind. Die Sch&uuml;lerinnen und Sch&uuml;ler tragen die Verantwortung f&uuml;r die Inhalte der von ihnen verarbeiteten Dokumente.&nbsp;Die Sch&uuml;lerinnen und Sch&uuml;ler sind nicht zu einer &Uuml;bermittlung von Daten aus dem Lernmanagementsystem an Dritte befugt. Das bedeutet, personenbezogene Daten d&uuml;rfen den Bereich einer Schule nicht verlassen.&nbsp;Eine Ver&ouml;ffentlichung oder Verbreitung von Fotos erfordert die Einwilligung des oder der Betroffenen/ Inhabers.&nbsp;Die Klassenlehrkr&auml;fte kl&auml;ren im Rahmen des Unterrichts die Sch&uuml;lerinnen und Sch&uuml;ler altersgerecht &uuml;ber die Pflichten und Handlungsleitlinien f&uuml;r die Nutzung von EKLARA auf. Eltern unterst&uuml;tzen ihre Kinder ebenfalls beim Verst&auml;ndnis und bei der Einhaltung normativer Vorgaben. Die hiesige Nutzungsordnung ist durch die zust&auml;ndige Schule an ihre Sch&uuml;lerinnen und Sch&uuml;ler bekanntzugeben.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">6. Umgang mit Zugangsdaten und Passw&ouml;rtern</span></u></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Sch&uuml;lerinnen und Sch&uuml;ler erhalten zur Inbetriebnahme von EKLARA individuelle Zugangsdaten zur Inbetriebnahme. Die Zugangsdaten werden durch den &bdquo;EKLARA-Sch&uuml;lerausweis&ldquo; mit einer optionalen QR-Code-Anmeldung zur Verf&uuml;gung gestellt.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Diese Zugangsdaten sind vertraulich zu behandeln. Wer fremde Zugangsdaten in Form des &bdquo;EKLARA-Sch&uuml;lerausweis&ldquo; erf&auml;hrt (z.B. durch Verlust, Vervielf&auml;ltigung, etc.) ist verpflichtet, <strong>dies umgehend der Klassenlehrkraft oder der Schulleitung/ Verwaltung</strong> mitzuteilen. Anschlie&szlig;end k&ouml;nnen die Zugangsdaten/ QR-Ausweis von den Zust&auml;ndigen ge&auml;ndert oder erneuert werden.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Bei einer Verwendung von EKLARA &uuml;ber einen Webbrowser d&uuml;rfen Kennw&ouml;rter <strong>nicht im Webbrowser gespeichert</strong> werden.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">7. Speicherdauer und L&ouml;schung personenbezogener Daten</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Unabh&auml;ngig von Ihrer eigenverantwortlichen L&ouml;schung personenbezogener Daten wie bspw. eigens generierter Inhaltsdaten (Chatinhalte, Aufgaben, Dokumente, Pinnwandeintr&auml;ge, Kalendereintr&auml;ge, Dateien) werden die eingestellten Inhalte, Telemetrie- und Protokolldaten gem&auml;&szlig; den Informationen in der Datenschutzinformation f&uuml;r Sch&uuml;lerinnen und Sch&uuml;ler gel&ouml;scht.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-indent:-21.3pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">8. Schuladministration</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Zur Administration von EKLARA benennt die Schulleiterin oder der Schulleiter schriftlich die Administratoren sowie deren Aufgaben. Die Administratoren d&uuml;rfen Protokolldaten ausschlie&szlig;lich f&uuml;r die Systembetreuung nutzen.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <br>
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="line-height:115%;font-size:16px;">9. Kommunikation &uuml;ber EKLARA</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:115%;">
                            Die Kommunikationsm&ouml;glichkeiten von EKLARA mit Ausnahme der Videokonferenz, Chat, <span style="color:black;">Schwarzes Brett und Pinnwand&nbsp;</span>werden an der Schule nicht genutzt.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Die Sch&uuml;lerinnen und Sch&uuml;ler haben sich an die unter Punkt 5 erl&auml;uterten normativen Vorgaben beim Versenden von Nachrichten zu halten, gesetzliche Vorschriften zu beachten sowie die Rechte ihrer Mitmenschen zu respektieren.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">10. Kommunikation &uuml;ber integrierte Videokonferenz (BigBlueButton), Chat,&nbsp;</span></u></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <strong><u>Schwarzes Brett und Pinnwand</u></strong>
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <u>Verbot von Aufzeichnungen und &ouml;ffentlicher Teilnahme</u>
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Es ist grunds&auml;tzlich verboten, Gespr&auml;che, Texte, Inhalte und &Uuml;bertragungen mitzuschneiden, aufzuzeichnen oder zu speichern. Dies gilt auch f&uuml;r die Anwendung jeder Art von Drittsoftware oder beispielsweise Handycams. Der Mitschnitt einer Videokonferenz, Chats, Mitteilungen von geteilten Inhalten kann rechtliche Konsequenzen haben. Es ist unzul&auml;ssig, dass Dritte (auch Eltern, Freunde oder Geschwister) bei der Videokonferenz und im Chat, im Schwarzen Brett und in der Pinwand zuh&ouml;ren, zusehen oder auf andere Art und Weise Einblick in die Kommunikation erhalten. Dar&uuml;ber hinaus ist die Nutzung in &ouml;ffentlich zug&auml;nglichen R&auml;umen wie beispielsweise ein Caf&eacute; oder ein Restaurant untersagt, da hierbei personenbezogene Daten durch unbeteiligte Dritte wahrgenommen oder gar aufgezeichnet werden k&ouml;nnten. Auch die Schulen d&uuml;rfen keine Aufzeichnungen von Sch&uuml;lerinnen und Sch&uuml;lern machen.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <u>Schutz der eigenen Privatsph&auml;re</u>
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            W&auml;hlen Sie einen passenden, m&ouml;glichst neutralen Ort f&uuml;r die Videokonferenz soweit eine &Uuml;bertragung von Bild und Ton aus Ihrem h&auml;uslichen Umfeld stattfindet. Auf diese Weise k&ouml;nnen Sie vermeiden, dass andere Teilnehmerinnen und Teilnehmer Ihr privates Umfeld im Hintergrund sehen k&ouml;nnen. Bestenfalls w&auml;hlen Sie eine aufger&auml;umte Arbeitsumgebung oder als neutralen Hintergrund eine wei&szlig;e Wand und nutzen Sie geschlossene Kopfh&ouml;rer mit einem integrierten Mikrofon bei einer Video- und Ton&uuml;bertragung.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">11. Protokollierung von Aktivit&auml;ten und &Uuml;berpr&uuml;fung der Kommunikation</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Die Verarbeitung der in der Datenschutzinformation f&uuml;r Sch&uuml;lerInnen und Sch&uuml;ler dargestellten protokollierten Daten soll die Funktionsf&auml;higkeit der Anwendungen und Dienste gew&auml;hrleisten. Dies dient den Gew&auml;hrleistungszielen der Datensicherheit und der Datenverf&uuml;gbarkeit. Dies umfasst die Zwecke der Gew&auml;hrleistung einer rechtskonformen Datenverarbeitung wie die Ermittlung von sch&auml;digenden Ma&szlig;nahmen und Missbrauchsversuchen. Der Zugriff auf die protokollierten Daten findet systemseitig bei EKLARA statt. Diese protokollierten Daten werden gespeichert und automatisiert nach Ablauf einer erforderlichen Aufbewahrungsfrist von 30 Tagen bei EKLARA gel&ouml;scht (Datensicherheit und Datenverf&uuml;gbarkeit). Im konkreten Verdachtsfall erfolgt die Speicherung solange, wie dies zum Umgang mit etwaigen Missbrauchsf&auml;llen erforderlich ist. Nur in begr&uuml;ndeten F&auml;llen wie die missbr&auml;uchliche Nutzung werden diese protokollierten Daten gegebenenfalls eingesehen und ausgewertet. Falls es notwendig ist, wird auch die Polizei eingeschaltet. Der Zugriff auf die Protokolldaten ist auf das technische Personal begrenzt, das f&uuml;r den Netzbetrieb und die Bereitstellung der verf&uuml;gbaren Dienste zust&auml;ndig ist. Die Einsicht in Protokolldaten ist zweckgebunden.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">12. Schulische Nutzung</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Eine private Nutzung von EKLARA ist ausgeschlossen. Die Schule unterliegt daher nicht den Pflichten eines Dienstanbieters im Sinne des Telekommunikationsgesetzes (TKG)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">13. Alternative Kommunikationsm&ouml;glichkeiten f&uuml;r Sch&uuml;lerinnen und Sch&uuml;ler</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Lehrkr&auml;fte m&uuml;ssen gew&auml;hrleisten, dass den an der Kommunikation via EKLARA zu beteiligenden Sch&uuml;lerinnen und Sch&uuml;lern keine Nachteile entstehen. Somit m&uuml;ssen - sofern der Sch&uuml;ler oder die Sch&uuml;lerin keinen Zugang zu einem Digitalen Endger&auml;t besitzt oder &uuml;ber keine (ausreichende) Internetanbindung im Falle des Lernens von zu Hause verf&uuml;gt - alternative M&ouml;glichkeiten zur Teilhabe am Unterricht durch die Lehrkraft er&ouml;ffnet werden (wie beispielsweise die &Uuml;bermittlung der Unterrichtsmaterialien per Post). Es muss gew&auml;hrleistet werden, dass wesentliche Informationen s&auml;mtliche Sch&uuml;lerinnen und Sch&uuml;ler erreichen, das hei&szlig;t auch diejenigen, die am Unterricht nicht in digitaler Form teilnehmen <u>k&ouml;nnen</u>.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-indent:-21.3pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">14. Folgen missbr&auml;uchlicher Nutzung</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Grunds&auml;tzlich gilt zum Schutz der Vertraulichkeit des Wortes das Verbot der unbefugten Aufnahme und der Verbreitung, beispielsweise, wenn solche Bild- und/oder Tonaufnahmen ins Internet gestellt werden oder per Messaging-App geteilt werden, und das Verbot des Mitschauens durch Dritte, wenn hierzu eine Erlaubnis nicht vorliegt.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Verst&ouml;&szlig;e k&ouml;nnen durch die vor&uuml;bergehende Sperrung des Accounts bis hin zum endg&uuml;ltigen Entzug des Zugangs zur Folge haben. Bei Sch&uuml;lerinnen und Sch&uuml;lern k&ouml;nnen zus&auml;tzliche Ordnungs- und Erziehungsma&szlig;nahmen eingeleitet werden und im Extremfall sogar zu einem Schulausschluss f&uuml;hren. Dies gilt auch f&uuml;r die missbr&auml;uchliche Nutzung dieser Anwendung entgegen dieser Nutzungsordnung oder der Anweisung durch die Lehrkraft im Unterricht.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Dar&uuml;ber hinaus sind bei Verletzung des allgemeinen Pers&ouml;nlichkeitsrechts durch unerlaubte Bildaufnahmen (&bdquo;Recht am eigenen Bild&ldquo;) zivilrechtliche Anspr&uuml;che m&ouml;glich, die gegebenenfalls geltend gemacht werden k&ouml;nnen. Werden gesetzliche Verst&ouml;&szlig;e von Sch&uuml;lerinnen und Sch&uuml;lern bekannt oder gemeldet, hat die jeweils zust&auml;ndige Lehrkraft die Inhalte zu sichern und den etwaigen Versto&szlig; zu pr&uuml;fen. Liegt aus Sicht der Lehrkraft ein gravierender Versto&szlig; vor, informiert sie die Schulleitung.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Der Schulleitung obliegen gegebenenfalls weitere Ma&szlig;nahmen.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">15. Informationen zur Verarbeitung der Daten von Nutzerinnen und Nutzern und deren Rechten</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Informationen zu den bei einer Nutzung von EKLARA verarbeiteten Daten sowie den damit verbundenen Rechten sind in der Datenschutzinformation f&uuml;r Sch&uuml;lerinnen und Sch&uuml;ler dargestellt.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-indent:-21.3pt;line-height:18.0pt;">
                            <strong>&nbsp;</strong>
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">16. Schlussbestimmungen</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Sollten einzelne Bestimmungen dieser Nutzungsordnung ganz oder teilweise unwirksam sein oder werden, ber&uuml;hrt dies nicht die Wirksamkeit der &uuml;brigen Bestimmungen.
                        </p>
                    </div>
                    <div v-else-if="accountRole === 'maintainer'">
                        <h3 class="d-flex flex-row justify-space-around align-center">
                            für Administratoren bei Anwendung der digitalen Anwendung EKLARA
                        </h3>
                        <br>
                        <p>
                            <b><ins>1. Vorwort</ins></b>
                        </p>
                        <p>
                            Diese Nutzungsordnung informiert Sie als
                            <b>Administratoren</b> über Pflichten und Handlungsleitlinien bei
                            Nutzung von EKLARA.
                        </p>

                        <br>
                        <p>
                            <b><ins>2. Geltungsbereich</ins></b>
                        </p>
                        <p>
                            Diese Nutzungsordnung regelt die schulische Nutzung von EKLARA
                            durch Administratoren.
                        </p>

                        <br>
                        <p>
                            <b><ins>3. Nutzungsberechtigte</ins></b>
                        </p>
                        <p>
                            Nutzungsberechtigte nach dieser Nutzungsordnung sind
                            Administratoren der Schule.
                        </p>

                        <br>
                        <p>
                            <b><ins>4. Zwecke der Nutzung</ins></b>
                        </p>
                        <p>
                            Durchführung des digitalen Unterrichts (synchron per
                            Videokonferenz und nicht synchron), per EKLARA-Plattform sowie der
                            schulbezogenen Kommunikation und Organisation zur Wahrnehmung des
                            staatlichen Bildungs- und Erziehungsauftrags insbesondere unter
                            dem Aspekt der Barrierefreiheit für Schülerinnen und Schüler der
                            sonderpädagogischen Bildungs- und Beratungszentren (auch Förder-
                            und Grundschulen genannt). Hierbei werden personenbezogene Daten
                            von SchülerInnen, Lehrkräften, Schulleitung/ Verwaltung,
                            Erziehungsberechtigen und Externen verarbeitet.
                        </p>
                        <br>
                        <p>
                            <b><ins>5. Normative Vorgaben für die Verarbeitung personenbezogener
                                Daten</ins></b>
                        </p>
                        <p><ins>Grundsätzliches</ins></p>
                        <p>
                            Auch im Rahmen der Nutzung von EKLARA müssen die geltenden
                            gesetzlichen Regelungen beachtet werden (unter anderem das
                            Datenschutzrecht, das Urheberrecht, das Strafrecht, das
                            bürgerliche Recht). Eine Gefährdung und Beeinträchtigung Dritter
                            (beispielsweise von Mitschülerinnen und Mitschülern) ist zu
                            unterlassen. Unzulässig ist ebenfalls die Weitergabe von Inhalten
                            (inklusive z.B. Screenshots von Chats), die den Nutzerinnen und
                            Nutzern über EKLARA zugänglich sind. Die Administratoren tragen
                            die Verantwortung für die Inhalte der von ihnen verarbeiteten
                            Dokumente. Die Administratoren sind nicht zu einer Übermittlung
                            von Daten aus dem Lernmanagementsystem an Dritte befugt. Das
                            bedeutet, personenbezogene Daten dürfen den Bereich einer Schule
                            nicht verlassen. Eine Veröffentlichung oder Verbreitung von Fotos
                            erfordert die Einwilligung des oder der Betroffenen/ Inhabers. Die
                            Administratoren klären im Rahmen von Handreichungen/ ggf.
                            Fortbildungen über die Pflichten und Handlungsleitlinien für die
                            Nutzung von EKLARA auf. Die hiesige Nutzungsordnung ist durch die
                            zuständige Schule an ihre NutzerInnen bekanntzugeben.
                        </p>
                        <p><ins>Verarbeitung personenbezogener Daten</ins></p>
                        <p>
                            Die Verarbeitung personenbezogener Daten findet gemäß der
                            Verwaltungsvorschrift des Kultusministeriums über den Datenschutz
                            an öffentlichen Schulen vom 4. Juli 2019 - Az.: 13-0557.0/106 -
                            statt (im Folgenden: Die Verwaltungsvorschrift). Personenbezogene
                            Daten dürfen mittels EKLARA verarbeitet werden, soweit dies für
                            die Aufgabenwahrnehmung der jeweiligen Schule zur
                            Unterrichtsvorbereitung, Unterrichtsdurchführung und
                            Unterrichtsnachbereitung erforderlich ist.
                        </p>
                        <br>
                        <p>
                            <b><ins>6. Umgang mit Zugangsdaten und Passwörtern</ins></b>
                        </p>
                        <p>
                            Administratoren erhalten zur Inbetriebnahme von EKLARA
                            individuelle Zugangsdaten zur Inbetriebnahme. Diese Zugangsdaten
                            sind vertraulich zu behandeln. Eine zusätzliche
                            2-Faktor-Authentifizierung (über Emailcode und/ oder zusätzlich
                            persönliche 4-Stellige-PIN) wird empfohlen. Die Nutzerin oder der
                            Nutzer ist selbst dafür verantwortlich, dass nur sie oder er
                            Zugriff auf beziehungsweise Kenntnis von ihren / seinen
                            Anmeldedaten hat. Sollten die eigenen Zugangsdaten durch ein
                            Versehen anderen Personen bekannt geworden sein, sind Sie
                            verpflichtet, sofort Maßnahmen zum Schutz des eigenen Zugangs zu
                            ergreifen. Falls noch möglich, sind Zugangspasswörter zu ändern.
                            Ist dies nicht möglich, ist die Schuladministration/
                            EKLARA-Dienstleister zu informieren. So können die Zugangsdaten
                            dann von den Zuständigen geändert oder erneuert werden. Bei einer
                            Verwendung von EKLARA über einen Webbrowser dürfen Kennwörter
                            <b>nicht im Webbrowser gespeichert</b> werden.
                        </p>
                        <br>
                        <p>
                            <b><ins>7. Verarbeitung personenbezogener Daten auf privaten
                                Datenverarbeitungsgeräten</ins></b>
                        </p>
                        <p>
                            Gemäß der Anlage 1 zur Verwaltungsvorschrift steht die
                            Verarbeitung personenbezogener Daten auf privaten Geräten durch
                            Administratoren unter dem Vorbehalt der Genehmigung durch die
                            Schulleitung und unterliegen den Regelungen der
                            Verwaltungsvorschrift zum möglichen Umfang der Verarbeitung
                            personenbezogener Daten auf privaten Geräten. Gemäß der
                            Verwaltungsvorschrift dürfen insbesondere besonders schutzwürdige
                            Daten, etwa über Krankheiten oder Erziehungs- und
                            Ordnungsmaßnahmen gegenüber Schülerinnen und Schülern,
                            <b>nicht auf dem privaten Datenverarbeitungsgerät gespeichert</b>
                            werden.
                        </p>
                        <br>
                        <p>
                            <b><ins>8. Speicherdauer und Pflichten der Nutzer/innen zur
                                Löschung</ins></b>
                        </p>
                        <p>
                            Gemäß der hiesigen Nutzungsordnung sind Sie verpflichtet,
                            personenbezogene Daten aus Ihrem Account nach den Vorgaben der
                            Verwaltungsvorschrift zu löschen. Hiernach sind zur
                            Aufgabenerfüllung der jeweiligen Schule verarbeitete
                            personenbezogene Daten insbesondere dann zu löschen, wenn deren
                            weitere Verarbeitung, insbesondere durch Speicherung, für die
                            Schule zur Erfüllung ihrer pädagogischen Aufgaben nicht mehr
                            erforderlich ist und es keinen weiteren Rechtsgrund für die
                            weitere Verarbeitung gibt. Die mögliche Vorhaltedauer und die
                            korrespondierende Verpflichtung zur Löschung verschiedener
                            Dokumentenkategorien können Sie Ziffer 2.5.3 der
                            Verwaltungsvorschrift entnehmen. Unabhängig von Ihrer
                            eigenverantwortlichen Löschung werden die eingestellten Inhalte,
                            Telemetrie- und Protokolldaten gemäß den Informationen in der
                            Datenschutzinformation für Administratoren gelöscht.
                        </p>
                        <br>
                        <p>
                            <b><ins>9. Schuladministration</ins></b>
                        </p>
                        <p>
                            Die Schulleitung benennt zur Administration von EKLARA schriftlich
                            die Administratoren sowie deren Aufgaben. Die Administratoren
                            dürfen Protokolldaten ausschließlich für die Systembetreuung
                            nutzen (siehe unten Ziffer 13). Eine Verhaltens- und
                            Leistungskontrolle der Lehrkräfte ist nicht zulässig (vgl. § 15
                            Absätze 5 und 7 Landesdatenschutzgesetz). Das Administrationskonto
                            ist für die Bereitstellung und Einstellungen von EKLARA auf
                            Schulebene vorgesehen und darf nicht für die unterrichtliche
                            Arbeit eingesetzt werden.
                        </p>
                        <br>
                        <p>
                            <b><ins>10. Kommunikation über EKLARA</ins></b>
                        </p>
                        <p>
                            Die Kommunikationsmöglichkeiten von EKLARA mit Ausnahme der
                            Videokonferenz, Chat, Schwarzes Brett, Elternbriefe und Pinwand
                            werden an der Schule nicht genutzt.
                        </p>
                        <br>
                        <p>
                            <b><ins>11. Kommunikation über Videokonferenz (BigBlueButton), Chat,
                                Elternbriefe, Schwarzes Brett und Pinnwand</ins></b>
                        </p>
                        <p>
                            <ins>Verbot von Aufzeichnungen und öffentlicher Teilnahme</ins>
                        </p>
                        <p>
                            Es ist grundsätzlich verboten, Gespräche, Texte, Inhalte und
                            Übertragungen mitzuschneiden, aufzuzeichnen oder zu speichern.
                            Dies gilt auch für die Anwendung jeder Art von Drittsoftware oder
                            beispielsweise Handycams. Der Mitschnitt einer Videokonferenz,
                            Chats, Mitteilungen von geteilten Inhalten kann rechtliche
                            Konsequenzen haben. Es ist unzulässig, dass Dritte (auch Eltern,
                            Freunde oder Geschwister) bei der Videokonferenz und im Chat, im
                            Schwarzen Brett und in der Pinwand zuhören, zusehen oder auf
                            andere Art und Weise Einblick in die Kommunikation erhalten.
                            Darüber hinaus ist die Nutzung in öffentlich zugänglichen Räumen
                            wie beispielsweise ein Café oder ein Restaurant untersagt, da
                            hierbei personenbezogene Daten durch unbeteiligte Dritte
                            wahrgenommen oder gar aufgezeichnet werden könnten. Auch die
                            Schulen dürfen keine Aufzeichnungen von Schülerinnen und Schülern
                            machen
                        </p>
                        <p><ins>Schutz der eigenen Privatsphäre</ins></p>
                        <p>
                            Wählen Sie einen passenden, möglichst neutralen Ort für die
                            Videokonferenz soweit eine Übertragung von Bild und Ton aus Ihrem
                            häuslichen Umfeld stattfindet. Auf diese Weise können Sie
                            vermeiden, dass andere Teilnehmerinnen und Teilnehmer Ihr privates
                            Umfeld im Hintergrund sehen können. Bestenfalls wählen Sie eine
                            aufgeräumte Arbeitsumgebung oder als neutralen Hintergrund eine
                            weiße Wand und nutzen Sie geschlossene Kopfhörer mit einem
                            integrierten Mikrofon bei einer Video- und Tonübertragung.
                        </p>
                        <br>
                        <p>
                            <b><ins>12. Nutzung des elektronischen Klassen- oder Tagebuchs
                                (ETB)</ins></b>
                        </p>
                        <p>
                            Beim ETB ist sicherzustellen, dass die Datensätze der Klassen- und
                            Kursbücher jederzeit an der Schule verfügbar sind. Es besteht die
                            Verpflichtung, in regelmäßigen Abständen die gespeicherten Inhalte
                            auszudrucken und in dieser Form zu archivieren. Für die
                            Authentifizierung ist neben Benutzername und Kennwort eine weitere
                            Authentifizierung nötig - die 2-Faktor-Authentifizierung (2FA).
                            Bei Verwendung einer 2-Faktor-Authentifizierung auf Basis von
                            E-Mails dürfen diese E-Mails nicht per Mail-Client auf dem Gerät
                            abgerufen werden, auf dem das E-Tagebuch genutzt wird. Bei Abruf
                            der E-Mails per Webbrowser auf dem gleichen Endgerät, dürfen die
                            Zugangsdaten nicht im Browser gespeichert werden. Die
                            Nutzerkennung und das Passwort des E-Mail-Accounts müssen sich von
                            den Zugangsdaten des E-Tagebuchs unterscheiden. Neben der 2FA kann
                            zusätzlich ein „Persönlicher PIN“ zum Schutz vergeben werden. Der
                            Verlust (auch bei Verdacht) des "persönlichen PINs" ist vom Nutzer
                            unverzüglich dem Schuladministrator anzuzeigen, damit er für die
                            Authentifizierung gesperrt und ein neuer generiert werden kann.
                        </p>
                        <br>
                        <p>
                            <b><ins>13. Nutzung im Rahmen des geltenden Rechts</ins></b>
                        </p>
                        <p>
                            Die Nutzung von EKLARA darf nur im Rahmen des geltenden Rechts
                            erfolgen. Zu beachten sind unter anderem das Jugendschutzrecht,
                            das Urheberrecht und das Datenschutzrecht. Pflichten, die sich aus
                            dem öffentlich-rechtlichen Dienstverhältnis ergeben, gelten auch
                            im Rahmen der hiesigen Nutzung.
                        </p>
                        <br>
                        <p>
                            <b><ins>14. Protokollierung von Aktivitäten und Überprüfung der
                                Kommunikation</ins></b>
                        </p>
                        <p>
                            Die Verarbeitung der in der Datenschutzinformation für
                            Administratoren dargestellten protokollierten Daten soll die
                            Funktionsfähigkeit der Anwendungen und Dienste gewährleisten. Dies
                            dient den Gewährleistungszielen der Datensicherheit und der
                            Datenverfügbarkeit. Dies umfasst die Zwecke der Gewährleistung
                            einer rechtskonformen Datenverarbeitung wie die Ermittlung von
                            schädigenden Maßnahmen und Missbrauchsversuchen. Der Zugriff auf
                            die protokollierten Daten findet systemseitig bei EKLARA statt.
                            Diese protokollierten Daten werden gespeichert und automatisiert
                            nach Ablauf einer erforderlichen Aufbewahrungsfrist von 30 Tagen
                            bei EKLARA gelöscht (Datensicherheit und Datenverfügbarkeit). Im
                            konkreten Verdachtsfall erfolgt die Speicherung solange, wie dies
                            zum Umgang mit etwaigen Missbrauchsfällen erforderlich ist. Siehe
                            zur Verarbeitung von protokollierten Daten auch § 12 Absatz 5 der
                            Rahmendienstvereinbarung zum Einsatz einer landeseinheitlichen
                            digitalen Bildungsplattform vom 06.02.2018 - AZ.: 53-6534.42/149
                            (im Folgenden: die Rahmendienstvereinbarung). Nur in begründeten
                            Fällen wie die missbräuchliche Nutzung werden diese
                            protokollierten Daten gegebenenfalls eingesehen und ausgewertet.
                            Falls es notwendig ist, wird auch die Polizei eingeschaltet. Der
                            Zugriff auf die Protokolldaten ist auf das technische Personal
                            begrenzt, das für den Netzbetrieb und die Bereitstellung der
                            verfügbaren Dienste zuständig ist. Die Einsicht in Protokolldaten
                            ist zweckgebunden. Eine Verhaltens- und Leistungskontrolle der
                            Lehrkräfte ist nicht zulässig (vgl. § 15 Absätze 5 und 7
                            Landesdatenschutzgesetz).
                        </p>
                        <br>
                        <p>
                            <b><ins>15. Dienstliche und Private Nutzung</ins></b>
                        </p>
                        <p>
                            Eine private Nutzung von EKLARA ist ausgeschlossen. Die Schule
                            unterliegt daher nicht den Pflichten eines Dienstanbieters im
                            Sinne des Telekommunikationsgesetzes (TKG).
                        </p>
                        <br>
                        <p>
                            <b><ins>16. Folgen missbräuchlicher Nutzung</ins></b>
                        </p>
                        <p>
                            Grundsätzlich gilt zum Schutz der Vertraulichkeit des Wortes das
                            Verbot der unbefugten Aufnahme und der Verbreitung (§ 201
                            Strafgesetzbuch), beispielsweise, wenn solche Bild- und/oder
                            Tonaufnahmen ins Internet gestellt werden oder per Messaging-App
                            geteilt werden, und das Verbot des Mitschauens durch Dritte, wenn
                            hierzu eine Erlaubnis nicht vorliegt. Verstöße können durch die
                            vorübergehende Sperrung des Accounts bis hin zum endgültigen
                            Entzug des Zugangs zur Folge haben. Darüber hinaus sind bei
                            Verletzung des allgemeinen Persönlichkeitsrechts durch unerlaubte
                            Bildaufnahmen („Recht am eigenen Bild“) zivilrechtliche Ansprüche
                            möglich, die gegebenenfalls geltend gemacht werden können. Der
                            Schulleitung/ Administratoren obliegen gegebenenfalls weitere
                            Maßnahmen.
                        </p>
                        <br>
                        <p>
                            <b><ins>17. Informationen zur Verarbeitung der Daten von NutzerInnen
                                und deren Rechten</ins></b>
                        </p>
                        <p>
                            Informationen zu den bei einer Nutzung von EKLARA verarbeiteten
                            Daten sowie den damit verbundenen Rechten sind in der
                            Datenschutzinformation für Administratoren dargestellt.
                        </p>
                        <br>
                        <p>
                            <b><ins>18. Schlussbestimmungen</ins></b>
                        </p>
                        <p>
                            Sollten einzelne Bestimmungen dieser Nutzungsordnung ganz oder
                            teilweise unwirksam sein oder werden, berührt dies nicht die
                            Wirksamkeit der übrigen Bestimmungen.
                        </p>
                    </div>
                    <div v-else-if="accountRole === 'parent'">
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-align:center;line-height:18.0pt;">
                            <strong>f&uuml;r Erziehungsberechtigte von Sch&uuml;lerinnen und Sch&uuml;lern bei</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-align:center;line-height:18.0pt;">
                            <strong>Anwendung der digitalen Anwendung EKLARA&nbsp;</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <strong>&nbsp;</strong>
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">1. Vorwort</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Diese Nutzungsordnung informiert Sie als <strong>Erziehungsberechtigte von</strong> <strong>Sch&uuml;lerinnen und Sch&uuml;ler</strong> &uuml;ber Pflichten und Handlungsleitlinien bei Nutzung von EKLARA.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">2. Geltungsbereich</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Diese Nutzungsordnung regelt die schulische Nutzung von EKLARA durch&nbsp;<strong>Erziehungsberechtigte von</strong> <strong>Sch&uuml;lerinnen und Sch&uuml;ler.</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-indent:-21.3pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">3. Nutzungsberechtigte</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Nutzungsberechtigte nach dieser Nutzungsordnung sind&nbsp;<strong>Erziehungsberechtigte von</strong> <strong>Sch&uuml;lerinnen und Sch&uuml;ler</strong> der Schule.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-indent:-21.3pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">4. Zwecke der Nutzung</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
                            Durchf&uuml;hrung des digitalen Unterrichts (synchron per Videokonferenz und nicht synchron), per EKLARA-Plattform sowie der schulbezogenen Kommunikation und Organisation zur Wahrnehmung des staatlichen Bildungs- und Erziehungsauftrags insbesondere unter dem Aspekt der Barrierefreiheit f&uuml;r Sch&uuml;lerinnen und Sch&uuml;ler der sonderp&auml;dagogischen Bildungs- und Beratungszentren (auch F&ouml;rder- und Grundschulen genannt). Hierbei werden personenbezogene Daten von Sch&uuml;lerInnen, Lehrkr&auml;ften, Schulleitung/ Verwaltung, Erziehungsberechtigen und Externen verarbeitet.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-indent:-21.3pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">5. Normative Vorgaben f&uuml;r die Nutzung von EKLARA</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Auch im Rahmen der Nutzung von EKLARA m&uuml;ssen die geltenden gesetzlichen Regelungen beachtet werden (unter anderem das Datenschutzrecht, das Urheberrecht, das Strafrecht, das b&uuml;rgerliche Recht). Eine Gef&auml;hrdung und Beeintr&auml;chtigung Dritter (beispielsweise Erziehungsberechtigte von Mitsch&uuml;lerinnen und Mitsch&uuml;lern, Klassenlehrkr&auml;fte) ist zu unterlassen.&nbsp;Unzul&auml;ssig ist ebenfalls die Weitergabe von Inhalten (inklusive z.B. Screenshots von Chats), die den Nutzerinnen und Nutzern &uuml;ber EKLARA zug&auml;nglich sind.&nbsp;<strong>Erziehungsberechtigte von</strong> <strong>Sch&uuml;lerinnen und Sch&uuml;ler</strong> tragen die Verantwortung f&uuml;r die Inhalte der von ihnen verarbeiteten Inhalte.&nbsp;<strong>Erziehungsberechtigte von</strong> <strong>Sch&uuml;lerinnen und Sch&uuml;ler</strong> sind nicht zu einer &Uuml;bermittlung von Daten aus dem Lernmanagementsystem an Dritte befugt. Das bedeutet, personenbezogene Daten d&uuml;rfen den Bereich einer Schule nicht verlassen.&nbsp;Eine Ver&ouml;ffentlichung oder Verbreitung von Fotos erfordert die Einwilligung des oder der Betroffenen/ Inhabers.&nbsp;Die Verwaltung ggf. Klassenlehrkr&auml;fte unterrichten&nbsp;<strong>Erziehungsberechtigte von</strong> <strong>Sch&uuml;lerinnen und Sch&uuml;ler</strong> &uuml;ber die Pflichten und Handlungsleitlinien f&uuml;r die Nutzung von EKLARA auf. Die hiesige Nutzungsordnung ist durch die zust&auml;ndige Schule an ihre&nbsp;<strong>Erziehungsberechtigte von</strong> <strong>Sch&uuml;lerinnen und Sch&uuml;ler</strong> bekanntzugeben.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">6. Umgang mit Zugangsdaten und Passw&ouml;rtern</span></u></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <strong>Erziehungsberechtigte von</strong> <strong>Sch&uuml;lerinnen und Sch&uuml;ler</strong> erhalten zur Inbetriebnahme von EKLARA individuelle Zugangsdaten zur Inbetriebnahme. Die Zugangsdaten werden durch den &bdquo;EKLARA-Schulausweis&ldquo; mit QR-Code zur Verf&uuml;gung gestellt. Diese Zugangsdaten sind vertraulich zu behandeln. Es wird abgeraten die Anmeldung &uuml;ber den QR-Code durchzuf&uuml;hren (Sonderf&auml;lle &ndash; bei u.a. Lese- und Schreibschw&auml;chen). Entsprechend ist hier die QR-Code-Anmeldung durch die Verwaltung/ EKLARA-Administrator deaktiviert. Bei der Aktivierung der QR-Code-Ausweis-Funktion ist eine zus&auml;tzliche 2-Faktor-Authentifizierung (&uuml;ber Emailcode und/ oder pers&ouml;nliche 4-Stellige-PIN) erforderlich.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Wer fremde Zugangsdaten in Form des &bdquo;EKLARA-Schulausweis&ldquo; erf&auml;hrt (z.B. durch Verlust, Vervielf&auml;ltigung, etc.) ist verpflichtet, <strong>dies umgehend der Schulleitung, Verwaltung ggf. der Klassenlehrkraft</strong> mitzuteilen. Sollten die eigenen Zugangsdaten durch ein Versehen anderen Personen bekannt geworden sein, sind Sie verpflichtet, sofort Ma&szlig;nahmen zum Schutz des eigenen Zugangs zu ergreifen. Falls noch m&ouml;glich, sind Zugangspassw&ouml;rter zu &auml;ndern. Ist dies nicht m&ouml;glich, ist die Schulleitung ggf. Klassenlehrkr&auml;fte zu informieren. So k&ouml;nnen die Zugangsdaten/ QR-Ausweis von den Zust&auml;ndigen ge&auml;ndert oder erneuert werden. Bei einer Verwendung von EKLARA &uuml;ber einen Webbrowser d&uuml;rfen Kennw&ouml;rter <strong>nicht im Webbrowser gespeichert</strong> werden.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">7. Speicherdauer und L&ouml;schung personenbezogener Daten</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Unabh&auml;ngig von Ihrer eigenverantwortlichen L&ouml;schung personenbezogener Daten werden die eingestellten Inhalte, Telemetrie- und Protokolldaten gem&auml;&szlig; den Informationen in der Datenschutzinformation f&uuml;r&nbsp;<strong>Erziehungsberechtigte von</strong> <strong>Sch&uuml;lerinnen und Sch&uuml;ler</strong> gel&ouml;scht.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-indent:-21.3pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">8. Schuladministration</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Zur Administration von EKLARA benennt die Schulleiterin oder der Schulleiter schriftlich die Administratoren sowie deren Aufgaben. Die Administratoren d&uuml;rfen Protokolldaten ausschlie&szlig;lich f&uuml;r die Systembetreuung nutzen.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <br>
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">9 Kommunikation &uuml;ber EKLARA</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            Die Kommunikationsm&ouml;glichkeiten von EKLARA mit Ausnahme der Videokonferenz, Chat, Schwarzes Brett, Elternbriefe und Pinwand werden an der Schule nicht genutzt.&nbsp;
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <strong>Erziehungsberechtigte von</strong> <strong>Sch&uuml;lerinnen und Sch&uuml;ler</strong> haben sich an die unter Ziffer 5 erl&auml;uterten normativen Vorgaben beim Versenden von Nachrichten zu halten, gesetzliche Vorschriften zu beachten sowie die Rechte ihrer Mitmenschen zu respektieren.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">10. Kommunikation &uuml;ber Videokonferenz (BigBlueButton), Chat, Elternbriefe, Schwarzes Brett und Pinnwand&nbsp;</span></u></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <u>Verbot von Aufzeichnungen und &ouml;ffentlicher Teilnahme</u>
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Es ist grunds&auml;tzlich verboten, Gespr&auml;che, Texte, Inhalte und &Uuml;bertragungen mitzuschneiden, aufzuzeichnen oder zu speichern. Dies gilt auch f&uuml;r die Anwendung jeder Art von Drittsoftware oder beispielsweise Handycams. Der Mitschnitt einer Videokonferenz, Chats, Mitteilungen von geteilten Inhalten kann rechtliche Konsequenzen haben. Es ist unzul&auml;ssig, dass Dritte (auch Eltern, Freunde oder Geschwister) bei der Videokonferenz und im Chat, im Schwarzen Brett und in der Pinwand zuh&ouml;ren, zusehen oder auf andere Art und Weise Einblick in die Kommunikation erhalten. Dar&uuml;ber hinaus ist die Nutzung in &ouml;ffentlich zug&auml;nglichen R&auml;umen wie beispielsweise ein Caf&eacute; oder ein Restaurant untersagt, da hierbei personenbezogene Daten durch unbeteiligte Dritte wahrgenommen oder gar aufgezeichnet werden k&ouml;nnten. Auch die Schulen d&uuml;rfen keine Aufzeichnungen von Sch&uuml;lerinnen und Sch&uuml;lern machen.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <u>Schutz der eigenen Privatsph&auml;re</u>
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            W&auml;hlen Sie einen passenden, m&ouml;glichst neutralen Ort f&uuml;r die Videokonferenz soweit eine &Uuml;bertragung von Bild und Ton aus Ihrem h&auml;uslichen Umfeld stattfindet. Auf diese Weise k&ouml;nnen Sie vermeiden, dass andere Teilnehmerinnen und Teilnehmer Ihr privates Umfeld im Hintergrund sehen k&ouml;nnen. Bestenfalls w&auml;hlen Sie eine aufger&auml;umte Arbeitsumgebung oder als neutralen Hintergrund eine wei&szlig;e Wand und nutzen Sie geschlossene Kopfh&ouml;rer mit einem integrierten Mikrofon bei einer Video- und Ton&uuml;bertragung.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">11. Protokollierung von Aktivit&auml;ten und &Uuml;berpr&uuml;fung der Kommunikation</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Die Verarbeitung der in der Datenschutzinformation f&uuml;r Erziehungsberechtigte dargestellten protokollierten Daten soll die Funktionsf&auml;higkeit der Anwendungen und Dienste gew&auml;hrleisten. Dies dient den Gew&auml;hrleistungszielen der Datensicherheit und der Datenverf&uuml;gbarkeit. Dies umfasst die Zwecke der Gew&auml;hrleistung einer rechtskonformen Datenverarbeitung wie die Ermittlung von sch&auml;digenden Ma&szlig;nahmen und Missbrauchsversuchen. Der Zugriff auf die protokollierten Daten findet systemseitig bei EKLARA statt. Diese protokollierten Daten werden gespeichert und automatisiert nach Ablauf einer erforderlichen Aufbewahrungsfrist von 30 Tagen bei EKLARA gel&ouml;scht (Datensicherheit und Datenverf&uuml;gbarkeit). Im konkreten Verdachtsfall erfolgt die Speicherung solange, wie dies zum Umgang mit etwaigen Missbrauchsf&auml;llen erforderlich ist. Nur in begr&uuml;ndeten F&auml;llen wie die missbr&auml;uchliche Nutzung werden diese protokollierten Daten gegebenenfalls eingesehen und ausgewertet. Falls es notwendig ist, wird auch die Polizei eingeschaltet. Der Zugriff auf die Protokolldaten ist auf das technische Personal begrenzt, das f&uuml;r den Netzbetrieb und die Bereitstellung der verf&uuml;gbaren Dienste zust&auml;ndig ist. Die Einsicht in Protokolldaten ist zweckgebunden.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">12. Private Nutzung</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Die Nutzung der EKLARA Plattform erfolgt seitens der Erziehungsberechtigten in privater Nutzung.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">13. Alternative Kommunikationsm&ouml;glichkeiten f&uuml;r Erziehungsberechtigte</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Lehrkr&auml;fte m&uuml;ssen gew&auml;hrleisten, dass den an der Kommunikation via EKLARA zu beteiligenden&nbsp;<strong>Erziehungsberechtigten von</strong> <strong>Sch&uuml;lerinnen und Sch&uuml;ler</strong> keine Nachteile entstehen. Somit m&uuml;ssen - sofern&nbsp;<strong>Erziehungsberechtigte von</strong> <strong>Sch&uuml;lerinnen und Sch&uuml;ler&nbsp;</strong>keinen Zugang zu einem Digitalen Endger&auml;t besitzt oder &uuml;ber keine (ausreichende) Internetanbindung im Falle der Kommunikation von zu Hause verf&uuml;gt - alternative M&ouml;glichkeiten zur Kommunikation durch die Lehrkraft er&ouml;ffnet werden (wie beispielsweise die &Uuml;bermittlung von Elternbriefen per Post). Es muss gew&auml;hrleistet werden, dass wesentliche Informationen&nbsp;<strong>Erziehungsberechtigte von</strong> <strong>Sch&uuml;lerinnen und Sch&uuml;ler</strong> erreichen, das hei&szlig;t auch diejenigen, die an der Kommunikation nicht in digitaler Form teilnehmen <u>k&ouml;nnen</u>.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-indent:-21.3pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">14. Folgen missbr&auml;uchlicher Nutzung</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Grunds&auml;tzlich gilt zum Schutz der Vertraulichkeit des Wortes das Verbot der unbefugten Aufnahme und der Verbreitung, beispielsweise, wenn solche Bild- und/oder Tonaufnahmen ins Internet gestellt werden oder per Messaging-App geteilt werden, und das Verbot des Mitschauens durch Dritte, wenn hierzu eine Erlaubnis nicht vorliegt.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Verst&ouml;&szlig;e k&ouml;nnen durch die vor&uuml;bergehende Sperrung des Accounts bis hin zum endg&uuml;ltigen Entzug des Zugangs zur Folge haben.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Dar&uuml;ber hinaus sind bei Verletzung des allgemeinen Pers&ouml;nlichkeitsrechts durch unerlaubte Bildaufnahmen (&bdquo;Recht am eigenen Bild&ldquo;) zivilrechtliche Anspr&uuml;che m&ouml;glich, die gegebenenfalls geltend gemacht werden k&ouml;nnen. Werden gesetzliche Verst&ouml;&szlig;e von&nbsp;<strong>Erziehungsberechtigten von</strong> <strong>Sch&uuml;lerinnen und Sch&uuml;ler</strong> bekannt oder gemeldet, hat die jeweils zust&auml;ndige Lehrkraft die Inhalte zu sichern und den etwaigen Versto&szlig; zu pr&uuml;fen. Liegt aus Sicht der Lehrkraft ein gravierender Versto&szlig; vor, informiert sie die Schulleitung.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Der Schulleitung obliegen gegebenenfalls weitere Ma&szlig;nahmen.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">15. Informationen zur Verarbeitung der Daten von Nutzerinnen und Nutzern und deren Rechten</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Informationen zu den bei einer Nutzung von EKLARA verarbeiteten Daten sowie den damit verbundenen Rechten sind in der Datenschutzinformation&nbsp;<strong>Erziehungsberechtigter von</strong> <strong>Sch&uuml;lerinnen und Sch&uuml;ler</strong> dargestellt.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-indent:-21.3pt;line-height:18.0pt;">
                            <strong>&nbsp;</strong>
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">16. Schlussbestimmungen</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Sollten einzelne Bestimmungen dieser Nutzungsordnung ganz oder teilweise unwirksam sein oder werden, ber&uuml;hrt dies nicht die Wirksamkeit der &uuml;brigen Bestimmungen.
                        </p>
                    </div>
                    <div v-else-if="usage === 'extern'">
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-align:center;line-height:18.0pt;">
                            <strong>f&uuml;r Externe bei</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-align:center;line-height:18.0pt;">
                            <strong>Anwendung der digitalen Anwendung EKLARA&nbsp;</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <strong>&nbsp;</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <strong>&nbsp;</strong>
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">1 Vorwort</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Diese Nutzungsordnung informiert Sie als <strong>Externe/r</strong> &uuml;ber Pflichten und Handlungsleitlinien bei Nutzung von EKLARA.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">2. Geltungsbereich</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Diese Nutzungsordnung regelt die schulische Nutzung von EKLARA durch&nbsp;<strong>Externe</strong><strong>.</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-indent:-21.3pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">3. Nutzungsberechtigte</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Nutzungsberechtigte nach dieser Nutzungsordnung sind&nbsp;<strong>Externe</strong> der Schule.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-indent:-21.3pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">4. Zwecke der Nutzung</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
                            Durchf&uuml;hrung des digitalen Unterrichts (synchron per Videokonferenz und nicht synchron), per EKLARA-Plattform sowie der schulbezogenen Kommunikation und Organisation zur Wahrnehmung des staatlichen Bildungs- und Erziehungsauftrags insbesondere unter dem Aspekt der Barrierefreiheit f&uuml;r Sch&uuml;lerinnen und Sch&uuml;ler der sonderp&auml;dagogischen Bildungs- und Beratungszentren (auch F&ouml;rder- und Grundschulen genannt). Hierbei werden personenbezogene Daten von Sch&uuml;lerInnen, Lehrkr&auml;ften, Schulleitung/ Verwaltung, Erziehungsberechtigen und Externen verarbeitet.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-indent:-21.3pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">5. Normative Vorgaben f&uuml;r die Nutzung von EKLARA</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Auch im Rahmen der Nutzung von EKLARA m&uuml;ssen die geltenden gesetzlichen Regelungen beachtet werden (unter anderem das Datenschutzrecht, das Urheberrecht, das Strafrecht, das b&uuml;rgerliche Recht). Eine Gef&auml;hrdung und Beeintr&auml;chtigung Dritter (beispielsweise Erziehungsberechtigte von Mitsch&uuml;lerinnen und Mitsch&uuml;lern, Klassenlehrkr&auml;fte, Externe) ist zu unterlassen.&nbsp;Unzul&auml;ssig ist ebenfalls die Weitergabe von Inhalten (inklusive z.B. Screenshots von Chats), die den Nutzerinnen und Nutzern &uuml;ber EKLARA zug&auml;nglich sind.&nbsp;<strong>Externe</strong> tragen die Verantwortung f&uuml;r die Inhalte der von ihnen verarbeiteten Inhalte.&nbsp;<strong>Externe</strong> sind nicht zu einer &Uuml;bermittlung von Daten aus dem Lernmanagementsystem an Dritte befugt. Das bedeutet, personenbezogene Daten d&uuml;rfen den Bereich einer Schule nicht verlassen.&nbsp;Eine Ver&ouml;ffentlichung oder Verbreitung von Fotos findet nur mit Einwilligung des oder der Betroffenen statt.&nbsp;Die Verwaltung ggf. Klassenlehrkr&auml;fte unterrichten&nbsp;<strong>Externe</strong> &uuml;ber die Pflichten und Handlungsleitlinien f&uuml;r die Nutzung von EKLARA auf. Die hiesige Nutzungsordnung ist durch die zust&auml;ndige Schule an ihre&nbsp;<strong>Externe</strong> bekanntzugeben.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">6. Umgang mit Zugangsdaten und Passw&ouml;rtern</span></u></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <strong>Externe</strong> erhalten zur Inbetriebnahme von EKLARA (hier BigBlueButton) individuelle Zugangsdaten in Form eines Links zur Inbetriebnahme. Die Zugangsdaten/ der Link werden durch eine Lehrkraft/ Mitarbeiter der Bildungseinrichtung.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Diese Zugangsdaten/ Link sind vertraulich zu behandeln. Wer fremde Zugangsdaten/ Link erf&auml;hrt (z.B. durch Weiterleitung, Hacking, Fishing etc.) ist verpflichtet, dies umgehend der Schulleitung, Verwaltung ggf. Klassenlehrkr&auml;fte, Mitarbeiter der Bildungseinrichtung mitzuteilen. Sollten die eigenen Zugangsdaten durch ein Versehen anderen Personen bekannt geworden sein, sind Sie verpflichtet, sofort Ma&szlig;nahmen zum Schutz des eigenen Zugangs/ Links zu ergreifen.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">7. Speicherdauer und L&ouml;schung personenbezogener Daten</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Unabh&auml;ngig von Ihrer eigenverantwortlichen L&ouml;schung personenbezogener Daten werden die eingestellten Inhalte, Telemetrie- und Protokolldaten gem&auml;&szlig; den Informationen in der Datenschutzinformation f&uuml;r&nbsp;<strong>Externe</strong> gel&ouml;scht.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-indent:-21.3pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">8. Schuladministration</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Zur Administration von EKLARA benennt die Schulleiterin oder der Schulleiter schriftlich die Administratoren sowie deren Aufgaben. Die Administratoren d&uuml;rfen Protokolldaten ausschlie&szlig;lich f&uuml;r die Systembetreuung nutzen.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <br>
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">9. Kommunikation &uuml;ber EKLARA</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            Die Kommunikationsm&ouml;glichkeiten von EKLARA mit Ausnahme der Videokonferenz, werden an der Schule nicht genutzt.&nbsp;<strong>Externe</strong> haben sich an die unter Ziffer 5 erl&auml;uterten normativen Vorgaben beim Versenden von Nachrichten zu halten, gesetzliche Vorschriften zu beachten sowie die Rechte ihrer Mitmenschen zu respektieren.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">10. Kommunikation &uuml;ber Videokonferenz (BigBlueButton)</span></u></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <u>Verbot von Aufzeichnungen und &ouml;ffentlicher Teilnahme</u>
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Es ist grunds&auml;tzlich verboten, Gespr&auml;che, Texte, Inhalte und &Uuml;bertragungen mitzuschneiden, aufzuzeichnen oder zu speichern. Dies gilt auch f&uuml;r die Anwendung jeder Art von Drittsoftware oder beispielsweise Handycams. Der Mitschnitt einer Videokonferenz, Chats, Mitteilungen von geteilten Inhalten kann rechtliche Konsequenzen haben. Es ist unzul&auml;ssig, dass Dritte (auch u.a. Eltern, Freunde oder Geschwister) bei der Videokonferenz zuh&ouml;ren, zusehen oder auf andere Art und Weise Einblick in die Kommunikation erhalten. Dar&uuml;ber hinaus ist die Nutzung in &ouml;ffentlich zug&auml;nglichen R&auml;umen wie beispielsweise ein Caf&eacute; oder ein Restaurant untersagt, da hierbei personenbezogene Daten durch unbeteiligte Dritte wahrgenommen oder gar aufgezeichnet werden k&ouml;nnten. Auch die Schulen d&uuml;rfen keine Aufzeichnungen von Sch&uuml;lerinnen und Sch&uuml;lern machen.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <u>Schutz der eigenen Privatsph&auml;re</u>
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            W&auml;hlen Sie einen passenden, m&ouml;glichst neutralen Ort f&uuml;r die Videokonferenz soweit eine &Uuml;bertragung von Bild und Ton aus Ihrem h&auml;uslichen Umfeld stattfindet. Auf diese Weise k&ouml;nnen Sie vermeiden, dass andere Teilnehmerinnen und Teilnehmer Ihr privates Umfeld im Hintergrund sehen k&ouml;nnen. Bestenfalls w&auml;hlen Sie eine aufger&auml;umte Arbeitsumgebung oder als neutralen Hintergrund eine wei&szlig;e Wand und nutzen Sie geschlossene Kopfh&ouml;rer mit einem integrierten Mikrofon bei einer Video- und Ton&uuml;bertragung.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">11. Protokollierung von Aktivit&auml;ten und &Uuml;berpr&uuml;fung der Kommunikation</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Die Verarbeitung der in der Datenschutzinformation f&uuml;r Externe dargestellten protokollierten Daten soll die Funktionsf&auml;higkeit der Anwendungen und Dienste gew&auml;hrleisten. Dies dient den Gew&auml;hrleistungszielen der Datensicherheit und der Datenverf&uuml;gbarkeit. Dies umfasst die Zwecke der Gew&auml;hrleistung einer rechtskonformen Datenverarbeitung wie die Ermittlung von sch&auml;digenden Ma&szlig;nahmen und Missbrauchsversuchen. Der Zugriff auf die protokollierten Daten findet systemseitig bei EKLARA statt. Diese protokollierten Daten werden gespeichert und automatisiert nach Ablauf einer erforderlichen Aufbewahrungsfrist von 30 Tagen bei EKLARA gel&ouml;scht (Datensicherheit und Datenverf&uuml;gbarkeit). Im konkreten Verdachtsfall erfolgt die Speicherung solange, wie dies zum Umgang mit etwaigen Missbrauchsf&auml;llen erforderlich ist. Nur in begr&uuml;ndeten F&auml;llen wie die missbr&auml;uchliche Nutzung werden diese protokollierten Daten gegebenenfalls eingesehen und ausgewertet. Falls es notwendig ist, wird auch die Polizei eingeschaltet. Der Zugriff auf die Protokolldaten ist auf das technische Personal begrenzt, das f&uuml;r den Netzbetrieb und die Bereitstellung der verf&uuml;gbaren Dienste zust&auml;ndig ist. Die Einsicht in Protokolldaten ist zweckgebunden.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">12. Dienstliche und private Nutzung</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Die Nutzung der EKLARA Plattform erfolgt seitens Externer in privater Nutzung.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">13. Alternative Kommunikationsm&ouml;glichkeiten&nbsp;</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Externe m&uuml;ssen gew&auml;hrleisten, dass den an der Kommunikation via EKLARA zu beteiligenden&nbsp;<strong>Externen</strong> keine Nachteile entstehen. Somit m&uuml;ssen - sofern&nbsp;<strong>Externe</strong><strong>&nbsp;</strong>keinen Zugang zu einem Digitalen Endger&auml;t besitzt oder &uuml;ber keine (ausreichende) Internetanbindung im Falle der Kommunikation von zu Hause verf&uuml;gt - alternative M&ouml;glichkeiten zur Kommunikation durch die Lehrkraft/ Mitarbeiter der Bildungseinrichtung er&ouml;ffnet werden (wie beispielsweise Telefon, oder die &Uuml;bermittlung von Briefen per Post, ggf. Email). Es muss gew&auml;hrleistet werden, dass wesentliche Informationen&nbsp;<strong>Externe</strong> erreichen, das hei&szlig;t auch diejenigen, die an der Kommunikation nicht in digitaler Form teilnehmen <u>k&ouml;nnen</u>.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-indent:-21.3pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">14. Folgen missbr&auml;uchlicher Nutzung</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Grunds&auml;tzlich gilt zum Schutz der Vertraulichkeit des Wortes das Verbot der unbefugten Aufnahme und der Verbreitung, beispielsweise, wenn solche Bild- und/oder Tonaufnahmen ins Internet gestellt werden oder per Messaging-App geteilt werden, und das Verbot des Mitschauens durch Dritte, wenn hierzu eine Erlaubnis nicht vorliegt.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Verst&ouml;&szlig;e k&ouml;nnen durch die vor&uuml;bergehende Sperrung des Accounts bis hin zum endg&uuml;ltigen Entzug des Zugangs zur Folge haben.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Dar&uuml;ber hinaus sind bei Verletzung des allgemeinen Pers&ouml;nlichkeitsrechts durch unerlaubte Bildaufnahmen (&bdquo;Recht am eigenen Bild&ldquo;) zivilrechtliche Anspr&uuml;che m&ouml;glich, die gegebenenfalls geltend gemacht werden k&ouml;nnen. Werden gesetzliche Verst&ouml;&szlig;e von&nbsp;<strong>Externen</strong> bekannt oder gemeldet, hat die jeweils zust&auml;ndige Lehrkraft/ Mitarbeiter der Bildungseinrichtung die Inhalte zu sichern und den etwaigen Versto&szlig; zu pr&uuml;fen. Liegt aus Sicht der Lehrkraft/ Mitarbeiter der Bildungseinrichtung ein gravierender Versto&szlig; vor, informiert sie die Schulleitung.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Der Schulleitung obliegen gegebenenfalls weitere Ma&szlig;nahmen.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">15. Informationen zur Verarbeitung der Daten von Nutzerinnen und Nutzern und deren Rechten</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Informationen zu den bei einer Nutzung von EKLARA verarbeiteten Daten sowie den damit verbundenen Rechten sind in der Datenschutzinformation&nbsp;<strong>Externe</strong> dargestellt.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;text-indent:-21.3pt;line-height:18.0pt;">
                            <strong>&nbsp;</strong>
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><u><span style="font-size:16px;">16. Schlussbestimmungen</span></u></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Sollten einzelne Bestimmungen dieser Nutzungsordnung ganz oder teilweise unwirksam sein oder werden, ber&uuml;hrt dies nicht die Wirksamkeit der &uuml;brigen Bestimmungen.
                        </p>
                    </div>
                    <hr class="my-4">
                    <h3
                        id="Datenschutz"
                        class="d-flex flex-row justify-space-around align-center"
                    >
                        Datenschutzinformation
                    </h3>
                    <p class="d-flex flex-row justify-space-around align-center">
                        gemäß Artikel 13 Datenschutzgrundverordnung (DSGVO) für die
                        schulische Nutzung des Dienstes EKALRA durch
                    </p>
                    <b class="d-flex flex-row justify-space-around align-center">
                        {{
                            accountRole === "teacher"
                                ? "Lehrkräfte"
                                : accountRole === "parent"
                                    ? "Erziehungsberechtigte von Schülerinnen und Schülern"
                                    : accountRole === "maintainer"
                                        ? "Administratoren "
                                        : usage === "extern" 
                                            ? "Externe"
                                            : "Schülerinnen und Schüler"
                        }}
                    </b>
                    <br>
                    <p>
                        Die Datenschutzinformation informiert Sie als von der Verarbeitung
                        personenbezogener Daten betroffene Person
                        <b>{{
                            accountRole === "teacher"
                                ? "(Lehrkraft) "
                                : accountRole === "parent"
                                    ? "(Erziehungsberechtigte von Schülerinnen und Schülern) "
                                    : accountRole === "maintainer"
                                        ? "(Administratoren) "
                                        : usage === "extern" 
                                            ? "(Externe) "
                                            : "(Schülerin oder Schüler) "
                        }}</b>
                        unter anderem darüber, welche Daten verarbeitet werden, welchen
                        Zwecken diese Datenverarbeitung dient, auf welchen Rechtsgrundlagen
                        sie beruht und welche Rechte Sie haben.
                    </p>
                    <br>
                    <i>
                        (Informationen, die der betroffenen Person zum Zeitpunkt der
                        Erhebung gem. Art. 13 Abs. 1 DSGVO mitgeteilt werden müssen)</i>

                    <p>
                        <b>1. Name und Kontaktdaten des / der Verantwortlichen
                            (verantwortliche Schulleitung)</b>
                    </p>
                    <p v-if="school && school.principal !== ''">
                        {{ school.principal }}
                    </p>
                    <br>
                    <p>
                        <b>2. Kontaktdaten des / der Datenschutzbeauftragten
                            (Datenschutzbeauftragte/r der verantwortlichen Schule)</b>
                    </p>
                    <p v-if="school && school.schoolDataSecurityOfficer !== ''">
                        {{ school.schoolDataSecurityOfficer }}
                    </p>
                    <br>
                    <div v-if="accountRole === 'parent'">
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">3. Personenbezogene Daten, die verarbeitet werden.</span></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:36.0pt;line-height:18.0pt;">
                            Wenn Sie EKLARA nutzen werden die folgenden Kategorien Ihrer personenbezogenen Daten verarbeitet.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:36.0pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
                            <strong><span style="line-height:150%;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Anmeldedaten</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Zur Verwaltung der Lizenzen und von Rechten und Rollen notwendige Daten:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:71.4pt;line-height:150%;">
                            <span style="line-height:150%;">&nbsp;</span>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Erziehungsberechtigte von Sch&uuml;lerInnen</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Nutzername, Passwort, optional - ggf. nutzerbezogener QR-Code
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Zuweisung einer neuen Funktion &ndash; mit Nutzername
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Zuordnung zu einer Nutzergruppe, od. Klasse mit Nutzername
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
                            <span style="line-height:150%;">&nbsp;</span>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong><span style="line-height:150%;">Generierte Inhaltsdaten</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Zur Teilnahme und Durchf&uuml;hrung des Unterrichts/ Bildungsauftrags
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            <u><span style="line-height:150%;"><span style="text-decoration: none;">&nbsp;</span></span></u>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.9pt;text-indent:-.5pt;line-height:150%;">
                            <strong>Kommunikation&nbsp;</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Elternbriefe &ndash; Mitteilungen (Texte, Anh&auml;nge, Dateien, Nutzerzuordnung - Lehrkraft/ Verwaltung)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Chatnachrichten (Text, Audio, Bild, Anh&auml;nge: Dateien)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Teilnahme an Videokonferenz mit Nutzernamen (Optional: Teilen von Audio/ Mikrofon, Video/ Kamera, Texte, Dateien, Bildschirm&uuml;bertragung)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Organisation</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Kalendereintr&auml;ge (Datum, Ort, Notiz)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Pinnwand &ndash; Eintr&auml;ge (Texte, Anh&auml;nge, Dateien)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
                            <strong><span style="font-size:17px;line-height:150%;">&nbsp;</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong><span style="line-height:150%;">Technische Daten</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Erforderliche Daten f&uuml;r Funktion und Service (Wartung, Pflege, Fehlerbehebungen)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Protokollierte Daten / Log Files</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - IP-Adresse des anfragenden Servers
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Zeitpunkt der Anfrage
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - HTTPs Request-Line (Methode, URL, Version)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - HTTPs Status-Line
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Gr&ouml;&szlig;e der Antwort in Bytes
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - User-Agent
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Im Falle der Nutzung der integrierten Videokonferenzsoftware BigBlueButton werden zus&auml;tzliche Daten protokolliert:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Bei Ein- und Austrittszeitpunkt mit Nutzernamen
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Zeitpunkt des Abrufs einer Chatnachricht
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Die IP-Adresse einer NAT (Network Address Translation)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Eine Aufzeichnung der Bild-, Toninhalte, Bildschirm&uuml;bertragung ist technisch unterbunden
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Im Falle von einer fehlerhaften HTTPs Anfrage mit einem Statuscode im Bereich 400 bis 599 wird zudem auch protokolliert:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - HTTPs Headers
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Anfragender Account/ Nutzer wenn vorhanden
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - HTTPs Message Body
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.9pt;line-height:150%;">
                            Beim Chat, Pinnwand werden in der Datenbank folgende Dinge pro Nutzer gespeichert:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:67.4pt;line-height:150%;">
                            -&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Mitgliedschaften in Gruppen mit Nutzernamen
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:67.4pt;line-height:150%;">
                            -&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Events in einer Gruppe mit Zeitpunkt wie Beitritt, Verlassen, Nachricht senden (mit Nachrichteninhalt, Nachricht l&ouml;schen)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:67.9pt;text-indent:-.5pt;line-height:150%;">
                            -&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Anzeigename Nutzername und wann Nutzer zuletzt online war, welche Nachricht angesehen wurde
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Zusatzfunktionen mit dem Ziel der Teilhabe und Barrierefreiheit:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:71.3pt;text-indent:-.5pt;line-height:150%;">
                            -&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Gegebenenfalls wird die Tatsache der Einbindung von Zusatzfunktionen in die Anwendungen und Dienste der NutzerInnen f&uuml;r das verbesserte Sehen und H&ouml;ren und / oder die verbesserte Motorik als ein personenbezogenes Datum der Erziehungsberechtigten verarbeitet. Diese Zusatzfunktionen dienen der verbesserten Teilhabe f&uuml;r Menschen mit einer Einschr&auml;nkung beim Sehen, H&ouml;ren oder in der Motorik und erf&uuml;llten mithin notwendige Anforderungen der Barrierefreiheit.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">4. Zwecke, f&uuml;r die die personenbezogenen Daten verarbeitet werden</span></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <strong>&nbsp;</strong>
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
                            Durchf&uuml;hrung des digitalen Unterrichts (synchron per Videokonferenz und nicht synchron), per EKLARA-Plattform sowie der schulbezogenen Kommunikation und Organisation zur Wahrnehmung des staatlichen Bildungs- und Erziehungsauftrags insbesondere unter dem Aspekt der Barrierefreiheit f&uuml;r Sch&uuml;lerinnen und Sch&uuml;ler der sonderp&auml;dagogischen Bildungs- und Beratungszentren (auch F&ouml;rder- und Grundschulen genannt). Hierbei werden personenbezogene Daten von Sch&uuml;lerInnen, Lehrkr&auml;ften, Schulleitung/ Verwaltung, Erziehungsberechtigen und Externen verarbeitet.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">5. Die Rechtsgrundlage f&uuml;r die Datenverarbeitung</span></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
                            Datenverarbeitung zur Wahrnehmung des staatlichen Bildungs- und Erziehungsauftrages soweit durch die Schule erforderlich.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">6. Dauer, f&uuml;r die Ihre personenbezogenen Daten gespeichert werden</span></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:18.0pt;line-height:18.0pt;">
                            <strong>&nbsp;</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;">
                            <strong>Anmeldedaten</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            - L&ouml;schung durch Verwaltung jederzeit manuell m&ouml;glich. Meist erst nach 12 Monaten zum Schuljahresende oder mit dem Austritt/ L&ouml;schung des Nutzers gel&ouml;scht.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong><span style="line-height:150%;">Generierte Inhaltsdaten</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            - Kommunikations- sowie Organisationsinhalte, die in EKLARA eingestellt werden, werden f&uuml;r eine Dauer von 12 Monaten aufbewahrt
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            - Generierten Inhalte k&ouml;nnen nutzerseitig/ vom Ersteller sofort gel&ouml;scht werden, sofern Sie keine anderen Gesetzesgrundlagen und Vorschriften unterliegen.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            - Inhalte und Informationen aus Videokonferenzen werden nicht gespeichert, sondern nach Beendigung der Konferenz gel&ouml;scht.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Technische Daten</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            - Log-Files (auch protokollierte Daten genannt) werden zum Zwecke von Fehleranalysen und Softwareoptimierung f&uuml;r eine Dauer von 30 Tagen gespeichert.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">7. Wer tr&auml;gt die datenschutzrechtliche Verantwortung?</span></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Verantwortliche Stelle f&uuml;r die Datenverarbeitung ist die Schule, die Sie besuchen, vertreten durch deren Schulleitung. Diese beauftragt sogenannte Auftragsverarbeiter mit der Bereitstellung von Anwendungen und Diensten.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">8. Wer erh&auml;lt Ihre personenbezogenen Daten?</span></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            In der Regel erhalten Auftragsverarbeiter (EKLARA) Ihre Daten, um sie in Ihren Anwendungen und Diensten verarbeiten zu k&ouml;nnen. EKLARA besch&auml;ftigt weitere Auftragsverarbeiter zum Zwecke der Datenspeicherung (Hetzner Online GmbH) sowie f&uuml;r Online &Uuml;bersetzungen DeepL SE.&nbsp;
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Wenn Sie in der Audio- / Videokonferenz sprechen, Ihr Bild zeigen, chatten oder Inhalte/ Dateien zeigen/ teilen, sind auch ihre Lehrkr&auml;fte und, je nach Art der Konferenz, Ihre Mitsch&uuml;lerinnen und Mitsch&uuml;ler und/ oder Ihre Erziehungsberechtigten Empf&auml;nger, der von Ihnen preisgegebenen Inhalte.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <br>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <br>
                        </p>
                    </div>
                    <div v-else-if="accountRole === 'teacher'">
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">3. Personenbezogene Daten, die verarbeitet werden.</span></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:36.0pt;line-height:18.0pt;">
                            Wenn Sie EKLARA nutzen werden die folgenden Kategorien Ihrer personenbezogenen Daten verarbeitet.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:36.0pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
                            <strong><span style="line-height:150%;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Anmeldedaten</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Zur Verwaltung der Lizenzen und von Rechten und Rollen notwendige Daten:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:71.4pt;line-height:150%;">
                            <span style="line-height:150%;">&nbsp;</span>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Lehrkr&auml;fte</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Nutzername, Passwort, optional - ggf. nutzerbezogener QR-Code
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Zuweisung einer neuen Funktion &ndash; mit Nutzername
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Zuordnung zu einer Nutzergruppe, od. Klasse mit Nutzername
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
                            <span style="line-height:150%;">&nbsp;</span>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong><span style="line-height:150%;">Generierte Inhaltsdaten</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Zur Teilnahme und Durchf&uuml;hrung des Unterrichts/ Bildungsauftrags
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
                            <u><span style="text-decoration:none;">&nbsp;</span></u>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong><span style="line-height:150%;">Unterricht</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Unterrichtsinhalte, Aufgaben (Text, Audio, Anh&auml;nge: Dateien)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Bereitgestellte, erstellte Aufgaben inkl. R&uuml;ckmeldungen (Text, Audio, Anh&auml;nge: Dateien)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Kommunikation&nbsp;</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Chatnachrichten (Text, Audio, Anh&auml;nge: Dateien)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Schwarzes Brett &ndash; Mitteilungen (Texte, Anh&auml;nge, Dateien, Teilnehmerzuordnung)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Elternbriefe &ndash; Mitteilungen (Texte, Anh&auml;nge, Dateien, Teilnehmerzuordnung)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Teilnahme an Videokonferenz mit Nutzernamen (Optional: Teilen von Audio/ Mikrofon, Video/ Kamera, Texte, Dateien, Bildschirm&uuml;bertragung)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Teilnehmerlisten innerhalb einer Klasse/ Gruppe mit Mitsch&uuml;lerInnen, Lehrkr&auml;ften, Erziehungsberechtigten mit Nutzernamen
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Organisation</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Kurs-, Vertretungs-, Raumpl&auml;ne
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Kalendereintr&auml;ge (Datum, Ort, Notiz, Teilnehmerzuordnung)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Pinnwand &ndash; Eintr&auml;ge (Texte, Anh&auml;nge, Dateien, Teilnehmerzuordnung)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Internet &ndash; Erstellte Verlinkungen zu anderen Internetseiten
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Ger&auml;teverleih &ndash; Angaben zum Gegenstand, Zustand, Foto, Verleihdatum, Teilnehmerzuordnung mit Nutzernamen
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Zensurliste &ndash; Zu zensierende W&ouml;rter im Chat, &Uuml;bersetzung
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
                            <strong><span style="font-size:17px;line-height:150%;">&nbsp;</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong><span style="line-height:150%;">Technische Daten</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Erforderliche Daten f&uuml;r Funktion und Service (Wartung, Pflege, Fehlerbehebungen)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Protokollierte Daten / Log Files</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - IP-Adresse des anfragenden Servers
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Zeitpunkt der Anfrage
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - HTTPs Request-Line (Methode, URL, Version)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - HTTPs Status-Line
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Gr&ouml;&szlig;e der Antwort in Bytes
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - User-Agent
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Im Falle der Nutzung der integrierten Videokonferenzsoftware BigBlueButton werden zus&auml;tzliche Daten protokolliert:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Bei Ein- und Austrittszeitpunkt mit Nutzernamen
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Zeitpunkt des Abrufs einer Chatnachricht
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Die IP-Adresse einer NAT (Network Address Translation)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Eine Aufzeichnung der Bild-, Toninhalte, Bildschirm&uuml;bertragung ist technisch unterbunden
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Im Falle von einer fehlerhaften HTTPs Anfrage mit einem Statuscode im Bereich 400 bis 599 wird zudem auch protokolliert:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - HTTPs Headers
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Anfragender Account/ Nutzer wenn vorhanden
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - HTTPs Message Body
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.9pt;line-height:150%;">
                            Beim Chat, Elternbriefe, Schwarzes Brett, Pinnwand werden in der Datenbank folgende Dinge pro Nutzer gespeichert:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:67.4pt;line-height:150%;">
                            -&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Mitgliedschaften in Gruppen mit Nutzernamen
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:67.4pt;line-height:150%;">
                            -&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Events in einer Gruppe mit Zeitpunkt wie Beitritt, Verlassen, Nachricht senden (mit Nachrichteninhalt, Nachricht l&ouml;schen)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:67.9pt;text-indent:-.5pt;line-height:150%;">
                            -&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Anzeigename Nutzername und wann Nutzer zuletzt online war, welche Nachricht angesehen wurde
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Zusatzfunktionen mit dem Ziel der Teilhabe und Barrierefreiheit:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:71.3pt;text-indent:-.5pt;line-height:150%;">
                            -&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Gegebenenfalls wird die Tatsache der Einbindung von Zusatzfunktionen in die Anwendungen und Dienste der NutzerInnen f&uuml;r das verbesserte Sehen und H&ouml;ren und / oder die verbesserte Motorik als ein personenbezogenes Datum der Lehrkr&auml;fte verarbeitet. Diese Zusatzfunktionen dienen der verbesserten Teilhabe f&uuml;r Menschen mit einer Einschr&auml;nkung beim Sehen, H&ouml;ren oder in der Motorik und erf&uuml;llten mithin notwendige Anforderungen der Barrierefreiheit.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <strong><span style="font-size:16px;">4. Zwecke, f&uuml;r die die personenbezogenen Daten verarbeitet werden</span></strong>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
                            Durchf&uuml;hrung des digitalen Unterrichts (synchron per Videokonferenz und nicht synchron), per EKLARA-Plattform sowie der schulbezogenen Kommunikation und Organisation zur Wahrnehmung des staatlichen Bildungs- und Erziehungsauftrags insbesondere unter dem Aspekt der Barrierefreiheit f&uuml;r Sch&uuml;lerinnen und Sch&uuml;ler der sonderp&auml;dagogischen Bildungs- und Beratungszentren (auch F&ouml;rder- und Grundschulen genannt). Hierbei werden personenbezogene Daten von Sch&uuml;lerInnen, Lehrkr&auml;ften, Schulleitung/ Verwaltung, Erziehungsberechtigen und Externen verarbeitet.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">5. Die Rechtsgrundlage f&uuml;r die Datenverarbeitung</span></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Datenverarbeitung zur Wahrnehmung des staatlichen Bildungs- und Erziehungsauftrages soweit durch die Schule erforderlich ist.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">6. Dauer, f&uuml;r die Ihre personenbezogenen Daten gespeichert werden</span></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <strong>&nbsp;</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;">
                            <strong>Anmeldedaten</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            - L&ouml;schung durch Verwaltung jederzeit manuell m&ouml;glich. Meist erst nach 12 Monaten zum Schuljahresende oder mit dem Austritt/ L&ouml;schung des Nutzers gel&ouml;scht.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong><span style="line-height:150%;">Generierte Inhaltsdaten</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            - Aufgaben die in EKLARA erstellt, bearbeitet werden, werden nach 30 Tagen gel&ouml;scht.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            - Kommunikations- sowie Organisationsinhalte, die in EKLARA eingestellt werden, werden f&uuml;r eine Dauer von 12 Monaten aufbewahrt.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            - Generierten Inhalte k&ouml;nnen nutzerseitig/ vom Ersteller sofort gel&ouml;scht werden, sofern Sie keine anderen Gesetzesgrundlagen und Vorschriften unterliegen.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            - Inhalte und Informationen aus Videokonferenzen werden nicht gespeichert, sondern nach Beendigung der Konferenz automatisch gel&ouml;scht.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Technische Daten</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            - Log-Files (auch protokollierte Daten genannt) werden zum Zwecke von Fehleranalysen und Softwareoptimierung f&uuml;r eine Dauer von 30 Tagen gespeichert.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">7. Wer tr&auml;gt die datenschutzrechtliche Verantwortung?</span></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Verantwortliche Stelle f&uuml;r die Datenverarbeitung ist die Schule, die Sie besuchen, vertreten durch deren Schulleitung. Diese beauftragt sogenannte Auftragsverarbeiter mit der Bereitstellung von Anwendungen und Diensten.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">8. Wer erh&auml;lt Ihre personenbezogenen Daten?</span></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            In der Regel erhalten Auftragsverarbeiter (EKLARA) Ihre Daten, um sie in Ihren Anwendungen und Diensten verarbeiten zu k&ouml;nnen. EKLARA besch&auml;ftigt weitere Auftragsverarbeiter zum Zwecke der Datenspeicherung (Hetzner Online GmbH) sowie f&uuml;r Online &Uuml;bersetzungen DeepL SE.&nbsp;
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Wenn Sie in der Audio- / Videokonferenz sprechen, Ihr Bild zeigen, chatten oder Inhalte/ Dateien zeigen/ teilen, sind auch ihre Lehrkr&auml;fte und, je nach Art der Konferenz, Ihre Mitsch&uuml;lerinnen und Mitsch&uuml;ler Empf&auml;nger der von Ihnen preisgegebenen Inhalte.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <em><u><span style="text-decoration: none;">&nbsp;</span></u></em>
                        </p>
                    </div>
                    <div v-else-if="accountRole === 'pupil'">
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">3. Personenbezogene Daten, die verarbeitet werden.</span></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:36.0pt;line-height:18.0pt;">
                            Wenn Sie EKLARA nutzen werden die folgenden Kategorien Ihrer personenbezogenen Daten verarbeitet.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:36.0pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
                            <strong><span style="line-height:150%;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Anmeldedaten</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Zur Verwaltung der Lizenzen und von Rechten und Rollen notwendige Daten:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:71.4pt;line-height:150%;">
                            <span style="line-height:150%;">&nbsp;</span>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Sch&uuml;lerInnen</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Nutzername, Passwort, ggf. nutzerbezogener QR-Code
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Zuweisung einer neuen Funktion &ndash; mit Nutzername
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Zuordnung zu einer Nutzergruppe, od. Klasse mit Nutzername
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:71.4pt;line-height:150%;">
                            <span style="line-height:150%;">&nbsp;</span>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong><span style="line-height:150%;">Generierte Inhaltsdaten</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Zur Teilnahme und Durchf&uuml;hrung des Unterrichts/ Bildungsauftrags.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
                            <u><span style="text-decoration:none;">&nbsp;</span></u>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong><span style="line-height:150%;">Unterricht</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Bereitgestellte Antworten auf Aufgaben, R&uuml;ckmeldungen (Text, Audio, Bild, Anh&auml;nge: Dateien)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - R&uuml;ckmeldung zu Unterrichtsinhalten, Aufgaben (Text, Audio, Bild, Anh&auml;nge: Dateien)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Kommunikation&nbsp;</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Chatnachrichten (Text, Audio, Bild, Anh&auml;nge: Dateien)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Teilnahme an Videokonferenz mit Nutzernamen (Optional: Teilen von Audio/ Mikrofon, Video/ Kamera, Texte, Dateien, Bildschirm&uuml;bertragung)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Organisation</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Kalendereintr&auml;ge (Datum, Ort, Notiz)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Pinnwand &ndash; Eintr&auml;ge (Texte, Anh&auml;nge, Dateien)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Internet &ndash; Erstellte Verlinkungen zu anderen Internetseiten
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong><span style="font-size:17px;line-height:150%;">&nbsp;</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong><span style="line-height:150%;">Technische Daten</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Erforderliche Daten f&uuml;r Funktion und Service (Wartung, Pflege, Fehlerbehebungen)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Protokollierte Daten / Log Files</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - IP-Adresse des anfragenden Servers
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Zeitpunkt der Anfrage
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - HTTPs Request-Line (Methode, URL, Version)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - HTTPs Status-Line
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Gr&ouml;&szlig;e der Antwort in Bytes
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - User-Agent
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Im Falle der Nutzung der integrierten Videokonferenzsoftware BigBlueButton werden zus&auml;tzliche Daten protokolliert:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Bei Ein- und Austrittszeitpunkt mit Nutzernamen
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Zeitpunkt des Abrufs einer Chatnachricht
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Die IP-Adresse einer NAT (Network Address Translation)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Eine Aufzeichnung der Bild-, Toninhalte, Bildschirm&uuml;bertragung ist technisch unterbunden
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Im Falle von einer fehlerhaften HTTPs Anfrage mit einem Statuscode im Bereich 400 bis 599 wird zudem auch protokolliert:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - HTTPs Headers
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Anfragender Account/ Nutzer wenn vorhanden
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - HTTPs Message Body
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.9pt;line-height:150%;">
                            Beim Chat, Pinnwand werden in der Datenbank folgende Dinge pro Nutzer gespeichert:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:67.4pt;line-height:150%;">
                            -&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Mitgliedschaften in Gruppen mit Nutzernamen
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:67.4pt;line-height:150%;">
                            -&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Events in einer Gruppe mit Zeitpunkt wie Beitritt, Verlassen, Nachricht senden (mit Nachrichteninhalt, Nachricht l&ouml;schen)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:67.9pt;text-indent:-.5pt;line-height:150%;">
                            -&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Anzeigename Nutzername und wann Nutzer zuletzt online war, welche Nachricht angesehen wurde
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Zusatzfunktionen mit dem Ziel der Teilhabe und Barrierefreiheit:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:71.3pt;text-indent:-.5pt;line-height:150%;">
                            -&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Gegebenenfalls wird die Tatsache der Einbindung von Zusatzfunktionen in die Anwendungen und Dienste der NutzerInnen f&uuml;r das verbesserte Sehen und H&ouml;ren und / oder die verbesserte Motorik als ein personenbezogenes Datum der Sch&uuml;lerInnen verarbeitet. Diese Zusatzfunktionen dienen der verbesserten Teilhabe f&uuml;r Menschen mit einer Einschr&auml;nkung beim Sehen, H&ouml;ren oder in der Motorik und erf&uuml;llten mithin notwendige Anforderungen der Barrierefreiheit.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">4. Zwecke, f&uuml;r die die personenbezogenen Daten verarbeitet werden</span></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
                            Durchf&uuml;hrung des digitalen Unterrichts (synchron per Videokonferenz und nicht synchron), per EKLARA-Plattform sowie der schulbezogenen Kommunikation und Organisation zur Wahrnehmung des staatlichen Bildungs- und Erziehungsauftrags insbesondere unter dem Aspekt der Barrierefreiheit f&uuml;r Sch&uuml;lerinnen und Sch&uuml;ler der sonderp&auml;dagogischen Bildungs- und Beratungszentren (auch F&ouml;rder- und Grundschulen genannt). Hierbei werden personenbezogene Daten von Sch&uuml;lerInnen, Lehrkr&auml;ften, Schulleitung/ Verwaltung, Erziehungsberechtigen und Externen verarbeitet.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">5. Die Rechtsgrundlage f&uuml;r die Datenverarbeitung</span></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
                            Art. 6 Abs. 1 lit. e Datenschutzgrundverordnung in Verbindung mit &sect; 4 Landesdatenschutzgesetz in Verbindung mit &sect;&sect; 1, 38 Abs. 6 Satz 2 Schulgesetz soweit die Datenverarbeitung zur Wahrnehmung des staatlichen Bildungs- und Erziehungsauftrages durch die Schule erforderlich ist.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">6. Dauer, f&uuml;r die Ihre personenbezogenen Daten gespeichert werden</span></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <strong>&nbsp;</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;">
                            <strong>Anmeldedaten</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            - L&ouml;schung durch Verwaltung jederzeit manuell m&ouml;glich. Meist erst nach 12 Monaten zum Schuljahresende oder mit dem Austritt/ L&ouml;schung des Nutzers gel&ouml;scht.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong><span style="line-height:150%;">Generierte Inhaltsdaten</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            - Aufgaben die in EKLARA erstellt, bearbeitet werden, werden nach 30 Tagen gel&ouml;scht.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            - Kommunikations- sowie Organisationsinhalte, die in EKLARA eingestellt werden, werden f&uuml;r eine Dauer von 12 Monaten aufbewahrt.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            - Generierten Inhalte k&ouml;nnen nutzerseitig/ vom Ersteller sofort gel&ouml;scht werden, sofern Sie keine anderen Gesetzesgrundlagen und Vorschriften unterliegen.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            - Inhalte und Informationen aus Videokonferenzen werden nicht gespeichert, sondern nach Beendigung der Konferenz automatisch gel&ouml;scht.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Technische Daten</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            - Log-Files (auch protokollierte Daten genannt) werden zum Zwecke von Fehleranalysen und Softwareoptimierung f&uuml;r eine Dauer von 30 Tagen gespeichert.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">7. Wer tr&auml;gt die datenschutzrechtliche Verantwortung?</span></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Verantwortliche Stelle f&uuml;r die Datenverarbeitung ist die Schule, die Sie besuchen, vertreten durch deren Schulleitung. Diese beauftragt sogenannte Auftragsverarbeiter mit der Bereitstellung von Anwendungen und Diensten.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">8. Wer erh&auml;lt Ihre personenbezogenen Daten?</span></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            In der Regel erhalten Auftragsverarbeiter (EKLARA) Ihre Daten, um sie in Ihren Anwendungen und Diensten verarbeiten zu k&ouml;nnen. EKLARA besch&auml;ftigt weitere Auftragsverarbeiter zum Zwecke der Datenspeicherung (Hetzner Online GmbH) sowie f&uuml;r optionale Online &Uuml;bersetzungen DeepL SE.&nbsp;
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Wenn Sie in der Audio- / Videokonferenz sprechen, Ihr Bild zeigen, chatten oder Inhalte/ Dateien zeigen/ teilen, sind auch ihre Lehrkr&auml;fte und, je nach Art der Konferenz, Ihre Mitsch&uuml;lerinnen und Mitsch&uuml;ler Empf&auml;nger der von Ihnen preisgegebenen Inhalte.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <br>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <br>
                        </p>
                    </div>
                    <div v-else-if="accountRole === 'maintainer'">
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">3. Personenbezogene Daten, die verarbeitet werden.</span></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:36.0pt;line-height:18.0pt;">
                            Wenn Sie EKLARA nutzen werden die folgenden Kategorien Ihrer personenbezogenen Daten verarbeitet.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:36.0pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
                            <strong><span style="font-size:17px;line-height:150%;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Anmeldedaten</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Zur Verwaltung der Lizenzen und von Rechten und Rollen notwendige Daten:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:71.4pt;line-height:150%;">
                            <span style="line-height:150%;">&nbsp;</span>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Lehrkr&auml;fte</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Nutzername, Passwort
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Zuweisung einer neuen Funktion &ndash; mit Nutzername
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Zuordnung zu einer Nutzergruppe, od. Klasse mit Nutzername
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:71.4pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>L&ouml;schfristen</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            L&ouml;schung jederzeit manuell m&ouml;glich (meist erst nach 12 Monaten (zum Schuljahresende) oder mit dem Austritt/ L&ouml;schung des Nutzers. Es gilt das <span style="line-height:150%;">Recht auf L&ouml;schung, Art. 17 DSGVO.</span>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Kommunikation&nbsp;</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Chatnachrichten (Text, Audio, Bild, Anh&auml;nge: Dateien)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Schwarzes Brett &ndash; Mitteilungen (Texte, Anh&auml;nge, Dateien, Teilnehmerzuordnung)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Elternbriefe &ndash; Mitteilungen (Texte, Anh&auml;nge, Dateien, Teilnehmerzuordnung)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Teilnahme an Videokonferenz mit Nutzernamen (Optional: Teilen von Audio/ Mikrofon, Video/ Kamera, Texte, Dateien, Bildschirm&uuml;bertragung)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Teilnehmerlisten innerhalb einer Klasse/ Gruppe mit Mitsch&uuml;lerInnen, Lehrkr&auml;ften, Erziehungsberechtigten mit Nutzernamen
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Organisation</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Kurs-, Vertretungs-, Raumpl&auml;ne
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Kalendereintr&auml;ge (Datum, Ort, Notiz, Teilnehmerzuordnung)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Pinnwand &ndash; Eintr&auml;ge (Texte, Anh&auml;nge, Dateien, Teilnehmerzuordnung)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Internet &ndash; Erstellte Verlinkungen zu anderen Internetseiten
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Ger&auml;teverleih &ndash; Erste Angaben zum Zustand, Foto, Verleihdatum, Teilnehmerzuordnung mit Nutzernamen
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Erstellte Zensurliste &ndash; Zu zensierende W&ouml;rter im Chat, &Uuml;bersetzung
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>L&ouml;schfristen</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Aufgaben die in EKLARA erstellt, bearbeitet werden, werden nach 30 Tagen gel&ouml;scht.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Kommunikations- sowie Organisationsinhalte, die in EKLARA eingestellt werden, werden f&uuml;r eine Dauer von 12 Monaten aufbewahrt.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Generierte Inhalte k&ouml;nnen nutzerseitig mit sofortiger Wirkung gel&ouml;scht werden.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Inhalte und Informationen aus Videokonferenzen werden nicht gespeichert, sondern nach Beendigung der Konferenz gel&ouml;scht.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;text-indent:35.4pt;line-height:150%;">
                            - Es gilt das <span style="line-height:150%;">Recht auf L&ouml;schung, Art. 17 DSGVO.</span>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
                            <strong><span style="font-size:17px;line-height:150%;">&nbsp;</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong><span style="font-size:17px;line-height:150%;">Technische Daten</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Erforderliche Daten f&uuml;r Funktion und Service (Wartung, Pflege, Fehlerbehebungen)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Protokollierte Daten / Log Files</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - IP-Adresse des anfragenden Servers
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Zeitpunkt der Anfrage
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - HTTPs Request-Line (Methode, URL, Version)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - HTTPs Status-Line
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Gr&ouml;&szlig;e der Antwort in Bytes
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - User-Agent
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Im Falle der Nutzung der integrierten Videokonferenzsoftware BigBlueButton werden zus&auml;tzliche Daten protokolliert:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Bei Ein- und Austrittszeitpunkt mit Nutzernamen
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Zeitpunkt des Abrufs einer Chatnachricht
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Die IP-Adresse einer NAT (Network Address Translation)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Eine Aufzeichnung der Bild-, Toninhalte, Bildschirm&uuml;bertragung ist technisch unterbunden
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Im Falle von einer fehlerhaften HTTPs Anfrage mit einem Statuscode im Bereich 400 bis 599 wird zudem auch protokolliert:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - HTTPs Headers
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Anfragender Account/ Nutzer wenn vorhanden
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - HTTPs Message Body
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.9pt;line-height:150%;">
                            Beim Chat, Elternbriefe, Schwarzes Brett, Pinnwand werden in der Datenbank folgende Dinge pro Nutzer gespeichert:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:67.4pt;line-height:150%;">
                            - &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Mitgliedschaften in Gruppen mit Nutzernamen
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:67.4pt;line-height:150%;">
                            - &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Events in einer Gruppe mit Zeitpunkt wie Beitritt, Verlassen, Nachricht senden (mit Nachrichteninhalt, Nachricht l&ouml;schen)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:67.9pt;text-indent:-.5pt;line-height:150%;">
                            - &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Anzeigename Nutzername und wann Nutzer zuletzt online war, welche Nachricht angesehen wurde
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Zusatzfunktionen mit dem Ziel der Teilhabe und Barrierefreiheit:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:71.3pt;text-indent:-.5pt;line-height:150%;">
                            - &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Gegebenenfalls wird die Tatsache der Einbindung von Zusatzfunktionen in die Anwendungen und Dienste der NutzerInnen f&uuml;r das verbesserte Sehen und H&ouml;ren und / oder die verbesserte Motorik als ein personenbezogenes Datum der Lehrkr&auml;fte verarbeitet. Diese Zusatzfunktionen dienen der verbesserten Teilhabe f&uuml;r Menschen mit einer Einschr&auml;nkung beim Sehen, H&ouml;ren oder in der Motorik und erf&uuml;llten mithin notwendige Anforderungen der Barrierefreiheit.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>L&ouml;schfristen</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:36.0pt;line-height:150%;">
                            - Log-Files (auch protokollierte Daten genannt) werden zum Zwecke von Fehleranalysen und Softwareoptimierung f&uuml;r eine Dauer von 30 Tagen gespeichert.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            - Es gilt das <span style="line-height:150%;">Recht auf L&ouml;schung, Art. 17 DSGVO.</span>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">4. Zwecke, f&uuml;r die die personenbezogenen Daten verarbeitet werden</span></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
                            Durchf&uuml;hrung des digitalen Unterrichts (synchron per Videokonferenz und nicht synchron), per EKLARA-Plattform sowie der schulbezogenen Kommunikation und Organisation zur Wahrnehmung des staatlichen Bildungs- und Erziehungsauftrags insbesondere unter dem Aspekt der Barrierefreiheit f&uuml;r Sch&uuml;lerinnen und Sch&uuml;ler der sonderp&auml;dagogischen Bildungs- und Beratungszentren (auch F&ouml;rder- und Grundschulen genannt). Hierbei werden personenbezogene Daten von Sch&uuml;lerInnen, Lehrkr&auml;ften, Schulleitung/ Verwaltung, Erziehungsberechtigen und Externen verarbeitet.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">5. Die Rechtsgrundlage f&uuml;r die Datenverarbeitung</span></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
                            Art. 6 Abs. 1 lit. e Datenschutzgrundverordnung in Verbindung mit &sect; 4 Landesdatenschutzgesetz in Verbindung mit &sect;&sect; 1, 38 Abs. 6 Satz 2 Schulgesetz soweit die Datenverarbeitung zur Wahrnehmung des staatlichen Bildungs- und Erziehungsauftrages durch die Schule erforderlich ist.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
                            Bei der aktiven Teilnahme an einer Videokonferenz (Chatten, Zeigen von Dokumenten, Dateien, &Uuml;bertragung von Bild und Ton &ndash; auch aus dem h&auml;uslichen Umfeld) und der Nutzung der &Uuml;bersetzungsfunktion die Einwilligung der Lehrkr&auml;fte gem&auml;&szlig; Art. 6 Abs. 1 lit a DSGVO.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
                            Diese Einwilligung ist jederzeit mit Wirkung f&uuml;r die Zukunft widerrufbar.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">6. Dauer, f&uuml;r die Ihre personenbezogenen Daten gespeichert werden</span></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
                            Kommunikationsinhalte, die Sie in EKLARA einstellen werden f&uuml;r eine Dauer von 12 Monaten aufbewahrt. Aufgaben die Sie in EKLARA erarbeiten, werden nach 28 Tagen gel&ouml;scht. Allerdings k&ouml;nnen Sie s&auml;mtliche von Ihnen generierte Inhalte selbst mit sofortiger Wirkung l&ouml;schen. Log-Files (auch protokollierte Daten genannt) werden zum Zwecke von Fehleranalysen und Softwareoptimierung f&uuml;r eine Dauer von 30 Tagen gespeichert.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
                            Inhalte und Informationen aus Videokonferenzen werden nicht gespeichert
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">7. Wer tr&auml;gt die datenschutzrechtliche Verantwortung?</span></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Verantwortliche Stelle f&uuml;r die Datenverarbeitung ist die Schule, die Sie besuchen, vertreten durch deren Schulleitung. Diese beauftragt sogenannte Auftragsverarbeiter mit der Bereitstellung von Anwendungen und Diensten.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">8. Wer erh&auml;lt Ihre personenbezogenen Daten?</span></strong></p>
                        </div>
                    </div>
                    <div v-else-if="usage === 'extern'">
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">3. Personenbezogene Daten, die verarbeitet werden.</span></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:36.0pt;line-height:18.0pt;">
                            Wenn Sie EKLARA nutzen werden die folgenden Kategorien Ihrer personenbezogenen Daten verarbeitet.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:36.0pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
                            <strong><span style="line-height:150%;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Anmeldedaten</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Zur Verwaltung der Lizenzen und von Rechten und Rollen notwendige Daten:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:71.4pt;line-height:150%;">
                            <span style="line-height:150%;">&nbsp;</span>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Externe</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Eigens vergebener Nutzername
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
                            <span style="line-height:150%;">&nbsp;</span>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong><span style="line-height:150%;">Generierte Inhaltsdaten</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.9pt;text-indent:-.5pt;line-height:150%;">
                            <strong>Kommunikation&nbsp;</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Teilnahme an Videokonferenz mit Nutzernamen (Optional: Teilen von Audio/ Mikrofon, Video/ Kamera, Texte, Dateien, Bildschirm&uuml;bertragung)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong><span style="font-size:17px;line-height:150%;">&nbsp;</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong><span style="line-height:150%;">Technische Daten</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Erforderliche Daten f&uuml;r Funktion und Service (Wartung, Pflege, Fehlerbehebungen)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Protokollierte Daten / Log Files</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - IP-Adresse des anfragenden Servers
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Zeitpunkt der Anfrage
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - HTTPs Request-Line (Methode, URL, Version)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - HTTPs Status-Line
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Gr&ouml;&szlig;e der Antwort in Bytes
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - User-Agent
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Im Falle der Nutzung der integrierten Videokonferenzsoftware BigBlueButton werden zus&auml;tzliche Daten protokolliert:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Bei Ein- und Austrittszeitpunkt mit Nutzernamen
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Zeitpunkt des Abrufs einer Chatnachricht
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Die IP-Adresse einer NAT (Network Address Translation)
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Eine Aufzeichnung der Bild-, Toninhalte, Bildschirm&uuml;bertragung ist technisch unterbunden
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Im Falle von einer fehlerhaften HTTPs Anfrage mit einem Statuscode im Bereich 400 bis 599 wird zudem auch protokolliert:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - HTTPs Headers
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - Anfragender Account/ Nutzer wenn vorhanden
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:70.8pt;line-height:150%;">
                            - HTTPs Message Body
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            Zusatzfunktionen mit dem Ziel der Teilhabe und Barrierefreiheit:
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:71.3pt;text-indent:-.5pt;line-height:150%;">
                            -&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Gegebenenfalls wird die Tatsache der Einbindung von Zusatzfunktionen in die Anwendungen und Dienste der NutzerInnen f&uuml;r das verbesserte Sehen und H&ouml;ren und / oder die verbesserte Motorik als ein personenbezogenes Datum der Externen verarbeitet. Diese Zusatzfunktionen dienen der verbesserten Teilhabe f&uuml;r Menschen mit einer Einschr&auml;nkung beim Sehen, H&ouml;ren oder in der Motorik und erf&uuml;llten mithin notwendige Anforderungen der Barrierefreiheit.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">4. Zwecke, f&uuml;r die die personenbezogenen Daten verarbeitet werden</span></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:150%;">
                            Durchf&uuml;hrung des digitalen Unterrichts (synchron per Videokonferenz und nicht synchron), per EKLARA-Plattform sowie der schulbezogenen Kommunikation und Organisation zur Wahrnehmung des staatlichen Bildungs- und Erziehungsauftrags insbesondere unter dem Aspekt der Barrierefreiheit f&uuml;r Sch&uuml;lerinnen und Sch&uuml;ler der sonderp&auml;dagogischen Bildungs- und Beratungszentren (auch F&ouml;rder- und Grundschulen genannt). Hierbei werden personenbezogene Daten von Sch&uuml;lerInnen, Lehrkr&auml;ften, Schulleitung/ Verwaltung, Erziehungsberechtigen und Externen verarbeitet.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">5. Die Rechtsgrundlage f&uuml;r die Datenverarbeitung</span></strong></p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
                            Datenverarbeitung zur Wahrnehmung des staatlichen Bildungs- und Erziehungsauftrages soweit durch die Schule erforderlich ist.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">6. Dauer, f&uuml;r die Ihre personenbezogenen Daten gespeichert werden</span></strong>.</p>
                        </div>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Anmeldedaten</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            - L&ouml;schung nach Verlassen oder Beenden der Videokonferenz.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong><span style="line-height:150%;">Generierte Inhaltsdaten</span></strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            - L&ouml;schung nach Verlassen oder Beenden der Videokonferenz.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            <strong>Technische Daten</strong>
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:150%;">
                            - Log-Files (auch protokollierte Daten genannt) werden zum Zwecke von Fehleranalysen und Softwareoptimierung f&uuml;r eine Dauer von 30 Tagen gespeichert.
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">7. Wer tr&auml;gt die datenschutzrechtliche Verantwortung?</span></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Verantwortliche Stelle f&uuml;r die Datenverarbeitung ist die Schule, die Sie besuchen, vertreten durch deren Schulleitung. Diese beauftragt sogenannte Auftragsverarbeiter mit der Bereitstellung von Anwendungen und Diensten.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                        </p>
                        <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                            <p><strong><span style="font-size:16px;">8. Wer erh&auml;lt Ihre personenbezogenen Daten?</span></strong></p>
                        </div>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            In der Regel erhalten Auftragsverarbeiter (EKLARA) Ihre Daten, um sie in Ihren Anwendungen und Diensten verarbeiten zu k&ouml;nnen. EKLARA besch&auml;ftigt weitere Auftragsverarbeiter zum Zwecke der Datenspeicherung (Hetzner Online GmbH) sowie f&uuml;r Online &Uuml;bersetzungen DeepL SE.
                        </p>
                        <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            Wenn Sie in der Audio- / Videokonferenz sprechen, Ihr Bild zeigen, chatten oder Inhalte/ Dateien zeigen/ teilen, sind auch ihre Lehrkr&auml;fte und, je nach Art der Konferenz, Ihre Mitsch&uuml;lerinnen und Mitsch&uuml;ler und/ oder Ihre Erziehungsberechtigten, oder Externe Empf&auml;nger, der von Ihnen preisgegebenen Inhalte.&nbsp;
                        </p>
                        <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <br>
                        </p>
                    </div>
                   
                    <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                        In der Regel erhalten Auftragsverarbeiter (EKLARA) Ihre Daten, um sie in Ihren Anwendungen und Diensten verarbeiten zu k&ouml;nnen. EKLARA besch&auml;ftigt weitere Auftragsverarbeiter zum Zwecke der Datenspeicherung (Hetzner Online GmbH) sowie f&uuml;r Online &Uuml;bersetzungen DeepL SE. In diesen jeweiligen Rechtsverh&auml;ltnissen gelten Vereinbarungen zur Auftragsdatenverarbeitung im Sinne des Artikel 28 Absatz 3 Datenschutzgrundverordnung.&nbsp;
                    </p>
                    <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                        Wenn Sie in der Audio- / Videokonferenz sprechen, ihr Bild zeigen, chatten oder Inhalte/ Dateien zeigen/ teilen, sind auch ihre Lehrkr&auml;fte und, je nach Art der Konferenz, Ihre Mitsch&uuml;lerinnen und Mitsch&uuml;ler Empf&auml;nger der von Ihnen preisgegebenen Inhalte.&nbsp;
                    </p>
                    <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                    </p>
                    <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                        <p><strong><span style="font-size:16px;">9. &Uuml;bermittlung an ein Drittland oder eine internationale Organisation</span></strong></p>
                    </div>
                    <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
&nbsp;
                    </p>
                    <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                        Es finden keine Datenverarbeitungen au&szlig;erhalb des Geltungsbereichs der Datenschutzgrundverordnung (Europ&auml;ischer Wirtschaftsraum, EWR) statt.
                    </p>
                    <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                        <br>
                    </p>
                    <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                        <em><u>(Zus&auml;tzliche Informationen die der betroffenen Person gem. Art. 13 Abs. 2 DSGVO zum Zeitpunkt der Erhebung dieser Daten zur Verf&uuml;gung zu stellen sind / Ihre Rechte als Betroffene(r))</u></em>
                    </p>
                    <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
&nbsp;
                    </p>
                    <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                        <strong>Bei der Verarbeitung Ihrer personenbezogenen Daten stehen Ihnen als betroffene Person die nachfolgend genannten Rechte gem. Art. 15 ff. DSGVO zu (Betroffenenrechte):</strong>
                    </p>
                    <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                        <strong>&nbsp;</strong>
                    </p>
                    <ol
                        style="margin-bottom:0cm;margin-top:0cm;"
                        start="1"
                        type="1"
                    >
                        <li style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <strong>Auskunftsrecht der betroffenen Person, Art. 15 DSGVO</strong>
                        </li>
                    </ol>
                    <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
                        Sie k&ouml;nnen Auskunft &uuml;ber Ihre durch die Auftragsverarbeiter im Auftrag Ihrer Schule verarbeiteten personenbezogenen Daten verlangen. Im Einzelnen sind dies: Auskunft &uuml;ber die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empf&auml;ngern, gegen&uuml;ber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Recht auf Berichtigung, L&ouml;schung, Einschr&auml;nkung der Verarbeitung oder Widerspruch, das Recht auf Beschwerde, Auskunft &uuml;ber die Herkunft ihrer Daten, sofern Sie nicht wissen, durch wen sie erhoben wurden. Sie k&ouml;nnen auch aussagef&auml;hige Informationen dar&uuml;ber verlangen, falls und wie eine Entscheidungsfindung automatisiert erfolgt ist.
                    </p>
                    <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
&nbsp;
                    </p>
                    <div style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;">
                        <p><strong><span style="font-family:&quot;Times New Roman&quot;;font-size:16px;">&nbsp; &nbsp; &nbsp;2.Recht auf Berichtigung, Art. 16 DSGVO</span></strong></p>
                    </div>
                    <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
                        Gem&auml;&szlig; Artikel 16 DSGVO k&ouml;nnen Sie die unverz&uuml;gliche Berichtigung unrichtiger oder Vervollst&auml;ndigung der bei Ihrer Schule im Rahmen der hiesigen Dienstebereitstellung gespeicherten personenbezogenen Daten verlangen.
                    </p>
                    <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
&nbsp;
                    </p>
                    <ol
                        style="margin-bottom:0cm;margin-top:0cm;"
                        start="3"
                        type="1"
                    >
                        <li style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <strong>Recht auf L&ouml;schung, Art. 17 DSGVO</strong>
                        </li>
                    </ol>
                    <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
                        <span style="color:black;">Sie k&ouml;nnen die L&ouml;schung der bei Ihrer Schule gespeicherten personenbezogenen Daten verlangen, wenn die personenbezogenen Daten f&uuml;r die Zwecke, f&uuml;r die sie verarbeitet wurden, nicht mehr notwendig sind. Dies gilt nur soweit, wie nicht die Verarbeitung zur Aus&uuml;bung des Rechts auf freie Meinungs&auml;u&szlig;erung und Information, zur Erf&uuml;llung einer rechtlichen Verpflichtung, aus Gr&uuml;nden des &ouml;ffentlichen Interesses oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen erforderlich ist. Auch im &ouml;ffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder statistische Zwecke k&ouml;nnen der L&ouml;schung entgegenstehen.</span>
                    </p>
                    <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
                        <span style="color:black;">&nbsp;</span>
                    </p>
                    <ol
                        style="margin-bottom:0cm;margin-top:0cm;"
                        start="4"
                        type="1"
                    >
                        <li style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;line-height:18.0pt;">
                            <strong>Recht auf Einschr&auml;nkung der Verarbeitung, Art. 18 DSGVO</strong>
                        </li>
                    </ol>
                    <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
                        <span style="color:black;">Sie k&ouml;nnen die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird oder die Verarbeitung unrechtm&auml;&szlig;ig ist, Sie aber deren L&ouml;schung ablehnen oder Ihre Schule die Daten nicht mehr ben&ouml;tigt, Sie diese jedoch zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen ben&ouml;tigen.</span>
                    </p>
                    <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
                        <span style="color:black;">&nbsp;</span>
                    </p>
                    <ol
                        style="margin-bottom:0cm;margin-top:0cm;"
                        start="5"
                        type="1"
                    >
                        <li style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <strong>Daten&uuml;bertragbarkeit, Art. 20 DSGVO</strong>
                        </li>
                    </ol>
                    <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
                        Sie k&ouml;nnen Ihre personenbezogenen Daten, die Sie uns per Einwilligung bereitgestellt haben und die wir automatisiert verarbeiten, in einem strukturierten, g&auml;ngigen und maschinenlesbaren Format erhalten oder die &Uuml;bermittlung an einen anderen Verantwortlichen verlangen (Recht auf Daten&uuml;bertragbarkeit).
                    </p>
                    <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
                        <span style="color:black;">&nbsp;</span>
                    </p>
                    <ol
                        style="margin-bottom:0cm;margin-top:0cm;"
                        start="6"
                        type="1"
                    >
                        <li style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <strong>Widerspruchsrecht, Art. 21 DSGVO</strong>
                        </li>
                    </ol>
                    <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
                        Sie k&ouml;nnen Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einlegen. Dieses Widerspruchsrecht ist das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Ihrer personenbezogener Daten Widerspruch einzulegen, auch wenn die Schule sie zur Wahrnehmung einer ihr &uuml;bertragenen Aufgabe f&uuml;r erforderlich h&auml;lt, oder wenn sie m&ouml;glicherweise im &ouml;ffentlichen Interesse liegt oder in Aus&uuml;bung &ouml;ffentlicher Gewalt erfolgt. Sollen Ihre personenbezogenen Daten dennoch verarbeitet werden, m&uuml;ssen zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachgewiesen werden, die die Interessen, Rechte und Freiheiten Ihrer Person &uuml;berwiegen. Die Verarbeitung kann auch dann erforderlich sein, wenn sie der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen dient.
                    </p>
                    <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
&nbsp;
                    </p>
                    <ol
                        style="margin-bottom:0cm;margin-top:0cm;"
                        start="7"
                        type="1"
                    >
                        <li style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <strong>Beschwerderecht bei einer Aufsichtsbeh&ouml;rde, Art. 77 DSGVO</strong>
                        </li>
                    </ol>
                    <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
                        Ihre erste Anlaufstelle bei einer Beschwerde &uuml;ber die Verletzung des Datenschutzes sollte immer die Schulleitung als verantwortliche Stelle sein. Auch sollten Sie Ihrer Schulleitung zun&auml;chst eine angemessene Frist einr&auml;umen, um sich Ihrem Anliegen anzunehmen. Sie k&ouml;nnen Sich auch an den Datenschutzbeauftragten Ihrer Schule wenden. Unabh&auml;ngig von diesen vorgenannten M&ouml;glichkeiten haben Sie jedoch das Recht, sich bei der zust&auml;ndigen Datenschutz-Aufsichtsbeh&ouml;rde &uuml;ber eine Verletzung des Datenschutzes zu beschweren. In Baden-W&uuml;rttemberg ist dies der Landesbeauftragte f&uuml;r den Datenschutz und die Informationsfreiheit: <a
                            href="https://www.baden-wuerttemberg.datenschutz.de/kontakt-aufnehmen/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >https://www.baden-wuerttemberg.datenschutz.de/kontakt-aufnehmen/</a>&nbsp;
                    </p>
                    <p style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;margin-left:35.4pt;line-height:18.0pt;">
&nbsp;
                    </p>
                    <ol
                        style="margin-bottom:0cm;margin-top:0cm;"
                        start="8"
                        type="1"
                    >
                        <li style="margin:0cm;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                            <strong>Es besteht eine Bereitstellungspflicht</strong>
                        </li>
                    </ol>
                    <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;line-height:18.0pt;">
                        Um die Anwendung von EKLARA nutzen zu k&ouml;nnen, ist die Bereitstellung Ihrer Daten erforderlich soweit die Lehrkraft/ Mitarbeiter der Bildungseinrichtung sich f&uuml;r den Einsatz von EKLARA zur Unterrichtsdurchf&uuml;hrung entscheidet und die Nutzung auf schulischen Ger&auml;ten erfolgt. Dies gilt nicht f&uuml;r die Nutzung von Videokonferenzanwendungen aus dem h&auml;uslichen Umfeld.
                    </p>
                    <div
                        v-if="accountRole === 'parent' || usage === 'extern'"
                    >
                        <hr class="my-4">

                        <h3
                            id="Einwilligungserklärung"
                            class="d-flex flex-row justify-space-around align-center"
                        >
                            Einwilligungserklärung
                        </h3>
                        <div class="d-flex flex-row justify-space-around align-center">
                            <p>
                                <b><ins v-if="accountRole === 'maintainer'">
                                       durch Administratoren in die Verarbeitung ihrer
                                       personenbezogenen Daten im Rahmen der schulischen Nutzung der
                                       Anwendung EKLARA
                                   </ins>
                                    <ins
                                        v-else-if="
                                            accountRole !== 'teacher' || accountRole !== 'maintainer'
                                        "
                                    >
                                        durch Schülerinnen und Schüler bzw. deren
                                        Erziehungsberechtigten in die Verarbeitung ihrer
                                        personenbezogenen Daten im Rahmen der schulischen Nutzung der
                                        Anwendung EKLARA
                                    </ins>
                                    <ins v-else-if="accountRole === 'teacher'">
                                        durch Lehrkräfte in die Verarbeitung ihrer personenbezogenen
                                        Daten im Rahmen der schulischen Nutzung der Anwendung EKLARA
                                    </ins></b>
                            </p>
                        </div>
                        <br>
                        <p>
                            Ich habe die Datenschutzinformation gemäß Art. 13
                            Datenschutzgrundverordnung (DSGVO),sowie die Nutzungsordnung betreffend die schulische Nutzung
                            der schulischen IT-Anwendung EKLARA zur Kenntnis genommen
                        </p>
                        <div v-if="accountRole === 'maintainer'">
                            <p>
                                Für die Nutzung der Übersetzungsfunktion ist eine gesonderte
                                Einwilligung erforderlich:
                            </p>
                            <br>
                            <ul>
                                <li>
                                    Ich, die Unterzeichnerin / der Unterzeichner, willige ein, dass
                                    meine personenbezogenen Daten nach Maßgabe der vorbezeichneten
                                    Datenschutzinformation verarbeitet werden können, wenn ich zum
                                    schulischen Gebrauch die
                                    <b>Übersetzungsfunktion von EKLARA</b> nutze. Ich halte mich bei
                                    der Nutzung dieser Funktion an die Vorgaben der Nutzungsordnung
                                    keine personenbezogenen Daten von mir oder anderen Personen in
                                    die Übersetzungsanwendung einzugeben
                                </li>
                            </ul>
                        </div>
                        <p>
                            Für die Teilnahme an Videokonferenzen sind gesonderte Einwilligung
                            der erforderlich:
                        </p>
                        <br>
                        <ul>
                            <li v-if="accountRole !== 'maintainer'">
                                Ich, die Nutzerin / der Nutzer, willige ein, dass meine
                                personenbezogenen Daten nach Maßgabe der vorbezeichneten
                                Datenschutzinformation verarbeitet werden können, wenn ich zur
                                schulischen Nutzung einer Videokonferenz über Big Blue Button im
                                Rahmen des Dienstes EKLARA kommuniziere, an einer
                                <b>Videokonferenz</b> teilnehme, chatte und / oder Dokumente
                                zeige. Dies gilt auch insoweit, als ich von meiner Wohnung aus
                                teilnehme und infolge dessen Bilder und Ton aus der Wohnung
                                verarbeitet werden.
                            </li>
                            <li v-else>
                                Ich, die Unterzeichnerin / der Unterzeichner, willige ein, dass
                                meine personenbezogenen Daten nach Maßgabe der vorbezeichneten
                                Datenschutzinformation verarbeitet werden können, wenn ich zur
                                schulischen Nutzung einer Videokonferenz über Big Blue Button im
                                Rahmen des Dienstes EKLARA kommuniziere, an einer
                                <b>Videokonferenz</b> teilnehme, chatte und / oder Dokumente
                                zeige. Dies gilt auch insoweit, als ich von meiner Wohnung aus
                                teilnehme und infolge dessen Bilder und Ton aus der Wohnung
                                verarbeitet werden.
                            </li>
                        </ul>
                        <br>
                        <p>
                            Die Einwilligungen für die Nutzung dieser Funktionen erfolgen auf
                            der Rechtsgrundlage des Art. 6 Abs. 1lit.a DSGVO.
                        </p>
                        <p>
                            <b v-if="accountRole === 'pupil'">
                                Name Schülerin/Schüler: {{ userName }}</b>
                            <b v-else-if="accountRole === 'teacher'">
                                Name Lehrer/Lehrerin: {{ userName }}</b>
                            <b v-else-if="accountRole === 'parent'">
                                Name Erziehungsberechtigter von Schülerin/Schüler:
                                {{ userName }}</b>
                            <b v-else-if="accountRole === 'maintainer'">
                                Name des Administrators: {{ userName }}</b>
                            <b v-else-if="usage === 'extern'">
                                Name Externer: 
                                <div class="d-flex flex-row justify-flex-start">
                                    <v-text-field
                                        v-model="name"
                                        label="Vor- und Nachname"
                                        outlined
                                        hide-details
                                        solo
                                        flat
                                        type="text"
                                        :height="20"
                                        :error="name === ''"
                                        style="max-width: 300px; "
                                    />
                                    <!-- <div class="mr-2" /> -->
                                    <!-- <v-text-field
                                        v-model="lastName"
                                        label="Nachname"
                                        outlined
                                        solo
                                        flat
                                        type="text"
                                        hide-details
                                        :height="20"
                                        style="max-width: 150px;"
                                        :error="lastName === ''"
                                    /> -->
                                </div></b>
                        </p>
                        <div v-if="accountRole !== 'maintainer' && usage !== 'extern'">
                            <p v-if="school && school.name !== ''">
                                Schule: {{ school.name }}
                            </p>
                            <p v-if="groupName">
                                Klasse: {{ groupName }}
                            </p>
                        </div>
                        <p>
                            Die Einwilligung erfolgt auf freiwilliger Basis. Sie kann jederzeit
                            mit Wirkung für die Zukunft widerrufen werden. Ab Zugang der
                            Widerrufserklärung dürfen diejenigen personenbezogenen Daten, auf
                            die sich die Einwilligungserklärung bezieht, nicht weiterverarbeitet
                            werden, sondern sind unverzüglich zu löschen, soweit es nicht eine
                            anderweitige Rechtsgrundlage für die Datenverarbeitung als die
                            Einwilligung gibt. Durch den Widerruf der Einwilligung wird
                            allerdings die Rechtmäßigkeit der bis dahin erfolgten Verarbeitung
                            nicht berührt. Durch die Nichterteilung oder dem Widerruf der
                            Einwilligung ist es nicht möglich
                            EKLARA zu nutzen. Andere Nachteile entstehen dem {{ usage==='extern'? 'Nutzer': accountRole ==='parent'?'Erziehungsberechtigten':'Nutzer' }} nicht.
                        </p>
                        <p v-if="accountRole !== 'maintainer'">
                            <b>
                                <a
                                    href="#Datenschutz"
                                >Anlage: Nutzungsordnung und Datenschutzinformation für
                                    {{
                                        accountRole === "pupil"
                                            ? "Schülerinnen/Schüler"
                                            : accountRole === "teacher"
                                                ? "Lehrkräfte"
                                                : accountRole === "parent"
                                                    ? "Erziehungsberechtigte von Schülerinnen und Schüler"
                                                    : usage === "extern"
                                                        ? "Externe"
                                                        : ""
                                    }}
                                </a></b>
                        </p>
                    </div>
                    <p v-if="accountRole === 'maintainer'">
                        <b>
                            <a
                                href="#Datenschutz"
                            >Anlage: Nutzungsordnung und Datenschutzinformation für Administratoren
                            </a></b>
                    </p>
                    <div
                        v-if="accountRole === 'maintainer' && usage !== 'einwilligung' && usage !== 'extern'"
                        class="d-flex flex-row justify-center align-center"
                    >
                        <button
                            class="d-flex flex-row justify-center align-center"
                            @click="
                                () => {
                                    showMore = !showMore;
                                    if (showMore) {
                                        jumpTo('showMore');
                                    }
                                }
                            "
                        >
                            <h3>Verarbeitungstätigkeit von eklara 2.0 LMS</h3>
                            <v-icon v-if="showMore">
                                mdi-chevron-up
                            </v-icon>
                            <v-icon v-else>
                                mdi-chevron-down
                            </v-icon>
                        </button>
                    </div>
                    <div v-if="showMore">
                        <br>
                        <h3>1. Verantwortliche Fachabteilung</h3>
                        <p>Ansprechpartner: {{ school?.schoolDataSecurityOfficer }}</p>
                        <p>Telefon: {{ school?.phone }}</p>
                        <p>E-Mail-Adresse: {{ school?.email }}</p>
                        <p>(Art. 30 Abs. 1 S. 2 lit a)</p>
                        <br>
                        <p>
                            Angeben zum Datenschutzbeauftragten:
                            {{ school?.generalDataSecurityOfficer }}
                        </p>
                        <br>
                        <h3 id="zweck">
                            2. Zwecke der Verarbeitung (Art. 30 Abs. 1 S. 2 lit b)
                        </h3>
                        <br>
                        <p>
                            <b>Durchführung von digital gestütztem Unterricht +
                                Kommunikation</b>
                        </p>
                        <p>
                            <ins>
                                auf der Rechtsgrundlage von DS-GVO Art. 6 lit. a (Einwilligung):
                            </ins>
                        </p>
                        <p>§120 SchulG Abs. 2 Satz 2, 3, §121 SchulG Abs. 1 Satz 5.</p>
                        <br>
                        <p>
                            Vermittlung von Medienkompetenzen barrierearm, Arbeit mit LMS,
                            Erfüllung staatlichen Bildungs- und Erziehungsauftrags insb. SBBZ.
                        </p>
                        <br>
                        <h3>
                            3. Beschreibung der Kategorien betroffener Personen (Art. 30 Abs.
                            1 S. 2 lit. c)
                        </h3>
                        <br>
                        <p>
                            (1) Schüler (Pseudonamen, Nutzernamen, Passwort, optional Vor- und
                            Nachnamen)
                        </p>
                        <p>
                            (2) Lehrkräfte (Pseudonamen, Nutzernamen, Passwort, optional Vor-
                            und Nachnamen)
                        </p>
                        <p>
                            (3) Schulleitung/ Verwaltung (Nutzernamen, Passwort, optional
                            Schulname, Adresse, Logo)
                        </p>

                        <p>
                            (4) Erziehungsberechtigte (Pseudonamen, Nutzernamen, Passwort,
                            optional Vor- und Nachnamen)
                        </p>
                        <p>
                            (5) Externe (Pseudonamen, Nutzernamen, Passwort, optional Vor- und
                            Nachnamen)
                        </p>
                        <br>
                        <h3>
                            4. Beschreibung der Kategorien von personenbezogenen Daten (Art.
                            30 Abs. 1 S. 2 lit. c)
                        </h3>
                        <br>
                        <p>
                            1. Stundenplan (Fächer, Zeiten, Lehrerzuweisung Kürzel,
                            Abwesenheiten, Raumzuordnung)
                        </p>
                        <p>
                            1.1 Ersteller I -> Inhalte, Aufgaben (Editor, Texte, Tabellen,
                            Präsentationen aller Dateiformate), Dateien
                        </p>
                        <p>
                            1.2 Ersteller II ->Inhalte, Aufgaben (Editor, Texte, Tabellen,
                            Präsentationen aller Dateiformate) inkl. Status (offen, erledigt,
                            korrigiert), Dateien, mit Nutzernamenlisten (Zuweisung)
                        </p>
                        <p>
                            1.3 Ersteller III -> Fächer, Räume, Zeiten, Nutzernamen
                            (Zuweisung),
                        </p>
                        <p>
                            2. Vertretungsplan (Fächer, Zeiten, Lehrerzuweisung Kürzel,
                            Abwesenheiten, Raumzuordnung)
                        </p>
                        <p>
                            2.1 Ersteller -> Anzahl verfügbarer Lehrstunden von Nutzern,
                            Abwesenheiten melden, Notizen
                        </p>
                        <p>
                            3. Nutzerverwaltung - Nutzer- und Rechteverwaltung (Fächer,
                            Lehrstunden, Gruppen/- Nutzerzuordnung, Kurse, Rollen, Notizen,
                            Inklusion Hilfswerkzeuge, Nutzernamen + Passwörter, Vorname &
                            Nachname optional, Einwilligung Nutzungsbedingungen, persönliche
                            Profileinstellungen wie Profilbilder, Inklusion Hilfswerkzeuge)
                        </p>
                        <p>
                            4. Schwarzes Brett (Mitteilung + ggf. Anhang, Datum, Empfänger,
                            Absender)
                        </p>
                        <p>
                            4.1 Ersteller -> Nutzernamenliste (Zuweisung), Status „Gesehen,
                            Nicht gesehen“
                        </p>
                        <p>
                            5. Chat (Mitteilung + ggf. Anhang, Datum, Empfänger, Absender,
                            Umfragen, Mikrofon falls freigegeben, Gruppenzuordnung)
                        </p>
                        <p>
                            5.1 Ersteller I -> Nutzernamenliste (Zuweisung), Status „Gesehen,
                            Nicht gesehen“, Umfragen optional mit Ergebnissen + optional mit
                            Nutzernamen,
                        </p>
                        <p>
                            5.2 Ersteller II -> Nutzernamenliste (Zuweisung), Status „Gesehen,
                            Nicht gesehen“
                        </p>
                        <p>
                            6. Elternbriefe (Mitteilung + ggf. Anhang, Datum, Nutzername vom
                            Empfänger und Absender, Umfragen, Status: angenommen, abgelehnt,)
                        </p>
                        <p>
                            6.1 Ersteller -> Nutzernamenliste (Zuweisung), Status „Gesehen,
                            Nicht gesehen“ mit Nutzernamen, Signatur optional, Umfragen
                            optional mit Ergebnissen + optional mit Nutzernamen,
                        </p>
                        <p>
                            7. Kalender (Termine + ggf. Anhang, Datum, Ort, Nutzername vom
                            Empfänger und Absender, Erinnerung)
                        </p>
                        <p>7.1 Ersteller -> Optionale Nutzernamenliste (Zuweisung),</p>
                        <p>
                            8. Klassenbuch (Abwesenheiten, Zeiten, Nutzerlisten, Fachinfo,
                            Notizen, Lehrernamen mit Kürzel, Datum, Gruppe/ Kursangabe)
                        </p>
                        <p>
                            9. Videoräume – BigBlueButton (Nutzernamenliste + Chat + Notizen +
                            Präsentationen + Kamera + Mikrofon + Bilder + Dateien, Bildschirm
                            falls freigegeben)
                        </p>
                        <p>
                            9.1 Ersteller -> Nutzernamenliste (Zuweisung) optional
                            ausblendbar, Chat + geteilte Notizen optional ausblendbar,
                        </p>

                        <p>
                            10. Dateienablagen (inkl. Lernpfade) (Dateien, Aufgaben, Ordner)
                        </p>
                        <p>
                            10.1 Ersteller -> Nutzernamenliste (Zuweisung) + Datum + Status
                            Aufgaben (offen, erledigt, korrigiert),
                        </p>
                        <p>11. Internet – Verlinkungen zu anderen Internetseiten</p>
                        <p>11.1 Ersteller -> Nutzernamenliste (Zuweisung)</p>
                        <p>
                            12. Geräteverleih (Gerät + Zustand, Ausleihfristen,
                            Nutzernamenliste)
                        </p>
                        <p>13. Bibliothek (Aufgaben, Grafiken)</p>
                        <p>14. Editor (Arbeitsblatt-Erstellungswerkzeug)</p>
                        <p>15. Texte (Arbeitswerkzeug)</p>
                        <p>16. Tabellen (Arbeitswerkzeug)</p>
                        <p>17. Präsentationen (Arbeitswerkzeug)</p>
                        <p>
                            18. Pinnwand (Arbeits- Kommunikationswerkzeug) (Dateien,
                            Textinhalte)
                        </p>
                        <p>
                            18.1 Ersteller -> Nutzernamenliste (Zuweisungen – Sehen,
                            Bearbeiten, Autor)
                        </p>
                        <p>19. Profileinstellungen (Avatar, Inklusion Hilfswerkzeuge)</p>
                        <p>
                            19.1 Ersteller I -> Benachrichtigung per Email und Ruhezeiten,
                        </p>
                        <p>
                            19.2 Ersteller II -> Profilbild Uploadfunktion, Benachrichtigung
                            per Email, Ruhezeiten, Zensurliste,
                        </p>
                        <p>
                            19.3 Ersteller III -> Profilbild Uploadfunktion, Benachrichtigung
                            per Email, Ruhezeiten, Zensurliste, Briefkopf optional: Schulname,
                            Straße + Nummer, Postleitzahl, E-Mail, Telefon, Logo)
                        </p>
                        <br>
                        <p>
                            <b>Für die technische Funktionalität werden folgende Daten
                                benötigt:</b>
                        </p>
                        <p><ins>Protokollierte Daten / Log Files:</ins></p>
                        <p>-IP-Adresse des anfragenden Servers</p>
                        <p>-Zeitpunkt der Anfrage</p>
                        <p>-HTTPs Request-Line (Methode, URL, Version)</p>
                        <p>-HTTPs Status-Line</p>
                        <p>-Größe der Antwort in Bytes</p>
                        <p>-User-Agent</p>
                        <br>
                        <p>
                            <ins>
                                Im Falle von einer fehlerhaften HTTPs Anfrage mit einem
                                Statuscode im Bereich 400 bis 599 wird zusätzlich folgendes
                                protokolliert:</ins>
                        </p>
                        <p>-HTTPs Headers</p>
                        <p>-Anfragender Account wenn vorhanden</p>
                        <p>-HTTPs Message Body</p>
                        <br>
                        <p>
                            <ins>Im Falle der Nutzung der integrierten Videokonferenzsoftware
                                BigBlueButton werden ebenfalls folgende Daten
                                protokolliert:</ins>
                        </p>
                        <br>
                        <p>
                            Im Falle der Nutzung der integrierten Videokonferenzsoftware
                            BigBlueButton werden ebenfalls folgende Daten protokolliert:
                        </p>
                        <p>-Zeitpunkt der Anfrage</p>
                        <p>-HTTPs Request-Line (Methode, URL, Version)</p>
                        <p>-HTTPs Status-Line</p>
                        <p>-Größe der Antwort in Bytes</p>
                        <p>-User-Agent</p>
                        <p>Bei Ein- und Austritt in Videokonferenz Nutzernamen:</p>
                        <p>-Zeitpunkt des Abrufs einer Chatnachricht</p>
                        <p>-Die IP-Adresse einer NAT (Network Address Translation)</p>
                        <br>
                        <h3>
                            4.1 Kategorien von Empfängern, gegenüber denen die
                            personenbezogenen Daten offen gelegt worden sind oder noch werden.
                            (Art. 30 Abs. 1 S. 2 lit.)
                        </h3>
                        <br>
                        <p>
                            Intern (Zugriffsberechtigte) - Nummern stellen Kategorien aus (4.)
                            dar.
                        </p>
                        <br>
                        <p>
                            <b>1. Schulleitung</b>– Nach Anweisung durch die Schulleitung auch
                            an schulische Verwaltung. Folgende Kategorien:
                        </p>
                        <p>
                            1. Stundenplan (Fächer, Zeiten, Lehrerzuweisung Kürzel,
                            Abwesenheiten, Raumzuordnung)
                        </p>
                        <p>
                            1. Stundenplan (Fächer, Zeiten, Lehrerzuweisung Kürzel,
                            Abwesenheiten, Raumzuordnung)
                        </p>
                        <p>
                            1.3 Ersteller III -> Fächer, Räume, Zeiten, Nutzernamen
                            (Zuweisung),
                        </p>
                        <p>
                            2. Vertretungsplan (Fächer, Zeiten, Lehrerzuweisung Kürzel,
                            Abwesenheiten, Raumzuordnung)
                        </p>
                        <p>
                            2.1 Ersteller -> Anzahl verfügbarer Lehrstunden von Nutzern,
                            Abwesenheiten melden, Notizen
                        </p>
                        <p>
                            3. Nutzerverwaltung - Nutzer- und Rechteverwaltung (Fächer,
                            Lehrstunden, Gruppen/- Nutzerzuordnung, Kurse, Rollen, Notizen,
                            Inklusion Hilfswerkzeuge, Nutzernamen + Passwörter, Vorname &
                            Nachname optional, Einwilligung Nutzungsbedingungen, persönliche
                            Profileinstellungen wie Profilbilder, Inklusion Hilfswerkzeuge)
                        </p>
                        <p>
                            4. Schwarzes Brett (Mitteilung + ggf. Anhang, Datum, Empfänger,
                            Absender)
                        </p>
                        <p>
                            4.1 Ersteller -> Nutzernamenliste (Zuweisung), Status „Gesehen,
                            Nicht gesehen“
                        </p>
                        <p>
                            5. Chat (Mitteilung + ggf. Anhang, Datum, Empfänger, Absender,
                            Umfragen, Mikrofon falls freigegeben, Gruppenzuordnung)
                        </p>
                        <p>
                            5.1 Ersteller I -> Nutzernamenliste (Zuweisung), Status „Gesehen,
                            Nicht gesehen“, Umfragen optional mit Ergebnissen + optional mit
                            Nutzernamen,
                        </p>
                        <p>
                            6. Elternbriefe (Mitteilung + ggf. Anhang, Datum, Nutzername vom
                            Empfänger und Absender, Umfragen, Status: angenommen, abgelehnt,)
                        </p>
                        <p>
                            6.1 Ersteller -> Nutzernamenliste (Zuweisung), Status „Gesehen,
                            Nicht gesehen“ mit Nutzernamen, Signatur optional, Umfragen
                            optional mit Ergebnissen + optional mit Nutzernamen,
                        </p>
                        <p>
                            7. Kalender (Termine + ggf. Anhang, Datum, Ort, Nutzername vom
                            Empfänger und Absender, Erinnerung)
                        </p>
                        <p>7.1 Ersteller -> Optionale Nutzernamenliste (Zuweisung),</p>
                        <p>
                            8. Klassenbuch (Abwesenheiten, Zeiten, Nutzerlisten, Fachinfo,
                            Notizen, Lehrernamen mit Kürzel, Datum, Gruppe/ Kursangabe)
                        </p>
                        <p>
                            9. Videoräume – BigBlueButton (Nutzernamenliste + Chat + Notizen +
                            Präsentationen + Kamera + Mikrofon + Bilder + Dateien, Bildschirm
                            falls freigegeben)
                        </p>
                        <p>
                            9.1 Ersteller -> Nutzernamenliste (Zuweisung) optional
                            ausblendbar, Chat + geteilte Notizen optional ausblendbar,
                        </p>
                        <p>11. Internet – Verlinkungen zu anderen Internetseiten</p>
                        <p>11.1 Ersteller -> Nutzernamenliste (Zuweisung),</p>
                        <p>
                            12. Geräteverleih (Gerät + Zustand, Ausleihfristen,
                            Nutzernamenliste)
                        </p>
                        <p>
                            18. Pinnwand (Arbeits- Kommunikationswerkzeug) (Dateien,
                            Textinhalte)
                        </p>
                        <p>
                            18.1 Ersteller -> Nutzernamenliste (Zuweisungen – Sehen,
                            Bearbeiten, Autor)
                        </p>
                        <p>19. Profileinstellungen (Avatar, Inklusion Hilfswerkzeuge)</p>
                        <p>
                            19.3 Ersteller III -> Profilbild Uploadfunktion, Benachrichtigung
                            per Email, Ruhezeiten, Zensurliste, Briefkopf optional: Schulname,
                            Straße + Nummer, Postleitzahl, E-Mail, Telefon, Logo)
                        </p>
                        <br>
                        <p><b>2. Lehrkräfte </b>- Folgende Kategorien:</p>

                        <p>
                            1. Stundenplan (Fächer, Zeiten, Lehrerzuweisung Kürzel,
                            Abwesenheiten, Raumzuordnung)
                        </p>
                        <p>
                            1.2 Ersteller II ->Inhalte, Aufgaben (Editor, Texte, Tabellen,
                            Präsentationen aller Dateiformate) inkl. Status (offen, erledigt,
                            korrigiert), Dateien, mit Nutzernamenlisten (Zuweisung)
                        </p>
                        <p>
                            2. Vertretungsplan (Fächer, Zeiten, Lehrerzuweisung Kürzel,
                            Abwesenheiten, Raumzuordnung)
                        </p>
                        <p>
                            2.1 Ersteller -> Anzahl verfügbarer Lehrstunden von Nutzern,
                            Abwesenheiten melden, Notizen
                        </p>
                        <p>
                            4. Schwarzes Brett (Mitteilung + ggf. Anhang, Datum, Empfänger,
                            Absender)
                        </p>
                        <p>
                            4.1 Ersteller -> Nutzernamenliste (Zuweisung), Status „Gesehen,
                            Nicht gesehen“
                        </p>
                        <p>
                            5. Chat (Mitteilung + ggf. Anhang, Datum, Empfänger, Absender,
                            Umfragen, Mikrofon falls freigegeben, Gruppenzuordnung)
                        </p>
                        <p>
                            5.1 Ersteller I -> Nutzernamenliste (Zuweisung), Status „Gesehen,
                            Nicht gesehen“, Umfragen optional mit Ergebnissen + optional mit
                            Nutzernamen,
                        </p>
                        <p>
                            6. Elternbriefe (Mitteilung + ggf. Anhang, Datum, Nutzername vom
                            Empfänger und Absender, Umfragen, Status: angenommen, abgelehnt,)
                        </p>
                        <p>
                            6.1 Ersteller -> Nutzernamenliste (Zuweisung), Status „Gesehen,
                            Nicht gesehen“ mit Nutzernamen, Signatur optional, Umfragen
                            optional mit Ergebnissen + optional mit Nutzernamen,
                        </p>
                        <p>
                            7. Kalender (Termine + ggf. Anhang, Datum, Ort, Nutzername vom
                            Empfänger und Absender, Erinnerung)
                        </p>
                        <p>7.1 Ersteller -> Optionale Nutzernamenliste (Zuweisung),</p>
                        <p>
                            8. Klassenbuch (Abwesenheiten, Zeiten, Nutzerlisten, Fachinfo,
                            Notizen, Lehrernamen mit Kürzel, Datum, Gruppe/ Kursangabe)
                        </p>
                        <p>
                            9. Videoräume – BigBlueButton (Nutzernamenliste + Chat + Notizen +
                            Präsentationen + Kamera + Mikrofon + Bilder + Dateien, Bildschirm
                            falls freigegeben)
                        </p>
                        <p>
                            9.1 Ersteller -> Nutzernamenliste (Zuweisung) optional
                            ausblendbar, Chat + geteilte Notizen optional ausblendbar,
                        </p>
                        <p>
                            10. Dateienablagen (inkl. Lernpfade) (Dateien, Aufgaben, Ordner)
                            10.1 Ersteller -> Nutzernamenliste (Zuweisung) + Datum + Status
                            Aufgaben (offen, erledigt, korrigiert),
                        </p>
                        <p>11. Internet – Verlinkungen zu anderen Internetseiten</p>
                        <p>11.1 Ersteller -> Nutzernamenliste (Zuweisung),</p>
                        <p>
                            12. Geräteverleih (Gerät + Zustand, Ausleihfristen,
                            Nutzernamenliste)
                        </p>
                        <p>13. Bibliothek (Aufgaben, Grafiken)</p>
                        <p>14. Editor (Arbeitsblatt-Erstellungswerkzeug)</p>
                        <p>15. Texte (Arbeitswerkzeug)</p>
                        <p>16. Tabellen (Arbeitswerkzeug)</p>
                        <p>17. Präsentationen (Arbeitswerkzeug)</p>
                        <p>
                            18. Pinnwand (Arbeits- Kommunikationswerkzeug) (Dateien,
                            Textinhalte)
                        </p>
                        <p>
                            18.1 Ersteller -> Nutzernamenliste (Zuweisungen – Sehen,
                            Bearbeiten, Autor)
                        </p>
                        <p>19. Profileinstellungen (Avatar, Inklusion Hilfswerkzeuge)</p>
                        <p>
                            19.1 Ersteller I -> Benachrichtigung per Email und Ruhezeiten,
                        </p>
                        <p>
                            19.2 Ersteller II -> Profilbild Uploadfunktion, Benachrichtigung
                            per Email, Ruhezeiten, Zensurliste,
                        </p>
                        <br>
                        <p><b>3. Schüler</b>- Folgende Kategorien</p>
                        <p>
                            1. Stundenplan (Fächer, Zeiten, Lehrerzuweisung Kürzel,
                            Abwesenheiten, Raumzuordnung)
                        </p>
                        <p>
                            1.1 Ersteller I -> Inhalte, Aufgaben (Editor, Texte, Tabellen,
                            Präsentationen aller Dateiformate), Dateien
                        </p>
                        <p>
                            2. Vertretungsplan (Fächer, Zeiten, Lehrerzuweisung Kürzel,
                            Abwesenheiten, Raumzuordnung)
                        </p>
                        <p>
                            4. Schwarzes Brett (Mitteilung + ggf. Anhang, Datum, Empfänger,
                            Absender)
                        </p>
                        <p>
                            5. Chat (Mitteilung + ggf. Anhang, Datum, Empfänger, Absender,
                            Umfragen, Mikrofon falls freigegeben, Gruppenzuordnung)
                        </p>
                        <p>
                            7. Kalender (Termine + ggf. Anhang, Datum, Ort, Nutzername vom
                            Empfänger und Absender, Erinnerung)
                        </p>
                        <p>
                            9. Videoräume – BigBlueButton (Nutzernamenliste + Chat + Notizen +
                            Präsentationen + Kamera + Mikrofon + Bilder + Dateien, Bildschirm
                            falls freigegeben)
                        </p>
                        <p>
                            10. Dateienablagen (inkl. Lernpfade) (Dateien, Aufgaben, Ordner)
                        </p>

                        <p>11. Internet – Verlinkungen zu anderen Internetseiten</p>
                        <p>14. Editor (Arbeitsblatt-Erstellungswerkzeug)</p>
                        <p>15. Texte (Arbeitswerkzeug)</p>
                        <p>16. Tabellen (Arbeitswerkzeug)</p>
                        <p>17. Präsentationen (Arbeitswerkzeug)</p>
                        <p>
                            18. Pinnwand (Arbeits- Kommunikationswerkzeug) (Dateien,
                            Textinhalte)
                        </p>
                        <br>
                        <p><b>4. Eltern </b>– Folgende Kategorien:</p>
                        <p>
                            1. Stundenplan (Fächer, Zeiten, Lehrerzuweisung Kürzel,
                            Abwesenheiten, Raumzuordnung)
                        </p>
                        <p>
                            2. Vertretungsplan (Fächer, Zeiten, Lehrerzuweisung Kürzel,
                            Abwesenheiten, Raumzuordnung)
                        </p>
                        <p>
                            4. Schwarzes Brett (Mitteilung + ggf. Anhang, Datum, Empfänger,
                            Absender)
                        </p>
                        <p>
                            5. Chat (Mitteilung + ggf. Anhang, Datum, Empfänger, Absender,
                            Umfragen, Mikrofon falls freigegeben, Gruppenzuordnung)
                        </p>
                        <p>
                            6. Elternbriefe (Mitteilung + ggf. Anhang, Datum, Nutzername vom
                            Empfänger und Absender, Umfragen, Status: angenommen, abgelehnt,)
                        </p>
                        <p>
                            6.1 Ersteller -> Nutzernamenliste (Zuweisung), Status „Gesehen,
                            Nicht gesehen“ mit Nutzernamen, Signatur optional, Umfragen
                            optional mit Ergebnissen + optional mit Nutzernamen,
                        </p>

                        <p>
                            7. Kalender (Termine + ggf. Anhang, Datum, Ort, Nutzername vom
                            Empfänger und Absender, Erinnerung)
                        </p>
                        <p>
                            9. Videoräume – BigBlueButton (Nutzernamenliste + Chat + Notizen +
                            Präsentationen + Kamera + Mikrofon + Bilder + Dateien, Bildschirm
                            falls freigegeben)
                        </p>
                        <p>14. Editor (Arbeitsblatt-Erstellungswerkzeug)</p>
                        <p>15. Texte (Arbeitswerkzeug)</p>
                        <p>16. Tabellen (Arbeitswerkzeug)</p>
                        <p>17. Präsentationen (Arbeitswerkzeug)</p>

                        <p>
                            18. Pinnwand (Arbeits- Kommunikationswerkzeug) (Dateien,
                            Textinhalte)
                        </p>
                        <p>19. Profileinstellungen (Avatar, Inklusion Hilfswerkzeuge)</p>
                        <p>
                            19.1 Ersteller I -> Benachrichtigung per Email und Ruhezeiten,
                        </p>
                        <br>
                        <p><b>Externe</b> – Folgende Kategorien:</p>
                        <p>
                            9. Videoräume – BigBlueButton (Nutzernamenliste + Chat + Notizen +
                            Präsentationen + Kamera + Mikrofon + Bilder + Dateien, Bildschirm
                            falls freigegeben)
                        </p>
                        <br>
                        <p>Extern - Nummern stellen Kategorien aus (4.) dar.</p>
                        <ul>
                            <li>
                                <b>Administrator IT Dienstleister eklara by concept hero UG/
                                    Hostingprovider Hetzner GmbH/ ggf. Schulträger</b>
                                – alle Kategorien nach Anweisung durch die Schulleitung
                            </li>
                            <li>
                                <b>DeepL SE – Übersetzung von folgenden Inhalten. IP-maskiert,
                                    Übermittlung von Inhalten pseudonymisiert, personenbezogene
                                    Inhalte eklara/ nutzerseitig zensiert</b>
                                Die Nutzer werden hierüber umfassend informiert und müssen bei
                                der Nutzung der Übersetzungsfunktion und damit verbundenen
                                Datentransfer aktiv zu stimmen.
                            </li>
                            <li>
                                <p>
                                    1. Stundenplan (Fächer, Zeiten, Lehrerzuweisung Kürzel,
                                    Abwesenheiten, Raumzuordnung)
                                </p>
                                <p>
                                    1.1 Ersteller I -> Inhalte, Aufgaben (Editor, Texte, Tabellen,
                                    Präsentationen aller Dateiformate), Dateien
                                </p>
                                <p>
                                    2. Vertretungsplan (Fächer, Zeiten, Lehrerzuweisung Kürzel,
                                    Abwesenheiten, Raumzuordnung)
                                </p>
                                <p>
                                    4. Schwarzes Brett (Mitteilung + ggf. Anhang, Datum,
                                    Empfänger, Absender)
                                </p>
                                <p>
                                    5. Chat (Mitteilung + ggf. Anhang, Datum, Empfänger, Absender,
                                    Umfragen, Mikrofon falls freigegeben, Gruppenzuordnung)
                                </p>
                                <p>
                                    6. Elternbriefe (Mitteilung + ggf. Anhang, Datum, Nutzername
                                    vom Empfänger und Absender, Umfragen, Status: angenommen,
                                    abgelehnt,)
                                </p>
                                <p>
                                    6.1 Ersteller -> Nutzernamenliste (Zuweisung), Status
                                    „Gesehen, Nicht gesehen“ mit Nutzernamen, Signatur optional,
                                    Umfragen optional mit Ergebnissen + optional mit Nutzernamen,
                                </p>
                                <p>
                                    7. Kalender (Termine + ggf. Anhang, Datum, Ort, Nutzername vom
                                    Empfänger und Absender, Erinnerung)
                                </p>
                                <p>
                                    9. Videoräume – BigBlueButton (Nutzernamenliste + Chat +
                                    Notizen + Präsentationen + Kamera + Mikrofon + Bilder +
                                    Dateien, Bildschirm falls freigegeben)
                                </p>
                                <p>
                                    10. Dateienablagen (inkl. Lernpfade) (Dateien, Aufgaben,
                                    Ordner)
                                </p>
                                <p>11. Internet – Verlinkungen zu anderen Internetseiten</p>
                                <p>14. Editor (Arbeitsblatt-Erstellungswerkzeug)</p>
                                <p>15. Texte (Arbeitswerkzeug)</p>
                                <p>16. Tabellen (Arbeitswerkzeug)</p>
                                <p>17. Präsentationen (Arbeitswerkzeug)</p>

                                <p>
                                    18. Pinnwand (Arbeits- Kommunikationswerkzeug) (Dateien,
                                    Textinhalte)
                                </p>
                                <p>
                                    19. Profileinstellungen (Avatar, Inklusion Hilfswerkzeuge)
                                </p>
                                Die Nutzer werden hierüber umfassend informiert und müssen bei
                                der Nutzung der Übersetzungsfunktion und damit verbundenen
                                Datentransfer aktiv zu stimmen.
                            </li>
                            <li><b>Betroffene </b>nach Art. 15 DS-GVO</li>
                        </ul>
                        <br>
                        <h3>
                            5. ggf. Übermittlungen von personenbezogenen Daten an ein
                            Drittland oder an eine internationale Organisation (Art. 30 Abs. 1
                            S. 2 lit. e)
                        </h3>
                        <br>
                        <p>Nennung der konkreten Datenempfänger</p>
                        <p>
                            Sofern es sich um eine in Art. 49 Abs. 1 Unterabsatz 2 DS-GVO
                            genannte Datenübermittlung handelt.
                        </p>
                        <br>

                        <p>
                            X Datenübermittlung in ein Drittland findet nicht statt und ist
                            auch nicht geplant
                        </p>
                        <br>
                        <h3>
                            6. Fristen für die Löschung der verschiedenen Datenkategorien
                            (Art. 30 Abs. 1 S. 2 lit. f)
                        </h3>
                        <br>
                        <p><b>Zugangsdaten/ Rollen/ Rechte/ Nutzerprofil:</b></p>
                        <p>
                            Löschung (Jederzeit möglich, meist erst nach 12 Monaten
                            (Schuljahr))
                        </p>
                        <p>
                            <b>Nutzerdaten/ erstellte Dateien/ Kursdateien/ andere Inhalte:</b>
                        </p>
                        <p>
                            Kommunikationsinhalte, die in eklara eingestellt, erstellt werden,
                            werden für eine Dauer von 12 Monaten aufbewahrt.
                        </p>
                        <p>
                            Aufgaben, Dateien die in eklara erstellt, bearbeitet, hochgeladen
                            werden, werden nach 30 Tagen gelöscht. Allerdings können sämtliche
                            von generierten Inhalte selbst mit sofortiger Wirkung gelöscht
                            oder in gesonderten eklara Ordnern bis zu 12 Monate aufbewahrt
                            werden.
                        </p>
                        <p>
                            <b>Log-Files</b> (auch protokollierte Daten genannt) werden zum
                            Zwecke von Fehleranalysen und Softwareoptimierung für eine Dauer
                            von 30 Tagen gespeichert.
                        </p>
                        <p>
                            <b>BigBlueButton</b> Inhalte und Informationen aus
                            Videokonferenzen werden nicht gespeichert sondern nach Beendigung
                            der Konferenz gelöscht.
                        </p>
                        <p>
                            Nach Schuljahresende kann eklara manuell von der Schulleitung
                            zurückgesetzt werden. Dateien, Inhalte würden gelöscht werden.
                        </p>
                        <br>
                        <h3>
                            7. Allgemeine Beschreibung der technischen und organisatorischen
                            Maßnahmen gemäß Artikel 32 Absatz 1 EU-DSGVO
                        </h3>
                        <br>
                        <p>
                            Siehe Technische und Organisatorische Maßnahmen (TOM) von EKLARA
                            <a href="#anlagen">(vgl. Anlage).</a>
                        </p>
                        <br>
                        <h3>
                            8.1 Rechtmäßigkeit, Verarbeitung nach Treu und Glauben,
                            Transparenz
                        </h3>
                        <br>
                        <p>
                            <b>Schüler, Eltern</b>- Art. 6 Abs. 1 lit e DSGVO i. V. mit § 4
                            LDSG i. V. m. §§ 1, 38 Abs. 6 Satz 2 SchulG sowie bei aktiver
                            Teilnahme an einer Videokonferenz die Einwilligung der
                            Schülerinnen und Schüler bzw. deren Erziehungsberechtigten und bei
                            der Nutzung der Übersetzungsfunktion die Einwilligung des
                            jeweiligen Nutzers gemäß Art. 6 Abs. 1 lit a DSGVO.
                        </p>
                        <p>
                            <b>Lehrkräfte, Schulleitung, Verwaltung </b>- §15 Abs. 1 LDSG § 36
                            Abs. 1 und 2 LDSG in Verbindung mit § 84 Abs. 1
                            Landesbeamtengesetz (LBG), § 85 Abs. 1 LBG und § 88 Abs. 1, Sätze
                            1, 2, 4 LBG. Näheres zur konkreten Verarbeitung von
                            personenbezogenen Daten in Schulen ist in der
                            Verwaltungsvorschrift"Datenschutz an öffentlichen Schulen"
                            Abschnitte I, III, IV vom 05.12.2014 geregelt.
                        </p>
                        <br>
                        <h3>8.2 Zweckbindung</h3>
                        <br>
                        <p>
                            <b><a href="zweck">S. Punkt 2.</a></b>
                        </p>
                        <br>
                        <h3>
                            8.3, 8.4, 8.5. Datenminimierung, Richtigkeit, Speicherbegrenzung
                        </h3>
                        <br>
                        <p>
                            <b>Datenminimierung</b> - Art. 5 Abs. 1 lt. e) DSGVO
                            Personenbezogene Daten werden in einer Form gespeichert, welche
                            die Identifizierung der betroffenen Personen nur so lange
                            ermöglicht, wie es für die Zwecke, für die sie verarbeitet werden,
                            erforderlich ist.
                        </p>
                        <p>
                            <b>Speicherbegrenzung</b> - Personenbezogene Daten sind „dem Zweck
                            angemessen, erheblich sowie auf das für die Zwecke der
                            Verarbeitung notwendige Maß beschränkt.
                        </p>
                        <p>
                            <b>Richtigkeit </b>- Personenbezogene Daten, die im Hinblick auf
                            die Zwecke ihrer Verarbeitung unrichtig sind, können unverzüglich
                            gelöscht oder berichtigt werden. Durch Nutzer/ ggf. Schulleitung/
                            IT-Dienstleister eklara
                        </p>
                        <br>
                        <h3>8.6. Integrität, Vertraulichkeit, Verfügbarkeit</h3>
                        <br>
                        <p>
                            <b>Vertraulichkeit </b>– Dank klarer Nutzer-, Rechte-, und
                            Rollenverteilung in eklara können Daten und Inhalte nur von
                            hierfür berechtigten Nutzern eingesehen/ bearbeitet werden.
                            Analoge Schulausweisen mit Logindaten (Nutzername, Passwort)
                            schützen. Nutzer werden zur sicheren Aufbewahrung vor Dritten
                            informiert. Sicherstellung durch u.a. Schulungen und/ oder
                            unterschriebene Nutzererklärungen.
                        </p>
                        <p>
                            Der Zugriff auf die Serverdaten (Standort: Datacenter Hetzner
                            GmbH, Sigmundstrasse 135, Nürnberg, Am Datacenter-Park 08223
                            Falkenstein/Vogtland) seitens der Bildungseinrichtung erfolgt
                            zusätzlich durch eine einzigartige vergebene URL. Das einfache
                            Auffinden der URL in Suchmaschinen ist deaktiviert.
                        </p>
                        <p>
                            Server-Passwörter und Zugangsdaten werden vom IT-Dienstleister
                            eklara initial erstellt (Mandanten). Mindestkriterien (Mind. 8
                            Zeichen, 1 Buchstaben, 1 Zahl) Nach erstmaliger Inbetriebnahme
                            kann die Bildungseinrichtung die Zugangsdaten eigenständig ändern
                            – welche dem IT-Dienstleister nicht mehr bekannt würden.
                            Server-Passwörter sind länger, komplexer und haben im Vergleich
                            erhöhtere Sicherheitskriterien.
                        </p>
                        <p>
                            Die Verschlüsselung aller Daten/ Inhalte durch HTTPS, SSL (TLS
                            1.2) Standards ist sichergestellt.
                        </p>
                        <p>
                            Siehe Technische und Organisatorische Maßnahmen (TOM) von EKLARA
                            <a href="#anlagen">(vgl. Anlage)</a>.
                        </p>
                        <br>
                        <p>
                            <b>Gewährleistung der Integrität</b> - Es erfolgt über weitere
                            angemietete deutsche Schulserver das Backup/ die Sicherungskopie
                            (Siehe die Technischen und Organisatorischen Maßnahmen von Hetzner
                            Online GmbH in der Anlage).
                        </p>
                        <p>
                            Servergewährleistung und Absicherung vor Dritten erfolgt über den
                            Serverprovider Hetzner GmbH mit Datacenter Standort Sigmundstrasse
                            135, Nürnberg. 14 Tage (festzulegen und einstellbar)
                            Backupsicherung, dann automatische Löschung.
                        </p>
                        <p>
                            <b>Log-Files</b>(auch protokollierte Daten genannt) werden zum
                            Zwecke von Fehleranalysen und Softwareoptimierung für eine Dauer
                            von 30 Tagen gespeichert.
                        </p>
                        <br>
                        <p>-IP-Adresse des anfragenden Servers</p>
                        <p>-Zeitpunkt der Anfrage</p>
                        <p>-HTTPs Request-Line (Methode, URL, Version)</p>
                        <p>-HTTPs Status-Line</p>
                        <p>-Größe der Antwort in Bytes</p>
                        <p>-User-Agent</p>

                        <p>
                            <b>Verfügbarkeit</b> – Der Serverprovider Hetzner GmbH garantiert
                            eine Verfügbarkeit der Daten von 99,48%.
                        </p>
                        <br>
                        <h3>
                            8.7 Internen Überprüfung, Bewertung und Evaluierung zur
                            Datenschutzgewährleistung
                        </h3>
                        <br>
                        <p>
                            Interne Datenschutzleitlinien im Umgang mit personenbezogenen
                            Daten werden schriftlich festgehalten und in entsprechenden
                            Schulungen oder unterschriebenen Nutzererklärungen vermittelt.
                        </p>
                        <br>
                        <p>
                            Zum Verantwortlichen zur Durchführung wurde folgende Person in der
                            Bildungseinrichtung ernannt:
                        </p>
                        <p>
                            <b>{{ school?.schoolDataSecurityOfficer }}</b>
                        </p>
                        <p>
                            Datenschutzverletzungen werden durch Mehraugenprinzip
                            identifiziert, mögliche Folgen abgeschätzt, Betroffene informiert.
                            Nachkorrekturen und Maßnahmen zur Vermeidung derselben
                            Verletzungen werden erarbeitet.
                        </p>
                        <p id="anlagen">
                            <b>Anlagen</b>
                        </p>
                        <p><a @click="downloadAVV">2023-01-01_ADV_TOM_eklara.pdf</a></p>
                    </div>
                </div>
                <!-- </v-card-text> -->
            </vue-custom-scrollbar>
            <div class="d-flex flex-column align-center pa-2">
                <div v-if="!revoke">
                    <div class="d-flex flex-row pa-0">
                        <p>Ich habe folgende Dokumente gelesen und stimme diesen zu:</p>
                    </div>
                    <div class="d-flex flex-column mb-2">
                        <div class="d-flex flex-row justify-flex-start">
                            <v-checkbox
                                v-model="confirmUsageAgreement"
                                class="pa-0 mt-0 mb-1"
                                label="Nutzungsordnung"
                                style="min-height: 0px !important"
                                dense
                                :hide-details="true"
                                @click="jumpTo('Nutzungsordnung')"
                            />
                            <v-btn
                                class="pa-0 ml-2"
                                x-small
                                @click="downloadNutzungsordnung(accountRole)"
                            >
                                <img
                                    :src="downloadIcon"
                                    class="icon20"
                                    style="cursor: pointer"
                                    alt="Datei herunterladen"
                                >
                            </v-btn>
                        </div>
                        <div class="d-flex flex-row justify-flex-start">
                            <v-checkbox
                                v-model="confirmDataProtectionAgreement"
                                class="pa-0 mt-0 mb-1"
                                label="Datenschutzinformation"
                                dense
                                :hide-details="true"
                                @click="jumpTo('Datenschutz')"
                            /><v-btn
                                class="pa-0 ml-2"
                                x-small
                                @click="downloadDatenschutz(accountRole)"
                            >
                                <img
                                    :src="downloadIcon"
                                    class="icon20"
                                    style="cursor: pointer"
                                    alt="Datei herunterladen"
                                >
                            </v-btn>
                        </div>
                        <div
                            v-if="accountRole === 'parent' || usage === 'extern'"
                            class="d-flex flex-row justify-flex-start"
                        >
                            <v-checkbox
                                v-model="confirmAgreement"
                                class="pa-0 mt-0 mb-1"
                                label="Einwilligungserklärung"
                                dense
                                :hide-details="true"
                                @click="jumpTo('Einwilligungserklärung')"
                            />
                            <v-btn
                                class="pa-0 ml-2"
                                x-small
                                @click="downloadEinwilligung(accountRole)"
                            >
                                <img
                                    :src="downloadIcon"
                                    class="icon20"
                                    style="cursor: pointer"
                                    alt="Datei herunterladen"
                                >
                            </v-btn>
                        </div>
                        
                        <div
                            v-if="usage === 'extern'"
                            class="d-flex flex-row justify-flex-start"
                        >
                            <v-text-field
                                v-model="name"
                                label="Vor- und Nachname"
                                outlined
                                hide-details
                                solo
                                flat
                                type="text"
                                :height="20"
                                :error="name === ''"
                                style="max-width: 300px; "
                            />
                            <!-- <div class="mr-2" />
                            <v-text-field
                                v-model="lastName"
                                label="Nachname"
                                outlined
                                solo
                                flat
                                type="text"
                                hide-details
                                height="15"
                                style="max-width: 150px;"
                                :error="lastName === ''"
                            /> -->
                        </div>
                    </div>
                    <div class="d-flex flex-row justify-space-between align-center">
                        <v-btn
                            class="mb-3 mr-3"
                            color="error"
                            @click="decline"
                        >
                            Ablehnen
                        </v-btn>
                        <v-btn
                            class="mb-3 ml-3"
                            color="success"
                            :disabled="!disableButton"
                            @click="confirmSignUsageAgreement"
                        >
                            Speichern
                        </v-btn>
                    </div>
                </div>
                <div v-else>
                    <div class="d-flex flex-row justify-space-around align-center">
                        <v-checkbox
                            v-model="revokeCheckbox"
                            class="mr-2"
                        />
                        <div class="d-flex flex-column justify-space-around">
                            <p>
                                Ich möchte meine Zustimmung zu dieser Einverständniserklärung
                                zurücknehmen.
                            </p>
                            <p
                                v-if="revokeCheckbox"
                                style="color: red; font-size: small"
                            >
                                Achtung: Mit dem Widerruf werden sie ausgeloggt und keinen
                                Zugriff mehr auf die Inhalte und Funktionen von eklara
                            </p>
                        </div>
                    </div>
                    <div class="d-flex flex-row justify-space-between align-center">
                        <v-btn
                            class="mb-3 mr-3"
                            @click="decline"
                        >
                            Abbrechen
                        </v-btn>
                        <v-btn
                            class="mb-3 ml-3"
                            color="success"
                            :disabled="!revokeCheckbox"
                            @click="revokeAgreement"
                        >
                            Speichern
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>


<script>
import { mapActions, mapGetters, mapState, mapMutations} from "vuex";
import * as backend from "../../api/backend";
import vueCustomScrollbar from "vue-custom-scrollbar";
import unterschriftIcon from "@/assets/Icons/unterschrift-113.svg";
import NutzungsordnungEltern from "@/assets/PDF/templates/Nutzungsordnung_eklara_Erziehungsberechtigte.pdf";
import NutzungsordnungSchueler from "@/assets/PDF/templates/Nutzungsordnung_eklara_SuS.pdf";
import NutzungsordnungLehrer from "@/assets/PDF/templates/Nutzungsordnung_eklara_LK.pdf";
import NutzungsordnungAdmin from "@/assets/PDF/templates/Nutzungsordnung_eklara_Administratoren.pdf";
import NutzungsordnungExtern from "@/assets/PDF/templates/Nutzungsordnung_eklara_Externe.pdf";
import DatenschutzEltern from "@/assets/PDF/templates/Datenschutzinformation_eklara_Erziehungsberechtigte.pdf";
import DatenschutzLehrer from "@/assets/PDF/templates/Datenschutzinformation_eklara_LK.pdf";
import DatenschutzSchueler from "@/assets/PDF/templates/Datenschutzinformation_eklara_SuS.pdf";
import DatenschutzAdmin from "@/assets/PDF/templates/Datenschutzerklärung_eklara_Administratoren.pdf";
import DatenschutzExtern from "@/assets/PDF/templates/Datenschutzerklärung_eklara_Externe.pdf";
import EinwilligungEltern from "@/assets/PDF/templates/Einwilligungserklärung_Erziehungsberechtigte.pdf";
import EinwilligungLehrer from "@/assets/PDF/templates/Einwilligungserklärung_eklara_LuL.pdf";
import EinwilligungSchueler from "@/assets/PDF/templates/Einwilligungserklärung_eklara_SuS.pdf";
import EinwilligungAdmin from "@/assets/PDF/templates/Einwilligungserklärung_eklara_Administratoren.pdf";
import EinwilligungExtern from "@/assets/PDF/templates/Einwilligungserklärung_eklara_Externe.pdf";

import downloadIcon from "@/assets/Icons/FaceliftIcons/herunterladen.svg";

export default {
  name: "Nutzungsbedingungen",
  components: { vueCustomScrollbar },
  props: {
    usageAgreementNotSigned: { required: true, default: false },
    currentAccount: { required: false },
    revoke: { required: true, default: false },
    usage: { required: false, default: "" },
  },
  data: () => ({
    name: '',
    lastName: '',

    isAtBottom: false,
    confirmUsageAgreement: false,
    confirmDataProtectionAgreement: false,
    revokeCheckbox: false,
    confirmAgreement: false,
    userName: null,
    schoolName: null,
    groupName: null,
    showMore: false,
    school: null,

    settings: {
      suppressScrollY: false,
      suppressScrollX: true,
      wheelPropagation: false,
    },
    unterschriftIcon,
    NutzungsordnungEltern,
    NutzungsordnungSchueler,
    NutzungsordnungLehrer,
    NutzungsordnungAdmin,
    NutzungsordnungExtern,
    DatenschutzEltern,
    DatenschutzLehrer,
    DatenschutzSchueler,
    DatenschutzAdmin,
    DatenschutzExtern,
    EinwilligungEltern,
    EinwilligungLehrer,
    EinwilligungSchueler,
    EinwilligungExtern,
    EinwilligungAdmin,
    downloadIcon,
  }),
  computed: {
    ...mapGetters("auth", ["accountRole"]),
    ...mapState("accounts", ["me"]),
    ...mapState('util', ['windowWidth']),

    disableButton() {

    if(this.accountRole ==='parent' || this.usage === 'extern')
    {
    return (
            this.confirmDataProtectionAgreement &&
            this.confirmUsageAgreement &&
            this.confirmAgreement
        );
    }
    return   this.confirmDataProtectionAgreement &&
        this.confirmUsageAgreement 
    },
    externName(){
        return this.name + this.lastName;
    }
  },
  watch: {
    async currentAccount(newVal, oldVal) {
      if (newVal && newVal !== null) {
        await this.pullMe(this.currentAccount);
        if (this.accountRole === "parent") {
          this.userName = this.currentAccount.accountName;
        }
      }
    },
  },
  async beforeMount() {
    if (this.currentAccount) {
      await this.pullMe(this.currentAccount);
    }
    this.school = await this.getSchool();
  },
  async mounted() {
    this.userName =
      this.accountRole === "parent"
        ? this.currentAccount.accountName
        : this.accountRole === "maintainer"
        ? "Verwaltung"
        : `${this.me.name} ${this.me.lastName}`;
    // this.school = await this.getSchool();
  },
  methods: {
    ...mapActions("auth", ["logoutUser"]),
    ...mapActions("auth", ["acceptTermsOfService"]),
    ...mapActions("accounts", ["toggleUsageAgreement", "pullMe"]),
    ...mapActions("school", ["getSchool", "postSchool"]),
    ...mapMutations('snackbar', ['showSnackbar']),
    async downloadNutzungsordnung(role) {
      let pdfBytes = "";
      if(this.usage === 'extern'){
            pdfBytes = await fetch(this.NutzungsordnungExtern).then((res) =>
                        res.arrayBuffer()
                    );
                    this.saveByteArray("Nutzungsordnung_Externe", pdfBytes);
                    return;
      }else{

      switch (role) {
        case "parent":
          pdfBytes = await fetch(this.NutzungsordnungEltern).then((res) =>
            res.arrayBuffer()
          );
          this.saveByteArray("Nutzungsordnung_Erziehungsberechtigte", pdfBytes);
          return;
        case "pupil":
          pdfBytes = await fetch(this.NutzungsordnungSchueler).then((res) =>
            res.arrayBuffer()
          );
          this.saveByteArray("Nutzungsordnung_SuS", pdfBytes);
          return;
        case "teacher":
          pdfBytes = await fetch(this.NutzungsordnungLehrer).then((res) =>
            res.arrayBuffer()
          );
          this.saveByteArray("Nutzungsordnung_LK", pdfBytes);
          return;
        case "maintainer":
          pdfBytes = await fetch(this.NutzungsordnungAdmin).then((res) =>
            res.arrayBuffer()
          );
          this.saveByteArray("Nutzungsordnung_Administratoren", pdfBytes);
          break;
      }
      }
    },
    async downloadEinwilligung(role) {
      let pdfBytes = "";
    if(this.usage === 'extern'){
            pdfBytes = await fetch(this.EinwilligungExtern).then((res) =>
                        res.arrayBuffer()
                    );
                    this.saveByteArray("Einwilligungserklärung_Externe", pdfBytes);
                    return;
      }else{
      switch (role) {
        case "parent":
          pdfBytes = await fetch(this.EinwilligungEltern).then((res) =>
            res.arrayBuffer()
          );
          this.saveByteArray(
            "Einwilligungserklärung_Erziehungsberechtigte",
            pdfBytes
          );
          return;
        case "pupil":
          pdfBytes = await fetch(this.EinwilligungSchueler).then((res) =>
            res.arrayBuffer()
          );
          this.saveByteArray("Einwilligungserklärung_SuS", pdfBytes);
          return;
        case "teacher":
          pdfBytes = await fetch(this.EinwilligungLehrer).then((res) =>
            res.arrayBuffer()
          );
          this.saveByteArray("Einwilligungserklärung_LK", pdfBytes);
          return;
        case "maintainer":
          pdfBytes = await fetch(this.EinwilligungAdmin).then((res) =>
            res.arrayBuffer()
          );
          this.saveByteArray(
            "Einwilligungserklärung_Administratoren",
            pdfBytes
          );
          return;
      }
      }
    },
    async downloadDatenschutz(role) {
      let pdfBytes = "";
      if(this.usage === 'extern'){
            pdfBytes = await fetch(this.DatenschutzExtern).then((res) =>
                        res.arrayBuffer()
                    );
                    this.saveByteArray("Datenschutzinformation_Externe", pdfBytes);
                    return;
      }else{
      switch (role) {
        case "parent":
          pdfBytes = await fetch(this.DatenschutzEltern).then((res) =>
            res.arrayBuffer()
          );
          this.saveByteArray(
            "Datenschutzinformation_Erziehungsberechtigte",
            pdfBytes
          );
          return;
        case "pupil":
          pdfBytes = await fetch(this.DatenschutzSchueler).then((res) =>
            res.arrayBuffer()
          );
          this.saveByteArray("Datenschutzinformation_SuS", pdfBytes);
          return;
        case "teacher":
          pdfBytes = await fetch(this.DatenschutzLehrer).then((res) =>
            res.arrayBuffer()
          );
          this.saveByteArray("Datenschutzinformation_LK", pdfBytes);
          return;
        case "maintainer":
          pdfBytes = await fetch(this.DatenschutzAdmin).then((res) =>
            res.arrayBuffer()
          );
          this.saveByteArray(
            "Datenschutzinformation_Administratoren",
            pdfBytes
          );
      }
      }
    },
    saveByteArray(reportName, byte) {
      let blob = new Blob([byte], { type: "application/pdf" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = reportName;
      link.click();
    },
    jumpTo(id) {
      const jumpTo = document.getElementById(id);
      jumpTo.scrollIntoView();
    },
    async downloadAVV() {
      const res = await backend.getAVVSigned();
      let blob = await res.blob();
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = "AVV_eklara";
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        a.remove();
        window.URL.revokeObjectURL(url);
      }, 1000);
    },
    async decline() {
      if (this.revoke) {
        this.revokeCheckbox = false;
        this.$emit("closeDialog");
      } else {
        await this.logoutUser();
        this.$router.push({ name: "login" });
      }
    },
    async revokeAgreement() {
      const accountId = this.currentAccount._id;
      const account = await this.toggleUsageAgreement({ accountId: accountId });
      await this.logoutUser();
      this.$router.push({ name: "login" });
    },
    async confirmSignUsageAgreement() {
    if(this.usage === 'extern'){
        if(this.externName === ''){
            this.showSnackbar({message: 'Bitte füllen Sie den Namen in der Einwilligungserklärung aus.', color: 'error', timeout:'10000'});
            return;
        }
        const agreementRes = await backend.postExternalUsageAgreement({
            name: this.name,
            lastName: this.lastName,
        })
        if(agreementRes.status === 200){
             this.showSnackbar({message: 'Einwilligung zur Nutzungsordnung und Datenschutzinformation erfolgreich abgegeben', color: 'success', timeout:'5000'});
        }
        else{
            this.showSnackbar({message: 'Ein Fehler ist aufgetreten, wenden Sie versuchen Sie es erneut oder wenden sich an den Support.', color: 'error', timeout:'5000'});
        }
        this.$emit("closeDialog",this.name);
        return;
    }
      const accountId = this.currentAccount._id;
      const account = await this.toggleUsageAgreement({ accountId: accountId });
      this.$emit("closeDialog");
    },
    async scrollUsageAgreement() {
      // const cardElement =  document.getElementById('nutzungserklärung');
      // const atBottom =
      // cardElement.scrollTop + cardElement.clientHeight > cardElement.scrollHeight - 150;
      // this.isAtBottom = atBottom;
    },
  },
};
</script>

<style scoped lang="scss">
.scroll-area {
  position: relative;
  margin: auto;
  max-height: calc(70vh - 140px);
  overflow-x: hidden;
}
@media screen and (max-width: 600px) {
  .scroll-area {
    position: relative;
    margin: auto;
    max-height: 42vh;
    overflow-x: hidden;
  }
}
</style>
