var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.accountRole === 'teacher' || _vm.accountRole === 'maintainer')?_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-btn',{staticClass:"text-none",staticStyle:{"margin":"6px","width":"228px"},attrs:{"elevation":"0"},on:{"click":_vm.openCropDialog}},[_c('img',{staticClass:"icon20 mr-2",attrs:{"src":_vm.bildIcon}}),_vm._v(" "+_vm._s(_vm.usage === 'profilePicture' ? 'Profilbild hochladen' : 'Bild hochladen')+" ")])],1):_vm._e(),(_vm.accountRole === 'teacher' || _vm.accountRole === 'maintainer')?_c('div',{staticClass:"d-flex justify-center align-center"},[(_vm.activateDeleteBtn)?_c('v-btn',{staticClass:"text-none",staticStyle:{"margin":"6px","width":"228px","color":"red"},attrs:{"id":"deleteUploadedPictureBtn","elevation":"0"},on:{"click":_vm.clickDelete}},[_c('img',{staticClass:"icon20 iconToRed mr-2",attrs:{"src":_vm.papierkorbIcon}}),_vm._v(" "+_vm._s('Bild löschen')+" ")]):_vm._e()],1):_vm._e(),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.cropDialog),callback:function ($$v) {_vm.cropDialog=$$v},expression:"cropDialog"}},[_c('v-card',[_c('v-card-title',[_c('h1',[_vm._v(" "+_vm._s(_vm.usage === 'profilePicture' ? 'Profilbild zuschneiden' : 'Eigenes Bild zuschneiden')+" ")]),_c('v-spacer'),_c('v-btn',{staticStyle:{"width":"30px","height":"30px"},attrs:{"x-small":"","elevation":"0"}},[_c('img',{staticClass:"icon20",attrs:{"src":_vm.cancelIcon,"alt":"Dialog schließen"},on:{"click":_vm.closeCropDialog}})])],1),_c('v-card-text',[_c('div',{staticClass:"d-flex justify-end align-center mb-2"},[_c('v-spacer'),_c('v-btn',{staticClass:"text-none",attrs:{"elevation":"0"},on:{"click":_vm.closeCropDialog}},[_vm._v(" Abbrechen ")]),_c('v-btn',{staticClass:"text-none ml-2",attrs:{"elevation":"0","disabled":!_vm.fileSelected},on:{"click":_vm.clickCropperSaveBtn}},[_vm._v(" Hochladen ")])],1),_c('vue-anka-cropper',{attrs:{"options":{
                        aspectRatio: 1,
                        closeOnSave: true,
                        cropArea: 'box',
                        croppedHeight: _vm.croppedHeight,
                        croppedWidth: _vm.croppedWidth,
                        cropperHeight: 800,
                        dropareaMessage: 'Bild hier ablegen oder Knopf nutzen',
                        layoutBreakpoint: 900,
                        maxFileSize: 5000000,
                        selectButtonLabel: 'Bild auswählen',
                        skin: 'eklara',
                        showPreview: false
                    }},on:{"cropper-saved":_vm.processData,"cropper-file-selected":function($event){_vm.fileSelected = true}}}),_c('p',{staticClass:"pt-4 text-center red--text"},[_c('b',[_vm._v("Stellen Sie sicher, dass Sie die Nutzungsrechte am Bild besitzen. Das Bild können andere Nutzer sehen. Die Dateigröße ist auf maximal 5 MB beschränkt.")])])],1)],1)],1),_c('are-you-sure-popup',{attrs:{"show-dialog":_vm.showDeleteDialog,"content-text-prop":_vm.deleteText,"usage":'ImageUpload',"headerColor":'#343e55',"ok-btn-color":"frot"},on:{"ok":_vm.confirmDelete,"close":function($event){_vm.showDeleteDialog = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }